import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import _ from "lodash";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { FaCaretDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { matchPath, useLocation, useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import Copy from "../../assets/icons/copy(2).svg";
import IssueTerminalIcon from "../../assets/icons/Group.svg";
import axios from "../../plugins/axios";
import {
  findUnassignedTerminal,
  getRequestTerminal,
  getTerminalRequestStats,
  issueterminal,
  updateTerminalRequests,
  viewAllTerminalRequests,
} from "../../plugins/urls";
import TerminalCards from "../Cards/TerminalCards";
import NoResultFound from "../NoResultFound/NoResultFound";
import Pagination2 from "../Pagination/pagination2";
import CustomSpinner from "../Spinner/Spinner";
import Swal from "../Swal/swal";
import FilterPOSRequest from "./FilterPosRequest";
import { downloadTerminalRequest } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import RequestTerminalForMerchant from "./RequestTerminalForMerchant";

export const PosRequestContext = createContext();
const PosRequest = () => {
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const showForBusinessReport = matchPath("/business-reports", pathname);
  const [state, setState] = useState({
    loading: false,
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 20,
    totalPages: 0,
    totalRequests: 0,
    terminalList: [],
    terminalStats: {},
    users: [],
    showOverlay: false,
    targetOverlay: null,
    userId: "",
    merchantId: "",
    merchantName: "",
    deliveryAddress: "",
    requestId: "",
    merchantUserID: "",
    terminalId: "",
    terminalSerialNumber: "",
    terminalType: "",
    actualTerminalName: "",
    preferredTerminalName: "",
    terminalCost: "",
    amountPaid: "",
    amountLeft: "",
  });
  const {
    loading,
    from,
    to,
    pageNo,
    pageSize,
    totalPages,
    terminalList,
    totalRequests,
    users,
    userId,
    requestId,
    merchantUserID,
    terminalStats,
    terminalSerialNumber,
    actualTerminalName,
    preferredTerminalName,
    terminalCost,
    amountPaid,
  } = state;
  const [unassignedTerminals, setUnassignedTerminals] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [showIssueTerminal, setShowIssueTerminal] = useState(false);
  const [loadingInner, setLoading] = useState(false);
  const [activeStatusId, setActiveStatusId] = useState({
    status: null,
    id: null,
  });
  const [fieldFilterValue, setFilterFieldValue] = useState({
    field: "all",
    value: "all",
  });
  const [searchParam, setSearchParam] = useState();
  const [showRFMmodal, setShowRFMmodal] = useState(false);
  useEffect(() => {
    getTerminals(fieldFilterValue);
    getTerminalReqStats();
  }, [pageNo, pageSize, fieldFilterValue]);
  useEffect(() => {
    getUnassignedTerminalId({ terminalName: actualTerminalName });
    getUsers();
  }, [merchantUserID]);

  const startLoading = () => {
    setState((state) => ({
      ...state,
      loading: true,
    }));
  };
  const stopLoading = () => {
    setState((state) => ({
      ...state,
      loading: false,
    }));
  };
  const onChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "terminalSerialNumber") {
      setState((state) => ({ ...state, terminalSerialNumber: value }));
    }

    if (name === "merchantUserID") {
      let valueObj = users.find(({ id }) => Number(id) === Number(value));

      if (valueObj) {
        getUnassignedTerminalId({ terminalName: valueObj.terminalName });
        setState((state) => ({
          ...state,
          userId: valueObj.userId,
          requestId: valueObj.id,
          terminalCost: valueObj.amount,
          amount: valueObj.amount,
          actualTerminalName: valueObj.terminalName,
          preferredTerminalName: valueObj.terminalName,
        }));
      } else {
        toast.error("kindly select a valid user");
        setState((state) => ({
          ...state,
          userId: "",
          terminalCost: "",
          amount: "",
          actualTerminalName: "",
          preferredTerminalName: "",
        }));
      }
    }
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const showIssueModal = () => {
    if (users) {
      setShowIssueTerminal(true);
    } else {
      getUsers();
      toast.error(`Unable to fetch users. Please reload this page.`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    }
  };

  const getTerminalReqStats = () => {
    startLoading();
    axios({
      method: "get",
      url: `${getTerminalRequestStats}`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            terminalStats: res.data.respBody,
          }));
          stopLoading();
        }
        stopLoading();
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message ?? err.message
            ? err.message
            : "Unable to fetch terminal request statistics",
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        stopLoading();
      });
    stopLoading();
  };
  const getTerminals = (filterParams = { field: "all", value: "all" }) => {
    startLoading();
    let reqBody = {
      pageNo: pageNo,
      pageSize,
      ...filterParams,
    };
    axios({
      method: "post",
      url: `${viewAllTerminalRequests}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            terminalList: res.data.respBody.content,
            totalPages: res.data.respBody.totalPages,
            totalRequests: res.data.respBody.totalElements,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        stopLoading();
      });
  };
  const getUsers = () => {
    axios({
      method: "get",
      url: `${getRequestTerminal}`,
    })
      .then((res) => {
        if (res && res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            users: res.data.respBody,
          }));
        }
      })
      .catch((err) => {
        if (err.response?.data?.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err?.response?.data?.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  const checkActive = (id) => loadingInner && activeStatusId.id === id;
  const onUpdateRequest = (userId, id, status) => {
    let reqBody = {
      userId,
      requestId: id,
      requestStatus: status,
    };
    if (status) {
      if (status.toLowerCase() === "approved") {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to approve this terminal request!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((remove) => {
          setActiveStatusId({ status, id });
          setLoading(true);
          if (remove.isConfirmed) {
            axios({
              method: "post",
              url: `${updateTerminalRequests}`,
              data: reqBody,
            })
              .then((res) => {
                if (res.data.respCode === 0) {
                  toast.success(res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  getTerminals();
                } else {
                  toast.info(res.data.respBody);
                }
              })
              .catch((err) => {
                toast.error(`${err.response.data.message}`, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              })
              .finally(() => {
                setActiveStatusId({});
                setLoading(false);
              });
          }
        });
      } else if (status.toLowerCase() === "reject") {
        Swal.fire({
          title: "Are you sure?",
          text: "Are you sure you want to reject this terminal request!",
          icon: "warning",
          input: "text",
          inputPlaceholder: "Provide reason for reject",
          inputAttributes: { required: true },
          showCancelButton: true,
          confirmButtonText: "Yes",
        }).then((remove) => {
          setActiveStatusId({ status, id });
          setLoading(true);
          if (remove.isConfirmed) {
            let reqBody = {
              requestId: id,
              requestStatus: "REJECTED",
              reason: remove?.value,
              userId,
            };
            axios({
              method: "post",
              url: `${updateTerminalRequests}`,
              data: reqBody,
            })
              .then((res) => {
                if (res.data.respCode === 0) {
                  toast.success(res.data.respDesc, {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                  });
                  getTerminals();
                } else if (res.data.respCode === 96) {
                  toast.error(res.data.respBody);
                } else {
                  toast.info(res.data.respBody);
                }
              })
              .catch((err) => {
                toast.error(`${err.response.data.message}`, {
                  transition: Slide,
                  hideProgressBar: true,
                  autoClose: 3000,
                });
              })
              .finally(() => {
                setActiveStatusId({});
                setLoading(false);
              });
          }
        });
      }
    }
  };
  const onIssueTerminal = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      userId: Number(userId),
      requestId,
      terminalSerialNumber,
    };
    axios({
      method: "post",
      url: `${issueterminal}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));

          toast.success(`Terminal Issued Successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getTerminals();
        } else if (res.data.respCode === 96) {
          toast.error(
            `${res.data ? res.data.respBody : "Something  went wrong"}`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          setState((state) => ({
            ...state,
            submit: false,
          }));
        } else {
          toast.error(
            `${res.data ? res.data.message : "Something  went wrong"}`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          setState((state) => ({
            ...state,
            submit: false,
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`Fill all required field and try again`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getUnassignedTerminalId = ({ terminalName }) => {
    axios({
      method: "get",
      url: `${findUnassignedTerminal({ terminalName })}`,
    })
      .then((res) => {
        if (res.data.respCode === 96) {
          toast.error(res.data.respBody);
          setUnassignedTerminals([]);
        } else {
          const termArr = res.data.respBody;
          setUnassignedTerminals(termArr);
        }
      })
      .catch((err) => {
        console.log(err, "error");
      });
  };

  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
  };

  return (
    <>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <div className="d-flex justify-content-start align-items-center width-50">
            <div className="d-flex justify-content-center align-items-center ">
              <FilterPOSRequest
                fieldFilterValue={fieldFilterValue}
                setFilterFieldValue={setFilterFieldValue}
              />
            </div>
            <div className="d-flex justify-content-center align-items-center filter-search ml-22">
              <div className="input_Search d-flex justify-content-center align-items-center">
                <div className="justify-content-center align-items-center">
                  <FiSearch color="#FF6700" />
                </div>
                <input
                  className="input ml-10"
                  placeholder="Search with requestId"
                  onChange={(e) => setSearchParam(e.target.value)}
                />
              </div>
              <button
                className="orange-button ml-10"
                onClick={() =>
                  setFilterFieldValue({
                    field: "requestId",
                    value: searchParam,
                  })
                }
              >
                Search
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center ">
            <div className="d-flex justify-content-center align-items-center ">
              <ExportData
                name={"POSRequest"}
                url={downloadTerminalRequest}
                filename={`POS terminal request report-${new Date().toISOString()}`}
              />
            </div>
            {_.isEmpty(showForBusinessReport) && (
              <div className="d-flex justify-content-center align-items-center ">
                <button
                  className="orange-button"
                  onClick={() => setShowRFMmodal(true)}
                >
                  Request for terminal
                </button>

                <button
                  className="request-button shadow ms-3 fw-bold d-flex justify-content-between align-items-center p-1"
                  style={{
                    backgroundColor: "#F9843526",
                    border: "2px solid #FF6700",
                    color: "#FF6700",
                    fontSize: "10px",
                  }}
                  onClick={showIssueModal}
                >
                  <img
                    src={IssueTerminalIcon}
                    alt=""
                    style={{ color: "#FF6700" }}
                    className="me-2"
                  />
                  ISSUE NEW TERMINAL
                </button>
                <Modal
                  show={showIssueTerminal}
                  onHide={() => setShowIssueTerminal(false)}
                  backdrop="static"
                  keyboard={false}
                  size="md"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header className="border-bottom-0 p-4 shadow rounded border-light">
                    <Modal.Title className=" m-auto fs-18">
                      Issue a new POS Terminal
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="fs-14  shadow">
                    <p className="text-center text-dark fw-500 w-75 m-auto mb-3">
                      Kindly complete the below form to issue a new POS terminal
                      device
                    </p>
                    <Form>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Select User</Form.Label>
                        <Form.Select
                          name="merchantUserID"
                          required
                          onChange={onChange}
                        >
                          <option>Select User</option>
                          {users
                            ? users?.map((user, i) => {
                                return (
                                  <option value={user.id} key={i}>
                                    {
                                      /* {user.firstname + " " + user.surname} */
                                      `${user?.merchantName}`
                                    }
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Serial Number
                        </Form.Label>

                        <div className="input-container">
                          {unassignedTerminals && (
                            <select
                              className="input select"
                              type="text"
                              name="terminalSerialNumber"
                              required
                              onChange={onChange}
                            >
                              <option>Assign to terminal</option>
                              {unassignedTerminals?.map((term, i) => {
                                return (
                                  <option
                                    value={term.terminalSerialNumber}
                                    key={i}
                                  >
                                    {`${term.terminalSerialNumber} (${term.actualTerminalName})`}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                        </div>
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Terminal Cost
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="terminalCost"
                          value={terminalCost}
                          // onChange={onChange}
                          className="fs-12 p-2"
                          // placeholder="NGN 50,000.00"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">Amount Paid</Form.Label>
                        <Form.Control
                          type="text"
                          name="amountPaid"
                          value={amountPaid}
                          // onChange={onChange}
                          className="fs-12 p-2"
                          // placeholder="NGN 50,000.00"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Actual Terminal Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="actualTerminalName"
                          value={actualTerminalName}
                          // onChange={onChange}
                          className="fs-12 p-2"
                          required
                          // placeholder="Nexgo"
                          disabled
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="fw-bold">
                          Preferred Terminal Name
                        </Form.Label>
                        <Form.Control
                          className="fs-12 p-2"
                          name="preferredTerminalName"
                          value={preferredTerminalName}
                          type="text"
                          placeholder="Enter preferred terminal name [Optional]"
                        />
                      </Form.Group>

                      <div className="d-flex justify-content-between align-items-center w-100 px-2">
                        <Button
                          onClick={() => setShowIssueTerminal(false)}
                          className="my-4 btn-light border-0 fs-14 fw-500 shadow text-dark px-5"
                        >
                          Cancel
                        </Button>

                        <Button
                          className="my-4 border-0 fs-14 fw-500"
                          style={{
                            backgroundColor: "rgb(255, 68, 0)",
                            color: "#fff",
                            paddingInline: "1em",
                          }}
                          disabled={!Boolean(terminalSerialNumber)}
                          onClick={onIssueTerminal}
                        >
                          Issue Terminal
                        </Button>
                      </div>
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            )}
          </div>
        </div>
      </div>

      <Container fluid>
        <Row className="mt-40 ">
          <Col>
            <TerminalCards
              title="Total Terminals Approved"
              value={terminalStats?.approvedRequest ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminals Rejected"
              value={terminalStats?.rejectedRequest ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminals Pending"
              value={terminalStats?.pendingRequest ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminals Requested"
              value={terminalStats?.totalRequest ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
        </Row>

        <div className="data-table mt-40">
          {loading ? (
            <CustomSpinner />
          ) : (
            <Table responsive borderless className="bg-inherit">
              <thead className="text-nowrap">
                <tr
                  style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                  className="t-head-colored"
                >
                  <th>MERCHANT ID</th>
                  <th>REQUEST ID</th>
                  <th className="px-4 py-2 th">REQUESTED BY</th>
                  <th className="px-4 py-2 th">REQUESTED for</th>
                  <th className="px-4 py-2 th">terminal Name</th>
                  <th className="px-4 py-2 th">amount</th>
                  {/* <th className="px-4 py-2 th">amount left</th> */}
                  <th className="px-4 py-2 th">status</th>
                  <th className="px-4 py-2 th">request date</th>
                  <th className="px-4 py-2 th">action</th>
                </tr>
              </thead>

              <tbody className="text-nowrap">
                {terminalList && terminalList.length > 0 ? (
                  terminalList.map((terminal, i) => {
                    const {
                      userId,
                      requestedBy,
                      amount,
                      merchantName,
                      status,
                      dateCreated,
                      terminalName,
                      id,
                    } = terminal || {};
                    const statusClass = () => {
                      if (status) {
                        if (status.toLowerCase() === "approved") {
                          return "tabactive";
                        } else if (status.toLowerCase() === "pending") {
                          return "tabpending";
                        } else if (status.toLowerCase() === "issued") {
                          return "tabissued";
                        }
                        // else if (status.toLowerCase() === "abandoned") {
                        //   return "tabdamaged";
                        // }
                        else {
                          return "tabdanger";
                        }
                      }
                    };

                    return (
                      <tr key={i}>
                        <td className="px-4 td">
                          {userId} <img src={Copy} alt="" className="ms-5" />
                        </td>
                        <td className="px-4 td">
                          {id} <img src={Copy} alt="" className="ms-5" />
                        </td>
                        <td className="px-4 td">{requestedBy}</td>
                        <td className="px-4 td">{merchantName ?? "N/A"}</td>
                        <td className="px-4 td">{terminalName}</td>
                        <td className="px-4 td">{amount}</td>
                        {/* <td className="px-4 td">{partPaymentAmount}</td> */}
                        <td className="px-4 td">
                          <span className={`${statusClass()}`}>{status}</span>
                        </td>
                        <td className="px-4 td">
                          {dateCreated
                            ? moment(new Date(dateCreated)).format("D/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td className="px-4 td">
                          <Button
                            variant="outline-success"
                            size="sm"
                            active={
                              checkActive(id) &&
                              activeStatusId.status === "APPROVED"
                            }
                            onClick={() => {
                              onUpdateRequest(userId, id, "APPROVED");
                            }}
                          >
                            {loadingInner &&
                            checkActive(id) &&
                            activeStatusId.status === "APPROVED" ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <MdCheckCircle size={25} />
                            )}
                          </Button>{" "}
                          <Button
                            variant="outline-danger"
                            size="sm"
                            onClick={() => {
                              onUpdateRequest(userId, id, "REJECT");
                            }}
                          >
                            {loadingInner &&
                            checkActive(id) &&
                            activeStatusId.status === "REJECT" ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />
                            ) : (
                              <MdCancel size={25} />
                            )}
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </Table>
          )}
        </div>
        <div>
          {loading ? (
            <CustomSpinner />
          ) : terminalList && terminalList.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalRequests}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
        </div>
        <RequestTerminalForMerchant
          show={showRFMmodal}
          setShowFn={setShowRFMmodal}
        />
      </Container>
    </>
  );
};

export default PosRequest;
