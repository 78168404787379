import React, {useState, useEffect} from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Wrapper from './style';


export default function PinModal(props) {
  const {
    showModal,
    hideModal,
    otp,
    setOtp  ,
    handleSubmit,
    setOTP,
    OTP
  } = props;
  const [locationKeys, setLocationKeys] = useState([]);

 // const history = useHistory();

  
  return (
    <Modal
      id='pin-modal'
      visible={showModal}
      closable={false}
      footer={null}
      centered
      onCancel={() => {
        hideModal(false);
      }}
      

    >
        <Wrapper>
            

      <div className='modal-body-rs p-2 pb-3'>

        <div className='waya-modal-body mt-3 center'>
          <h4 style={{display:"flex",marginLeft:"40%", textAlign: 'centre'}} className='modal-header-sub-data mb-0 center'>{'2FA'}</h4>

          <p style={{display:"flex",marginLeft:"20%", textAlign: 'centre'}} className='modal-header-sub-data text-secondary'>Please enter your OTP to continue</p>
          <OtpInput
            value={OTP}
            onChange={(e) => setOTP(e)}
            numInputs={6}
            isInputSecure
            containerStyle='otp-cont'
            inputStyle='otp-input'

          />

          <div className='w-100 mt-5 text-center'>
            <button
              type='button'
              className='btn btn-primary btn-lg w-50'
             
              onClick={handleSubmit}
              content='Submit'
            >Submit</button>
          </div>
          {/*<div className={!isResendOtp ? 'd-none' : 'mt-3 text-center'}>
            <a
              className={!isResendOtp ? 'd-none' : 'text-dark decoration-none'}
              onClick={(e) => {
                e.preventDefault();
                // hideModal(false);
                resendOtp(true);
              }}
              href='/#'
            >
              Resend Code
            </a>
            </div>
          <div className={!isResendPin ? 'd-none' : 'mt-5 mb-3 text-center'}>
            <a
              href='/#'
              className={!isResendPin ? 'd-none' : 'text-dark decoration-none'}
              onClick={(e) => {
                e.preventDefault();
                setModalAction('resetPin');
                hideModal(false);
                setShowResendOtp(true);
                // getResetPinOtp(profile.email);
                // setShowResetPin(true);
                // hideModal(false);
              }}
            >
              Reset Pin
            </a>
          </div>*/}
        </div>
      </div>
      </Wrapper>
    </Modal>
  );
}

/*
PinModal.defaultProps = {
  separator: <span>-</span>,
  title: '',
  description: '',
  buttonLabel: 'Login',
  numInputs: 4,
  isResendOtp: false,
};

/*PinModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  // center: PropTypes.bool.isRequired,
  otp: PropTypes.string.isRequired,
  setOtp: PropTypes.func.isRequired,
  separator: PropTypes.node,
  buttonLabel: PropTypes.string,
  isResendOtp: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  numInputs: PropTypes.number,
  resendOtp: PropTypes.func.isRequired,
};
*/