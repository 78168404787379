import React from "react";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import "./modal.scss";
import { Modal, ModalBody, ModalFooter } from "react-bootstrap";

export const CustomModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.clicked}
      keyboard={false}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="px-4 border-bottom-0">
        <Modal.Title className="ms-auto fs-18 my-2">{props.title}</Modal.Title>
      </Modal.Header>
      <ModalBody>{props.children}</ModalBody>
      <ModalFooter
        className={`border-top-0 d-flex justify-content-center mb-4`}
      >
        {props.close ? (
          <button
            type="button"
            className="button-white me-5"
            style={{ color: "#FF6700" }}
            onClick={props.clicked}
          >
            {props.close}
          </button>
        ) : null}
        <button
          type="submit"
          className={`${props.extraPadding} orange-button fs-14`}
          onClick={props.submit}
          disabled={props.disabled}
        >
          {props.loading === true ? <SubmitLoader /> : props.action}
        </button>
      </ModalFooter>
    </Modal>
  );
};
