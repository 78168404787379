import axios from "axios";
import { Navigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { terminalTypes } from "../../plugins/urls";

export const getTerminalTypes = ({ setState }) => {
  axios({
    method: "get",
    url: `${terminalTypes}`,
  })
    .then((res) => {
      if (res.data.respCode === 0) {
        setState((state) => ({
          ...state,
          terminalTypesList: res.data.respBody,
        }));
      }
    })
    .catch((err) => {
      if (err.response.data.status === 401) {
        toast.error(`${err.response.data.error}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        Navigate({ to: "/login" });
        return;
      }
    });
};
