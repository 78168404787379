import axios from "../plugins/axios";
import {Slide, toast} from "react-toastify";

// const user = JSON.parse(localStorage.getItem('userDetails'))?.user;

export const apiCall = ({ data, url, method, setLoading, success, error, multipart, meta  })=>{
    setLoading && setLoading(true);
    axios({
        method,
        url,
        data,
        // header: {
        //     ContentType: multipart ? 'multipart/form-data' : 'application/json'
        // }
    }).then(res=>{
        setLoading && setLoading(false);

        if(res.data.status === true || (res.status > 199 && res.status < 227)){
            success && success(res.data[meta?.dataIndex] || res.data)
        }else{
            error && error(res.data[meta?.dataIndex] || res.data);
            toast.error(`${res.data[meta?.messageIndex] || res?.data?.message}`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
            });
        }

    }).catch(err=>{
        error && error(err.response[meta?.messageIndex] || err?.response?.data);
       setLoading && setLoading(false);
        toast.error(`${err?.response?.data?.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
        });
    })
};

export const downloadExcel = ({ url, method = 'post', data, fileName, resIndex, resIndex2 }) =>axios({
    method,
    url,
    data
})
    .then((response) => {
        const filename = response.headers['content-disposition'];
        return response;
    })
    .then((blob) => {
        // Create blob link to download
        let url = null;
        if (resIndex2){

            if (blob.data[resIndex][resIndex2]?.length > 0) {
                url =  window.URL.createObjectURL(
                    new Blob([blob.data[resIndex][resIndex2]])
                );
            } else {
                toast.error('Cannot export empty data', {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            }

        } else {
            if (blob.data[resIndex]?.length > 0) {
                url =  window.URL.createObjectURL(
                    new Blob([blob.data[resIndex]])
                );
            } else {
                toast.error('Cannot export empty data', {
                    transition: Slide,
                    hideProgressBar: true,
                    autoClose: 3000,
                });
            }
        }


        const link = document.createElement('a');
        link.href = url;

        link.setAttribute(
            'download',
            `${fileName}_${new Date()}.xls`
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        url !== null
        && link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
    });
