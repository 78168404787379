import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { MdFileDownload } from "react-icons/md";
import { Link, useLocation, useMatch } from "react-router-dom";
import { Slide, toast } from "react-toastify";
import responseIcon from "../../assets/icons/cob.png";
import axios from "../../plugins/axios";
import {
  acceptMerchantDispute,
  allWayaDisputes,
  rejectMerchantDispute,
  uploadFile,
} from "../../plugins/urls";
import Layout from "../Layout/Layout";
import { DisputeResponseModal } from "../Modal/DisputeResponseModal";
import RejectResponseModal from "./RejectResponseModal";

export default function DisputeDetails() {
  const match = useMatch("/disputes/details/:id");
  const id = match ? match.params.id : "";

  const { state } = useLocation();

  const {
    adminStatus,
    customer,
    transactionDate,
    dateCreated,
    disputeType,
    transactionReference,
    amount,
    reason,
    merchantID,
    dueWhen,
    disputeResolution,
    comment,
    merchantName,
    merchantEmail,
  } = state;

  //just for demo
  const [image, setImage] = useState(null);

  const [submit, setSubmit] = useState(false);
  const [acceptDispute, setAcceptDispute] = useState(false);
  const [rejectDispute, setRejectDispute] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileName, setFileName] = useState("");
  const [commentText, setCommentText] = useState("");
  const [filePath, setFilePath] = useState("");

  const onChangeFileName = (e) => {
    setFileName(e.target.value);
  };

  const onChangeComment = (e) => {
    setCommentText(e.target.value);
  };

  const showAcceptModal = () => {
    if (!acceptDispute) {
      setAcceptDispute(true);
    } else {
      setAcceptDispute(false);
    }
  };

  const showRejectModal = () => {
    if (!rejectDispute) {
      setRejectDispute(true);
    } else {
      setRejectDispute(false);
    }
  };

  const getAllDisputes = () => {
    let reqBody = {
      id: 0,
      pageNo: 1,
      pageSize: 20,
    };

    axios({
      method: "post",
      url: `${allWayaDisputes}`,
      data: reqBody,
    })
      .then((res) => {
        // console.log(res);
        return res;
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.error(`${err.response.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };

  const handleAcceptResponse = () => {
    setSubmit(true);

    axios({
      method: "put",
      url: `${acceptMerchantDispute}?disputeId=${id}&merchantId=${merchantID}`,
    })
      .then((res) => {
        if (res.data) {
          toast.success(`Dispute Accepted Successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllDisputes();
          setAcceptDispute(false);
        } else {
          setSubmit(false);
          toast.error(`An error occured while trying to processs request`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setAcceptDispute(false);
        }
      })
      .catch((err) => {
        setSubmit(false);
        toast.error(`${err}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setAcceptDispute(false);
      });
  };

  const handleRejectResponse = () => {
    setSubmit(true);

    let reqBody = {
      amount: amount ? amount : "",
      comment: commentText ? commentText : "",
      disputeId: id ? Number(id) : 0,
      fileName: fileName ? fileName : "",
      filePath: filePath ? filePath : "",
      merchantId: merchantID ? merchantID : "",
    };

    axios({
      method: "put",
      url: `${rejectMerchantDispute}`,
      data: reqBody,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.respDesc === "Successful") {
          setSubmit(false);
          toast.success(`${res.data.respDesc}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllDisputes();
          setRejectDispute(false);
        } else {
          setSubmit(false);
          toast.error(`${res.data.respDesc}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setRejectDispute(false);
        }
      })
      .catch((err) => {
        setSubmit(false);
        toast.error(`${err.response.data.respDesc}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setRejectDispute(false);
      });
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    if (selectedFile !== undefined) {
      const formData = new FormData();
      formData.append("file", selectedFile, selectedFile.name);

      axios({
        method: "post",
        url: `${uploadFile}?fileName=${fileName}&userId=${id}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.data.status === true) {
            toast.success(`${res.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setFilePath(res.data.data);
          } else {
            toast.error(`${res.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            toast.error(`${err.response.data.error}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          } else {
            toast.error(`${err.response.data.error}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        });
    }
  };

  const fileUploadHandler = (event) => {
    const { target } = event;
    if (target.value.length > 0) {
      setSelectedFile(target.files[0]);
      setIsFilePicked(true);
    } else {
      target.reset();
      setIsFilePicked(false);
      toast.error("Please select a file");
      console.log("Please select a file");
    }
  };

  return (
    <Layout>
      <DisputeResponseModal
        show={acceptDispute}
        clicked={showAcceptModal}
        submit={handleAcceptResponse}
        loading={submit}
        icon={responseIcon}
        action="Yes, Accept"
        size="md"
        close="Cancel"
        subtitle="Are you sure you want to accept this response?"
      >
        <div className="d-flex justify-content-center align-items-center text-center p-2">
          <p>
            By accepting this response, you agree that the merchant have{" "}
            <span className="text-success fw-500">WON</span> this dispute
            transaction and refund to customer will be canceled. <br /> OR{" "}
            <br /> If merchant have already been charged, payment will be
            refunded to the merchant.
          </p>
        </div>
      </DisputeResponseModal>
      <DisputeResponseModal
        show={rejectDispute}
        clicked={showRejectModal}
        submit={handleRejectResponse}
        loading={submit}
        icon={responseIcon}
        action="Yes, reject"
        size="md"
        close="Cancel"
        subtitle="Are you sure you want to reject this response?"
      >
        <RejectResponseModal
          fileUploadHandler={fileUploadHandler}
          selectedFile={selectedFile}
          isFilePicked={isFilePicked}
          commentText={commentText}
          fileName={fileName}
          filePath={filePath}
          onChangeFileName={onChangeFileName}
          handleFileUpload={handleFileUpload}
          onChangeComment={onChangeComment}
        />
      </DisputeResponseModal>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <Link to="/disputes" className="text-decoration-none">
            <h4 className="text-darker fs-12 fw-700 cursor-pointer">
              <span className="me-1">
                <BiArrowBack size={20} color="#000" />
              </span>
              Back
            </h4>
          </Link>
        </div>
      </div>

      <Container>
        <Row>
          <Col>
            <div
              className="shadow"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 d-flex justify-content-between align-items-center p-2"
                style={{
                  background: "#E9E9E9",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p className="text-dark fw-500 fs-14">Dispute Details</p>
                  <p
                    style={{
                      background: "#FF6700",
                      width: "2.5em",
                      marginTop: "-1em",
                      padding: "2px",
                    }}
                  ></p>
                </div>
                <p className="text-grey d-flex justify-content-between align-items-center fs-12">
                  Status
                  {adminStatus === "OPEN" ? (
                    <span
                      className="ms-2 py-2 px-4 fw-bold bg-white rounded"
                      style={{ color: "#FF6700" }}
                    >
                      Open
                    </span>
                  ) : adminStatus === "CLOSE" || adminStatus === "CLOSED" ? (
                    <span
                      className="ms-2 py-2 px-4 fw-bold bg-white rounded"
                      style={{ color: "#27AE60" }}
                    >
                      Closed
                    </span>
                  ) : adminStatus === "INITIATED" ? (
                    <span
                      className="ms-2 py-2 px-4 fw-bold bg-white rounded"
                      style={{ color: "#828282" }}
                    >
                      Initiated
                    </span>
                  ) : (
                    <span style={{ color: "#828282" }} className="fw-bold">
                      {adminStatus ? adminStatus : "NIL"}
                    </span>
                  )}
                </p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Customer Name</p>
                  <p className="fs-12 fw-bold">{customer ? customer : "N/A"}</p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Transaction Date</p>
                  <p className="fs-12 fw-bold">
                    {transactionDate
                      ? moment(new Date(transactionDate)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Dispute Initiation Date</p>
                  <p className="fs-12 fw-bold">
                    {dateCreated
                      ? moment(new Date(dateCreated)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Dispute Type</p>
                  <p className="fs-12 fw-bold">
                    {disputeType ? disputeType : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Reference ID</p>
                  <p className="fs-12 fw-bold">
                    {transactionReference ? transactionReference : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Transaction Amount</p>
                  <p className="fs-12 fw-bold">
                    {amount ? `NGN${amount.toLocaleString()}` : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mt-40 shadow"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#E9E9E9",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">
                  Reason for dispute in details
                </p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3 fs-12 fw-bold">
                <p>{reason ? reason : "N/A"}</p>
              </div>
            </div>
          </Col>
          <Col>
            <div
              className="shadow"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">Merchant Details</p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Name</p>
                  <p className="fs-12 fw-bold text-capitalize">
                    {merchantName ? merchantName : "N/A"}
                    {""}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Email Address</p>
                  <p className="fs-12 fw-bold text-capitalize">
                    {merchantEmail ? merchantEmail : "N/A"}
                    {""}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Merchant ID</p>
                  <p className="fs-12 fw-bold">
                    {merchantID ? merchantID : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mt-40 shadow"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">Merchant Response</p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12" style={{ color: "#5D5D5D" }}>
                    Response State
                  </p>
                  <p className="fs-12">
                    {disputeResolution === "declined" ? (
                      <span className="text-danger fw-bold">Rejected</span>
                    ) : disputeResolution === "accepted" ? (
                      <span className="text-success fw-bold">Accepted</span>
                    ) : (
                      "..."
                    )}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12" style={{ color: "#5D5D5D" }}>
                    Response Due Date
                  </p>
                  <p className="fs-12 fw-bold">
                    {dueWhen
                      ? moment(new Date(dueWhen)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "..."}
                  </p>
                </div>
                <div className="mt-10 fs-14">
                  <label className="py-1 fs-12">
                    Reason for rejecting Chargeback
                  </label>
                  <textarea
                    name="merchantReason"
                    id="merchantReason"
                    placeholder="Merchant reason for rejecting dispute"
                    className="fw-bold fs-12 p-2"
                    cols="75"
                    rows="5"
                    defaultValue={comment ? comment : "No comment"}
                    readOnly
                  ></textarea>
                </div>
                <div
                  className="input-div rounded p-1"
                  style={{ border: "1.5px dashed #333" }}
                >
                  <div className="d-flex w-100">
                    <div className="custom-file-input-wrapper d-flex align-items-center m-auto w-25">
                      {/* {image === null ("render N/A") : ("render uploaded image")} */}
                      {image ? (
                        <span className="imageName">
                          <img
                            style={{ width: "15rem", height: "10rem" }}
                            src={image ? URL.createObjectURL(image) : null}
                            alt={image ? image.name : null}
                          />
                        </span>
                      ) : (
                        <label
                          className="pointer fs-12 px-2 py-5 text-center m-auto"
                          htmlFor="imageInput1"
                        >
                          No image uploaded
                          <br />
                          <input
                            id="imageInput1"
                            className="custom-file-input"
                            type="file"
                            // onChange={imageHandler}
                            // accept
                          />
                          <br />
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center cursor-pointer">
                  <div style={{ color: "#4A4AFF" }}>
                    <span className="action-purple">
                      <MdFileDownload size={15} />
                    </span>
                  </div>
                  <span
                    className="fs-12 mt-2 ms-2 cursor-pointer"
                    style={{ color: "#4A4AFF" }}
                  >
                    Download Document
                  </span>
                </div>
                <div className="mt-20 p-3 d-flex justify-content-center align-items-center">
                  <div>
                    <button
                      className="button-white me-2 fw-500 px-3 py-2 dispute-btns fs-12"
                      onClick={showAcceptModal}
                    >
                      Accept Response
                    </button>
                    <button
                      className="orange-button ms-2 fw-500 px-3 py-2 dispute-btns fs-12"
                      onClick={showRejectModal}
                    >
                      Reject Response
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
