import { Autocomplete, TextField } from "@mui/material";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaPlus } from "react-icons/fa";
import { IoFilterOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import Swal from "sweetalert2";
import axios from "../../plugins/axios";
import {
  createterminalType,
  getTerminalsStats,
  terminalTypes,
  terminalTypeUrl,
  updateTerminalTypeUrl,
} from "../../plugins/urls";
import { getBase64 } from "../../plugins/utils";
import TerminalCards from "../Cards/TerminalCards";
import NoResultFound from "../NoResultFound/NoResultFound";
import CustomSpinner from "../Spinner/Spinner";

const PosTypes = () => {
  //   const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();

  const [state, setState] = useState({
    loading: false,
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 20,
    totalPages: 0,
    terminalList: [],
    terminalStats: {},
    totalTerminalTypes: 0,
    terminalName: "",
    amount: "",
    quantity: 0,
    images: [],
    totalQuantityToAdd: 0,
  });
  const {
    loading,
    pageNo,
    pageSize,
    terminalList,
    terminalStats,
    images,
    totalQuantityToAdd,
  } = state;

  const [currentPage, setCurrentPage] = useState(1);
  const [createTerminalTypeModal, setCreateTerminalTypeModal] = useState(false);
  const [updateTerminalTypeModal, setUpdateTerminalTypeModal] = useState(false);
  const [innerloading, setInnerloading] = useState(false);
  const [updateData, setUpdateData] = useState({
    terminalCategory: "",
    terminalName: "",
    amount: 0,
    quantity: 0,
  });

  const startLoading = () => {
    setState((state) => ({
      ...state,
      loading: true,
    }));
  };
  const stopLoading = () => {
    setState((state) => ({
      ...state,
      loading: false,
    }));
  };
  const onShowUpdateTerminalType = (terminalTypeData) => {
    console.log(terminalTypeData, "td");
    setUpdateData(terminalTypeData);
    setUpdateTerminalTypeModal(true);
  };

  const onDeleteTerminalType = ({ id }) => {
    Swal.fire({
      icon: "question",
      title: "Do you want to delete this terminal type?",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        axios.put(`${terminalTypeUrl}/delete/${id}`).then((res) => {
          if (res.data.respCode === 0 && res.status === 200) {
            toast.success(`${res.data.respDesc}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        });
      }
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((s) => ({ ...s, [name]: value }));
  };

  const updateTerminal = () => {
    setInnerloading(true);
    const { deleted, ...reqBody } = updateData;
    axios
      .put(`${terminalTypeUrl}/update`, reqBody)
      .then((res) => {
        if (res.data.respCode === 0 && res.status === 200) {
          toast.success(`${res.data.respDesc}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .finally((res) => {
        setInnerloading(false);
      });
  };

  useEffect(() => {
    if (isEmpty(terminalStats)) {
      getTerminalTypeStats();
    }
    getTerminalTypes();
  }, []);

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const imageHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      let uploadedimage = e.target.files[0];
      // formData.append("image", uploadedimage);
      getBase64(uploadedimage)
        .then((result) => {
          uploadedimage["base64"] = result;
          setState((state) => ({
            ...state,
            image: uploadedimage.base64,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };

  const getTerminalTypeStats = () => {
    startLoading();
    axios({
      method: "get",
      url: `${getTerminalsStats}`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            terminalStats: res?.data?.respBody,
          }));
          stopLoading();
        }
        stopLoading();
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message ?? err.message
              ? err.message
              : "Unable to fetch terminal statistics"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        stopLoading();
      });
    stopLoading();
  };

  const getTerminalTypes = () => {
    axios({
      method: "get",
      url: `${terminalTypes}`,
    }).then((res) => {
      if (res.data.respCode === 0) {
        console.log(res.data.respBody);
        setState((s) => ({ ...s, terminalList: res?.data?.respBody }));
        /*  setState((s) => ({
          ...s,
          terminalList: res?.data?.respBody,
        })); */
      }
    });
    /*  .catch((err) => {
        if (err.response.data.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }); */
  };

  const createNewTerminalType = () => {
    const { amount, quantity, terminalCategory, terminalName } = state;
    const reqBody = {
      amount,
      quantity: parseInt(quantity),
      terminalCategory,
      terminalName,
    };
    setInnerloading(true);
    axios
      .post(`${createterminalType}`, reqBody)
      .then((res) => {
        toast.success(`${res.data.respDesc}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .catch((err) =>
        toast.error(
          `${
            err?.response?.data?.message ?? err.message
              ? err.message
              : "Unable to create terminal type"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        )
      )
      .finally(() => {
        setInnerloading(false);
      });
  };
  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    // getTerminals(selected);
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };
  return (
    <>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <div className="d-flex justify-content-start align-items-center width-50">
            <div className="d-flex justify-content-center align-items-center ">
              {/* <FilterPOSRequest /> */}
              <button className=" d-flex align-items-center border-0 bg-transparent px-2 ">
                <IoFilterOutline size={15} />
                <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center ">
            <button
              className="request-button fs-13 fw-700 border-none"
              onClick={() => setCreateTerminalTypeModal(true)}
            >
              CREATE TERMINAL TYPE
            </button>
          </div>
        </div>
      </div>

      <Container fluid>
        <Row className="mt-40 ">
          <Col>
            <TerminalCards
              title="Total Terminal Types"
              value={terminalStats?.totalTerminalTypes ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminal Quantity"
              value={terminalStats?.totalTerminals ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminal Quantity Left"
              value={terminalStats?.terminalsLeft ?? 0}
              color="text-darker fs-12"
              textColor="text-darker"
            />
          </Col>
        </Row>

        <div className="data-table mt-40">
          {loading ? (
            <CustomSpinner />
          ) : (
            <Table responsive borderless className="bg-inherit">
              <thead className="text-nowrap">
                <tr
                  style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                  className="t-head-colored"
                >
                  <th className="px-4 py-2 th">TERMINAL TYPE NAME</th>
                  <th className="px-4 py-2 th">TERMINAL CATEGORY</th>
                  <th className="px-4 py-2 th">TERMINAL COST</th>
                  <th className="px-4 py-2 th">TERMINAL QUANTITY</th>
                  <th className="px-4 py-2 th">TOTAL QUANTITY LEFT</th>
                  <th className="px-4 py-2 th">LAST UPDATED</th>
                  <th className="px-4 py-2 th">ACTIONS</th>
                </tr>
              </thead>

              <tbody className="text-nowrap">
                {!isEmpty(terminalList) ? (
                  terminalList.map((terminal, i) => {
                    const {
                      id,
                      terminalName,
                      terminalCategory,
                      amount,
                      quantity,
                      totalQuantityLeft,
                      lastUpdated,
                    } = terminal;

                    return (
                      <tr key={i}>
                        <td className="px-4 td">{terminalName ?? "N/A"}</td>
                        <td className="px-4 td">{terminalCategory ?? "N/A"}</td>
                        <td className="px-4 td">{amount ?? "N/A"}</td>
                        <td className="px-4 td">{quantity ?? "N/A"}</td>
                        <td className="px-4 td">
                          {totalQuantityLeft ?? "N/A"}
                        </td>
                        <td className="px-4 td">{lastUpdated ?? "N/A"}</td>
                        <td className="px-4 td">
                          <div className="dropdown ms-2">
                            <i
                              className=" dropdown"
                              id="terminal-config"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <BsThreeDotsVertical size={12} />
                            </i>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <span
                                className="dropdown-item fs-12 "
                                onClick={() =>
                                  onShowUpdateTerminalType(terminal)
                                }
                              >
                                Update terminal type
                              </span>
                              <span
                                className="dropdown-item fs-12 "
                                onClick={() => onDeleteTerminalType({ id })}
                              >
                                Delete terminal type
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </Table>
          )}
        </div>
        <>
          {/*  <div>
          {terminalList && terminalList.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalTerminalTypes}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
        </div> */}
        </>
        <Modal
          show={createTerminalTypeModal}
          onHide={() => setCreateTerminalTypeModal(false)}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex align-items-center justify-content-end me-3">
            <i
              className="text-grey cursor-pointer fs-16 p-1"
              onClick={() => setCreateTerminalTypeModal(false)}
            >
              <AiFillCloseCircle size={18} color="grey" />
            </i>
          </div>
          <Modal.Header className="border-bottom-0 p-3 ">
            <Modal.Title className=" m-auto fs-18">
              Create new terminal type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="fs-14  shadow">
            <p className="text-center text-dark fw-500 w-75 m-auto mb-3">
              You currently have {terminalList.length} terminal types, creating
              new one will add to it.
            </p>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Type Name</Form.Label>
                <Form.Control
                  className="fs-12 p-2"
                  type="text"
                  name="terminalName"
                  onChange={onChange}
                  required
                  placeholder="Enter terminal type name"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Category</Form.Label>
                <Form.Control
                  className="fs-12 p-2"
                  type="text"
                  name="terminalCategory"
                  onChange={onChange}
                  required
                  placeholder="Enter terminal type category"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Cost</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  onChange={onChange}
                  className="fs-12 p-2"
                  placeholder="Enter terminal Cost"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  onChange={onChange}
                  className="fs-12 p-2"
                  required
                  placeholder="Enter Terminal Quantity"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="input-div">
                  <label className="text-darker fs-14 fw-600">
                    Upload Terminal Images
                  </label>
                  <p className="fs-12 text-muted">
                    This image will show when the page is requesting for a
                    terminal. We recommend a 148 x 107 pixel JPG or PNG, under 1
                    MB in size.
                  </p>
                  <div className="d-flex w-100">
                    <div className="custom-file-input-wrapper d-flex align-items-center me-1">
                      {images ? (
                        images?.map((image, i) => (
                          <label
                            className="pointer fs-12 p-1"
                            htmlFor="imageInput1"
                          >
                            <span className="imageName">
                              <img
                                style={{ width: "8.0rem", height: "5.0rem" }}
                                src={image ? image : null}
                                alt={image ? "pos image" : null}
                              />
                              <input
                                id="imageInput1"
                                className="custom-file-input"
                                type="file"
                                onChange={imageHandler}
                                accept
                              />
                            </span>
                          </label>
                        ))
                      ) : (
                        <label
                          className="pointer fs-12 p-4"
                          htmlFor="imageInput1"
                        >
                          <i className="pe-1 mb-4">
                            <FaPlus />
                          </i>
                          Upload Image 1 <br />
                          <input
                            id="imageInput1"
                            className="custom-file-input"
                            type="file"
                            onChange={imageHandler}
                            accept
                          />
                          <br />
                        </label>
                      )}
                      {/* {image ? (
                <label className="pointer fs-12 p-1"  htmlFor="imageInput1">
                <span className="imageName">
                  <img
                    style={{ width: "8.0rem", height: "5.0rem" }}
                    src={image ? image : null}
                    alt={image ? "pos image" : null}
                  />
                  <input
                    id="imageInput1"
                    className="custom-file-input"
                    type="file"
                    onChange={imageHandler}
                    accept
                  />
                </span>
                </label>
              ) : (
                <label className="pointer fs-12 p-4"  htmlFor="imageInput1">
                  <i className="pe-1 mb-4">
                    <FaPlus />
                  </i>
                  Upload Image 1 <br />
                  <input
                    id="imageInput1"
                    className="custom-file-input"
                    type="file"
                    onChange={imageHandler}
                    accept
                  />
                  <br />
                </label>
              )} */}
                    </div>
                  </div>
                </div>
              </Form.Group>

              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <Button
                  className="my-4 border-0 fs-14 fw-500 w-100 text-center"
                  style={{
                    backgroundColor: "rgb(255, 68, 0)",
                    color: "#fff",
                    paddingInline: "1em",
                  }}
                  onClick={createNewTerminalType}
                >
                  {innerloading ? <Spinner /> : "Create Terminal Type"}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
        <Modal
          show={updateTerminalTypeModal}
          onHide={() => setUpdateTerminalTypeModal(false)}
          backdrop="static"
          keyboard={false}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="d-flex align-items-center justify-content-end me-3">
            <i
              className="text-grey cursor-pointer fs-16 p-1"
              onClick={() => setUpdateTerminalTypeModal(false)}
            >
              <AiFillCloseCircle size={18} color="grey" />
            </i>
          </div>
          <Modal.Header className="border-bottom-0 p-4 shadow rounded border-light">
            <Modal.Title className=" m-auto fs-18">
              Update terminal type
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="fs-14  shadow">
            <Form>
              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Type Name</Form.Label>
                <Form.Control
                  className="fs-12 p-2"
                  type="text"
                  name="terminalName"
                  defaultValue={updateData.terminalName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Category</Form.Label>
                <Form.Control
                  type="text"
                  name="terminalCategory"
                  defaultValue={updateData.terminalCategory}
                  className="fs-12 p-2"
                  onChange={handleChange}
                  placeholder="Enter terminal Category"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">Terminal Cost</Form.Label>
                <Form.Control
                  type="text"
                  name="amount"
                  defaultValue={updateData.amount}
                  className="fs-12 p-2"
                  onChange={handleChange}
                  placeholder="Enter terminal Cost"
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label className="fw-bold">
                  Total Quantity Added
                </Form.Label>
                <Form.Control
                  type="text"
                  name="quantity"
                  defaultValue={updateData.quantity}
                  className="fs-12 p-2"
                  required
                  onChange={handleChange}
                  placeholder="Enter Terminal Quantity"
                />
              </Form.Group>
              {/*<Form.Group className="mb-3">
                <Form.Label className="fw-bold">Total Quantity Left</Form.Label>
                <Form.Control
                  type="text"
                  name="totalQuantityLeft"
                  defaultValue={updateData.totalQuantityLeft}
                  className="fs-12 p-2"
                  onChange={handleChange}
                  required
                  placeholder="Enter Terminal Quantity"
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <div className="input-div">
                  <label className="text-darker fs-14 fw-600">
                    Upload Terminal Images
                  </label>
                  <p className="fs-12 text-muted">
                    This image will show when the page is requesting for a
                    terminal. We recommend a 148 x 107 pixel JPG or PNG, under 1
                    MB in size.
                  </p>
                  <div className="d-flex w-100">
                    <div className="custom-file-input-wrapper d-flex align-items-center me-1">
                      {image ? (
                        <label
                          className="pointer fs-12 p-1"
                          htmlFor="imageInput1"
                        >
                          <span className="imageName">
                            <img
                              style={{ width: "8.0rem", height: "5.0rem" }}
                              src={image ? image : null}
                              alt={image ? "pos image" : null}
                            />
                            <input
                              id="imageInput1"
                              className="custom-file-input"
                              type="file"
                              onChange={imageHandler}
                              accept
                            />
                          </span>
                        </label>
                      ) : (
                        <label
                          className="pointer fs-12 p-4"
                          htmlFor="imageInput1"
                        >
                          <i className="pe-1 mb-4">
                            <FaPlus />
                          </i>
                          Upload Image 1 <br />
                          <input
                            id="imageInput1"
                            className="custom-file-input"
                            type="file"
                            onChange={imageHandler}
                            accept
                          />
                          <br />
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </Form.Group> */}

              <div className="d-flex justify-content-between align-items-center w-100 px-2">
                <Button
                  className="my-4 border-0 fs-14 fw-500"
                  style={{
                    backgroundColor: "rgb(255, 68, 0)",
                    color: "#fff",
                    paddingInline: "1em",
                  }}
                  onClick={updateTerminal}
                >
                  Save Changes
                  {innerloading && <Spinner size="sm" />}
                </Button>
              </div>
            </Form>
          </Modal.Body>
        </Modal>
      </Container>
    </>
  );
};

export default PosTypes;
