import React, { useState } from "react";
import { IoFilterOutline } from "react-icons/io5";

const FilterKYC = ({ filterUsers }) => {
  const [filterParams, setFilterParams] = useState({ field: "", value: "" });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((fp) => ({ ...fp, field: name, value }));
  };
  const applyFilters = (p) => {
    filterUsers(p);
  };
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div
          className="dropdown-menu "
          data-auto-close="outside"
          style={{ width: "22rem" }}
        >
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Status</label>
              <div className="input-container">
                <select className="input" name="status" onChange={handleChange}>
                  <option value="">Show All</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="INCOMPLETED">Incompleted</option>
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Tier Levels</label>
              <div className="input-container">
                <select
                  className="input"
                  name="terminalName"
                  onChange={handleChange}
                >
                  <option value="">Show All</option>

                  <option value="tier1">Level 1</option>
                  <option value="tier2">Level 2</option>
                  <option value="tier3">Level 3</option>
                  <option value="tier4">Level 4</option>
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Date Range</label>
              <div className="input-container d-flex">
                <span className="text-muted fs-12 me-1 mt-1">From</span>
                <input className="input" type="date" />
                <span className="text-muted fs-12 me-2 mt-1">to</span>

                <input className="input" type="date" />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter">Clear Filter</button>
              <button
                className="px-3 py-2 apply-filter "
                onClick={(e) => {
                  e.preventDefault();
                  applyFilters(filterParams);
                }}
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterKYC;
