import { useEffect, useState } from "react";
import { CustomModal } from "../../components/Modal/CustomModal";
import "./style.scss";

import exportIcon from "../../assets/icons/coolicon.png";
import axios from "axios";
import moment from "moment";
import { isEmpty } from "lodash";

export const ExportData = ({ name, url, filename }) => {
  const [exportData, setExportData] = useState({
    loading: false,
    showByDateRange: false,
    disabled: true,
  });

  const [exportFormData, setExportFormData] = useState({});
  const { loading, showByDateRange, disabled } = exportData;

  const { startDate, endDate } = exportFormData;
  const handleChange = (e) => {
    const { name } = e.target;
    let value = name === "issued" ? e.target.checked : e.target.value;

    if (name === "startDate" || name === "endDate") {
      value = moment(value).format("YYYY-MM-DD HH:mm:ss");
    }
    setExportFormData((s) => ({ ...s, [name]: value }));
  };
  useEffect(() => {
    if (startDate && endDate) {
      setExportData((s) => ({ ...s, disabled: false }));
    }
  }, [startDate, endDate]);

  const handleSubmit = () => {
    const qs = new URLSearchParams(exportFormData);

    setExportData((s) => ({ ...s, loading: true }));

    axios
      .get(`${url}?${qs}`)
      .then((res) => {
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${filename}.csv`);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      })
      .finally(() => {
        setExportData((s) => ({ ...s, loading: false }));
        setExportFormData({});
      });
  };
  /* const getUsers = (event, done) => {
    if (!loading) {
      setexportData((s) => ({
        ...s,
        loading: true,
      }));
      axios
        .get(url)
        .then((res) => {
          console.log(res, "res");
          setexportData((s) => ({
            ...s,
            listOfUser: res.data.userListJson,
            loading: false,
          }));
          done(true); // Proceed and get data from dataFromListOfUsersState function
        })
        .catch(() => {
          setexportData((s) => ({ ...s, loading: false }));
          done(false);
        });
    }
  }; */
  return (
    <div>
      <button
        className="orange-button d-flex justify-content-center align-items-center text-white rounded mr-5"
        data-toggle="dropdown"
      >
        <img src={exportIcon} alt="" className="me-2" />
        Export Data
      </button>
      <div className="dropdown-menu fs-14 fw-bold">
        <a
          href="#*"
          className="dropdown-item fs-14"
          onClick={() => {
            setExportData((s) => ({ ...s, showByDateRange: true }));
          }}
        >
          By Date Range
        </a>
      </div>
      <CustomModal
        show={showByDateRange}
        clicked={() => {
          setExportData((s) => ({ ...s, showByDateRange: false }));
        }}
        disabled={disabled}
        submit={handleSubmit}
        loading={loading}
        title="Select date range for data export "
        action="Download Data"
        size="md"
      >
        <div className="input-container">
          <div className="input-item">
            <label className="inputLabel">{"Start Date"}</label>
            <input
              type="datetime-local"
              name={"startDate"}
              onChange={handleChange}
              max={moment().format("YYYY-MM-DDTHH:mm:ss")}
            />
          </div>
          <div className="input-item">
            <label className="inputLabel">{"End Date"}</label>
            <input
              type="datetime-local"
              name={"endDate"}
              onChange={handleChange}
              disabled={isEmpty(startDate)}
              min={startDate}
              max={moment().format("YYYY-MM-DDTHH:mm:ss")}
            />
          </div>
        </div>
        {name === "POSDevice" && (
          <div className="issued">
            <label>{"ISSUED"}</label>
            <input type={"checkbox"} name={"issued"} onChange={handleChange} />
          </div>
        )}
        {name === "POSRequest" && (
          <div className="issued">
            <label>{"STATUS"}</label>
            <select name={"status"} onChange={handleChange}>
              <option value={""}>{"Choose status"}</option>
              <option value={"APPROVED"}>{"APPROVED"}</option>

              <option value={"PENDING"}>{"PENDING"}</option>

              <option value={"REJECTED"}>{"REJECTED"}</option>

              <option value={"ISSUED"}>{"ISSUED"}</option>

              <option value={"COMPLETED"}>{"COMPLETED"}</option>
            </select>
          </div>
        )}
        {name === "AdminUser" && (
          <div className="issued">
            <label>{"ADMIN TYPE"}</label>
            <select name={"type"} onChange={handleChange}>
              <option value={""}>{"Choose Admin type"}</option>
              <option value={"ALL"}>{"ALL"}</option>
              <option value={"ADMIN"}>{"ADMIN"}</option>
              <option value={"MERCHANT_ADMIN"}>{"MERCHANT_ADMIN"}</option>
            </select>
          </div>
        )}
      </CustomModal>
    </div>
  );
};
