import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import { Button, Col, Container, Row } from "react-bootstrap";
import { IoCaretBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import Layout from "../../Layout/Layout";
import { useMatch } from "react-router";
import { viewCorporateProfile } from "../../../plugins/urls";
import { Slide, toast } from "react-toastify";
import Divider from "../../Divider/Divider";
import profileVector from "../../../assets/icons/Vector.png";
import { MdContentCopy } from "react-icons/md";
import { BsCheck2 } from "react-icons/bs";
import { updateMerchantProfile } from "../../../plugins/urls";

export default function ManageProfile() {
  const match = useMatch("/manage-profile/:userId");
  const id = match ? match.params.userId : "";

  const [state, setState] = useState({
    organisationName: "",
    organisationEmail: "",
    organisationPhone: "",
    organizationAddress: "",
    organizationCity: "",
    organizationState: "",
    firstName: "",
    surname: "",
    businessType: "",
    // status: true,
    corporate: true,
  });

  const {
    organisationEmail,
    organisationName,
    organisationPhone,
    organizationAddress,
    organizationCity,
    organizationState,
    businessType,
    firstName,
    surname,
    // status,
    corporate,
  } = state;

  const [copy, setCopy] = useState(false);
  const [copyStatus, setCopyStatus] = useState("");
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    getCorporateUserById();
  }, []);

  const getCorporateUserById = () => {
    axios({
      method: "get",
      url: `${viewCorporateProfile}/${id}`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        // console.log(res.data);
        if (res.data) {
          const { firstName, surname, status, corporate } = res.data.data;
          const {
            organisationEmail,
            organisationName,
            organisationPhone,
            organizationAddress,
            organizationCity,
            organizationState,
            businessType,
          } = res.data.data.otherDetails;
          setState((state) => ({
            ...state,
            organisationEmail: organisationEmail,
            organisationName: organisationName,
            organisationPhone: organisationPhone,
            organizationAddress: organizationAddress,
            organizationCity: organizationCity,
            organizationState: organizationState,
            businessType: businessType,
            firstName: firstName,
            surname: surname,
            status: status,
            corporate: corporate,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  }

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    setEditing(editing);

    let reqBody = {
      organisationName,
      organisationEmail,
      organizationAddress,
      organizationCity,
      organisationPhone,
      organizationState,
      firstName,
      surname,
      businessType,
    };

    axios({
      method: "put",
      url: `${updateMerchantProfile}/${id}`,
      data: reqBody,
    })
      .then((res) => {
        // console.log("Updated Data: ", res.data);
        let {
          organisationName,
          organisationEmail,
          organisationPhone,
          organizationAddress,
          organizationCity,
          organizationState,
          businessType,
          firstName,
          surname,
        } = res.data.data;
        if (res.data && res.data.status === true) {
          setState((state) => ({
            ...state,
            organisationEmail: organisationEmail,
            organisationName: organisationName,
            organisationPhone: organisationPhone,
            organizationAddress: organizationAddress,
            organizationCity: organizationCity,
            organizationState: organizationState,
            businessType: businessType,
            firstName: firstName,
            surname: surname,
          }));
          refreshPage();
          toast.success(`${res.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast(`${res.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        if (err.response.statusCode === 401) {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
    setEditing(!editing);
  };

  const refreshPage = () => {
    window.location.reload(true);
  }

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <Link to="/corporate-users" className="text-decoration-none">
              <h4 className="text-darker fs-14 fw-700 cursor-pointer">
                <span className="mr-15">
                  <IoCaretBack />
                </span>
                Back
              </h4>
            </Link>
          </Col>
        </Row>
        <Row className="mt-20">
          <h4 className="fw-bold fs-18">User Profile</h4>
        </Row>
        <>
          <div className="d-flex justify-content-between mt-20 align-items-center">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center justify-content-between">
                <img
                  src={profileVector}
                  alt=""
                  style={{
                    backgroundColor: "#FFCFBB",
                    borderRadius: "50%",
                    width: "35%"
                  }}
                />
                <div className="ms-1">
                  <h5 className="text-dark fw-bold fs-16">User name</h5>
                  <p className="fw-500 m-0 fs-14 text-capitalize" style={{ color: "#555" }}>
                    {organisationName ? organisationName : "N/A"}
                  </p>
                </div>
              </div>
              <div style={{ marginLeft: "3em" }}>
                <h5 className="text-dark fw-bold fs-16">User ID</h5>
                <p
                  className="fw-500 d-flex align-items-center m-0 fs-14"
                  style={{ color: "#555" }}
                >
                  {id}{" "}
                  <MdContentCopy
                    className="ms-2 cursor-pointer copy-icon"
                    onClick={() => {
                      navigator.clipboard.writeText(`${id}`);
                      setCopy(!copy);
                      setCopyStatus("Copied!");
                    }}
                  />
                  {copy === true ? (
                    <span className="text-success ms-2 fs-14">
                      <BsCheck2 size={20} /> {copyStatus}
                    </span>
                  ) : null}
                </p>
              </div>
            </div>

            <div>
              {!editing ? (
                <Button
                  className="btn-lg fs-12 fw-500 orange-button border-0 "
                  style={{ backgroundColor: "#FF6700" }}
                  onClick={() => setEditing(!editing)}
                >
                  Edit user's details
                </Button>
              ) : (
                <Button
                  className="btn-lg fs-12 fw-500 orange-button border-0 "
                  style={{ backgroundColor: "#FF6700" }}
                  onClick={handleUpdateProfile}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </div>

          <div className="mt-40">
            <Divider />
          </div>

          <div className="mt-40">
            <Row>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">Role</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2"
                    name="role"
                    value={corporate === true ? "CORPORATE" : "ADMIN"}
                    readOnly
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">Status</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2"
                    name="status"
                    // value={status === true ? "ACTIVE" : "INACTIVE"}
                    value=""
                    readOnly
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">
                    Email Address
                  </label>
                  <input
                    type="email"
                    className="form-control fs-12 p-2"
                    name="organisationEmail"
                    value={organisationEmail ? organisationEmail : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-40">
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">First Name</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="firstName"
                    value={firstName ? firstName : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">Last Name</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="surname"
                    value={surname ? surname : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">
                    Phone Number
                  </label>
                  <input
                    type="tel"
                    className="form-control fs-12 p-2"
                    name="organisationPhone"
                    value={organisationPhone ? organisationPhone : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-40">
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">State</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="organizationState"
                    value={organizationState ? organizationState : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">City</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="organizationCity"
                    value={organizationCity ? organizationCity : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">Address</label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="organizationAddress"
                    value={organizationAddress ? organizationAddress : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mt-40">
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">
                    Business Name
                  </label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2 text-capitalize"
                    name="organisationName"
                    value={organisationName ? organisationName : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">
                    Business Type
                  </label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2"
                    name="businessType"
                    value={businessType ? businessType : ""}
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label className="fs-14 text-grey fw-bold">
                    WayaPOS Account Number
                  </label>
                  <input
                    type="text"
                    className="form-control fs-12 p-2"
                    name="accountNumber"
                    value=""
                    onChange={onChange}
                    disabled={!editing}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </>
      </Container>
    </Layout>
  );
}
