import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";

export default function ResolutionState({ data }) {
  const {
    disputeResolution,
    customer,
    resolutionDate,
    transactionDate,
    dateCreated,
    disputeType,
    transactionReference,
    amount,
    reason,
    merchantID,
    merchantEmail,
    merchantName,
    comment,
    customerEmail,
  } = data;
  return (
    <Container className="" style={{ marginTop: "1em" }}>
      <Row>
        <Col>
          <div
            className="shadow"
            style={{
              background: "#E9E9E9",
              borderRadius: "10px",
            }}
          >
            <div
              className="shadow fs-16 d-flex justify-content-between align-items-center p-2"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <div>
                <p className="text-dark fs-14 fw-500">Dispute Details</p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <p className="text-grey d-flex justify-content-between align-items-center fs-12 fw-500">
                Resolution State
                {disputeResolution === "declined" ? (
                  <span className="ms-2 fs-12 text-danger py-2 px-4 fw-bold bg-grey rounded">
                    Rejected
                  </span>
                ) : disputeResolution === "accepted" ? (
                  <span className="ms-2 fs-12 text-success py-2 px-4 fw-bold bg-grey rounded">
                    Accepted
                  </span>
                ) : (
                  <span className="ms-2 fs-12 text-grey py-2 px-4 fw-bold bg-grey rounded">
                    Nil
                  </span>
                )}
              </p>
            </div>
            <div className="p-3">
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Customer Name</p>
                <p className="fs-12 fw-bold text-capitalize">
                  {customer ? customer : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Customer Email</p>
                <p className="fs-12 fw-bold">
                  {customerEmail ? customerEmail : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Transaction Date</p>
                <p className="fs-12 fw-bold">
                  {transactionDate
                    ? moment(new Date(transactionDate)).format(
                        "MMMM Do YYYY, hh:mm A"
                      )
                    : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Date Created</p>
                <p className="fs-12 fw-bold">
                  {dateCreated
                    ? moment(new Date(dateCreated)).format(
                        "MMMM Do YYYY, hh:mm A"
                      )
                    : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Dispute Type</p>
                <p className="fs-12 fw-bold">
                  {disputeType ? disputeType : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Reference ID</p>
                <p className="fs-12 fw-bold">
                  {transactionReference ? transactionReference : ""}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Refund Requested</p>
                <p className="fs-12 fw-bold">
                  {amount ? `NGN${amount.toLocaleString()}` : "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div
            className="mt-40 shadow"
            style={{
              background: "#E9E9E9",
              borderRadius: "10px",
            }}
          >
            <div
              className="shadow fs-16 p-2"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <p className="text-dark fs-14 fw-500">
                Reason for dispute in details
              </p>
              <p
                style={{
                  background: "#FF6700",
                  width: "2.5em",
                  marginTop: "-1em",
                  padding: "2px",
                }}
              ></p>
            </div>
            <div className="p-3 fs-12 fw-bold text-capitalize">
              <p>{reason ? reason : ""}</p>
            </div>
          </div>
        </Col>
        <Col>
          <div
            className="shadow"
            style={{
              background: "#FFF",
              borderRadius: "10px",
            }}
          >
            <div
              className="shadow fs-16 p-2"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <p className="text-dark fs-14 fw-500">Merchant Details</p>
              <p
                style={{
                  background: "#FF6700",
                  width: "2.5em",
                  marginTop: "-1em",
                  padding: "2px",
                }}
              ></p>
            </div>
            <div className="p-3">
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Name</p>
                <p className="fs-12 fw-bold text-capitalize">
                  {merchantName ? merchantName : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Email Address</p>
                <p className="fs-12 fw-bold">
                  {merchantEmail ? merchantEmail : "N/A"}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12">Merchant ID</p>
                <p className="fs-12 fw-bold">
                  {merchantID ? merchantID : "N/A"}
                </p>
              </div>
            </div>
          </div>
          <div
            className="mt-40 shadow"
            style={{
              background: "#FFF",
              borderRadius: "10px",
            }}
          >
            <div
              className="shadow fs-16 p-2"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <p className="text-dark fs-14 fw-500">Merchant Response</p>
              <p
                style={{
                  background: "#FF6700",
                  width: "2.5em",
                  marginTop: "-1em",
                  padding: "2px",
                }}
              ></p>
            </div>
            <div className="p-3">
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12 text-dark">Response State</p>
                <p className="fs-12 fw-bold text-capitalize">
                  {disputeResolution === "declined" ? (
                    <span className="text-danger">Rejected</span>
                  ) : disputeResolution === "accepted" ? (
                    <span className="text-success">Accepted</span>
                  ) : (
                    "..."
                  )}
                </p>
              </div>
              <div className="td p-2 d-flex justify-content-between align-items-center">
                <p className="fs-12 text-dark">Response Date</p>
                <p className="fs-12 fw-bold">
                  {resolutionDate
                    ? moment(new Date(resolutionDate)).format(
                        "MMMM Do YYYY, hh:mm A"
                      )
                    : "N/A"}
                </p>
              </div>
              <div className="mt-10 fs-14">
                <label className="py-1 fs-12">Additional Comment</label>
                <textarea
                  name="merchantReason"
                  id="merchantReason"
                  cols="75"
                  rows="5"
                  className="fs-12 p-2 fw-bold"
                  defaultValue={comment ? comment : "No comment"}
                  readOnly
                ></textarea>
              </div>

              <div className="bg-white d-flex justify-content-center align-items-center rounded shadow w-50 mx-auto my-2">
                <div style={{ color: "#4A4AFF" }} className="p-2">
                  <span className="action-purple">
                    <AiFillEye size={15} />
                  </span>
                </div>
                <span className="fs-12 mt-2 ms-2" style={{ color: "#4A4AFF" }}>
                  View Proof Documents
                </span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
