import React, { useState } from "react";
import axios from "../../plugins/axios";
import { Container } from "react-bootstrap";
import { CollapsibleComponent } from "./CollapsibleComponent";
import "./EditPricingForm.css";
import { allPricing, updateAllPricingsByProducts } from "../../plugins/urls";
import { Slide, toast } from "react-toastify";

export default function WayaPosPricings() {
  const [state, setState] = useState({
    update: false,
    cap: 0,
    discount: 0,
    pricingRate: 0,
    pageNo: 0,
    pageSize: 20,
    // userId: "",
  });

  const {cap, discount, pricingRate, pageNo, pageSize} = state;

  const getAllPricing = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      // id: user ? user.id : "",
    };

    axios({
      method: "post",
      url: `${allPricing}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          const result = res.data.respBody.content;
          return result;
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  // const updatePricingsByProduct = () => {
  //   setState((state) => ({
  //     ...state,
  //     update: true,
  //   }));

  //   let reqBody = {
  //     discount,
  //     pricingRate,
  //     cap,
  //   };
    
  //   axios({
  //     method: "put",
  //     url: `${updateAllPricingsByProducts}`,
  //     data: reqBody,
  //   })
  //     .then((res) => {
  //       // console.log(res.data);
  //       let {cap, discount, pricingRate} = res.data.respBody;
  //       if (res.data.respCode === 0) {
  //         setState((state) => ({
  //           ...state,
  //           cap: cap,
  //           discount: discount,
  //           pricingRate: pricingRate,
  //           submit: false,
  //           add: false,
  //         }));
  //         toast.success(`Pricing updated successfully`, {
  //           transition: Slide,
  //           hideProgressBar: true,
  //           autoClose: 3000,
  //         });
  //         getAllPricing();
  //       } else {
  //         setState((state) => ({
  //           ...state,
  //           submit: false,
  //           add: false,
  //           defaultPricing: false,
  //         }));
  //         toast.error(`${res.data.respBody}`, {
  //           transition: Slide,
  //           hideProgressBar: true,
  //           autoClose: 3000,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       setState((state) => ({
  //         ...state,
  //         submit: false,
  //       }));
  //       toast.error(`${err.response.data.message}`, {
  //         transition: Slide,
  //         hideProgressBar: true,
  //         autoClose: 3000,
  //       });
  //     });
  // };

  return (
    <Container fluid="lg">
      <div className="text-center p-4">
        <h5 className="fw-600">Edit Pricings</h5>
      </div>
      <CollapsibleComponent
        title="Card Acquiring"
        formTitle="Card Acquiring"
      />
      {/* <CollapsibleComponent
        title="USSD Acquiring"
        formTitle="USSD Acquiring"
      />
      <CollapsibleComponent
        title="Bank Account Acquiring"
        formTitle="Bank Account Acquiring"
      />
      <CollapsibleComponent
        title="Wallet Acquiring"
        formTitle="Wallet Acquiring"
      /> */}
      <CollapsibleComponent
        title="PayAttitude Acquiring"
        formTitle="PayAttitude Acquiring"
      />
    </Container>
  );
}
