import React, { useEffect, useState } from "react";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { ReactComponent as Check } from "../../assets/icons/approve.svg";
import { ReactComponent as Upload } from "../../assets/icons/Upload.svg";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";
import Modal from "../Modal/Modal";
import {
  kycStatus,
  getKYCRequirementsByTier,
  approveUserKYC,
  rejectUserKYC,
  uploadUserKYC,
} from "../../plugins/urls";
import { getBase64 } from "../../plugins/utils";
import { FcCancel } from "react-icons/fc";
import { FaPlus } from "react-icons/fa";
import { BsCheckCircle } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { AiOutlineEye } from "react-icons/ai";
import reject from "../../assets/icons/reject.svg";

const Tier4 = (props) => {
  const { id, tierName, completedTier } = props;
  const [state, setState] = useState({
    CAC: {},
    utilityBill: {},
    mermart: {},
    TIN: {},
    tierLevelStatus: {},
    selectedKYC: "",
    viewUrl: "",
    formDataImage: new FormData(),
    image: "",
    imageString: "",
    submit: false,
  });
  const {
    tierLevelStatus,
    CAC,
    TIN,
    mermart,
    selectedKYC,
    viewUrl,
    formDataImage,
    image,
    imageString,
    submit,
  } = state;
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [reason, setReason] = useState("");

  const onChange = (e) => setReason(e.target.value);

  const getFileName = (name) => {
    switch (name.toLowerCase()) {
      case "cac-certificate":
        return "cacDocument";
      case "mermart":
        return "mermart";
      case "tin":
        return "tin";

      default:
        break;
    }
  };
  const switchStatus = (status) => {
    switch (status.toUpperCase()) {
      case "APPROVE":
        return (
          <span className="text-sharp-green">
            <i className="me-2">
              <BsCheckCircle size={15} />
            </i>
            {status}
          </span>
        );
      case "REJECT":
        return (
          <span className="text-red">
            <i className="me-2">
              <FcCancel size={15} />
            </i>
            {status}
          </span>
        );
      case "UNDER REVIEW":
        return (
          <span className="text-yellow">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );
      case "PENDING":
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );
      default:
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            Pending
          </span>
        );
    }
  };
  const imageHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      let uploadedimage = e.target.files[0];
      // formDataImage.append("file", uploadedimage);
      getBase64(uploadedimage)
        .then((result) => {
          uploadedimage["base64"] = result;
          setState((state) => ({
            ...state,
            image: uploadedimage,
            imageString: uploadedimage.base64,
          }));
          // console.log(image, imageString)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };
  const findReq = (reqList, reqName) => {
    let reqObj = reqList.find((req) => req.reqItem === reqName);
    return reqObj;
  };
  const getUserKYCRequirements = () => {
    axios({
      method: "get",
      url: `${getKYCRequirementsByTier}/${id}/${tierName}`,
    })
      .then((res) => {
        if (res.data.data) {
          let reqList = res.data.data;
          setState((state) => ({
            ...state,
            CAC: findReq(reqList, "CAC DOCUMENT") ?? null,
            mermart: findReq(reqList, "MERMART") ?? null,
            TIN: findReq(reqList, "TIN") ?? null,
          }));
        }
      })
      .catch((err) => {});
  };
  const uploadDoc = (file) => {
    setState((state) => ({
      ...state,
      selectedKYC: file,
    }));
    setUploadImageModal(true);
  };
  const onUploadUserKYC = (fileName, id) => {
    let formFileName = getFileName(fileName);
    formDataImage.append(`${formFileName}`, image);

    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${uploadUserKYC}/${id}/${fileName}?fileName=${formFileName}`,
      data: formDataImage,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          submit: false,
          selectedKYC: "",
          image: "",
          imageString: "",
          formDataImage: new FormData(),
        }));
        setUploadImageModal(false);
        toast.success(
          `${res.data.message ? res.data.message : "File saved successfully"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        getUserKYCRequirements();
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err.response.data.message
              ? err.response.data.message
              : err.response.data.error
              ? err.response.data.error
              : "Unable to save document"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        setState((state) => ({
          ...state,
          submit: false,
          selectedKYC: "",
          image: "",
          imageString: "",
          formDataImage: new FormData(),
        }));
      });
    setState((state) => ({
      ...state,
      submit: false,
    }));
  };
  const onViewKYCDoc = (url, mode) => {
    setState((state) => ({
      ...state,
      viewUrl: url ? url : "",
    }));
    setViewImageModal(mode);
  };
  const onApproveUserKYC = (id) => {
    if (!id) {
      toast.error(`cannot approve a kyc requirement without data`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "get",
      url: `${approveUserKYC}/${id}`,
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.success(
          `${res.data.message ? res.data.message : "File approved"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err.response.data.message
              ? err.response.data.message
              : err.response.data.error
              ? err.response.data.error
              : "Unable to save document"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };
  const onRejectUserKYC = (id) => {
    if (!id) {
      toast.error(`cannot reject a kyc requirement without data`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${rejectUserKYC}/reason/${id}?reason=${reason}`,
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        setOpenReject(false);
        toast.success(
          `${res.data.message ? res.data.message : "File rejected"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.success(
          `${
            err.response.data.message
              ? err.response.data.message
              : err.response.data.error
              ? err.response.data.error
              : "Unable to save document"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };
  useEffect(() => {
    getUserKYCRequirements();
  }, []);

  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="d-flex justify-content-between">
        <div>
          <p className="fs-18 fw-700 text-grey">Tier 4</p>
        </div>

        <div>
          <p className="fs-14 fw-700 text-darker m-0">Status</p>
          <button
            className={` mt-2 ${
              completedTier === true ? "button-success" : "orange-button"
            }`}
          >
            {completedTier === true ? (
              <span>Completed</span>
            ) : (
              <span>Incomplete</span>
            )}
          </button>
        </div>
      </div>

      <div className="tier p-20 mt-40">
        <div className="   ">
          <table className="bg-inherit table table-borderless table-responsive w-100">
            <thead>
              <tr className="kyc-table" style={{ borderRadius: "5px" }}>
                <th>KYC Requirement</th>
                <th>Status</th>
                <th></th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            <tbody className="align-bottom">
              <tr>
                <td className="tier-td text-wrap">CAC Document - Cerificate</td>
                <td className="tier-td text-nowrap">
                  {CAC?.status ? (
                    switchStatus(CAC.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                {CAC?.reqValue && (
                  <td className="tier-td">
                    <input
                      disabled
                      className="tier-detail w-100"
                      value={CAC?.reqValue}
                    />
                  </td>
                )}
                <td className="tier-td text-end">
                  <span
                    className="tabblack ml-10 "
                    onClick={() => uploadDoc("cac-certificate")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {CAC?.reqValue ? "Change" : "Upload"}
                  </span>
                  {CAC?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          CAC?.uploadUrl
                            ? onViewKYCDoc(CAC?.uploadUrl, true)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {CAC?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() => onApproveUserKYC(CAC?.id ?? null)}
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() => setOpenReject(CAC?.id ?? null)}
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="tier-td text-wrap">CAC Document - Memart</td>
                <td className="tier-td text-nowrap">
                  {mermart?.status ? (
                    switchStatus(CAC.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>

                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                <td className=" tier-td">
                  {mermart?.reqValue && (
                    <input
                      disabled
                      className="tier-detail w-100"
                      value={mermart?.reqValue ? mermart.reqValue : ""}
                    />
                  )}
                </td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10 "
                    onClick={() => uploadDoc("mermart")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {mermart?.reqValue ? "Change" : "Upload"}
                  </span>
                  {mermart?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          mermart?.uploadUrl
                            ? onViewKYCDoc(mermart.uploadUrl, true)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {mermart?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() =>
                              onApproveUserKYC(mermart?.id ?? null)
                            }
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() => setOpenReject(mermart?.id ?? null)}
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="tier-td text-wrap">
                  Taxpayer Identification Number (TIN)
                </td>
                <td className="tier-td text-nowrap">
                  {TIN?.status ? (
                    switchStatus(TIN.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>

                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                <td className=" tier-td">
                  {TIN?.reqValue && (
                    <input
                      disabled
                      className="tier-detail w-100"
                      value={TIN?.reqValue ? TIN.reqValue : ""}
                    />
                  )}
                </td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10 "
                    onClick={() => uploadDoc("tin")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {TIN?.reqValue ? "Change" : "Upload"}
                  </span>
                  {TIN?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          TIN?.uploadUrl
                            ? onViewKYCDoc(TIN.uploadUrl, true)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {TIN?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() => onApproveUserKYC(TIN?.id ?? null)}
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() => setOpenReject(TIN?.id ?? null)}
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {uploadImageModal && (
        <Modal
          show={uploadImageModal}
          clicked={() => setUploadImageModal(false)}
          submit={() => onUploadUserKYC(selectedKYC, id)}
          loading={submit}
          title={`Upload ${selectedKYC} file`}
          action="Submit"
        >
          <label className="pointer fs-12 p-1 " for="imageInput1">
            <span className="imageName border-1 p-1 border-dark rounded-3">
              {imageString && imageString.length ? (
                <img
                  style={{
                    width: "8.0rem",
                    height: "5.0rem",
                    padding: "10px",
                    border: "1px solid grey",
                  }}
                  src={imageString}
                  alt="pos "
                />
              ) : (
                <span className="imageName bg-grey p-4">
                  <i className="">
                    <FaPlus />
                  </i>
                </span>
              )}

              <input
                id="imageInput1"
                className="custom-file-input"
                type="file"
                onChange={imageHandler}
                accept
              />
            </span>
          </label>
        </Modal>
      )}

      {viewImageModal && (
        <Modal
          show={viewImageModal}
          clicked={() => setViewImageModal(false)}
          submit={() => setViewImageModal(false)}
          // loading={submit}
          title=""
          action="Close"
        >
          {/*<div style={{ width: "10rem", height: "10rem" }}>*/}
          <img src={viewUrl} alt="" width="100%" height="100%" />
          {/*</div>*/}
        </Modal>
      )}

      <Modal show={openReject} clicked={() => setOpenReject(false)} title="">
        <div className="mx-auto" style={{ width: "20rem", height: "18rem" }}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img src={reject} alt="" />
              <div className="fs-16 ms-2 text-danger">REJECT APPROVAL</div>
            </div>
            <div className="py-2 fs-14 text-muted text-center">
              Are you sure you want to Reject this KYC request?
            </div>
            <div className="input-div full-width">
              <label className="text-default fs-14">State Reason</label>
              <div className="inputnoBorder input-container">
                <textarea
                  className="input"
                  value={reason}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ background: "#F2F2F2" }}
            >
              <button className="btn" onClick={() => setOpenReject(false)}>
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => onRejectUserKYC(openReject)}
              >
                <span className="text-orange">Yes, Proceed</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Tier4;
