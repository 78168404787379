import WayaDispute from "../../components/DisputeComponents/WayaDispute";
import PosDevice from "../../components/PosComponents/PosDevice";
import PosRequest from "../../components/PosComponents/PosRequest";
import WayaPos from "../../components/TransactionComponents/WayaPos";
import WayaSettlement from "../../components/TransactionComponents/WayaSettlement";
import Kyc from "../../components/UsersComponents/Kyc";
import Users from "../../components/UsersComponents/Users";
import { baseUrl } from "../../plugins/urls";
import AdminLogs from "../Logs/AdminLogs";
import RequestLogs from "../Logs/RequestLog";
import Pricing from "../Tech/Pricing";
import AdminUsers from "../Users/AdminUsers";

export const downloadMerchant = `${
  baseUrl().merchants
}/auth/merchant/reports/file/download`;

export const downloadAdminUser = `${baseUrl().role}/reports/file/download`;

export const downloadSettlement = `${
  baseUrl().settlementV1
}/settlement/reports/file/download`;

export const downloadDispute = `${
  baseUrl().dispute
}/wayaposDisputes/reports/file/download`;

export const downloadTerminal = `${
  baseUrl().terminal
}/terminals/reports/file/download`;

export const downloadTerminalRequest = `${
  baseUrl().terminal
}/terminals/request/reports/file/download`;

export const downloadTransactions = `${
  baseUrl().transactions
}/transaction/reports/file/download`;

export const downloadAuditLog = `${
  baseUrl().log
}/auditlogs/reports/file/download`;

export const downloadRequestLog = `${baseUrl().log}/reports/file/download`;

export const tabData = [
  {
    index: "adminUsers",
    Component: AdminUsers,
    label: "Admin User",
  },
  {
    index: "corporateUsers",
    Component: Users,
    label: "Corporate Users",
  },
  {
    index: "kyc",
    Component: Kyc,
    label: "Corporate KYC",
  },
  {
    index: "cashoutTransactions",
    Component: WayaPos,
    label: "Cashout Transactions",
  },

  {
    index: "cashoutSettlements",
    Component: WayaSettlement,
    label: "Cashout Settlements",
  },
  {
    index: "posDevice",
    Component: PosDevice,
    label: "POS Device",
  },
  {
    index: "PosRequest",
    Component: PosRequest,
    label: "POS Request",
  },
  /* {
    index:"posTypes",
    Component:PosTypes,
    label:"POS Terminal Types"
}, */
  {
    index: "disputeLogs",
    Component: WayaDispute,
    label: "Dispute Logs",
  },
  {
    index: "pricing",
    Component: Pricing,
    label: "Pricing",
  },

  {
    index: "requestLogs",
    Component: RequestLogs,
    label: "Admin Request Logs",
  },

  {
    index: "adminLogs",
    Component: AdminLogs,
    label: "Admin Logs",
  },
];
