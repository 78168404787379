import React from "react";
import "../UsersComponents/users.scss";

const PricingCards = (props) => {
  return (
    <div className="admin-user-cards">
      <h4 className={`fs-14 fw-600 text-darker `}>
         {props.title}
      </h4>
      <h4 className={`fs-22 fw-600 text-darker`}>
        {props.number}
      </h4>
    </div>
  );
};

export default PricingCards;