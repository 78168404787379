import React, { useMemo, useState } from "react";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";
import { AiFillEye } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { IoCaretBack } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import Layout from "../Layout/Layout";
import NoResultFound from "../NoResultFound/NoResultFound";
import AuthCard from "./AuthCard";

let pagesize = 10;

const data = [
  {
    fileName: "Scanned Document",
    fileType: "PDF",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },

  {
    fileName: "Scanned Document",
    fileType: "CSV",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },
  {
    fileName: "Scanned Document",
    fileType: "XLSX",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },
  {
    fileName: "Scanned Document",
    fileType: "PDF",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },
  {
    fileName: "Scanned Document",
    fileType: "TXT",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },
  {
    fileName: "Scanned Document",
    fileType: "DOCX",
    docType: "Invoice",
    disputeId: "97439",
    uploadedBy: "Phillip Templar",
    dateUploaded: "Feb, 9th 2022 08:57:01 PM",
  },
];

export default function DisputeDocs() {
  const [state, setState] = useState({
    // docsList: [],
    docsList: data,
  });

  const { docsList } = state;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pagesize;
    const lastPageIndex = firstPageIndex + pagesize;
    return docsList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, docsList]);

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <Link to="/disputes" className="text-decoration-none">
              <h4 className="text-darker fs-14 fw-700 cursor-pointer">
                <span className="mr-15">
                  <IoCaretBack />
                </span>
                Back
              </h4>
            </Link>
          </Col>
          <Col>
            <div className="d-flex justify-content-end align-items-end">
              <div className="input_Search d-flex justify-content-start align-items-start w-50">
                <div className="justify-content-center align-items-center">
                  <FiSearch color="#FF6700" />
                </div>
                <input
                  className="input ml-10"
                  name="search"
                  placeholder="Search with Dispute Id"
                  // onChange={handleSearch}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-40">
          <Col>
            <AuthCard
              cardTitle="All uploaded files"
              // number={totalPendingInvites ? totalPendingInvites.length : 0}
              value={"0"}
            />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>

        <Row className="mt-40">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-flex justify-content-center align-items-center mr-30">
              <div className="btn-group">
                <button
                  class="btn dropdown-toggle change-button d-flex justify-content-between"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: "170px" }}
                >
                  File Type
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#*">
                    PDF
                  </a>
                  <a class="dropdown-item" href="#*">
                    DOCX
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mr-30">
              <div class="btn-group">
                <button
                  class="btn dropdown-toggle change-button d-flex justify-content-between"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: "170px" }}
                >
                  Document Type
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#*">
                    Invoice
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mr-30">
              <div class="btn-group">
                <button
                  class="btn dropdown-toggle change-button d-flex justify-content-between"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: "170px" }}
                >
                  Uploaded by
                </button>
                <div class="dropdown-menu">
                  <a class="dropdown-item" href="#*">
                    Phillip Templar
                  </a>
                  <a class="dropdown-item" href="#*">
                    Unknown
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="data-table mt-40">
          <Table responsive borderless className="bg-inherit w-100">
            <thead>
              <tr
                style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                className="t-head-colored"
              >
                <th className="py-2 th">FILE NAME</th>
                <th className="py-2 th">FILE TYPE</th>
                <th className="py-2 th">DOC TYPE</th>
                <th className="py-2 th">DISPUTE ID</th>
                <th className="py-2 th">UPLOADED BY</th>
                <th className="py-2 th">DATE UPLOADED</th>
                <th className="py-2 th"></th>
              </tr>
            </thead>

            <tbody>
              {docsList ? (
                docsList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  currentTableData.map((doc, i) => {
                    const {
                      fileName,
                      fileType,
                      docType,
                      disputeId,
                      uploadedBy,
                      dateUploaded,
                    } = doc;
                    return (
                      <tr key={i}>
                        <td className="td">{fileName}</td>
                        <td className="td">{fileType}</td>
                        <td className="td">{docType}</td>
                        <td className="td">{disputeId}</td>
                        <td className="td">{uploadedBy}</td>
                        <td className="td text-wrap" style={{ width: "12em" }}>
                          {dateUploaded}
                        </td>
                        {/* <td className="td">{moment().format("YYYY-MM-DD")}</td> */}
                        <td className="td">
                          <span className="action-purple">
                            <AiFillEye size={15} color="#644CFA" />
                          </span>
                          <span className="ms-4">
                            <MdDelete size={20} color="#FF6700" />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={docsList.length}
            pagesize={pagesize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Container>
    </Layout>
  );
}
