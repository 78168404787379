import React from "react";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { deactivateSingleUser } from "../../plugins/urls";
import axios from "../../plugins/axios";

const Popover = ({ userId, business }) => {
  const navigate = useNavigate();
  const {firstName, surname, email} = business;
  // console.log(business);

  const handleDeactivateUser = () => {
    axios({
      method: "post",
      url: `${deactivateSingleUser}?userId=${userId}`,
      data: userId,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.status === true) {
          toast.success(`${res.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.success(`There was an error deactivating user`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  return (
    <ul className="popOver">
      <li
        className="popover-list-item"
        onClick={() => {
          navigate(`/manage-profile/${userId}`);
        }}
      >
        Manage Profile
      </li>
      <li
        className="popover-list-item"
        onClick={() => {
          navigate(`/team-members/${userId}`);
        }}
      >
        Manage Team Members
      </li>
      <li
        className="popover-list-item"
        onClick={() => {
          navigate(`/requested-terminals/${userId}`, {
            state:{firstName, surname, email},
          });
        }}
      >
        Manage Terminals
      </li>
      {/* <li className="popover-list-item">Manage KYC</li>
      <li className="popover-list-item">Login Tracker</li>
      <li className="popover-list-item">Activity Log</li>
      <li className="popover-list-item">Reset Credentials</li> */}
      <li className="popover-list-item" onClick={handleDeactivateUser} >Deactivate User</li>
      {/* <li className="popover-list-item"> Delete User</li> */}
    </ul>
  );
};

export default Popover;
