import React, { useState } from "react";
import AuthComponent from "../../components/AuthComponent/AuthComponent";
import AuthContainer from "../../components/AuthComponent/AuthContainer/AuthContainer";
import VerifyOtp from "./VerifyOtp";
import SubmitLoader from "../../components/SubmitLoader/SubmitLoader";
import axios from "../../plugins/axios";
import { signIn, twoFa } from "../../plugins/urls";
import { useNavigate } from "react-router-dom";
import { toast, Slide } from "react-toastify";
import PinModal from "./twoFA";
import { Box, Modal } from "@mui/material";
import { Typography } from "antd";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #FF6700',
  boxShadow: 24,
  p: 4,
};

const Login = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    emailOrPhoneNumber: "",
    password: "",
    submit: false,
    otp: false,
    message: "",
  });
  const [show2Fa, setShow2Fa] = useState(false);
  const [goToCustomer,setShowGotoCustomer] = useState({show:false})
  const [OTP, setOTP] = useState('');

  const { submit, emailOrPhoneNumber, password, message, otp } = state;
  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onLogin = (e) => {
   // e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      emailOrPhoneNumber,
      password,
      otp:OTP
    };

    axios({
      method: "post",
      url: `${signIn}`,
      data: reqBody,
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        if (res.data.status === true) {
          const { token, user } = res.data.data;
          if(user.isAdmin){
          localStorage.setItem(
            "userDetails",
            JSON.stringify({
              token,
              user,
            })
          );
          localStorage.setItem("reloadCount", 0);
          navigate("/dashboard");
          }else{
            setShowGotoCustomer({show:true,user:user})
          }
        } else {
          toast.error(`${res.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
          message: err.response.data.message,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };


  const onOTP = (e) => {
    e.preventDefault();
   

    let reqBody = {
      emailOrPhoneNumber,
      password,
      otp,
    };

    axios({
      method: "post",
      url: `${twoFa}/${state.emailOrPhoneNumber}`,
      data: reqBody,
    })
      .then((res) => {
        
        if (res.data.status === true) {
          setShow2Fa(true);

        } else {
          if(res.data?.message ===" 2FA not active for this user"){
            onLogin();
          }
        }
      })
      .catch((err) => {
        onLogin();
        console.log(err);
      
      });
  };
  return (
    <>
    {
      show2Fa ? <PinModal handleSubmit={onLogin}  setOTP={setOTP} OTP={OTP} showModal={show2Fa} hideModal={setShow2Fa}/>: ""
    }
    {goToCustomer.show&&<Modal
  open={goToCustomer.show}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      {`Dear ${goToCustomer?.user?.firstName} ${goToCustomer?.user?.lastName}`}
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Kindly logon to <a href={`${process.env.REACT_APP_SIGN_URL}`} target="_blank">customer website</a> to acess your dashboard 
    </Typography>
  </Box>
</Modal>}
      {otp ? (
        <VerifyOtp phoneOrEmail={emailOrPhoneNumber} />
      ) : (
        <AuthComponent>
          <AuthContainer>
            <div className="login-header">
              <h4 className="fs-24 fw-500 login-text">Sign In</h4>
            </div>

            <div className="mt-40 full-width">
              <form onSubmit={onOTP}>
                <div className="input-div full-width">
                  <label className="text-default fs-14">
                    Email or Phone number{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inputnoBorder input-container">
                    <input
                      className="input"
                      type="text"
                      onChange={onChange}
                      name="emailOrPhoneNumber"
                      required
                    />
                  </div>
                </div>

                <div className="input-div full-width">
                  <label className="text-default fs-14">
                    Password <span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="inputnoBorder input-container">
                    <input
                      className="input"
                      type="password"
                      name="password"
                      onChange={onChange}
                      required
                    />
                  </div>
                </div>

                <button className="mt-20 orange-button full-width">
                  {submit ? <SubmitLoader className="left-43" /> : "Log in"}
                </button>

                <div className="text-center mt-20">
                  {message === "Account not Verified" ? (
                    <h4 className="fs-16 fw-400 text-default">
                      <span className="mx-1">Yet to Verify your account?</span>
                      <span
                        className="text-orange cursor-pointer"
                        onClick={() =>
                          setState((state) => ({
                            ...state,
                            otp: true,
                          }))
                        }
                      >
                        Verify Now
                      </span>
                      {/* <div className="text-center">
                        <span className="mx-1">Or</span>
                        <span
                          className="text-orange cursor-pointer"
                          onClick={() => navigate("/register")}
                        >
                          Start Afresh
                        </span>
                      </div> */}
                    </h4>
                  ) : null}
                  {/* <h4 className="fs-16 fw-400 text-default">Don't have an account? <span className="text-orange cursor-pointer" onClick={()=>navigate('/register')}> Sign up instead </span></h4> */}
                  <h4
                    className="mt-20 fs-16 text-orange fw-400 cursor-pointer"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot your password?
                  </h4>
                </div>
              </form>
            </div>
          </AuthContainer>
        </AuthComponent>
      )}
    </>
  );
};

export default Login;
