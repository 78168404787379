import React, { useState } from "react";
import Layout from "../../components/Layout/Layout";
import {
  RoutingTable,
  SchemeTable,
  StationTable,
} from "../../components/TechComponents/RoutingTable";

const Routing = () => {
  const [activeTab, setActiveTab] = useState(1);
  const navigateToTab = (tabindex) => {
    setActiveTab(tabindex);
  };
  return (
    <Layout title="Transaction Routing">
      <div className="data-table mt-40">
        {activeTab === 1 && (
          <RoutingTable activeTab={activeTab} navigateToTab={navigateToTab} />
        )}
        {activeTab === 2 && (
          <SchemeTable activeTab={activeTab} navigateToTab={navigateToTab} />
        )}
        {activeTab === 3 && (
          <StationTable activeTab={activeTab} navigateToTab={navigateToTab} />
        )}
      </div>
    </Layout>
  );
};

export default Routing;
