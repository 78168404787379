import React, {useMemo, useState} from "react";
import {FiSearch, FiFilter} from "react-icons/fi";
import {FaTimes} from "react-icons/fa";
import SimpleDropDown from "./DropDown";
import {debounce} from "lodash";
import Button from 'react-bootstrap/Button';

const SimpleSearchBox = ({filter = false, items, selected, setSelected, filterClss, placeholder = 'search here...', camel = false, handleSearch, clearSearch}) => {
    const [val, setVal] = useState('');
    const handleChange = (e) =>  {
        handleSearch(e.target.value);
        setVal(e.target.value);
    };
    const handleChangeLocal = (e) =>  {
        setVal(e.target.value);
    };
    const handleClear = () => {
        clearSearch();
        setVal('')
        // e.target.reset();
    };
    const debouncedChangeHandler = useMemo(
        () => debounce(handleChange, 1200)
        , []);

    return (
        <div className="input_Search d-flex  align-items-center  w-100 ">
            <div className="">
                <div className="justify-content-center align-items-center">
                    {
                        filter
                            ? (
                                <div className='d-flex'>
                                    <FiFilter className='mt-2 text-muted' />
                                    <SimpleDropDown items={items} selected={selected} setSelected={setSelected} clss={filterClss} camel={camel} />
                                </div>
                            )
                            :  <FiSearch color="#FF6700" />
                    }
                </div>
            </div>
            <form className='w-100'>
                <div className='d-flex justify-content-between'>
                   <div className="w-100">
                       <input
                           className="input ml-10"
                           onChange={debouncedChangeHandler}
                           onChangeCapture={handleChangeLocal}
                           placeholder={placeholder}
                           value={val}
                       />
                   </div>
                    <div className="justify-content-center align-items-center ps-2">
                        {
                            filter && val !== ''
                            && (
                                <button onClick={handleClear} className='btn-sm btn' type='submit' >
                                    <FaTimes  className='text-muted' />
                                </button>
                            )

                        }
                    </div>
                </div>

            </form>

        </div>
    );

};
export default SimpleSearchBox;
