import { Box, Tab, Tabs, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import Layout from "../../components/Layout/Layout";
import { tabData, tabpanels } from "./data";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    display: "none",
  },
});

const StyledTab = styled((props) => <Tab {...props} />)(({ theme }) => ({
  textTransform: "none",
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  color: "#858585",
  "&.Mui-selected": {
    color: "#fff",
    backgroundColor: "#FF6700",
    borderRadius: "30px",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export const BusinessReports = () => {
  const [value, setValue] = useState("adminUsers");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout title="Business Report">
      <div className="data-table mt-40">
        <Box
          sx={{
            flexGrow: 1,
            bgcolor: "#F2F2F2",
          }}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="visible arrows tabs"
          >
            {tabData.map(({index,label})=><StyledTab label={label} value={index}/>)}
          </StyledTabs>
        </Box>

        {tabData.map(({index,Component})=><TabPanel value={value} index={index}>
          <Component hidden={true}/>
        </TabPanel>)}
      </div>
    </Layout>
  );
};
