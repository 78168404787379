import React, { useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { FaFilter, FaCaretDown } from "react-icons/fa";
import {
  fetchKycStatistics,
  fetchTierLevelsStatistics,
  fetchAllUsersWithKYCStatus,
  filterKYCbyTierLevel,
  filterUser,
} from "../../plugins/urls";
import { toast, Slide } from "react-toastify";
import TerminalCards from "../Cards/TerminalCards";
import axios from "../../plugins/axios";
import NoResultFound from "../NoResultFound/NoResultFound";
import { ReactComponent as Setting } from "../../assets/icons/setting-red.svg";
import { useNavigate } from "react-router";
import TierCards from "../Cards/TierCards";
import Pagination2 from "../Pagination/pagination2";
import { AiFillCloseCircle } from "react-icons/ai";
import CustomSpinner from "../Spinner/Spinner";
import moment from "moment";
import FilterKYC from "./filterKYC/FilterKYC";

const Kyc = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    totalUsers: 0,
    totalTierLevels: 0,
    totalVerifiedKYC: 0,
    totalPendingKYC: 0,
    usersWithKYCList: [],
    pageNo: 1,
    pageSize: 20,
    total: 0,
    tierSearch: "",
  });
  const [loading, setLoading] = useState(false);
  const [tierUserCount, setTierUserCount] = useState({
    tier1: 0,
    tier2: 0,
    tier3: 0,
    tier4: 0,
  });
  const { tier1, tier2, tier3, tier4 } = tierUserCount;
  const {
    totalUsers,
    usersWithKYCList,
    totalTierLevels,
    totalPendingKYC,
    totalVerifiedKYC,
    pageNo,
    pageSize,
    email,
  } = state;

  const getUsers = () => {
    const qs = new URLSearchParams({ pageNo, pageSize });
    setLoading(true);
    axios({
      method: "get",
      url: `${fetchAllUsersWithKYCStatus}?${qs}`,
    })
      .then((res) => {
        setLoading(false);

        if (res.data) {
          const { users, currentPage, totalItems, totalPages } =
            res?.data?.data;
          setState((state) => ({
            ...state,
            currentPage,
            totalItems,
            totalPages,
            usersWithKYCList: users,
          }));
        } else {
          toast.error(
            `${
              res.data.message
                ? res.data.message
                : "Unable to fetch users with kyc tier levels"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const filterUsers = (data = {}) => {
    const qs = new URLSearchParams({ ...data, pageNo, pageSize });
    setLoading(true);
    axios({
      method: "get",
      url: `${filterUser}?${qs}`,
    })
      .then((res) => {
        setLoading(false);
        if (res.data) {
          const {
            content,
            pageable: { pageNumber },
            totalElements,
            totalPages,
          } = res?.data?.respBody;
          setState((state) => ({
            ...state,
            currentPage: pageNumber + 1,
            totalItems: totalElements,
            totalPages,
            usersWithKYCList: content,
          }));
        } else {
          toast.error(
            `${
              res.data.message
                ? res.data.message
                : "Unable to fetch users with kyc tier levels"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const getKycStats = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${fetchKycStatistics}`,
    })
      .then((res) => {
        setLoading(false);
        // console.log("tier levels statistics", res.data);
        if (res.data && res.data.status === true) {
          setState((state) => ({
            ...state,
            totalVerifiedKYC: res.data.data
              ? res.data.data.totalVerifiedKYC
              : 0,
            totalPendingKYC: res.data.data ? res.data.data.totalPendingKYC : 0,
            totalUsers: res.data.data ? res.data.data.totalUsers : 0,
            totalTierLevels: res.data.data ? res.data.data.totalTierLevels : 0,
          }));
        } else {
          toast.error(
            `${res.data.message ?? "Unable to fetch total kyc statisticss"}`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const getTierLevelStats = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${fetchTierLevelsStatistics}`,
    })
      .then((res) => {
        setLoading(false);
        // console.log("tier levels user counts", res.data);
        if (res.data.data && res.data.status === true) {
          let userCountData = res.data.data;
          setTierUserCount((tierUserCount) => ({
            ...tierUserCount,
            tier1: userCountData ? userCountData.tier1 : 0,
            tier2: userCountData ? userCountData.tier2 : 0,
            tier3: userCountData ? userCountData.tier3 : 0,
            tier4: userCountData ? userCountData.tier4 : 0,
          }));
        } else {
          toast.error(
            `${
              res.data.message ?? "Unable to fetch user count for tier levels"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  }; /* 
  const getKYCbyTierLevel = (page) => {
    // if (tierSearch !== "tier1" || "tier2" || "tier3" || "tier4") {
    //   toast.error(`please enter valid tier level`, {
    //     transition: Slide,
    //     hideProgressBar: true,
    //     autoClose: 3000,
    //   });
    //   return;
    // }
    //  const getTier=()=>{
    //   switch (tierSearch) {
    //     case 1:
    //      return "tier1";
    //     case 2:
    //      return "tier2";
    //     case 3:
    //      return "tier3";
    //     case 4:
    //      return "tier4";
    //     default:
    //       break;
    //   }
    //  }
    setLoading(true);
    axios({
      method: "get",
      url: `${filterKYCbyTierLevel}/${tierSearch}?page=${page}&size=${pageSize}`,
    })
      .then((res) => {
        setLoading(false);
        if (res.data) {
          setState((state) => ({
            ...state,
            usersWithKYCList: res.data?.data,
          }));
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  }; */
  const cleartierSearch = (e) => {
    setState((state) => ({
      ...state,
      tierSearch: "",
    }));
    getUsers();
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const lastIndex = pageNo > 1 ? pageNo * pageSize : pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected + 1,
    }));
    // getUsers();
    // setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getUsers();
  };

  useEffect(() => {
    // kyc statistics, Tier level statistics,  get all users
    getKycStats();
    // get tier  statistics by tier level
    getTierLevelStats();
  }, []);

  useEffect(() => {
    // get users with their kyc status
    getUsers();
  }, [pageSize, pageNo]);
  return loading ? (
    <div className="mt-5 pt-5 w-100">
      <div className="d-flex align-items-center justify-content-center">
        <CustomSpinner />
      </div>
    </div>
  ) : (
    <div>
      <div className="d-flex justify-content-start align-items-center width-50 mb-3">
        <div className="d-flex justify-content-center align-items-center ">
          <FilterKYC filterUsers={filterUsers} />
        </div>
        <div className="d-flex justify-content-center align-items-center filter-search ml-22">
          <div className="input_Search d-flex justify-content-center align-items-center">
            <div className="justify-content-center align-items-center">
              <FiSearch color="#FF6700" />
            </div>
            <input
              className="input ml-10"
              placeholder="Search by email"
              name="email"
              onChange={onChange}
            />
            {!email ? null : (
              <i onClick={cleartierSearch}>
                {" "}
                <AiFillCloseCircle color="#FF6700" />
              </i>
            )}
          </div>
          <button
            className="orange-button ml-10"
            onClick={() => filterUsers({ field: "email", value: email })}
          >
            Search
          </button>
        </div>
      </div>

      <Container fluid>
        <Row className="">
          <Col>
            <TerminalCards
              title="Total Number of Users"
              value={totalUsers}
              color="text-dark"
              textColor="text-dark"
              size="fs-16"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Tier Levels"
              value={totalTierLevels}
              color="text-dark"
              textColor="text-dark"
              size="fs-16"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Completely Verified Users"
              value={totalVerifiedKYC}
              color="text-dark"
              textColor="text-dark"
              size="fs-16"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Users Pending Verification"
              value={totalPendingKYC}
              color="text-dark"
              textColor="text-dark"
              size="fs-16"
            />
          </Col>
        </Row>
        <Row className="my-3 ">
          <Col className=" mb-2 mb-xl-0">
            <TierCards title="Tier 1 Users" value={tier1} />
          </Col>
          <Col className=" mb-2 mb-xl-0">
            <TierCards title="Tier 2 Users" value={tier2} />
          </Col>
          <Col className=" mb-2 mb-xl-0">
            <TierCards title="Tier 3 Users" value={tier3} />
          </Col>
          <Col className=" mb-2 mb-xl-0">
            <TierCards title="Tier 4 Users" value={tier4} />
          </Col>
          {/* <Col className="mb-2 mb-xl-0">
            <TierCards title="Tier 5 Users" value="28,394" />
          </Col> */}
        </Row>

        <div className="admin-users-table">
          <table className="bg-inherit table table-borderless table-responsive w-100">
            <thead>
              <tr className=" t-head-colored">
                <th scope="col" className="py-2 th ">
                  S/N
                </th>
                <th scope="col" className="py-2 th ">
                  CUSTOMER NAME
                </th>
                <th scope="col" className="py-2 th ">
                  EMAIL
                </th>
                <th scope="col" className="py-2 th text-start">
                  PHONE
                </th>
                {/* <th scope="col" className="py-2 th ">
                  BUSINESS TYPE
                </th> */}
                <th scope="col" className="py-2 th text-nowrap">
                  TIER LEVEL
                </th>
                <th scope="col" className="py-2 th text-nowrap">
                  STATUS
                </th>
                <th scope="col" className="py-2 th text-nowrap">
                  DATE
                </th>

                <th scope="col" className="py-2 th text-center">
                  ACTIONS
                </th>
                {/* <th scope="col" className="py-2 th "></th> */}
              </tr>
            </thead>
            <tbody>
              {usersWithKYCList && usersWithKYCList.length > 0 ? (
                usersWithKYCList.map((business, i) => {
                  const {
                    customerEmail: email = "N/A",
                    customerName: name = "N/A",
                    customerPhoneNumber: phone = "N/A",
                    customerId: id = null,
                    modifiedAt,
                    kycStatus,
                  } = business;
                  // console.log(kycStatus instanceof Object)
                  const modifiedDate = modifiedAt
                    ? moment(new Date(modifiedAt)).format("D-MM-YYYY")
                    : "N/A";
                  return (
                    <tr key={i} className=" ">
                      <td className=" td text-wrap">{i + 1}</td>
                      <td className=" td text-wrap">{name}</td>
                      <td className=" td text-wrap">{email}</td>
                      <td className=" td text-wrap">{phone}</td>
                      {/* <td className=" td text-wrap">
                        {businessType ? businessType : "N/A"}
                      </td> */}
                      <td className=" td text-wrap">
                        {kycStatus?.tiers?.id ?? 1}
                      </td>
                      <td className=" td text-wrap">
                        {kycStatus?.tiers?.tiers === "tier4" &&
                        kycStatus?.processFlg
                          ? "COMPLETED"
                          : "INCOMPLETE"}
                      </td>
                      <td className=" td text-wrap">{modifiedDate}</td>
                      <td className="td text-nowrap">
                        <span
                          className="actionDanger"
                          onClick={() => navigate(`/corporate-users/${id}`)}
                        >
                          <Setting /> Manage User’s Tier Levels
                        </span>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </table>
        </div>
        {usersWithKYCList && usersWithKYCList.length > 0 ? (
          <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
            <div className="d-flex me-5">
              <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
              <div className="dropdown">
                <i
                  id="addminuserdropdownMenuButton1"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <FaCaretDown />
                </i>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="adminuserdropdownMenuButton1"
                >
                  <li className="dropdown-item" onClick={() => noPerPage(10)}>
                    10
                  </li>
                  <li className="dropdown-item" onClick={() => noPerPage(20)}>
                    20
                  </li>
                  <li className="dropdown-item" onClick={() => noPerPage(50)}>
                    50
                  </li>
                  <li className="dropdown-item" onClick={() => noPerPage(100)}>
                    100
                  </li>
                </ul>
              </div>
            </div>
            <div className="me-4">
              <p className="m-0">
                {firstIndex} - {lastIndex} of {totalUsers}
              </p>
            </div>
            <div className="mt-3 me-2">
              <Pagination2
                total={Math.ceil(totalUsers / pageSize)}
                paginate={paginate}
                selected={pageNo}
              />
            </div>
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export default Kyc;
