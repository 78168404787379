import React, { useEffect } from "react";
import { toast, Slide } from "react-toastify";
import axios from "../../plugins/axios";
import { getKYCRequirementsByTier, kycStatus } from "../../plugins/urls";
import { FcCancel } from "react-icons/fc";
import { BsCheckCircle } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { useState } from "react";

const Tier2 = (props) => {
  const { id, tierName, completedTier } = props;
  const [state, setState] = useState({
    BVN: {},
    tierLevelStatus: {},
    isSubmitted: 1,
    isCompleted: false,
  });
  const { BVN, isCompleted, isSubmitted } = state;

  const switchStatus = (status) => {
    switch (status.toUpperCase()) {
      case "VERIFIED":
        return (
          <span className="text-sharp-green">
            <i className="me-2">
              <BsCheckCircle size={15} />
            </i>
            {status}
          </span>
        );
      case "UNVERIFIED":
        return (
          <span className="text-red">
            <i className="me-2">
              <FcCancel size={15} />
            </i>
            {status}
          </span>
        );
      case "UNDER REVIEW":
        return (
          <span className="text-yellow">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );
      case "PENDING":
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );

      default:
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            Pending
          </span>
        );
    }
  };
  const findReq = (reqList, reqName) => {
    let reqObj = reqList.find((req) => req.reqItem === reqName);
    return reqObj;
  };
  const getUserKYCRequirements = () => {
    axios({
      method: "get",
      url: `${getKYCRequirementsByTier}/${id}/${tierName}`,
    }).then((res) => {
      console.log(res.data);
      if (res.data.data) {
        let reqList = res.data.data;
        setState((state) => ({
          ...state,
          BVN: findReq(reqList, "BVN") ?? null
        }));
      }
    }).catch((err) => {
      console.log(err.response);
      toast.error(
        `${
          err?.response?.data?.message ??
          err?.response?.data?.error ??
          err.message
        }`,
        {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        }
      );
    });
  };

  useEffect(() => {
    getUserKYCRequirements();
  }, []);
  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="d-flex justify-content-between">
        <div>
          <p className="fs-18 fw-700 text-grey">Tier 2</p>
        </div>

        <div>
          <p className="fs-14 fw-700 text-darker m-0">Status</p>
          <button
              className={` mt-2 ${
                  completedTier === true ? "button-success" : "orange-button"
              }`}
          >
            { completedTier === true ? (
                <span>Completed</span>
            ) : (
                <span>Incomplete</span>
            )}
          </button>
        </div>
      </div>

      <div className="tier p-20 mt-40">
        <div className="   ">
          <table className="bg-inherit table table-borderless table-responsive w-100">
            <thead>
              <tr className="kyc-table" style={{ borderRadius: "5px" }}>
                <th>KYC Requirement</th>
                <th>Status</th>
                <th>Actions</th>
                <th></th>
              </tr>
            </thead>

            <tbody className="align-bottom">
              <tr>
                <td className="tier-td">BANK VERIFICATION NUMBER</td>
                <td className="tier-td ">
                  {BVN?.status ? (
                    switchStatus(BVN.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                <td className=" tier-td">
                  <input
                    disabled
                    className="tier-detail w-100"
                    value={BVN?.reqValue ? BVN.reqValue : ""}
                  />
                </td>
                <td className="tier-td text-end">
                  {/* <span className="tabblack ml-10 ">
                  <Upload size={20} color="#000" /> Upload
                </span>
                <span className="tabblack ml-10">
                  <AiOutlineEye size={20} color="#000" /> View
                </span>
                <span className="tabactive ml-10">
                  <Check size={20} color="#FF6700" /> Approve
                </span>
                <span className="actionDanger ml-10">
                  <Cancel size={20} color="#FF6700" /> Reject
                </span> */}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Tier2;
