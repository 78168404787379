import React, { useState } from "react";
import Header from "./Header/Header";
import SideBar from "./SideBar/SideBar";
import "./Layout.scss";
import NotificationSideBar from "./NotificationSideBar/NotificationSideBar";

const Layout = (props) => {
  const [openNotificationSideBar, setOpenNotificationSideBar] = useState(false);
  return (
    <>
      {props.hidden ? (
        props.children
      ) : (
        <div id="wrapper">
          <div id="header">
            <Header
              title={props.title}
              open={openNotificationSideBar}
              setOpen={setOpenNotificationSideBar}
            />
          </div>

          <div id="sidebar">
            <SideBar title={props.title} />
          </div>

          <div id="main">{props.children}</div>

          <div id="notification-sidebar">
            <NotificationSideBar
              title={props.title}
              open={openNotificationSideBar}
              setOpen={setOpenNotificationSideBar}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Layout;
