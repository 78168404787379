import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import BarChart from "../../components/DashboardComponent/BarChart";
import PieCharts from "../../components/DashboardComponent/PieCharts";
import TransactionCount from "../../components/DashboardComponent/TransactionCount";
import Layout from "../../components/Layout/Layout";
import Switch from "react-switch";
import {
  getTerminalMetricsRequest,
  getRevenueMetricsRequest,
  getMembershipRequest,
} from "../../services/requests/dashboard.request.js";

const Dashboard = () => {
  const [dashboardStatistics, setDashboardStatistics] = useState(null);
  const [membershipMetrics, setMembershipMetrics] = useState(null);
  const navigate = useNavigate();
  let reloadCount = 0;
  useEffect(() => {
    // debugger
    // if (typeof window !== "undefined") {
    // console.log('dash', reloadCount)
    reloadCount = JSON.parse(localStorage.getItem("reloadCount"));
    // console.log(localStorage.getItem('reloadCount'))
    // }
    if (reloadCount === 0) {
      window.location.reload();
      localStorage.setItem("reloadCount", reloadCount + 1);
    }
  });

  useEffect(() => {
    // *****************API CALLS************************
    getRevenueMetrics();
    // *****************API CALLS************************
  }, []);

  const getRevenueMetrics = async () => {
    const totalTerminal = await getTerminalMetricsRequest();
    let revenueMetrics = await getRevenueMetricsRequest();
    const membershipReponse = await getMembershipRequest();
    revenueMetrics = revenueMetrics.respBody;
    setMembershipMetrics(membershipReponse.respBody);
    setDashboardStatistics({
      ...dashboardStatistics,
      totalRegisteredUser: membershipReponse.respBody.totalUser,
      totalTerminalIssued: totalTerminal.respBody.totalTerminals,
      totalRevenueAcquire: revenueMetrics.totalActualAmount,
      totalRevenueSettled: revenueMetrics.totalSettlementAmount,
      totalRevenuePending: revenueMetrics.pending,
      totalRevenueRefunded: revenueMetrics.refunded,
    });
  };

  const [showValues, setShowValues] = useState(false);
  const toggle = () => {
    setShowValues(!showValues);
  };
  return (
    <Layout title="Overview">
      <div className="my-3">
        <h4 className="fs-14 text-default fw-light text-center ">
          WAYAPOS PRODUCT PERFORMANCE METRICS
        </h4>
        <div className="d-flex justify-content-end">
          <div className="show-values-button p-2 d-flex">
            <p className="fs-12 text-dark text-end me-2">
              Show Dashboard Values{" "}
            </p>
            <Switch
              checked={showValues}
              onChange={toggle}
              onColor="#FFDFD2"
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              height={15}
              width={30}
              handleDiameter={15}
              className="react-switch"
              id="material-switch"
            />
          </div>
        </div>
      </div>
      <TransactionCount
        showValues={showValues}
        dashboardStatistics={dashboardStatistics}
      />
      <BarChart membershipMetrics={membershipMetrics} />
      <PieCharts />
    </Layout>
  );
};

export default Dashboard;
