import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useMatch } from "react-router-dom";
import Layout from "../Layout/Layout";

export default function InitiatedDisputeDetails() {
  const match = useMatch("/disputes/initiated/:id");
  const id = match ? match.params.id : "";

  const { state } = useLocation();
  //   console.log(state);

  const {
    adminStatus,
    customer,
    transactionDate,
    dateCreated,
    disputeType,
    transactionReference,
    amount,
    reason,
    merchantID,
    dueWhen,
    merchantName,
    merchantEmail,
    disputeResolution,
  } = state;

  return (
    <Layout>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <Link to="/disputes" className="text-decoration-none">
            <h4 className="text-darker fs-12 fw-700 cursor-pointer">
              <span className="me-1">
                <BiArrowBack size={20} color="#000" />
              </span>
              Back
            </h4>
          </Link>
        </div>
      </div>

      <Container>
        <Row>
          <Col>
            <div
              className="shadow"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 d-flex justify-content-between align-items-center p-2"
                style={{
                  background: "#E9E9E9",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <p className="text-dark fs-14 fw-500">Dispute Details</p>
                  <p
                    style={{
                      background: "#FF6700",
                      width: "2.5em",
                      marginTop: "-1em",
                      padding: "2px",
                    }}
                  ></p>
                </div>
                <p className="text-grey d-flex justify-content-between align-items-center fs-12 fw-500">
                  Status
                  {adminStatus === "OPEN" ? (
                    <span className="ms-2 py-2 px-4 bg-white text-success rounded">
                      Open
                    </span>
                  ) : (
                    <span className="ms-2 py-2 px-4 bg-white text-danger rounded">
                      {adminStatus === "OPEN" ? (
                        <span style={{ color: "#FF6700" }}>Open</span>
                      ) : adminStatus === "CLOSE" ||
                        adminStatus === "CLOSED" ? (
                        <span
                          style={{
                            color: "#27AE60",
                          }}
                        >
                          Closed
                        </span>
                      ) : (
                        <span style={{ color: "#828282" }}>
                          {adminStatus ? adminStatus : "Initiated"}
                        </span>
                      )}
                    </span>
                  )}
                </p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Customer Name</p>
                  <p className="fs-12 fw-bold text-capitalize">
                    {customer ? customer : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Transaction Date</p>
                  <p className="fs-12 fw-bold">
                    {dateCreated
                      ? moment(new Date(dateCreated)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Dispute Initiation Date</p>
                  <p className="fs-12 fw-bold">
                    {transactionDate
                      ? moment(new Date(transactionDate)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "N/A"}
                  </p>
                </div>
                {/* <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-14">Date Created</p>
                  <p className="fw-500 fs-14">
                    {dateCreated ? moment(new Date(dateCreated)).format(
                        "MMMM Do YYYY, hh:mm A"
                      ) : "N/A"}
                  </p>
                </div> */}
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Dispute Type</p>
                  <p className="fs-12 fw-bold">
                    {disputeType ? disputeType : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Reference ID</p>
                  <p className="fs-12 fw-bold">
                    {transactionReference ? transactionReference : "N/A"}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Transaction Amount</p>
                  <p className="fs-12 fw-bold">
                    {amount ? `NGN${amount.toLocaleString()}` : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mt-40 shadow"
              style={{
                background: "#E9E9E9",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#E9E9E9",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">
                  Reason for dispute in details
                </p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3 fs-14">
                <p className="fs-12 fw-bold text-capitalize">
                  {reason ? reason : "N/A"}
                </p>
              </div>
            </div>
          </Col>
          <Col>
            <div
              className="shadow"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">Merchant Details</p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Name</p>
                  <p className="fs-12 fw-bold text-capitalize">
                    {merchantName ? merchantName : "N/A"}
                    {""}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Email Address</p>
                  <p className="fs-12 fw-bold">
                    {merchantEmail ? merchantEmail : "N/A"}
                    {""}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12">Merchant ID</p>
                  <p className="fs-12 fw-bold">
                    {merchantID ? merchantID : "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="mt-40 shadow"
              style={{
                background: "#FFF",
                borderRadius: "10px",
              }}
            >
              <div
                className="shadow fs-16 p-2"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <p className="text-dark fs-14 fw-500">Merchant Response</p>
                <p
                  style={{
                    background: "#FF6700",
                    width: "2.5em",
                    marginTop: "-1em",
                    padding: "2px",
                  }}
                ></p>
              </div>
              <div className="p-3">
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12" style={{ color: "#5D5D5D" }}>
                    Response State
                  </p>
                  <p className="fs-12 fw-bold">
                    {disputeResolution === "declined" ? (
                      <span className="text-danger">Rejected</span>
                    ) : disputeResolution === "accepted" ? (
                      <span className="text-success">Accepted</span>
                    ) : (
                      "..."
                    )}
                  </p>
                </div>
                <div className="td p-2 d-flex justify-content-between align-items-center">
                  <p className="fs-12" style={{ color: "#5D5D5D" }}>
                    Response Due Date
                  </p>
                  <p className="fs-12 fw-bold">
                    {dueWhen
                      ? moment(new Date(dueWhen)).format(
                          "MMMM Do YYYY, hh:mm A"
                        )
                      : "..."}
                  </p>
                </div>
                <div className="mt-10 fs-14">
                  <label className="py-1 fs-12">
                    Reason for rejecting Chargeback
                  </label>
                  <textarea
                    name="merchantReason"
                    id="merchantReason"
                    className="fs-12 p-2"
                    placeholder="Merchant reason for rejecting dispute"
                    cols="75"
                    rows="5"
                    readOnly
                  ></textarea>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
