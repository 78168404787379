import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import Logo  from "../../../assets/icons/newLogo.png";
import { ReactComponent as Logout } from "../../../assets/icons/logout.svg";
import { ReactComponent as SideWayaBank } from "../../../assets/icons/side_wayabank.svg";
import { ReactComponent as SideWayaGram } from "../../../assets/icons/side_wayagram.svg";
import { ReactComponent as SideWayaWeb } from "../../../assets/icons/side_web.svg";
import { getAssignedMenu } from "../../../plugins/urls";
import { Icon } from "../../../utilities/handlers";
import Divider from "../../Divider/Divider";
import { menuOptions } from "./data";
import "./side.scss";

const SideBar = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  const [menuItem, setMenuItems] = useState();

  const userId = JSON.parse(localStorage.getItem("userDetails")).user.id;
  const getUserAssignedMenu = () => {
    axios.get(getAssignedMenu).then((res) => {
      if (res.status === 200 && res.data.status === true) {
        const {
          roleName,
          menu: { menuString },
        } = res.data.data;
        setMenuItems({ roleName, menu: menuString });
      }
    });
  };

  useEffect(() => {
    getUserAssignedMenu();
  }, [userId, menuItem?.menu.length]);

  const { roleName, menu } = menuItem || {};
  return (
    <div className="Sidebar">
      <div className="side-padding">
        <img src={Logo} width="120px" />
      </div>

      <div className="NavigationBox">
        {menuOptions.map((i) => {
          if (i.subOptions) {
            return (
              <ul
                className={`${
                  props.title === i.title ? "Navigation-active" : "Navigation"
                }
               ${
                 roleName === "SUPER_ADMIN" ||
                 "OWNER" ||
                 i.option.some((r) => menu?.includes(r))
                   ? "visible"
                   : "invisible"
               }`}
              >
                <li
                  className="dropdown"
                  href={`#${i.id}`}
                  data-toggle="collapse"
                >
                  <Icon
                    IconComp={i.IconComp}
                    className={
                      props.title === i.title ? "activeIcon" : "sideIcon"
                    }
                    size={20}
                  />
                  <NavLink to="#">{i.label}</NavLink>

                  <ul className="collapse list-unstyled nav-link" id={i.id}>
                    {i.subOptions.map((sub) => {
                      return (
                        <>
                          {(roleName === "SUPER_ADMIN" ||
                            "OWNER" ||
                            menu?.includes(sub.option)) && (
                            <li className="submenu">
                              <NavLink
                                to={sub.route}
                                activeclassname="selected"
                              >
                                {sub.label}
                              </NavLink>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </li>
              </ul>
            );
          } else {
            return (
              <ul
                className={`${
                  props.title === i.title ? "Navigation-active" : "Navigation"
                } ${
                  roleName === "SUPER_ADMIN" ||
                  "OWNER" ||
                  menu?.includes(i.option)
                    ? "visible"
                    : "invisible"
                }`}
              >
                <li>
                  <Icon
                    IconComp={i.IconComp}
                    className={
                      props.title === i.title ? "activeIcon" : "sideIcon"
                    }
                    size={20}
                  />
                  <NavLink to={i.route}>{i.label}</NavLink>
                </li>
              </ul>
            );
          }
        })}
      </div>

      <Divider />

      <div className="NavigationBox">
        <ul className="Navigation">
          <li>
            <SideWayaBank className="sideIcon" size={20} />
            <a href="https://wayabank.ng" target="_blank" rel="noreferrer">
              Wayabank
            </a>
          </li>
        </ul>
        <ul className="Navigation">
          <li>
            <SideWayaGram className="sideIcon" size={20} />
            <a href="https://wayagram.ng" target="_blank" rel="noreferrer">
              Wayagram
            </a>
          </li>
        </ul>
        <ul className="Navigation">
          <li>
            <SideWayaWeb className="sideIcon" size={20} />
            <a href="https://wayapay.ng" target="_blank" rel="noreferrer">
              Wayapay
            </a>
          </li>
        </ul>

        <ul className="Navigation">
          <li onClick={logout}>
            <Logout className="sideIcon" size={20} />
            <NavLink to="/">Log Out</NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideBar;
