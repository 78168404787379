import React, { useState, useEffect } from "react";
import axios from "axios";
import { allUsers } from "../../plugins/urls";

export default function DeactivateSingleUser({ onChange }) {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      let response = await axios({
        method: "get",
        url: `${allUsers}?size=1000`,
      });
      let usersList = response.data.users;
      setUsers(usersList);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <form action="" id="user-select-form">
      <div className="input-div mb-3">
        <label htmlFor="users" className="mb-1">
          Select User
        </label>
        <div className="input-container">
          <select
            className="input select w-100 text-capitalize"
            name="users"
            id="users"
            onChange={onChange}
          >
            <option>{"--Select user--"}</option>
            {users?.map(
              ({ userId, active, otherDetails: { organisationName } }) => (
                <option key={userId} value={JSON.stringify({ userId, active })}>
                  {organisationName}
                </option>
              )
            )}
          </select>
        </div>
      </div>
    </form>
  );
}
