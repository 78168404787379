import React, { useEffect, useState, createContext } from "react";
import Layout from "../../components/Layout/Layout";
import Tier1 from "../../components/UsersComponents/Tier1";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { CgArrowLongLeft, CgArrowLongRight } from "react-icons/cg";
import { useMatch, useNavigate } from "react-router";
import { Col, Container, Row } from "react-bootstrap";
import axios from "../../plugins/axios";
import { kycStatus } from "../../plugins/urls";
import Tier2 from "../../components/UsersComponents/Tier2";
import Tier3 from "../../components/UsersComponents/Tier3";
import Tier4 from "../../components/UsersComponents/Tier4";
import { Slide, toast } from "react-toastify";

export const TierContext = createContext();

const TierLevel = () => {
  const [state, setState] = useState({
    level: 1,
    completedTier: "",
    tiers: [],
  });
  const { level } = state;
  const navigate = useNavigate();
  const match = useMatch("/corporate-users/:id");
  const id = match ? match.params.id : "";

  const getUserKYCStatus = () => {
    axios({
      method: "get",
      url: `${kycStatus}/${id}`,
    }).then((res) => {
      let tiers = res.data.data;
      let tierEnts = {
        [`tier${tiers[0]?.tiers?.id}Completed`]: tiers[0]?.processFlg,
        [`tier${tiers[1]?.tiers?.id}Completed`]: tiers[1]?.processFlg,
        [`tier${tiers[2]?.tiers?.id}Completed`]: tiers[2]?.processFlg,
        [`tier${tiers[3]?.tiers?.id}Completed`]: tiers[3]?.processFlg,
      };

      setState((state) => ({
        ...state,
        ...tierEnts,
      }));
    });
  };

  useEffect(() => {
    getUserKYCStatus();
  }, [level]);

  const renderPages = () => {
    switch (level) {
      case 1:
        return (
          <Tier1
            id={id}
            tierName="tier1"
            completedTier={state?.tier1Completed}
          />
        );
      case 2:
        return (
          <Tier2
            id={id}
            tierName="tier2"
            completedTier={state?.tier2Completed}
          />
        );
      case 3:
        return (
          <Tier3
            id={id}
            tierName="tier3"
            completedTier={state?.tier3Completed}
          />
        );
      case 4:
        return (
          <Tier4
            id={id}
            tierName="tier4"
            completedTier={state?.tier4Completed}
          />
        );
      // case 5:
      //     return <Tier5 id={id} tierName="tier5" />;
      default:
        return <Tier1 id={id} tierName="tier1" />;
    }
  };

  const onClickNext = () => {
    let step = level;
    if (state && !state[`tier${level}Completed`]) {
      toast.error(`Kindly complete Tier level ${level} to proceed`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } else {
      step = step >= 3 ? 4 : step + 1;
      setState((state) => ({
        ...state,
        level: step,
      }));
    }
    // If the current step is 1 or 2, then add one on "next" button click
  };

  const onClickPrev = () => {
    let step = level;
    // If the current level is 2 or 3 or 4, then subtract one on "previous" button click
    step = step <= 1 ? 1 : step - 1;
    setState((state) => ({
      ...state,
      level: step,
    }));
  };
  return (
    <Layout title="Corporate Users">
      <TierContext.Provider value={{}}>
        <Container>
          <Row className="font-default">
            <Col lg={4}>
              <h4
                className="text-darker fs-14 fw-700 cursor-pointer"
                onClick={() => navigate(-1)}
              >
                <span className="mr-15">
                  <Back />
                </span>
                Back
              </h4>
            </Col>
            <Col>
              <p className="text-darker fs-18 fw-500 cursor-pointer">
                Upgrade KYC Level
              </p>
            </Col>
          </Row>
          <div className="tier p-30">
            {renderPages()}

            <div className="d-flex justify-content-center">
              <div className="kyc-action d-flex align-items-center px-2">
                <p
                  className="mb-0 fs-14 text-default cursor-pointer"
                  onClick={onClickPrev}
                >
                  <i className="me-2 text-default">
                    <CgArrowLongLeft />
                  </i>{" "}
                  Previous
                </p>
                <span
                  className="ml-22 mb-0 fs-14 text-default fw-700"
                  style={{
                    background: "#F8F8F8",
                    border: "1px solid #E0E0E0",
                    padding: "0.4em 0.8em",
                  }}
                >
                  {level}
                </span>
                <p
                  className="mb-0 fs-14 text-default cursor-pointer ml-22"
                  onClick={onClickNext}
                >
                  Next
                  <i className="ms-2 text-default">
                    <CgArrowLongRight />
                  </i>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </TierContext.Provider>
    </Layout>
  );
};

export default TierLevel;
