import React from "react";

const data = [
  {
    label: "Station Name",
    name: "name",
    required: true,
    NotEditAbleOnEdit: true,
  },
  {
    label: "status",
    name: "status",
    required: true,
    options: ["ACTIVE", "INACTIVE"],
  },
  { label: "Last Echo", name: "last_echo" },
  { label: "Station Last ZPK Change", name: "last_zpk_change" },
  { label: "Station New ZPK_KCV", name: "new_zpk_kcv" },
  { label: "Station ZMK", name: "zmk" },
  { label: "Station ZMK_KCV", name: "zmk_kcv" },
  { label: "Station ZPK", name: "zpk" },
  { label: "Station ZPK_KCV", name: "zpk_kcv" },
];

const AddStation = (props) => {
  const { onChange, modalValue, formData } = props;
  return (
    <form>
      {data.map(({ label, name, NotEditAbleOnEdit, options }) => (
        <div className="input-div">
          <label className="text-darker fs-14">{label}</label>
          <div
            className={`${
              modalValue === "edit" && NotEditAbleOnEdit
                ? "container-disabled"
                : null
            } input-container`}
          >
            {options ? (
              <select
                name={name}
                className="input select"
                value={formData[name] ?? ""}
                onChange={onChange}
              >
                <option>{"Select status"}</option>
                {options.map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </select>
            ) : (
              <input
                className="input"
                type="text"
                name={name}
                value={formData[name] ?? ""}
                disabled={modalValue === "edit" && NotEditAbleOnEdit}
                required
                onChange={onChange}
              />
            )}
          </div>
        </div>
      ))}
    </form>
  );
};

export default AddStation;
