import React from "react";

export default function ChangeBusinessOwner() {
  return (
    <div>
      <div>
        <p className="text-center p-2 my-2 fs-16">
          This action will transfer this business to another administrator. You
          will no longer be the owner of this business, and so you can be
          removed from it.
        </p>
        <div className="my-4 fs-14">
          <div className="row w-100 my-6 mx-auto">
            <label className="text-grey fw-500">
              New Business Owner's Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="input-styles mt-2"
            />
          </div>
          <div className="row w-100 my-4 mx-auto">
            <label className="text-grey fw-500">
              New Business Owner's Mobile Number
            </label>
            <input type="tel" name="tel" id="tel" className="input-styles mt-2" />
          </div>
        </div>
      </div>
    </div>
  );
}
