import React, { useState } from "react";
import axios from "../../plugins/axios";
import { Slide, toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import { CustomModal } from "../Modal/CustomModal";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import "./EditPricingForm.css";
import SelectUserType from "./SelectUserType";
import { updateAllPricingByProductName } from "../../plugins/urls";

export default function EditPricingForm(props) {
  const [state, setState] = useState({
    add: false,
    submit: false,
    remove: false,
    selectAllUsers: true,
    users: [],
    pricingRate: 0,
    cap: 0,
    discount: 0,
  });
  const {
    add,
    submit,
    remove,
    users,
    pricingRate,
    discount,
    cap,
    selectAllUsers,
  } = state;
  const [isChecked, setIsChecked] = useState(true);

  const handleAllUserChange = () => {
    setIsChecked(!isChecked);
    setState((state) => ({
      ...state,
      selectAllUsers: true,
    }));
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const showModal = () => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        selectAllUsers: false,
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
        selectAllUsers: true,
      }));
    }
  };

  const showRemoveModal = () => {
    if (!remove) {
      setState((state) => ({
        ...state,
        remove: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        remove: false,
      }));
    }
  };

  const saveChanges = () => {
    let reqBody = {
      discount,
      pricingRate,
      cap,
    };

    const products = ["CARD", "PAYATTITUDE"];
    setState((state) => ({
      ...state,
      submit: true,
    }));
    if (props.formTitle === "Card Acquiring") {
      axios({
        method: "put",
        url: `${updateAllPricingByProductName}?product=${products[0]}`,
        data: reqBody,
      })
        .then((res) => {
          if (res.data.respCode === 0) {
            // const { pricingRate, cap, discount } = res.data.respBody;
            setState((state) => ({
              ...state,
              pricingRate: pricingRate,
              cap: cap,
              submit: false,
              discount: discount,
            }));
            toast.success(`${res.data.respDesc}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    } else if (props.formTitle === "PayAttitude Acquiring") {
      axios({
        method: "put",
        url: `${updateAllPricingByProductName}?product=${products[1]}`,
        data: reqBody,
      })
        .then((res) => {
          console.log("Pricing by Product: ", res.data);
          // console.log("Pricing by Product: ", res.data.respBody);
          if (res.data.respCode === 0) {
            // const { pricingRate, cap, discount } = res.data.respBody;
            setState((state) => ({
              ...state,
              pricingRate: pricingRate,
              cap: cap,
              discount: discount,
              submit: false,
            }));
            toast.success(`${res.data.respDesc}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    } else {
      return;
    }
  };

  return (
    <>
      <CustomModal
        show={add}
        clicked={showModal}
        loading={submit}
        submit={showRemoveModal}
        action="Proceed"
        size="lg"
        disabledProps={true}
      >
        <SelectUserType
          showRemoveModal={showRemoveModal}
          remove={remove}
          users={users}
          onChange={onChange}
        />
      </CustomModal>
      <Col
        className="mt-20 bg-white"
        style={{ border: "1px solid grey", borderRadius: "10px" }}
      >
        <div className="row mt-20 text-center">
          <h3
            style={{ color: "#FF6700", fontSize: "1.4rem" }}
            className="fw-700"
          >
            {props.formTitle}
          </h3>
        </div>
        <Row className="mt-20">
          <div className="ms-4 text-start" style={{ width: "max-content" }}>
            <label className="checkbox-container fs-6 fw-500">
              <span className="ms-1 fs-14">Select all Users</span>
              <input
                type="checkbox"
                checked={isChecked}
                value="Users"
                onChange={handleAllUserChange}
                onClick={showModal}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Row>
        <div
          className="p-4 m-4"
          style={{ border: "1px solid grey", borderRadius: "20px" }}
        >
          <div className="text-start">
            <div className="mb-4">
              <label className="fw-bold fs-14">Rate</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control fs-14"
                  aria-label="Percentage"
                  name="pricingRate"
                  value={pricingRate === 0 ? "" : pricingRate}
                  onChange={onChange}
                />
                <span
                  className="input-group-text fs-4 px-4"
                  style={{ background: "#555", color: "#fff" }}
                >
                  %
                </span>
              </div>
            </div>
            <div className="mb-4">
              <label className="fw-bold fs-14">Discount</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control p-2 fs-14"
                  aria-label="Percentage"
                  name="discount"
                  value={discount === 0 ? "" : discount}
                  onChange={onChange}
                />
                <span
                  className="input-group-text fs-4 px-4"
                  style={{ background: "#555", color: "#fff" }}
                >
                  %
                </span>
              </div>
            </div>
            <div className="mb-4">
              <label className="fw-bold fs-14">Processing fees capped at</label>
              <div className="input-group">
                <span
                  className="input-group-text fs-6 px-5 fw-600"
                  style={{ background: "#DDD" }}
                >
                  NGN
                </span>
                <input
                  type="text"
                  className="form-control fs-14"
                  style={{ paddingBlock: "14px" }}
                  aria-label="Percentage"
                  name="cap"
                  value={cap === 0 ? "" : cap}
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="orange-button fs-14 fw-700 py-3 px-5 m-4"
              onClick={saveChanges}
            >
              {props.loading ? <SubmitLoader /> : "Save Changes"}
            </button>
          </div>
        </div>
      </Col>
    </>
  );
}
