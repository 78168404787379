import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { Link, useLocation, useMatch } from "react-router-dom";
import Layout from "../Layout/Layout";
import ResolutionState from "./ResolutionState";

export default function DisputeResolution() {
  const match = useMatch("/disputes/resolution/:id");
  const id = match ? match.params.id : "";

  const { state } = useLocation();

  const [showResolutionState, setShowResolutionState] = useState(false);

  const toggleShow = () => {
    setShowResolutionState(!showResolutionState);
  };

  return (
    <Layout>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <Link to="/disputes" className="text-decoration-none">
            <h4 className="text-darker fs-12 fw-700 cursor-pointer">
              <span className="me-1">
                <BiArrowBack size={20} color="#000" />
              </span>
              Back
            </h4>
          </Link>
        </div>
      </div>

      <Container fluid="lg">
        <Row
          className="shadow p-2"
          style={{
            background: "#FFF",
            borderRadius: "10px",
          }}
        >
          <Col>
            <div className="me-4">
              <p className="text-grey d-flex justify-content-between align-items-center fs-14 fw-500 p-2">
                Resolution State{" "}
                <span className="ms-2 py-2 px-4 fs-12 bg-white text-success rounded shadow text-capitalize">
                  {state.disputeResolution === "accepted" ? (
                    <span className="text-success">Accepted</span>
                  ) : state.disputeResolution === "declined" ? (
                    <span className="text-danger">Rejected</span>
                  ) : (
                    <span className="text-grey">{"Nil"}</span>
                  )}
                </span>
              </p>
              <div className="p-2 d-flex justify-content-between align-items-center">
                <p className="fs-14">Merchant Name</p>
                <p className="fs-12 fw-bold">
                  {state ? state.merchantName : "N/A"}
                </p>
              </div>
              <div className="p-2 d-flex justify-content-between align-items-center">
                <p className="fs-14">Resolution Date</p>
                <p className="fs-12 fw-bold">
                  {state
                    ? moment(new Date(state.resolutionDate)).format(
                        "MMMM Do YYYY, hh:mm A"
                      )
                    : ""}
                </p>
              </div>
            </div>
          </Col>

          <Col>
            <div className="ms-4">
              <p className="fs-14">
                This dispute has been{" "}
                {state.disputeResolution === "accepted" ? (
                  <span>accepted</span>
                ) : state.disputeResolution === "declined" ? (
                  <span>rejected</span>
                ) : (
                  <span className="text-grey">{"Nil"}</span>
                )}{" "}
                and merchant have{" "}
                <span className="text-uppercase">
                  {state.status === "WON" ? (
                    <span className="text-success">WON</span>
                  ) : state.status === "LOST" ? (
                    <span className="text-danger">LOST</span>
                  ) : (
                    <span className="text-grey">{"Nil"}</span>
                  )}
                </span>
                . Click the link below to view more details about this dispute.
              </p>
              <button
                className="py-2 px-4 fs-14 fw-500 bg-white rounded border-0 shadow"
                style={{ color: "#FF6700" }}
                onClick={toggleShow}
              >
                View details
              </button>
            </div>
          </Col>
        </Row>
        <div>{showResolutionState && <ResolutionState data={state} />}</div>
      </Container>
    </Layout>
  );
}
