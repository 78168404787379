import React from "react";
import Layout from "../../components/Layout/Layout";
import { RemoteUpdateComp } from "./RemoteUpdateComp";

const RemoteUpdate = () => {
  return (
    <Layout title="TMS">
      <div className="data-table mt-40">
        <RemoteUpdateComp />
      </div>
    </Layout>
  );
};

export default RemoteUpdate;
