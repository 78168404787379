import React, { useContext, useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";
import Pagination2 from "../../Pagination/pagination2";
import { SimpleTableContext } from "./Table";

const TableFooter = ({ setPageFn }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { pageNo, pageSize, setPageNo, setPageSize, totalElements } =
    useContext(SimpleTableContext);
  const items = [5, 10, 15, 20, 50, 100];
  const paginate = (ev) => {
    let selected = ev.selected;
    setPageFn({ pageNumber: selected, pageSize });
    setPageNo(selected);
    setCurrentPage(selected + 1);
  };
  
  // useEffect(() => {
  //   setPageFn({ pageNumber: pageNo, pageSize });
  // }, [pageSize, pageNo]);

  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;

  const noPerPage = (no) => {
    setPageSize(no);
  };
  return (
    <>
      <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
        <div className="d-flex me-5">
          <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
          <div className="dropdown">
            <i
              id="addminuserdropdownMenuButton1"
              data-toggle="dropdown"
              aria-expanded="false"
            >
              <FaCaretDown />
            </i>
            <ul
              className="dropdown-menu"
              aria-labelledby="adminuserdropdownMenuButton1"
            >
              {items.map((i) => (
                <li className="dropdown-item" onClick={() => noPerPage(i)}>
                  {i}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="me-4">
          <p className="m-0">
            {firstIndex} - {lastIndex} of {totalElements}
          </p>
        </div>
        <div className="mt-3 me-2">
          <Pagination2
            total={Math.ceil(totalElements / pageSize)}
            paginate={paginate}
            selected={currentPage}
          />
        </div>
      </div>
    </>
  );
};

export default TableFooter;
