import React from 'react'
import './container.scss'
import Logo from '../../../assets/img/newLogo.png'

const AuthContainer = (props) => {
  return (
    <div className="auth-container">
      <div className="text-center">
        <img src={Logo} width='150px'/>
      </div>
        {props.children}
    </div>
  )
}

export default AuthContainer