import { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import { Container, Table } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";
import { BsPencilFill } from "react-icons/bs";
import { MdSettings } from "react-icons/md";
import { IoReload } from "react-icons/io5";
import LargeModal from "../../components/Modal/LargeModal";
import Modal from "../../components/Modal/Modal";
import {allCommission} from "../../plugins/urls";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";

const Commissions = () => {
  const [state, setState] = useState({
    openManage: false,
    openEdit: false,
    submit: false,
  });

  const navigate = useNavigate();

  const getCommission = () => {
    axios({
      method: "get",
      url: `${allCommission}`,
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            terminalTypesList: res.data.respBody,
          }));
        }
      })
      .catch((err) => {
        if (err.response.data.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(()=>{
    getCommission();
  },[])

  const { openManage, openEdit, submit } = state;
  const openManageModal = () => {
    setState((state) => ({
      ...state,
      openManage: true,
    }));
  };
  const openEditModal = () => {
    setState((state) => ({
      ...state,
      openEdit: true,
    }));
  };

  const closeManageModal = () => {
    setState((state) => ({
      ...state,
      openManage: false,
    }));
  };
  const closeEditModal = () => {
    setState((state) => ({
      ...state,
      openEdit: false,
    }));
  };

  return (
    <Layout>
      <Container>
        <div className="d-flex justify-content-between align-items-center p-1">
          <div className="d-flex align-items-center s w-50">
            <div className="tabdanger p-2 rounded me-2 text-red">
              <select className="input  text-red">
                <option>Search By...</option>
                <option>nbjvgsbh</option>
              </select>
            </div>
            <div className="border-red rounded p-2 d-flex  align-items-center w-75 ">
              <div className="">
                <FiSearch color="#FF6700" />
              </div>
              <input
                className="input ml-10 "
                placeholder="Search with terminal id or merchant id"
              />
            </div>
          </div>
          <div>
            <button
              className="orange-button px-2 d-flex align-items-center"
              onClick={openManageModal}
            >
              <i className="me-1">
                <MdSettings size={18} />
              </i>
              Manage Commissions
            </button>
          </div>
        </div>
        <div className="mt-3">
          <Table responsive borderless className="bg-inherit mb-5">
            <thead className="t-head-colored text-uppercase">
              <tr className="py-3 ">
                <th className="py-3 th ">BILLERS</th>
                <th className="py-3 th ">
                  general commision to corporate account
                </th>
                <th className="py-3 th ">commission to agents</th>
                <th className="py-3 th ">commission to agent aggregator</th>
                <th className="py-3 th ">BUSINESS NAME</th>
                <th className="py-3 th ">corporate user id</th>
                <th className="py-3 th ">corporate user phone number</th>
                <th className="py-3 th ">corporate user email</th>
                <th className="py-3 th "></th>
              </tr>
            </thead>
            <tbody className="">
              <tr className="">
                <td className=" td ">MTN</td>
                <td className=" td ">{3}</td>
                <td className=" td ">{3}%</td>
                <td className=" td ">{3}%</td>
                <td className=" td ">Shoprite</td>
                <td className=" td ">WA3322008</td>
                <td className=" td ">09052378230</td>
                <td className=" td ">philiptemplar@gmail.com</td>
                <td className="td text-theme d-flex flex-nowrap ">
                  <i className="me-3" onClick={openEditModal}>
                    <BsPencilFill />
                  </i>
                  <i className="me-3">
                    <IoReload />
                  </i>
                  <i className="">
                    <FaTrashAlt />
                  </i>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="t-head-colored mt-5 py-4 w-100"></div>
        </div>
        <LargeModal
          show={openManage}
          clicked={closeManageModal}
          //   submit={onCreateAdmin}
          loading={submit}
          title="Manage Commissions"
          action="Save Changes"
        >
          <div class="row flex align-items-end fs-12">
            <div class="col-12 col-lg ">
              <div className="commssion-input-wrapper ">
                <label className="text-darker fs-12 mb-1">Biller</label>
                <select className="commision-input p-1 w-100">
                  <option>MTN</option>
                  <option></option>
                </select>
              </div>
            </div>
            <div class="col-12 col-lg">
              <div className="commssion-input-wrapper">
                <label className="text-darker fs-12 mb-1 ">
                  General Commission to Corperate Account
                </label>
                <input
                  className="commision-input p-1"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
            <div class="col-12 col-lg ">
              <div className="commssion-input-wrapper">
                <label className="text-darker fs-12 mb-1 ">
                  Commission to Agents
                </label>
                <input
                  className="commision-input p-1"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
            <div class="col-12 col-lg ">
              <div className="commssion-input-wrapper">
                <label className="text-darker fs-12 mb-1">
                  Commission to Ageent Aggregator
                </label>
                <input
                  className="commision-input p-1"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
          </div>

          <div className="row mt-4 fs-13">
            <div class="col-12 ">
              <div className="commssion-input-wrapper w-100 mb-3">
                <label className="text-darker fs-12 mb-1 ">
                  Set Specific Users to Commissions
                </label>
                <input
                  className="commision-input p-1 w-100"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
            <div class="col-12 ">
              <div className="commssion-input-wrapper w-100">
                <label className="text-darker fs-12 mb-1 ">
                  Set commission for the selected users
                </label>
                <input
                  className="commision-input p-1 w-100"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
            <div>
              <button
                className="rounded text-white p-2 border-0 "
                style={{ backgroundColor: " #6A6A6A" }}
              >
                View all users with specific commissions
              </button>
            </div>
          </div>
        </LargeModal>
        <Modal
          show={openEdit}
          clicked={closeEditModal}
          //   submit={onCreateAdmin}
          loading={submit}
          title="Edit Specific Commission"
          action="Save Changes"
        >
          <div class="row  fs-12">
            <div class="col-12 col-lg ">
              <div className="commssion-input-wrapper ">
                <label className="text-darker fs-12 mb-1">Biller</label>
                <select className="commision-input p-1 w-100">
                  <option>MTN</option>
                  <option></option>
                </select>
              </div>
            </div>
            <div class="col-12 ">
              <div className="commssion-input-wrapper w-100">
                <label className="text-darker fs-12 mb-1 ">
                  Set commission for the selected users
                </label>
                <input
                  className="commision-input p-1 w-100"
                  type="text"
                  name="terminalId"
                  // value={terminalId}
                  required
                  // onChange={onChange}
                />
              </div>
            </div>
          </div>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Commissions;
