import React from "react";
import { Container } from "react-bootstrap";
import { CollapsibleComponent } from "./CollapsibleComponent";

export default function AgencyPricings() {
  return (
    <Container fluid="lg">
      <div className="text-center p-4">
        <h5 className="fw-600">Edit Pricings</h5>
      </div>
      <CollapsibleComponent
        title="Top up via Card"
        formTitle="Top up via Card"
      />
      <CollapsibleComponent
        title="Top up via PayAttitude"
        formTitle="Top up via PayAttitude"
      />
    </Container>
  );
}
