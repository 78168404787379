import "./FileUpload.css";

const DeactivateMultipleUsers = ({
  fileUploadHandler,
  selectedFile,
  isFilePicked,
}) => {
  return (
    <div>
     <div className="d-flex justify-content-center align-items-center mt-20">
        <span>
          <p className="fs-14">Select Excel Sheet</p>
          <input
            type="file"
            name="file"
            id="inputGroupFile02"
            // value={selectedFile}
            accept=".xls,.xlsx"
            className="custom-file-input-upload w-100"
            aria-describedby="basic-addon2"
            onChange={fileUploadHandler}
          />
        </span>
        <label
          className="tabblack browse-btn text-grey bg-lightgrey"
          htmlFor="inputGroupFile02"
          style={{marginTop:"2.33em", paddingBlock:"0.7em"}}
        >
          Browse
        </label>
      </div>
      {isFilePicked ? (
        <div className="px-4" style={{ fontSize: "12px" }}>
          <p>
            <strong> Filename: </strong> {selectedFile.name}
          </p>
          <p>
            <strong>Type: </strong> {selectedFile.type}
          </p>
          <p>
            <strong>Size in bytes: </strong>
            {selectedFile.size}
          </p>
          <p>
            <strong>lastModifiedDate: </strong>
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p>
        </div>
      ) : (
        <p className="px-4" style={{ fontSize: "12px" }}>Select a file to show details</p>
      )}
    </div>
  );
};

export default DeactivateMultipleUsers;
