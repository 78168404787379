import React, { useState } from "react";

const AddAdmin = (props) => {
  const {
    firstName,
    surname,
    email,
    phoneNumber,
    dateOfBirth,
    gender,
    role,
    password,
    adminType,
    onChange,
    handleNumChange,
  } = props;

  const [roles, setDefaultRole] = useState({});
  return (
    <form>
      <div className="container-fluid ">
        <div className="row mb-3">
          <div className="col-12 col-md-6 ">
            <label className="fs-12 fw-400 mb-1">First Name</label>
            <input
              className="admin-user-inputs"
              placeholder=""
              type="text"
              name="firstName"
              value={firstName}
              required
              onChange={onChange}
            />
          </div>
          <div className="col-12 col-md-6  ">
            <label className="fs-12 fw-400 mb-1">Last Name</label>
            <input
              className="admin-user-inputs"
              type="text"
              name="surname"
              value={surname}
              required
              onChange={onChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6">
            <label className="fs-12 fw-400 mb-1">Email Address</label>
            <input
              className="admin-user-inputs"
              type="text"
              name="email"
              value={email}
              required
              onChange={onChange}
            />
          </div>
          <div className="col-12 col-md-6">
            <label className="fs-12 fw-400 mb-1">Phone Number</label>
            <input
              className="admin-user-inputs"
              type="number"
              maxLength={11}
              name="phoneNumber"
              value={phoneNumber}
              required
              onChange={handleNumChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12 col-md-6 ">
            <label className="fs-12 fw-400 mb-1">Date of Birth</label>
            <input
              className="admin-user-inputs"
              // placeholder="DD/MM/YYYY"
              type="date"
              name="dateOfBirth"
              value={dateOfBirth}
              required
              onChange={onChange}
            />
          </div>
          <div className="col-12 col-md-6 ">
            <div className="d-flex flex-column">
              <label className="fs-12 fw-400 mb-1">Gender</label>
              <select
                className="admin-user-inputs my-1 "
                value={gender}
                name="gender"
                required
                onChange={onChange}
              >
                <option value="">Gender</option>
                <option value="MALE">Male</option>
                <option value="FEMALE">Female</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 ">
            <div className="d-flex flex-column">
              <label className="fs-12 fw-400 mb-1">Admin Type</label>
              <select
                className="admin-user-inputs my-1 "
                value={adminType}
                name="adminType"
                required
                onChange={onChange}
              >
                <option value="">Select admin Type</option>
                <option value={"OWNER"}>{"OWNER"}</option>
                <option value={"SUPER_ADMIN"}>{"SUPER ADMIN"}</option>
                <option value={"ADMIN"}>{"ADMIN"}</option>
              </select>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <label className="fs-12 fw-400 mb-1">Password</label>
            <input
              className="admin-user-inputs"
              type="password"
              name="password"
              value={password}
              required
              onKeyUp={props.handlePasswordValidation}
              onChange={props.onChange}
            />
            <p className="text-danger fs-12">{props.passwordErr}</p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AddAdmin;
