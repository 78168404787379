import { useState, useMemo, useEffect } from "react";
import moment from "moment";
import axios from "../../../plugins/axios";
import { Col, Container, Pagination, Row, Table } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { IoCaretBack } from "react-icons/io5";
import { Link, useMatch } from "react-router-dom";
import Layout from "../../Layout/Layout";
import NoResultFound from "../../NoResultFound/NoResultFound";
import { getReferredUsers } from "../../../plugins/urls";
import { Slide, toast } from "react-toastify";

let pagesize = 10;

const ReferralList = () => {
  const match = useMatch("/referral-list/:referralCode");
  const refCode = match ? match.params.referralCode : "";

  const [state, setState] = useState({
    usersReferralList: [],
  });

  const { usersReferralList } = state;
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pagesize;
    const lastPageIndex = firstPageIndex + pagesize;
    return usersReferralList.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, usersReferralList]);


  useEffect(() => {
    getUsersReferrals();
  }, []);

  const getUsersReferrals = () => {
    axios({
      method: "get",
      url: `${getReferredUsers}/${refCode}`,
    })
      .then((res) => {
        console.log("Users Referrals List: ", res.data.data);
        if (res.data) {
          setState((state) => ({
            ...state,
            usersReferralList: res.data.data.referredUsers,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <Link to="/referrals" className="text-decoration-none">
              <h4 className="text-darker fs-14 fw-700 cursor-pointer">
                <span className="mr-15">
                  <IoCaretBack />
                </span>
                Back
              </h4>
            </Link>
          </Col>
        </Row>

        <Row>
          <div className="d-flex justify-content-start align-items-center w-50 mt-40">
            <div className="d-flex justify-content-center align-items-center filter-search ml-22">
              <div
                className="input_Search d-flex justify-content-center align-items-center "
                style={{ marginLeft: "-1em" }}
              >
                <div className="justify-content-center align-items-center">
                  <FiSearch color="#FF6700" />
                </div>
                <input
                  className="input ml-10"
                  placeholder="Search Users by Name, Email or Date"
                />
              </div>
              <button className="orange-button ml-10 fs-14 py-2">Search</button>
            </div>
          </div>
        </Row>

        <Row className="mt-40">
          <div className="d-flex justify-content-start align-items-center">
            <div className="d-flex justify-content-center align-items-center mr-30">
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle change-button d-flex justify-content-between fs-14"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: "160px" }}
                >
                  Location
                </button>
                <div className="dropdown-menu fs-14">
                  <a className="dropdown-item" href="#*">
                    Location 1
                  </a>
                  <a className="dropdown-item" href="#*">
                    Location 2
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center mr-30">
              <div className="btn-group">
                <button
                  className="btn dropdown-toggle change-button d-flex justify-content-between fs-14"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  style={{ width: "160px" }}
                >
                  Date
                </button>
                <div className="dropdown-menu fs-14">
                  <a className="dropdown-item" href="#*">
                    1
                  </a>
                  <a className="dropdown-item" href="#*">
                    2
                  </a>
                  <a className="dropdown-item" href="#*">
                    3
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Row>

        <div className="data-table mt-40">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr
                style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                className="t-head-colored"
              >
                <th className="py-2 th">BUSINESS NAME</th>
                <th className="py-2 th">PHONE</th>
                <th className="py-2 th">EMAIL</th>
                <th className="py-2 th">LOCATION</th>
                <th className="py-2 th">NO OF TRANSACTIONS</th>
                <th className="py-2 th">DATE CREATED</th>
              </tr>
            </thead>

            <tbody>
              {usersReferralList ? (
                usersReferralList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  currentTableData.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td className="td">{""}</td>
                        <td className="td">{""}</td>
                        <td className="td">{""}</td>
                        <td className="td">{""}</td>
                        <td className="td">{""}</td>
                        <td className="td">{moment().format("YYYY-MM-DD")}</td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={usersReferralList.length}
            pagesize={pagesize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Container>
    </Layout>
  );
};

export default ReferralList;
