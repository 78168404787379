export const baseUrlList = {
  auth: `${process.env.REACT_APP_BASE_URL}/auth-service/api/v1`,
  //  auth: `https://e28d-105-113-90-92.ngrok-free.app/auth-service/api/v1`,
  terminal: `${process.env.REACT_APP_BASE_URL}/terminal-service/api/v1`,
  transactions: `${process.env.REACT_APP_BASE_URL}/transaction-service`,
  dispute: `${process.env.REACT_APP_BASE_URL}/wayapos-settlement-service`,
  tech: `${process.env.REACT_APP_BASE_URL}/settings-service/api/v1`,
  kyc: `${process.env.REACT_APP_BASE_URL}/pos-kyc-service`,
  role: `${process.env.REACT_APP_BASE_URL}/wayapos-role-service/api/v1`,
  log: `${process.env.REACT_APP_BASE_URL}/auditlog-service/api/v1`,
  merchants: `${process.env.REACT_APP_BASE_URL}/agency-service/api/v1`,
  referrals: `${process.env.REACT_APP_BASE_URL}/referral-service/api/v1`,
  file: `${process.env.REACT_APP_BASE_URL}/file-resource-service`,
  middleware: `${process.env.REACT_APP_BASE_URL}/middleware-service`,
  settings: `${process.env.REACT_APP_BASE_URL}/setting-service/api/v1`,
  settlementV1: `${process.env.REACT_APP_BASE_URL}/wayapos-settlement-service/api/v1`,
};
