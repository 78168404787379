import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CountCards from './CountCards';
import './dashboard.scss';
import TUsers from '../../assets/icons/t_registeredUsers.svg';
import TRevenue from '../../assets/icons/t_revenue.svg';
import TTerminals from '../../assets/icons/t_terminalsIssued.svg';
import TReveneueRefunded from '../../assets/icons/t_revenueRefunded.svg';

const TransactionCount = ({ showValues, dashboardStatistics }) => {
	return (
		<div className="transaction-count">
			<Row className="">
				<Col className="px-0">
					<CountCards
						countTitle="Total Registered Users"
                        countValue={dashboardStatistics ? dashboardStatistics?.totalRegisteredUser : 0}
						icon={TUsers}
						showValues={showValues}
					/>
				</Col>
				<Col className="px-0">
					<CountCards
						countTitle="Total Terminal Issued "
						countValue={dashboardStatistics ? dashboardStatistics?.totalTerminalIssued : 0}
						icon={TTerminals}
						showValues={showValues}
					/>
				</Col>
				<Col className="px-0">
					<CountCards
						countTitle="Total Revenue Acquires"
						countValue={dashboardStatistics ? dashboardStatistics?.totalRevenueAcquire : 0}
						icon={TRevenue}
						showValues={showValues}
					/>
				</Col>
				<Col className="px-0">
					<CountCards
						countTitle="Total Revenue Settled"
						countValue={dashboardStatistics ? dashboardStatistics?.totalRevenueSettled : 0}
						icon={TRevenue}
						showValues={showValues}
					/>
				</Col>
				<Col className="px-0">
					<CountCards
						countTitle="Total Revenue Pending"
						countValue={dashboardStatistics ? dashboardStatistics?.totalRevenuePending : 0}
						icon={TRevenue}
						showValues={showValues}
					/>
				</Col>
				<Col className="px-0">
					<CountCards
						countTitle="Total Revenue Refunded"
						countValue={dashboardStatistics ? dashboardStatistics?.totalRevenueRefunded : 0}
						icon={TReveneueRefunded}
						showValues={showValues}
					/>
				</Col>
			</Row>
		</div>
	);
};

export default TransactionCount;
