import React from "react";
import { IoFilterOutline } from "react-icons/io5";
import { useContext } from "react";
import { WayaDisputeContext } from "./WayaDispute";

const disputeStatusList = ["OPEN", "CLOSED", "INITIATED"];
const resolutionStatusList = ["Accepted", "Rejected"];

const FilterDispute = () => {
  const {
    handleDateSearch,
    handleFilterByDisputeStatus,
    handleFilterByResolutionStatus,
    clearFilter,
  } = useContext(WayaDisputeContext);
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div className="dropdown-menu " data-auto-close="outside"  style={{ width: "22rem" }}>
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Dispute Status</label>
              <div className="input-container">
                  <select
                    className="input select"
                    name="disputeStatus"
                    id="disputeStatus"
                    onChange={handleFilterByDisputeStatus}
                  >
                    <option value="">Show All</option>
                    {disputeStatusList.map((val, i) => (
                      <option key={i} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Resolution Status</label>
              <div className="input-container">
                  <select
                    className="input select"
                    name="resolutionStatus"
                    id="resolutionStatus"
                    onChange={handleFilterByResolutionStatus}
                  >
                    <option value="">Show All</option>
                    {resolutionStatusList.map((val, index) => (
                      <option key={index} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Transaction Date</label>
              <div className="d-flex justify-content-center align-items-center mt-1">
              <div className="form-group fs-14">
                <div className="input-group date" id="datetimepicker1">
                  <input
                    type="date"
                    className="form-control fs-14"
                    onChange={handleDateSearch}
                    style={{ color: "#555", width: "23em" }}
                  />
                </div>
              </div>
            </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-4">
              <button className="px-4 py-2 text-white border-0 fs-12 rounded" style={{background:"#FF6700"}} onClick={clearFilter}>
                Clear Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterDispute;
