import React, { useEffect, useState } from "react";
import axios from "../../../plugins/axios";
import moment from "moment";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import { BsFillEyeFill, BsPlusLg } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import exportIcon from "../../../assets/icons/coolicon.png";
import { IoCaretBack, IoFilterOutline } from "react-icons/io5";
import { Link, useMatch, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Layout";
import TerminalCards from "../../Cards/TerminalCards";
import NoResultFound from "../../NoResultFound/NoResultFound";
import { CSVLink } from "react-csv";
import Swal from "../../Swal/swal";
import {
  viewAUsersRequestedTerminalsStats,
  viewRequestedTerminalsById,
} from "../../../plugins/urls";
import { Slide, toast } from "react-toastify";

export default function RequestedTerminals() {
  const [state, setState] = useState({
    requestedTerminals: [],
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 20,
    approvedTerminals: 0,
    pendingTerminals: 0,
    rejectedTerminals: 0,
    totalTerminals: 0,
  });
  const {
    requestedTerminals,
    from,
    to,
    pageNo,
    pageSize,
    approvedTerminals,
    pendingTerminals,
    rejectedTerminals,
    totalTerminals,
  } = state;

  const match = useMatch("/requested-terminals/:userId");
  const id = match ? match.params.userId : "";
  const currentDate = moment(new Date(Date.now())).format(
    "LL HH:mm A");

  const navigate = useNavigate();

  useEffect(() => {
    viewAUsersReqTerminalsStatus();
    viewAllRequestedTerminalsByUser();
  }, []);

  const viewAllRequestedTerminalsByUser = () => {
    let reqBody = {
      from,
      pageNo,
      pageSize,
      to,
      userId: id,
    };

    axios({
      method: "post",
      url: `${viewRequestedTerminalsById}`,
      data: reqBody,
    })
      .then((res) => {
        // console.log("Requested Terminals: ", res.data);
        if (res.data) {
          setState((state) => ({
            ...state,
            requestedTerminals: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.error}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const viewAUsersReqTerminalsStatus = () => {
    let reqBody = {
      userId: id,
    };

    axios({
      method: "post",
      url: `${viewAUsersRequestedTerminalsStats}`,
      data: reqBody,
    })
      .then((res) => {
        // console.log("Resp Body: ", res.data.respBody);
        if (res.data.respCode === 0) {
          const {
            approvedTerminals,
            pendingTerminals,
            rejectedTerminals,
            totalTerminals,
          } = res.data.respBody;
          setState((state) => ({
            ...state,
            approvedTerminals,
            pendingTerminals,
            rejectedTerminals,
            totalTerminals,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.error}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const headers = [
    { label: "Request ID", key: "requestID" },
    { label: "Terminal Name", key: "terminalName" },
    { label: "Terminal Cost", key: "terminalCost" },
    { label: "Amount Paid", key: "amountPaid" },
    { label: "Status", key: "status" },
    { label: "Issued At", key: "issuedAt" },
  ];

  const csvReport = {
    data: requestedTerminals,
    headers: headers,
    filename: `User${id}-RequestedTerminalsReport_${currentDate}.csv`,
  };

  const onRequestNewTerminal = () => {
    Swal.fire({
      title: "Sorry, KYC required",
      text: "Hi, we noticed that you are yet to complete your KYC, this is a requirement for requesting a WayaPOS. Kindly upgrade your KYC upto TierLevel 3 or 4 to qualify you for our POS terminal.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Submit KYC",
      // confirmButtonColor:"#FF6700"
    })
    .then(remove => {
      if (remove.isConfirmed) {
        navigate(`/corporate-users/${id}`);
      }
    })
  };

  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <Link to="/corporate-users" className="text-decoration-none">
              <h4 className="text-darker fs-14 fw-700 cursor-pointer">
                <span className="mr-15">
                  <IoCaretBack />
                </span>
                Back
              </h4>
            </Link>
          </Col>
        </Row>

        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-between filter-contents align-items-center">
            <div className="d-flex justify-content-start align-items-center width-50">
              <div className="d-flex justify-content-center align-items-center ">
                <div className="d-flex justify-content-center align-items-center ">
                  <IoFilterOutline size={15} style={{ marginRight: 15 }} />
                  <h4 className="fs-14 text-darker mt-05">Filter</h4>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center filter-search ml-12">
                <div className="input_Search d-flex justify-content-center align-items-center">
                  <div className="justify-content-center align-items-center">
                    <FiSearch color="#FF6700" />
                  </div>
                  <input
                    className="input ml-10"
                    placeholder="Search with terminal id"
                  />
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-end align-items-center w-100">
              <div className="d-flex justify-content-center align-items-center ">
                <Button
                  className="mr-5 btn-md fs-12 btn-success border-0 p-2"
                  style={{ paddingBlock: "1em" }}
                >
                  <img src={exportIcon} alt="" className="me-2" />
                  <CSVLink
                    {...csvReport}
                    style={{ color: "#FFF", textDecoration: "none" }}
                  >
                    Export Data
                  </CSVLink>
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <button
                  className="btn btn-primary mr-5 btn-md fs-12 border-0 p-2"
                  style={{ backgroundColor: "#FF6700", paddingBlock: "1em" }}
                  onClick={onRequestNewTerminal}
                >
                  <BsPlusLg
                    size={15}
                    className="me-1"
                    style={{ marginTop: "-2px" }}
                  />
                  Request New Terminal
                </button>
              </div>
              <div>
                <Button
                  className="primary-button btn border-0 fs-12 fw-500 btn-md button-hover p-2"
                  style={{ paddingBlock: "1em", backgroundColor: "#4A4AFF" }}
                  onClick={() => {
                    navigate(`/acquired-terminals/${id}`);
                  }}
                >
                  <BsFillEyeFill
                    size={15}
                    className="me-1"
                    style={{ marginTop: "-2px" }}
                  />
                  View Acquired Terminals
                </Button>
              </div>
            </div>
          </div>
        </div>

        <Row className="mt-20">
          <Col>
            <TerminalCards
              title="Total Requested Terminals"
              value={totalTerminals ? totalTerminals : "0"}
              color="text-dark"
              textColor="text-dark"
              size="fs-14"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Terminals Under Review"
              value={pendingTerminals ? pendingTerminals : "0"}
              color="text-yellow"
              textColor="text-yellow"
              size="fs-14"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Approved Terminals"
              value={approvedTerminals ? approvedTerminals : "0"}
              color="text-success"
              textColor="text-success"
              size="fs-14"
            />
          </Col>
          <Col>
            <TerminalCards
              title="Total Rejected Terminals"
              value={rejectedTerminals ? rejectedTerminals : "0"}
              color="text-danger"
              textColor="text-danger"
              size="fs-14"
            />
          </Col>
        </Row>

        <div className="data-table mt-40 data-table--scroll">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr
                style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                className="text-nowrap t-head-colored"
              >
                <th>REQUEST ID</th>
                <th className="px-4 py-2 th">TERMINAL NAME</th>
                <th className="px-4 py-2 th">TERMINAL COST</th>
                <th className="px-4 py-2 th">AMOUNT PAID</th>
                <th className="px-4 py-2 th">STATUS</th>
                <th className="px-4 py-2 th">ISSUED DATE</th>
              </tr>
            </thead>

            <tbody>
              {requestedTerminals ? (
                requestedTerminals.length === 0 ? (
                  <NoResultFound />
                ) : (
                  requestedTerminals.map((business, i) => {
                    const {
                      requestID,
                      terminalName,
                      terminalCost,
                      amountPaid,
                      issuedAt,
                      status,
                    } = business;
                    const statusClass = () => {
                      if (status) {
                        if (status.toLowerCase() === "approved") {
                          return "tabactive";
                        } else if (status.toLowerCase() === "under review") {
                          return "tabpending";
                        } else if (status.toLowerCase() === "rejected") {
                          return "tabdanger";
                        } else {
                          return "tabdamaged";
                        }
                      }
                    };
                    return (
                      <tr key={i} className="text-nowrap">
                        <td className="px-4 td">
                          {requestID ? requestID : "N/A"}
                        </td>
                        <td className="px-4 td">
                          {terminalName ? terminalName : "N/A"}
                        </td>
                        <td className="px-4 td">
                          {terminalCost ? terminalCost : "N/A"}
                        </td>
                        <td className="px-4 td">
                          {amountPaid ? amountPaid : "N/A"}
                        </td>
                        <td className="px-4 td">
                          <span className={`${statusClass()}`}>
                            {status ? status : "N/A"}
                          </span>
                        </td>
                        <td className="px-4 td">
                          {issuedAt ? issuedAt : "N/A"}
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    </Layout>
  );
}
