import React from "react";

export const SettleManualComp = ({ handleChange, formState }) => {
  const { showDate } = formState;
  return (
    <form onChange={handleChange}>
      <div className="container-fluid ">
        <div className="mb-2">
          <label className="d-block">Settle By</label>
          <select className="w-100 p-2" name={"by"}>
            <option value={"byRef"}>Reference Number</option>
            <option value={"byDate"}>Date</option>
          </select>
        </div>
        <div className="mb-2">
          <label className="d-block">Payment Status</label>
          <select className="w-100  p-2" name={"paymentStatus"}>
            <option>{"--select payment status--"}</option>
            <option value={"SUCCESSFUL"}>SUCCESSFUL</option>
            <option value={"FAILED"}>FAILED</option>
            <option value={"ABANDONED"}>ABANDONED</option>
            <option value={"REFUNDED"}>REFUNDED</option>
          </select>
        </div>
        <div className="mb-2">
          <label className="d-block">Processor</label>

          <select className="w-100  p-2" name={"processor"}>
            <option>{"--processor--"}</option>
            <option value={"UPSL"}>UPSL</option>
            <option value={"NIBSS"}>NIBSS</option>
            <option value={"ISW"}>ISW</option>
          </select>
        </div>
        {showDate && (
          <div className="row mb-3">
            <div className="col-12 col-md-6 ">
              <label className="fs-12 fw-400 mb-1">Start Date</label>
              <input
                className="admin-user-inputs"
                type="date"
                name="startDate"
              />
            </div>
            <div className="col-12 col-md-6  ">
              <label className="fs-12 fw-400 mb-1">End Date</label>
              <input className="admin-user-inputs" type="date" name="endDate" />
            </div>
          </div>
        )}
      </div>
    </form>
  );
};
