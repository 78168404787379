import React, { useEffect, useState, useRef, createContext } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Cards from "../../components/Cards/Cards";
import { FiSearch } from "react-icons/fi";
import { FaCaretDown } from "react-icons/fa";
import { ReactComponent as Copy } from "../../assets/icons/copy-icon.svg";
import csv from "../../assets/icons/csv.svg";
import { useNavigate } from "react-router";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";
import { CSVLink } from "react-csv";
import {
  settlementTransactions,
  settlementStats,
  settlementStatBalance,
  searchSettlement,
  settleManually,
} from "../../plugins/urls";
import { copyValue } from "../../plugins/utils";
import NoResultFound from "../NoResultFound/NoResultFound";
import moment from "moment";
import SettlementCard from "../Cards/SettlementCard";
import FilterCashoutTransactions from "./FilterCashoutTransactions";
import { BsFillCaretDownFill } from "react-icons/bs";
import Pagination2 from "../Pagination/pagination2";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import { downloadSettlement } from "../../pages/BusinessReports/data";
import Modal from "../../components/Modal/Modal";
import { SettleManually } from "./settleManually";


export const SettlementTransactionsContext = createContext();

const WayaSettlement = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const referenceIDref = useRef(null);

  let data = [
    {
      referenceID: "44aa22f4-fc64-5b",
      businessName: "Business Name1",
      beneficiary: "WayaPOS",
      actualAmount: "2200",
      settledAmount: "2000",
      createdAt: 1648653566495,
      status: "pending",
    },
  ];

  const [state, setState] = useState({
    unitItem: {},
    transactions: [],
    totalTransactions: 0,
    totalPages: 0,
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 10,
    rrn: "",
    failed: 0,
    pending: 0,
    settled: 0,
    last_settlement: 0,
    next_settlement: 0,
    current_balance: 0,
    lastSettlementCompletedDate: null,
    nextSettlementCompletedDate: null,
    field: "all",
    value: "all",
    manual: false,
    submit: false,
  });
  const {
    from,
    to,
    pageNo,
    pageSize,
    rrn,
    failed,
    pending,
    settled,
    transactions,
    last_settlement,
    next_settlement,
    current_balance,
    lastSettlementCompletedDate,
    nextSettlementCompletedDate,
    totalPages,
    totalTransactions,
    manual,
    unitItem,
    submit,
  } = state;

  const [filterParams, setFilterParams] = useState({
    status: "",
    terminalName: "",
    startDate: "",
    endDate: "",
    transactionType: "",
    paymentChannel: "",
    field: "all",
    value: "all",
  });
  const [currentPage, setCurrentPage] = useState();

  const onFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterParams((filterParams) => ({
      ...filterParams,
      [name]: value,
      field: name,
      value,
    }));
  };

  const setStartDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: date,
    }));
  };
  const setEndDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      endDate: date,
    }));
  };
  const clearFilter = (e) => {
    e.preventDefault();
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: "",
      endDate: "",
      terminalName: "",
      status: "",
      paymentChannel: "",
      transactionType: "",
    }));
    getTransactions();
  };
  const handleFilter = (e) => {
    e.preventDefault(e);
    const { field, value } = filterParams;
    filterSearch({ field, value });
  };
  const getTransactions = () => {
    let reqBody = {
      from,
      to,
      pageNo,
      pageSize,
      paramList: [
        {
          userid: user ? user.id : "",
        },
      ],
    };

    axios({
      method: "get",
      url: `${settlementTransactions}?pageNo=${
        pageNo == 0 ? 1 : pageNo
      }&pageSize=${pageSize}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            transactions: res.data.respBody.content,
            totalPages: res.data.respBody.totalPages,
            totalTransactions: res.data.respBody.totalElements,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  const getSettlementStats = () => {
    axios
      .post(settlementStats)
      .then((res) => {
        if (res.data.respCode === 0) {
          const { settled, pending, failed } = res.data.respBody;
          setState((state) => ({
            ...state,
            failed,
            settled,
            pending,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response.data.message
              ? err.response.data.message
              : "Something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };
  const getSettlementStatBalance = () => {
    axios(settlementStatBalance)
      .then((res) => {
        if (res?.data?.respCode === 0) {
          const {
            lastSettlementAmount: {
              completedON: lastSettlementCompletedDate,
              settledAmount: lastSettledAmount,
            },
            nextSettlementAmount: {
              completedON: nextSettlementCompletedDate,
              settledAmount: nextSettledAmount,
            },
            totalSettlementAmount,
          } = res?.data?.respBody;
          setState((state) => ({
            ...state,
            last_settlement: lastSettledAmount,
            next_settlement: nextSettledAmount,
            current_balance: totalSettlementAmount,
            lastSettlementCompletedDate,
            nextSettlementCompletedDate,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response?.data.message
              ? err.response?.data.message
              : "Something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };
  const searchTransactionByReferenceID = () => {
    if (!rrn) {
      toast.error(`${"Please input referenceID"}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    filterSearch({
      field: "referenceID",
      value: rrn,
    });
  };

  const filterSearch = (obj) => {
    const qs = new URLSearchParams(obj);
    axios({
      method: "get",
      url: `${searchSettlement}?${qs}`,
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.respCode === 0) {
          // let searched = [];
          //  searched.push(res?.data?.respBody);
          toast.success(res?.data?.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            transactions: res.data.respBody.content,
            totalPages: res.data.respBody.totalPages,
            totalTransactions: res.data.respBody.totalElements,
          }));
          
        }else{        
        throw new Error(res?.data?.respDesc ?? "Error occured");
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message
              ? err.message
              : "Something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  useEffect(() => {
    getSettlementStats();
    getSettlementStatBalance();
  }, []);
  useEffect(() => {
    getTransactions();
  }, [pageNo, pageSize]); /* 
  const headers = [
    { label: "Reference ID", key: "referenceID" },
    { label: "Business Name", key: "businessName" },
    { label: "Beneficiary", key: "beneficiary" },
    { label: "Actual Amount", key: "actualAmount" },
    { label: "Settled Amount", key: "settledAmount" },
    { label: "Created At", key: "createdAt" },
    { label: "Status", key: "status" },
  ];
  const csvReport = {
    data: data,
    headers: headers,
    filename: "WayaCashoutSettlements_Report.csv",
  }; */
  const firstIndex = pageNo * pageSize;
  const lastIndex = firstIndex + pageSize;
  const paginate = (ev) => {
    let selected = ev.selected + 1;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    // getTerminals(selected);
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };
  const onChange = (e) => {
    setState((state) => ({
      ...state,
      unitItem: { ...unitItem, [e.target.name]: e.target.value },
    }));
  };

  const onSettle = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));


    axios
      .post(`${settleManually}/byReferenceNumber?paymentStatus=${unitItem?.paymentStatus}&processor=${unitItem?.processor}&referenceNumber=${unitItem?.referenceNumber}`, {})
      .then((res) => {
        if (res.data && res?.data?.respBody) {
          setState((state) => ({
            ...state,
            submit: false,
            manual: false,
            unitName: "",
          }));

          toast.success(res?.data?.respBody, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getSettlementStats();
          getSettlementStatBalance();
        } else {
          console.log('rrr', res?.data)
          toast.error(
            ` Error: ${
              res.data
                ? res?.data?.respDesc
                : res?.data?.respBody
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response.data.data
              ? err.response.data.data
              : err.response.data.respDesc
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
          manual: false,
        }));
      });
  };
  return (
    <>
      <SettlementTransactionsContext.Provider
        value={{
          filterParams,
          setStartDate,
          setEndDate,
          clearFilter,
          onFilterChange,
          handleFilter,
        }}
      >
        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-between filter-contents align-items-center">
            <div className="d-flex justify-content-start align-items-center width-50">
              <div className="d-flex justify-content-center align-items-center ">
                <div className="d-flex justify-content-center align-items-center ">
                  <FilterCashoutTransactions />
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center filter-search ml-22">
                <div className="input_Search d-flex justify-content-center align-items-center">
                  <div className="justify-content-center align-items-center">
                    <FiSearch color="#FF6700" />
                  </div>
                  <input
                    className="input ml-10"
                    placeholder="search with reference id"
                    name="rrn"
                    value={rrn}
                    onChange={(e) =>
                      setState((state) => ({
                        ...state,
                        [e.target.name]: e.target.value,
                      }))
                    }
                  />
                </div>
                <button
                  className="orange-button ml-10"
                  onClick={searchTransactionByReferenceID}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center ">
              <div className="d-flex justify-content-center align-items-center ">
                {/*  <button
                  className="btn-md border-0 rounded shadow p-1 d-flex justify-content-between align-items-center fw-bold fs-14"
                  style={{ color: "#FF6700", backgroundColor: "#FFF" }}
                >
                  <img
                    src={csv}
                    alt=""
                    className="me-2 fs-14"
                    style={{
                      color: "#FF6700",
                      backgroundColor: "#FFF",
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                 <CSVLink
                    {...csvReport}
                    style={{ color: "#FF6700", textDecoration: "none" }}
                  >
                    Export
                    <BsFillCaretDownFill color="#bbb" className="ms-2" />
                  </CSVLink> 
                </button>*/}
                <button
                className="orange-button mr-10"
                style={{marginRight:20}}
                onClick={() => setState((s) => ({ ...s, manual: true }))}

                >
                  Settle Manually
                </button>
                <ExportData
                  url={downloadSettlement}
                  filename={`Waya cashout settlement report-${new Date().toISOString()}`}
                />
              </div>
            </div>
          </div>
        </div>

        <Container fluid>
          <Row className="mt-40">
            <Col>
              <Cards
                cardTitle="Last Settlements Disbursed"
                value={last_settlement}
                color="text-orange"
                fontSize="fs-14"
                textColor="text-darker"
                date={`settled on
                  ${
                    Boolean(lastSettlementCompletedDate)
                      ? moment(lastSettlementCompletedDate).format(
                          "MMMM Do YYYY"
                        )
                      : "N/A"
                  }`}
              />
            </Col>
            <Col>
              <Cards
                cardTitle="Next Settlements"
                value={next_settlement}
                color="text-orange"
                fontSize="fs-14"
                textColor="text-darker"
                date={`settled on
                  ${
                    Boolean(nextSettlementCompletedDate)
                      ? moment(nextSettlementCompletedDate).format(
                          "MMMM Do YYYY"
                        )
                      : "N/A"
                  }`}
              />
            </Col>
            <Col>
              <Cards
                cardTitle="Current Balance"
                value={current_balance}
                color="text-orange"
                fontSize="fs-14"
                paddingTitle="pb-3"
                textColor="text-darker"
                date=""
              />
            </Col>
            <Col>
              <SettlementCard
                failed={failed}
                pending={pending}
                settled={settled}
              />
            </Col>
          </Row>

          <div className="data-table mt-40 p-2">
            <table className="bg-inherit table table-borderless table-responsive w-100">
              <thead>
                <tr
                  style={{
                    borderRadius: "5px",
                    borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <th className="">Reference Id</th>
                  <th className="">Business Name</th>
                  <th className="">Beneficiary</th>
                  <th className="text-wrap">Actual Amount</th>
                  <th className="text-wrap">Settled Amount</th>
                  <th className="">Created At</th>
                  <th className="">Status</th>
                  {/* <th>action</th> */}
                </tr>
              </thead>

              <tbody>
                {transactions ? (
                  transactions.length === 0 ? (
                    <NoResultFound />
                  ) : (
                    transactions.map((transaction, i) => {
                      const {
                        referenceID,
                        businessName,
                        beneficiary,
                        actualAmount,
                        settledAmount,
                        createdAt,
                        status,
                      } = transaction;
                      const statusClass = () => {
                        if (status) {
                          if (status.toLowerCase() === "settled") {
                            return "successful";
                          } else if (status.toLowerCase() === "failed") {
                            return "failed";
                          } else {
                            return "pending";
                          }
                        }
                      };

                      return (
                        <tr key={i}>
                          <td className="td">
                            {referenceID ? (
                              <span ref={referenceIDref}>
                                {referenceID}{" "}
                                <i
                                  className="ms-1"
                                  onClick={() => copyValue(referenceID)}
                                >
                                  <Copy />
                                </i>
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td className="td">{businessName?businessName:'N/A'}</td>
                          <td className="td">{beneficiary}</td>
                          <td className="td">NGN {actualAmount}</td>
                          <td className="td">NGN {settledAmount}</td>
                          <td className="td">
                            {createdAt
                              ? moment(new Date(createdAt)).format("D/MM/YYYY")
                              : "N/A"}
                          </td>
                          <td className="td">
                            <span
                              className={`status-btn ${statusClass()}`}
                              onClick={() => {
                                navigate(`/settlement/${referenceID}`, {
                                  state: transaction,
                                });
                              }}
                            >
                              {status}
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </table>
          </div>
          {transactions && transactions.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => noPerPage(100)}
                    >
                      100
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalTransactions}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
          {manual && (
            <Modal
              show={manual}
              clicked={() => setState((s) => ({ ...s, manual: false }))}
              submit={onSettle}
              loading={submit}
              title="Manual Settlement"
              action="Submit"
            >
              <SettleManually onChange={onChange} unitItem={unitItem} />
            </Modal>
        )}
        </Container>
      </SettlementTransactionsContext.Provider>
    </>
  );
};

export default WayaSettlement;
