import React from "react";
import { useContext } from "react";
import { PricingsContext } from "../../pages/Tech/Pricing";

export default function CustomPricing() {
  const {
    onChange, 
    state: { userId, pricingRate, discount, cap },
  } = useContext(PricingsContext);
  // console.log("Pricings Context: ", userId, pricingRate, discount, cap);

  return (
    <div>
      <div className="mb-4">
        <label className="fs-14 fw-500">Rate</label>
        <div className="input-group">
          <input
            type="number"
            className="form-control fs-14"
            aria-label="Percentage"
            name="pricingRate"
            value={pricingRate === 0 || pricingRate === null ? "" : pricingRate}
            onChange={onChange}
          />
          <span
            className="input-group-text fs-4 px-4"
            style={{ background: "#555", color: "#fff" }}
          >
            %
          </span>
        </div>
      </div>

      <div className="mb-4">
        <label className="fs-14 fw-500">Discount</label>
        <div className="input-group">
          <input
            type="number"
            className="form-control fs-14"
            aria-label="Percentage"
            name="discount"
            value={discount === 0 || discount === null ? "" : discount}
            onChange={onChange}
          />
          <span
            className="input-group-text fs-4 px-4"
            style={{ background: "#555", color: "#fff" }}
          >
            %
          </span>
        </div>
      </div>

      <div className="mb-4">
        <label className="fs-14 fw-500">Processing fees capped at</label>
        <div className="input-group">
          <span
            className="input-group-text fs-6 px-4 fw-600"
            style={{ background: "#DDD" }}
          >
            NGN
          </span>
          <input
            type="number"
            className="form-control fs-14"
            style={{paddingBlock: "14px"}}
            aria-label="Percentage"
            name="cap"
            value={cap === 0 || cap === null ? "" : cap}
            onChange={onChange}
          />
        </div>
      </div>

      <div>
        <input
          type="hidden"
          className="form-control p-2"
          name="userId"
          value={userId === null ? "" : userId}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
