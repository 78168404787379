import React from "react";
import { useState } from "react";
import { IoFilterOutline } from "react-icons/io5";

const FilterPOSRequest = ({ setFilterFieldValue }) => {
  const [localfieldFilterValue, setLocalFilterFieldValue] = useState({
    field: "all",
    value: "all",
  });
  const onFilterChange = (e) => {
    const { name, value } = e.target;
    setLocalFilterFieldValue({ field: name, value });
  };
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div className="dropdown-menu " style={{ width: "22rem" }}>
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Status</label>
              <div className="input-container">
                <select
                  className="input"
                  name="status"
                  onChange={onFilterChange}
                >
                  <option>Show All</option>
                  <option value="APPROVED">Approved</option>
                  <option value="PENDING">Pending</option>
                  <option value={"REJECTED"}>Rejected</option>
                  <option value={"ISSUED"}>Issued</option>
                  <option value={"COMPLETED"}>Completed</option>
                </select>
              </div>
            </div>
            <div className="">
              <label
                className="text-darker fs-14 fw-600"
                htmlFor="terminalName"
              >
                Terminal Name
              </label>
              <div className="input-container">
                <select
                  className="input"
                  name="terminalName"
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  <option value="NEXGOG2">NEXGOG2</option>
                  <option value="NEXGOG3">NEXGOG3</option>
                  <option value="PAX">PAX</option>
                  <option value="TOPWISE">TOPWISE</option>
                  <option value="MOREFUN">MOREFUN</option>
                  <option value="VERIFONE">VERIFONE</option>
                </select>
              </div>
            </div>

            <div className="">
              <label className="text-darker fs-14 fw-600">Date Range</label>
              <div className="input-container d-flex">
                <span className="text-muted fs-12 me-1 mt-1">From</span>
                <input
                  name={"date"}
                  type={"date"}
                  placeholder={"From"}
                  onChange={onFilterChange}
                />
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter">Clear Filter</button>
              <button
                type="submit"
                className="px-3 py-2 apply-filter"
                onClick={(e) => {
                  e.preventDefault();
                  setFilterFieldValue(localfieldFilterValue);
                }}
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterPOSRequest;
