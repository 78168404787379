import axios from "axios";
import moment from "moment";
import React, { createContext, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useLocation, useMatch, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import { ReactComponent as Copy } from "../../assets/icons/copy-icon.svg";
import Cards from "../../components/Cards/Cards";
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import { SettleManualComp } from "../../components/UsersComponents/SettleManualComp";
import { settleManually } from "../../plugins/urls";

export const SettlementContext = createContext();
const Settlement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const match = useMatch("/settlement/:id");
  const id = match ? match.params.id : "";

  const [state, setState] = useState({ ...location?.state, submit: false });
  const [loading, setLoading] = useState(false);
  const [settlManually, setSettle] = useState(false);

  const [formState, setFormState] = useState({ showDate: false });
  const handleSubmit = () => {
    const { by, showDate, endDate, startDate, ...formData } = formState;

    let reqObj = {};
    if (by === "byDate") {
      reqObj = { ...formData, endDate, startDate };
    } else {
      reqObj = { ...formData, referenceNumber: id };
    }
    const qs = new URLSearchParams(reqObj);
    setLoading(true);
    axios
      .post(
        `${settleManually}${by === "byDate" ? "" : "/byReferenceNumber/byAdmin"}?${qs}`,
        reqObj
      )
      .then((resp) => {
        const {
          status,
          data: { respCode, respBody, respDesc },
        } = resp;
        if (status === 200 && respCode === 0) {
          toast.success(respDesc);
        } else if (respCode === 96) {
          toast.error(respDesc);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setLoading(false);
        setSettle(false);
        setFormState({})
      });
  };
  const {
    id: txnId,
    referenceID,
    actualAmount,
    settledAmount,
    wayaPosFee,
    processing_fees,
    createdAt,
    completedON,
    status,
  } = state;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "by") {
      setFormState((state) => ({
        ...state,
        showDate: value === "byDate" ? true : false,
      }));
    }
    setFormState((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const referenceIDref = useRef(null);

  const copyReferenceId = (id) => {
    navigator.clipboard.writeText(id);
  };
  return (
    <Layout title={`Settlements > ${id}`}>
      <SettlementContext.Provider
        value={{
          state,
        }}
      >
        <div className="font-default address">
          <div className="d-flex justify-content-between  align-items-center">
            <h4
              className="text-darker fs-14 fw-700 cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <span className="mr-15">
                <Back />
              </span>
              Back
            </h4>
            <button onClick={() => setSettle(true)} className="orange-button">
              {"Settle Manually"}
            </button>
          </div>
          <div className="mt-20 settlement-details-wrapper p-2">
            <Row className="mt-1 mb-3">
              <Col>
                <Cards
                  cardTitle="Settled Amount"
                  value={settledAmount}
                  color="text-orange"
                  fontSize="fs-13"
                  size="fs-16"
                  textColor="text-darker"
                />
              </Col>
              <Col>
                <Cards
                  cardTitle="Completed on"
                  value={moment(completedON).format("ddd DD MMM YYYY")}
                  noCurrency={true}
                  fontSize="fs-13"
                  size="fs-16"
                  color="text-orange"
                  textColor="text-darker"
                />
              </Col>
              <Col></Col>
            </Row>

            <div className="settlement-details-wrapper px-3 ">
              <table className="bg-inherit table table-borderless table-responsive w-100 ">
                <thead>
                  <tr
                    style={{
                      borderRadius: "5px",
                      borderBottom: "1px solid rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <th className="fs-14">Reference Id</th>
                    <th className="fs-14">Actual Amount</th>
                    <th className="fs-14">WayaPos Fees</th>
                    <th className="fs-14">Processing Fees</th>
                    <th className="fs-14">Settled Amount</th>
                    <th className="fs-14">Created At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="fs-13">
                      {referenceID ? (
                        <span ref={referenceIDref}>
                          {referenceID}
                          <i
                            className="ms-1"
                            onClick={() => copyReferenceId(referenceID)}
                          >
                            <Copy />
                          </i>
                        </span>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="fs-13">NGN {actualAmount}</td>
                    <td className="fs-13">NGN {wayaPosFee}</td>
                    <td className="fs-13">NGN {processing_fees}</td>
                    <td className="fs-13">NGN {settledAmount}</td>
                    <td className="fs-13">
                      {createdAt
                        ? moment(new Date(createdAt)).format("D/MM/YYYY")
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <p
              className="text-orange fs-13 text-center my-5 pointer"
              onClick={() => navigate(`/transaction/${txnId}`)}
            >
              Click here to view details about this transaction
            </p>
          </div>
        </div>
        {settlManually && (
          <Modal
            show={settlManually}
            clicked={() => {
              setSettle(false);
              setFormState({});
            }}
            submit={handleSubmit}
            loading={loading}
            title="Settle this transaction"
            action="settle"
          >
            <SettleManualComp
              handleChange={handleChange}
              formState={formState}
            />
          </Modal>
        )}
      </SettlementContext.Provider>
    </Layout>
  );
};

export default Settlement;
