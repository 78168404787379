import React, { useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import PosDevice from "../../components/PosComponents/PosDevice";
import PosRequest from "../../components/PosComponents/PosRequest";
import PosTypes from "../../components/PosComponents/PosTypes";

const Terminals = () => {
  const [state, setState] = useState({
    tab: "device",
  });

  const { tab } = state;

  const switchTabs = (value) => {
    setState((state) => ({
      ...state,
      tab: value,
    }));
  };

  const renderPages = () => {
    switch (tab) {
      case "device":
        return <PosDevice />;
      case "request":
        return <PosRequest />;
      case "types":
        return <PosTypes />;
      default:
        return <PosDevice />;
    }
  };
  return (
    <Layout title="Manage POS">
      <Container fluid>
        <div
          className="bg-grey d-flex justify-content-between font-secondary br-30 mb-30 cursor-pointer"
          style={{ width: "fit-content" }}
        >
          <div
            className={`d-flex justify-content-between ${
              tab === "device" ? "active-option" : "inactive-option"
            }`}
            onClick={() => switchTabs("device")}
          >
            <h4
              className={`fs-14 text-nowrap ${
                tab === "device" ? "text-white" : "text-darker"
              }`}
            >
              Manage POS Device
            </h4>
          </div>

          <div
            className={`d-flex justify-content-between ${
              tab === "request" ? "active-option" : "inactive-option"
            }`}
            onClick={() => switchTabs("request")}
          >
            <h4
              className={`fs-14 text-nowrap ${
                tab === "request" ? "text-white" : "text-darker"
              }`}
            >
              Manage POS Request
            </h4>
          </div>

          <div
            className={`d-flex justify-content-between ${
              tab === "types" ? "active-option" : "inactive-option"
            }`}
            onClick={() => switchTabs("types")}
          >
            <h4
              className={`fs-14 text-nowrap ${
                tab === "types" ? "text-white" : "text-darker"
              }`}
            >
              Manage Terminal Types
            </h4>
          </div>
        </div>

        {renderPages()}
      </Container>
    </Layout>
  );
};

export default Terminals;
