

import React from 'react'

const TierCards = (props) => {
  return (
    <div className="cards d-flex align-items-center  ">
    <h6 className={`fs-14 fw-600 text-darker me-4`}>
       {props.title}
    </h6>
    <h4 className={`fs-20 fw-700 text-darker`}>
      {props.value}
    </h4>
  </div>
  )
}

export default TierCards