import React from "react";

export default function RejectResponseModal({
  fileUploadHandler,
  selectedFile,
  isFilePicked,
  commentText,
  fileName,
  onChangeFileName,
  handleFileUpload,
  onChangeComment,
}) {
  return (
    <div className="px-2">
      <div className="d-flex justify-content-center align-items-center text-center p-2">
        <p>
          By accepting this response, you agree that the merchant have{" "}
          <span className="text-danger fw-500">LOST</span> this dispute
          transaction and payment shall be deducted from merchant's next
          settlement.
        </p>
      </div>

      <div className="d-flex justify-content-center align-items-center mt-20">
        <div>
          <label htmlFor="" className="fw-500 mb-1 fs-12">
            Reason for rejecting merchant repsonse
          </label>
          <span className="text-danger ml-2 fw-500">*</span>
          <textarea
            name="commentText"
            id="commentText"
            cols="54"
            rows="3"
            className="p-2 mb-3"
            value={commentText}
            onChange={onChangeComment}
            required
          ></textarea>

          <p className="fw-500 fs-12">
            Upload proof Document
            <span className="text-grey ml-2 fw-500">{"(optional)"}</span>
          </p>
          <div>
            <input
              type="text"
              name="fileName"
              id="fileName"
              placeholder="Enter Document name"
              value={fileName}
              onChange={onChangeFileName}
              className="p-2 fs-12"
              style={{ width: "36em", marginTop: "-1em" }}
            />
          </div>

          <input
            type="file"
            name="file"
            id="inputGroupFile02"
            // value={selectedFile}
            // accept=".xls,.xlsx"
            className="custom-file-input-upload"
            aria-describedby="basic-addon2"
            onChange={fileUploadHandler}
            placeholder="Click or drag files to upload"
            style={{ width: "75%" }}
          />
        </div>
        <label
          className="tabblack text-white border border-0 fs-12 px-3 cursor-pointer"
          // htmlFor="inputGroupFile02"
          style={{
            margin: "15.15em 1.5em 0 -10em",
            width: "28%",
            paddingBlock: "0.85em",
            background: "#FF6700",
          }}
          onClick={handleFileUpload}
        >
          Add file
        </label>
      </div>
      {isFilePicked ? (
        <div className="px-4" style={{ fontSize: "12px" }}>
          <p>
            <strong> Filename: </strong> {selectedFile.name}
          </p>
          <p>
            <strong>Type: </strong> {selectedFile.type}
          </p>
          <p>
            <strong>Size in bytes: </strong>
            {selectedFile.size}
          </p>
          <p>
            <strong>lastModifiedDate: </strong>
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p>
        </div>
      ) : (
        <p className="px-4" style={{ fontSize: "12px" }}>
          Select a file to show details
        </p>
      )}
    </div>
  );
}
