import React from "react";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
// import "./modal.scss";
import { Modal, ModalFooter } from "react-bootstrap";

export const DisputeModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.clicked}
      keyboard={false}
      size={props.size}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-bottom-0 p-4 shadow rounded border-light">
        <Modal.Title className=" m-auto fs-16">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="fs-12">
        <p className="text-center text-dark fw-500 w-75 m-auto mb-3">
          {props.subtitle}
        </p>
        <>{props.children}</>
      </Modal.Body>
      <ModalFooter className="border-top-0 d-flex justify-content-between align-items-center mx-auto w-75" style={{marginTop:"-3em"}}>
        {props.close ? (
          <button
            type="button"
            className="my-4 btn-light border-0 fs-12 fw-500 shadow text-dark px-5 py-2"
            style={{ color: "#FF6700" }}
            onClick={props.clicked}
          >
            {props.close}
          </button>
        ) : null}
        <button
          type="submit"
          className={`${props.extraPadding} orange-button fs-12 my-4 border-0 fw-500`}
          onClick={props.submit}
        >
          {props.loading ? <SubmitLoader /> : props.action}
        </button>
      </ModalFooter>
    </Modal>
  );
};
