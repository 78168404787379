import { baseUrlList } from "./baseUrl";

export const baseUrl = () => {
  const { ...allUrls } = baseUrlList;
  return allUrls;
};

//Auth service

//Sign in
export const signIn = `${baseUrl().auth}/auth/login`;

//Sign in
export const twoFa = `${baseUrl().merchants}/auth/generate-login-otp`;

//Sign up
export const signUp = `${baseUrl().auth}/auth/create-corporate`;

//Password reset
export const sendOtp = `${baseUrl().auth}/password/forgot-password/byEmail`;
export const resetPassword = `${baseUrl().auth}/password/forgot-password`;

//Verify OTP
export const verify = `${baseUrl().auth}/auth/verify-otp`;

//Resend OTP
export const resend = `${baseUrl().auth}/auth/admin-resend-otp`;

//business types
export const businessTypesList = `${baseUrl().auth}/business/type/find/all`;
export const createBusinessType = `${baseUrl().auth}/business/type/create`;
export const updateBusinessType = `${baseUrl().auth}/business/type/edit`;
export const deleteBusinessType = `${baseUrl().auth}/business/type/delete`;

// Admin User
export const pendingAdminUserInvite = `${
  baseUrl().role
}/user-role/fetch/invite/pending`;
export const activeAdminUsers = `${baseUrl().auth}/dashboard/active-users`;
export const allAdminUsers = `${baseUrl().role}/filters/allAdminUsers`;
export const allMerchantAdmin = `${baseUrl().role}/fetchAllMerchantsAdmin`;
export const getAdminUserByEmail = `${baseUrl().auth}/user/email`;
export const createAdminUser = `${baseUrl().role}/createAdminUser`;
export const createUnit = `${baseUrl().role}/createUnit`;

export const assignRole = `${baseUrl().role}/merchant/assignedMenuToAdmin`;

export const updateUnit = `${baseUrl().role}/updateUnit`;
export const allUnits = `${baseUrl().role}/getAllUnit`;
export const menuAssignUpdate = `${baseUrl().role}/menuAssignUpdate`;
export const getAssignedMenu = `${baseUrl().role}/getUserAssignedMenu`;
export const defaultAdminUserRoles = `${
  baseUrl().auth
}/admin/roles/roles-with-privileges`;

export const getAllMenu = `${baseUrl().role}/getAllMenu`;
export const roleUsers = `${baseUrl().auth}/admin/users/role`;
export const allPriviledges = `${
  baseUrl().auth
}/admin/privileges/all-privileges`;
export const removeAdminUser = `${baseUrl().auth}profile/delete-restore`;
export const createCustomRole = `${
  baseUrl().auth
}/admin/manage-user/create-custom-role`;

//User service
export const allMerchants = `${baseUrl().merchants}/agent/getallmerchants`;
export const viewMerchant = `${
  baseUrl().merchants
}/agent/viewmerchantbymerchantid`;
export const deleteUser = `${baseUrl().role}/admin/delete`;
export const getUserProfile = `${baseUrl().auth}/profile/`; /* userId */

//Corporate Users
export const allUsers = `${baseUrl().auth}/admin/users/corporate-profile`;
export const activeCorporate = `${
  baseUrl().auth
}/dashboard/active-cooperate-users`;
export const registeredCorporate = `${
  baseUrl().auth
}/dashboard/total-registered-cooperate-users`;
export const inactiveCorporate = `${
  baseUrl().auth
}/dashboard/in-active-cooperate-users`;
export const deactivateUsers = `${
  baseUrl().auth
}/admin/bulk/account-deactivation`;
export const deactivateSingleUser = `${
  baseUrl().auth
}/admin/users/activation-toggle`;
export const downloadExcelTemplate = `${
  baseUrl().merchants
}/auth/download/sampleBulkCorporate/fileFormat`;
export const createSingleCorporateUser = `${
  baseUrl().auth
}/admin/users/create-corporate`;
export const createBulkUsersOnline = `${
  baseUrl().auth
}/admin/users/create-corporate-multiple-form`;
export const createUsersWithExcelFileUpload = `${
  baseUrl().merchants
}/auth/upload/multiple/corporate`;
export const viewCorporateProfile = `${baseUrl().auth}/profile`;
export const updateMerchantProfile = `${
  baseUrl().auth
}/admin/update-business-details`;
export const manageUsersRoles = `${baseUrl().auth}admin/manage-user/roles`;

//Referrals
export const allReferrals = `${baseUrl().auth}/referral/get-referral-code-list`;
export const sendReferralBonus = `${
  baseUrl().referrals
}/admin/users/send-referral-bonus`;
export const sendReferralBonusToMultipleUsers = `${
  baseUrl().referrals
}/admin/users/send-referral-bonus-to-multiple-users`;
export const sendBulkBonus = `${
  baseUrl().auth
}/admin/users/bulk-referral-bonus-excel`;
export const getUsersReferralBonus = `${
  baseUrl().referrals
}/admin/get-users-referral-bonus`;
export const assignReferralCode = `${
  baseUrl().referrals
}/admin/users/assign-referral-code`;
export const getUsersTotalReferralEarnings = `${
  baseUrl().referrals
}/admin/get-users-total-referral-earnings`;
export const getAllReferralCodes = `${
  baseUrl().referrals
}/get-all-referral-code`;
export const getUsersWithReferralCodes = `${
  baseUrl().referrals
}/admin/get-users-with-referral-code`;
export const getReferredUsers = `${
  baseUrl().referrals
}/admin/get-users-that-have-been-referred`;

//Terminal Services
export const getTerminalsStats = `${
  baseUrl().terminal
}/terminals/admingetallstats`;
export const terminalTypes = `${baseUrl().terminal}/terminals/getterminaltypes`;
export const terminalSearch = `${
  baseUrl().terminal
}/terminals/viewallrequestedterminalbyparam`;
export const getRequestTerminal = `${
  baseUrl().terminal
}/terminals/request/getAllApprovedUsers`;
// export const getRequestTerminal = `https://services.wayapos.ng/terminal-service/api/v1/terminals/getapprovedterminalrequest`

export const findUnassignedTerminal = ({ terminalName }) => {
  const tName = terminalName ? `?terminalName=${terminalName}` : "";
  return `${baseUrl().terminal}/terminals/findunassignedterminals${tName}`;
};
export const requestTerminal = `${
  baseUrl().terminal
}/terminals/request/byAdmin`;

export const getMerchantWalletDetails = (id) =>
  `${baseUrl().settlementV1}/wallet/detailsByMerchantId?merchantId=${id}`;

export const createterminal = `${baseUrl().terminal}/terminals/createterminal`;

export const createterminalType = `${
  baseUrl().terminal
}/terminals/createterminaltypes`;

export const terminalTypeUrl = `${baseUrl().terminal}/terminals/terminalType`;

export const updateterminal = `${baseUrl().terminal}/terminals/updateterminal`;

export const issueterminal = `${baseUrl().terminal}/terminals/issueterminal`;

export const deleteterminal = `${baseUrl().terminal}/terminals/deleteTerminal`;
export const getTerminalRequestStats = `${
  baseUrl().terminal
}/terminals/terminalRequestStats`;

export const viewAllTerminalRequests = `${
  baseUrl().terminal
}/terminals/viewallrequestedterminalbyparam`;

export const updateTerminalRequests = `${
  baseUrl().terminal
}/terminals/updateterminalrequest`;

export const viewAllTerminals = `${
  baseUrl().terminal
}/terminals/viewallterminalbyparam`;

export const activateTerminal = `${
  baseUrl().terminal
}/terminals/activateterminal`;
export const viewRequestedTerminalsById = `${
  baseUrl().terminal
}/terminals/viewrequestedterminalbyuserid`;
export const viewAUsersRequestedTerminalsStats = `${
  baseUrl().terminal
}/terminals/getUserRequestedTerminalStats`;
export const viewAUsersAcquiredTerminals = `${
  baseUrl().terminal
}/terminals/viewallterminalbyuser`;

//Transaction Services

export const allTransactions = `${
  baseUrl().transactions
}/api/v1/transactions/viewalltransasctions`;

export const filterAllTransactions = `${
  baseUrl().transactions
}/api/v1/transactions/filters`;

export const viewTransaction = `${
  baseUrl().transactions
}/api/v1/transactions/viewonetransactionsbyuser`;

export const transactionStats = `${
  baseUrl().transactions
}/api/v1/transactions/metric/data`;

export const revenueStats = `${
  baseUrl().settlementV1
}/settlement/getTransactionStats`;

//Dispute service
export const allWayaDisputes = `${
  baseUrl().dispute
}/wayaposDisputes/viewAllDisputes/byAdmin`;
// export const allWayaDisputes = `${baseUrl().dispute}/wayaposDisputes/merchant/all`;

export const allAuthDisputes = `${
  baseUrl().dispute
}/auth-notification-dispute/viewAllDisputes`;

export const createWayaPosDisputes = `${
  baseUrl().dispute
}/wayaposDisputes/createDispute`;

export const createAuthDisputes = `${
  baseUrl().dispute
}/auth-notification-dispute/createDispute`;

export const allOtherDisputes = `${
  baseUrl().dispute
}/othersDispute/viewAllDisputes`;

export const createOtherDispute = `${
  baseUrl().dispute
}/othersDispute/createDispute`;

export const getMerchantDisputeById = `${
  baseUrl().dispute
}/wayaposDisputes/viewMerchantDisputeByID`;

export const acceptMerchantDispute = `${
  baseUrl().dispute
}/wayaposDisputes/acceptMerchantDispute/byAdmin`;

export const rejectMerchantDispute = `${
  baseUrl().dispute
}/wayaposDisputes/reject/chargeback/byAdmin`;

export const getDisputeMetrics = `${baseUrl().dispute}/wayaposDisputes/metrics`;

//File service
export const uploadFile = `${baseUrl().file}/upload/others`;

//settlement service
export const settlementTransactions = `${
  baseUrl().settlementV1
}/settlement/adminviewall`;
export const settlementStats = `${
  baseUrl().settlementV1
}/settlement/stats/admin`;
export const settlementStatBalance = `${
  baseUrl().settlementV1
}/settlement/statistics`;
export const viewSettlementByRRN = `${
  baseUrl().dispute
}/settlement/viewSettlementByReferenceId`;

export const searchSettlement = `${baseUrl().dispute}/api/v1/settlement/search`;
export const settleManually = `${baseUrl().settlementV1}/settlement/doManual`;
//Tech Service

//Routing rules
export const allRouting = `${baseUrl().transactions}/rules/routing`;
export const createRouting = `${baseUrl().transactions}/rules/create/routing`;
export const updateRouting = `${baseUrl().transactions}/rules/update/routing`;
export const deleteRouting = `${baseUrl().transactions}/rules/delete/routing`;

//Pricing
export const allPricing = `${baseUrl().tech}/pricing/viewall`;
export const createPricing = `${baseUrl().tech}/pricing/create`;
export const updatePricing = `${baseUrl().tech}/pricing/update`;
export const viewPricingByid = `${baseUrl().tech}/pricing/byUser`;
export const updateAllPricingByProductName = `${
  baseUrl().tech
}/pricing/updateAll/byProduct`;
export const resetPricing = `${
  baseUrl().tech
}/pricing/id/{id}/userId/{userId}/reset`;

//Stations
export const allStation = `${baseUrl().transactions}/rules/stations`;
export const createStation = `${baseUrl().transactions}/rules/create/station`;
export const updateStation = `${baseUrl().transactions}/rules/update/station`;
export const deleteStation = `${baseUrl().transactions}/rules/delete/station`;

//Scheme
export const allScheme = `${baseUrl().tech}/scheme/viewAllSchemes`;
export const createScheme = `${baseUrl().tech}/scheme/create`;
export const updateScheme = `${baseUrl().tech}/scheme/update`;
export const deleteScheme = `${baseUrl().tech}/scheme/delete`;

// MIDDLEWARE - Commission
export const allCommission = `${
  baseUrl().middleware
}/adminCommission/commissionHistory`;

export const allRemoteUpdate = `${baseUrl().middleware}/pos/fetchAll`;
export const createRemoteUpdate = `${baseUrl().middleware}/pos/updateApp`;

//KYC Services
export const kycStatus = `${baseUrl().kyc}/kyc/wayaPos/getKycStatus/userId/`;
export const getKYCRequirementsByTier = `${
  baseUrl().kyc
}/kyc/user/kyc-requirements/tier`;
export const fetchAllUsersWithKYCStatus = `${
  baseUrl().kyc
}/kyc/list/all/paginated`;
export const filterUser = `${baseUrl().kyc}/kyc/filters`;
export const filterKYCbyTierLevel = `${baseUrl().kyc}/kyc/filter/kyc/status`;
export const approveUserKYC = `${
  baseUrl().kyc
}/kyc/manage-requirement/wayaPos/approve-req`;
export const rejectUserKYC = `${
  baseUrl().kyc
}/kyc/manage-requirement/reject-req`;
export const uploadUserKYC = `${baseUrl().kyc}/kyc/wayapos/upload`;
export const totalTierLevelsSupported = `${
  baseUrl().kyc
}/kyc/fetchAllTransactionLevels`;
export const getTotalVerifiedUsers = `${
  baseUrl().kyc
}/kyc/count/totalVerifiedStatus`;
export const fetchKycStatistics = `${baseUrl().kyc}/kyc/statistics`;
export const fetchTierLevelsStatistics = `${
  baseUrl().kyc
}/kyc/tier/count-users-by-tier`;
export const uploadUserBusinessLogo = `${baseUrl().kyc}/business/logo`;
export const fetchUserBusinessLogo = `${baseUrl().kyc}/business/logo/user`;

export const allLogs = `${baseUrl().log}/auditlogs/all/byAdmin`;

export const allRequestLogs = `${baseUrl().log}/requestLogs/byAdmin`;
export const filterLogs = `${baseUrl().log}/filter/field`;
export const updateLogs = `${baseUrl().log}/updateLog`;
