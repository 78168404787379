import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import Card from 'react-bootstrap/Card';
import {FaCaretDown, FaTimes} from "react-icons/fa";
import 'react-datepicker/dist/react-datepicker.css'
import DatePicker from "react-datepicker";
import moment from 'moment'



const SimpleDateFilter = ({setDateFilter}) => {
    const [open, setOpen] = useState(false);
    const [dateString, setDateString] = useState('Date');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        start && end && setDateFilter(start, end);
       start && end && setDateString(`From ${moment(start).format('MMM Do YY')} to ${moment(end).format('MMM Do YY')}`)
    };
    const handleClose = () => {
        setStartDate(new Date());
        setEndDate(null);
        setOpen(false);
    };
    const handleClear = () => {
        handleClose();
        setDateString('Date');
        setDateFilter('', '');
    };

    return (
        <>
            <Button
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                className="no-btn w-100 border"
            >
               <div className="d-flex justify-content-between collapse-btn">
                   <div className='pe-3 align-content-start text-muted w-100'>{dateString}</div>

                           <div className='d-flex'>
                               <span> <FaCaretDown /></span>
                               {
                                   dateString !== 'Date' && (
                               <span onClick={handleClear} className="ps-1"><FaTimes className='text-orange' /></span>
                                   )
                               }
                           </div>

               </div>
            </Button>
            <div>
                <Collapse in={open} dimension="width">
                    <Card body style={{ width: '400px' }}>
                    <div className="d-flex justify-content-center align-items-center filter-search ml-22">

                        <div className="input-container d-flex justify-content-center align-items-center">
                            <DatePicker
                                dateFormat="dd-MM-yyyy"
                                onChange={onChange}
                                isClearable
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="input"
                                wrapperClassName="datePicker"
                                selectsRange
                                inline
                                onClickOutside={handleClose}
                            />
                        </div>
                    </div>
                    </Card>
                </Collapse>
            </div>
        </>
    );
};

export default SimpleDateFilter;
