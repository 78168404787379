import { Badge, IconButton, useTheme } from "@mui/material";
import React from "react";
import { FaBell } from "react-icons/fa";
import "./Header.scss";

const Header = ({ title, open, setOpen }) => {
  const handleChange = (e) => {
    setOpen(!open);
  };
  return (
    <div className="Header">
      <div className="d-flex justify-content-between">
        {/* <Col lg={1} sm={1} md={1} xs={2} className="hide">
                  <DrawerToggle clicked={props.drawerToggle} />
                </Col> */}
        <h1 className="Pagetitle">{title}</h1>

        <div className="user">
          <div>
            <div className="notif">
              <label>
                <IconButton size="small">
                  <Badge badgeContent={2} color={"primary"}>
                    <FaBell />
                  </Badge>
                </IconButton>
              </label>
              <input
                type="checkbox"
                id="toggle"
                name={"notif"}
                defaultChecked={open}
                onChange={handleChange}
              />
            </div>
          </div>
          <p className="greet">
            Hi, <span className="name">Admin</span>
          </p>
          <div className="userIcon">
            <h1 className="initials">A</h1>
            {/* <h1 className={headerStyles.initials}>{ data ? data.firstName.substring(0, 1) : 'A'}</h1> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
