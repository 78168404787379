import React from "react";
import "./notificationSideBar.scss";
import Divider from "../../Divider/Divider";

const NotificationSideBar = ({ open, setOpen, title }) => {
  return (
    <>
      <div className={`NotificationSidebar ${open ? "open" : ""}`}>
        <div className="notificationBox">
          <ul
            className={
              title === "Dashboard" ? "Navigation-active" : "Navigation"
            }
          >
            <li>
              {/*  <DashboardIcon
              className={
                props.title === "Dashboard" ? "activeIcon" : "sideIcon"
              }
              size={20}
            /> */}
              <div className="card" onClick={() => setOpen(!open)}>
                <p>
                  {
                    "2 NexgoG2 terminal has just been requested by waya live merchant"
                  }
                </p>
              </div>
            </li>
          </ul>
        </div>

        <Divider />
      </div>
    </>
  );
};

export default NotificationSideBar;
