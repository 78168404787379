import { isEmpty, truncate } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { FaCaretDown, FaPlus, FaTrash } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { MdSettings } from "react-icons/md";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import AdminUserCards from "../../components/Cards/AdminUserCards";
import Layout from "../../components/Layout/Layout";
import Modal from "../../components/Modal/Modal";
import RemoveUserModal from "../../components/Modal/RemoveUserModal";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import Pagination2 from "../../components/Pagination/pagination2";
import AddAdmin from "../../components/UsersComponents/AddAdmin";
import { EditAdminMenu } from "../../components/UsersComponents/EditAdminMenu";
import axios from "../../plugins/axios";
import {
  allAdminUsers,
  assignRole,
  createAdminUser,
  deleteUser,
  menuAssignUpdate,
} from "../../plugins/urls";
import { downloadAdminUser } from "../BusinessReports/data";
import { ExportData } from "../BusinessReports/ExportData";

const AdminUsers = ({ hidden }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    adminUsers: [],
    totalActiveAdminUsers: 0,
    totalPendingInvites: 0,
    userRoles: ["superAdmin", "Reviewer"],
    admin: true,
    firstName: "",
    surname: "",
    email: "",
    phoneNumber: "",
    dateOfBirth: "",
    gender: "",
    adminType: "",
    checker: "",
    role: "",
    password: "",
    searchEmail: "",
    pageNo: 0,
    pageSize: 10,
    submit: false,
  });
  const [addUser, setAddUser] = useState(false);
  const [editUserMenu, setEditUserMenu] = useState({
    edit: false,
    menu: [],
    others: { approval: "", id: "", status: "", menuAssignId: "" },
  });
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [passwordErr, setPasswordErr] = useState("");

  const {
    adminUsers,
    totalActiveAdminUsers,
    userRoles,
    submit,
    unitId,
    firstName,
    surname,
    email,
    phoneNumber,
    dateOfBirth,
    checker,
    gender,
    role,
    adminType,
    password,
    searchEmail,
    pageNo,
    pageSize,
  } = state;
  const [currentPage, setCurrentPage] = useState(1);
  const onChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "menuItem") {
      let menuString = editUserMenu.menu ?? [];
      if (checked) {
        if (!menuString?.includes(value)) {
          menuString.push(value);
          setEditUserMenu((s) => ({ ...s, menu: menuString }));
        }
      } else {
        if (menuString?.includes(value)) {
          const index = menuString.indexOf(value);
          menuString.splice(index, 1);
        }
      }
    }
    setState((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handlePasswordValidation = (e) => {
    const passwordInputValue = e.target.value.trim();
    const passwordInputFieldName = e.target.name;

    if (passwordInputFieldName === "password") {
      const minLengthRegExp = /.{8,}/;
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);

      let errMsg = "";
      if (!minLengthPassword) {
        errMsg = "Password should be at least a minumum of 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
  };
  const handleNumChange = (e) => {
    let limit = 13;
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value.slice(0, limit),
    }));
  };
  const clearSearchEmail = (e) => {
    setState((state) => ({
      ...state,
      searchEmail: "",
    }));
    getUsers();
  };

  useEffect(() => {
    getUsers();
  }, [pageSize, pageNo]);

  const getUsers = () => {
    axios({
      method: "get",
      url: `${allAdminUsers}?pageNo=${currentPage}&pageSize=${pageSize}&field=all&value=all`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          adminUsers: res.data.data?.content ?? [],
          totalActiveAdminUsers: res?.data?.data?.totalElements ?? "N/A",
        }));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err.response.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };
  const getUserByEmail = () => {
    if (!searchEmail) {
      toast.error(`Please input an email`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    const found = adminUsers.filter(({ email }) => searchEmail === email);
    setState((state) => ({
      ...state,
      adminUsers: found,
    })); /* 
    axios({
      method: "get",
      url: `${getAdminUserByEmail}/${searchEmail}`,
    })
      .then((res) => {
        console.log("user", res.data.data);
        let searched = [];
        if (res.data.data) {
          searched.push(res.data.data);
          setState((state) => ({
            ...state,
            adminUsers: searched,
          }));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err?.response?.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(
            `${
              err?.response?.data?.message
                ? err.response.data.message
                : err?.message
                ? err.message
                : "Something went wrong"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      }); */
  };
  const onCreateAdmin = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      firstName,
      surname,
      email,
      phoneNumber: `234${phoneNumber.slice(1)}`,
      dateOfBirth,
      gender,
      adminType,
      password,
      role: "ROLE_APP_ADMIN",
      admin: true,
      referenceCode: "",
      // password: Math.random().toString(36).slice(2),
    };

    axios({
      method: "post",
      url: `${createAdminUser}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data && res.data.status === true) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
            firstName: "",
            surname: "",
            email: "",
            phoneNumber: "",
            dateOfBirth: "",
            referenceCode: "",
            gender: "",
            password: "",
          }));

          toast.success(res?.data.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getUsers();
          setAddUser(false);
        } else {
          toast.error(
            ` Error: ${
              res.data
                ? res.data.message
                : "An error occured while creating user"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response.data.data
              ? err.response.data.data
              : err.response.data.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
      });
  };
  const assignMenuToUser = (e) => {
    e.preventDefault();
    const { menu, others } = editUserMenu;
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios
      .post(menuAssignUpdate, {
        menuString: menu,
        role: checker,
        unitId,
        ...others,
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === true) {
          toast.success(res.data.message, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getUsers();
        }
      })
      .catch((err) => {
        toast.error(`An error occurred,all fields are required`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        setEditUserMenu({});
      });
  };
  const onDeleteAdmin = (e, id) => {
    e.preventDefault();
    console.log(id);
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "put",
      url: `${deleteUser}`,
      data: {
        adminId: id,
        deleted: true,
      },
    })
      .then((res) => {
        if (res.data.status) {
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.success(`User Deleted successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getUsers();
          setRemoveUserModal(false);
        } else {
          toast.error(`An error occured while creating user`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            submit: false,
          }));
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setState((state) => ({
          ...state,
          submit: false,
        }));
      });
  };
  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };

  return (
    <Layout title="Admin Users" hidden={hidden}>
      <div>
        <div className="d-flex justify-content-md-between  align-items-start">
          <div className="d-flex w-50 bg-gray">
            <div className="input_Search d-flex justify-content-center align-items-center  w-50 ms-2 me-2 me-md-0">
              <div className="justify-content-center align-items-center">
                <FiSearch color="#FF6700" />
              </div>
              <input
                className="input ml-10"
                placeholder="Search Users by Email "
                name="searchEmail"
                value={searchEmail}
                onChange={onChange}
                required
              />
              {!searchEmail ? null : (
                <i onClick={clearSearchEmail}>
                  {" "}
                  <AiFillCloseCircle color="#FF6700" />
                </i>
              )}
            </div>
            <button className="orange-button ml-10" onClick={getUserByEmail}>
              Search
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center ">
            {!hidden && (
              <>
                <div className="me-3 px-3">
                  <button
                    className="manage-users-btn fs-12 p-2"
                    onClick={() => navigate("/admin/manage-unit")}
                  >
                    <i className="me-1">
                      <MdSettings />
                    </i>
                    Manage Units
                  </button>
                </div>

                <div className=" ">
                  <button
                    style={{ padding: "3px", marginRight: "4px" }}
                    className="orange-button fs-12"
                    onClick={() => setAddUser(true)}
                  >
                    <FaPlus color={"#fff"} className="mr-5" />
                    Create Admin
                  </button>
                </div>
              </>
            )}
            <ExportData
              name={"AdminUser"}
              url={downloadAdminUser}
              filename={`Admin user report-${new Date().toISOString()}`}
            />
          </div>
        </div>

        <Container fluid>
          <Row className="mt-40 ">
            <Col className="pe-0">
              <AdminUserCards
                title="All Active Admin Users"
                number={totalActiveAdminUsers}
              />
            </Col>
            <Col>
              {/*  <AdminUserCards
                title="Total Pending Invites"
                number={totalPendingInvites ? totalPendingInvites.length : 0}
              /> */}
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <div className="admin-users-table mt-5">
            <Table responsive borderless className="bg-inherit">
              <thead>
                <tr className=" t-head-colored">
                  <th className="py-3 th ">FULL NAME</th>
                  <th className="py-3 th ">EMAIL</th>
                  <th className="py-3 th ">UNIT</th>
                  <th className="py-3 th ">MENU</th>
                  <th className="py-3 th ">DATE CREATED</th>
                  <th className="py-3 th text-center">ACTIONS</th>
                  <th className="py-3 th "></th>
                </tr>
              </thead>
              <tbody>
                {adminUsers && adminUsers.length > 0 ? (
                  adminUsers?.map((user, i) => {
                    const {
                      firstName,
                      surname,
                      email,
                      approval,
                      id,
                      status,
                      menu: { menuString, menuAssignId, unit },
                      createdAt: [y, m, d],
                    } = user;

                    return (
                      <tr
                        key={i}
                        className="p-4"
                        style={{ borderBottom: "0.5px solid #D9D5EC" }}
                      >
                        <td className=" td ">
                          {firstName} {surname}
                        </td>
                        <td className=" td ">{email}</td>

                        <td className=" td ">{unit?.unitName ?? "N/A"}</td>

                        <td className=" td ">{`${
                          !isEmpty(menuString) ? truncate(menuString) : "N/A"
                        }`}</td>
                        <td className=" td ">
                          {moment(
                            `${y}${m.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}${d.toLocaleString("en-US", {
                              minimumIntegerDigits: 2,
                              useGrouping: false,
                            })}`
                          ).format("ll")}
                        </td>
                        {
                          <td className=" td text-center">
                            <button
                              className="change-role-btn"
                              onClick={() => {
                                setEditUserMenu((s) => ({
                                  ...s,
                                  edit: true,
                                  menu: menuString,
                                  others: {
                                    approval,
                                    id,
                                    status,
                                    menuAssignId,
                                  },
                                }));
                              }}
                            >
                              {"EDIT MENU"}
                            </button>
                          </td>
                        }
                        <td className=" td text-center">
                          <i
                            className="text-theme"
                            onClick={() => {
                              setRemoveUserModal(true);
                              setSelectedUser(user);
                            }}
                          >
                            <FaTrash />
                          </i>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </Table>
            {adminUsers && adminUsers.length > 0 ? (
              <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
                <div className="d-flex me-5">
                  <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                  <div className="dropdown">
                    <i
                      id="addminuserdropdownMenuButton1"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaCaretDown />
                    </i>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="adminuserdropdownMenuButton1"
                    >
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(10)}
                      >
                        10
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(20)}
                      >
                        20
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(50)}
                      >
                        50
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(100)}
                      >
                        100
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="me-4">
                  <p className="m-0">
                    {firstIndex} - {lastIndex} of {totalActiveAdminUsers}
                  </p>
                </div>
                <div className="mt-3 me-2">
                  <Pagination2
                    total={Math.ceil(totalActiveAdminUsers / pageSize)}
                    paginate={paginate}
                    selected={currentPage}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </Container>

        {addUser && (
          <Modal
            show={addUser}
            clicked={() => setAddUser(false)}
            submit={onCreateAdmin}
            loading={submit}
            title="Create New Admin User"
            action="Create New User"
          >
            <AddAdmin
              onChange={onChange}
              handleNumChange={handleNumChange}
              handlePasswordValidation={handlePasswordValidation}
              passwordErr={passwordErr}
              firstName={firstName}
              surname={surname}
              email={email}
              phoneNumber={phoneNumber}
              dateOfBirth={dateOfBirth}
              gender={gender}
              role={role}
              adminType={adminType}
              password={password}
            />
          </Modal>
        )}

        {editUserMenu.edit && (
          <Modal
            show={editUserMenu.edit}
            clicked={() => setEditUserMenu({ edit: false, menu: [] })}
            submit={assignMenuToUser}
            loading={submit}
            title="Edit Menu"
            action="Save Changes"
          >
            <EditAdminMenu
              onChange={onChange}
              modalState={editUserMenu.edit}
              // role={role}
              roles={userRoles}
              menu={editUserMenu.menu}
            />
          </Modal>
        )}

        {removeUserModal && (
          <RemoveUserModal
            title="Remove Admin User"
            show={removeUserModal}
            close="Cancel"
            clicked={() => setRemoveUserModal(false)}
            action="Yes, Remove"
            user={selectedUser}
            loading={submit}
            submit={(e) => {
              onDeleteAdmin(e, selectedUser.id);
            }}
          >
            <div className="d-flex flex-column">
              <p className="text-center">
                By doing this, <strong>{selectedUser.email}</strong> will no
                longer be able to access this business anymore. Do you want to
                continue?
              </p>
            </div>
          </RemoveUserModal>
        )}
      </div>
    </Layout>
  );
};

export default AdminUsers;
