import React from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { statesArray } from "./Users";

export default function CreateSingleUser(props) {
  const {
    firstName,
    surname,
    businessType,
    phoneNumber,
    orgState,
    orgPhone,
    email,
    dateOfBirth,
    orgName,
    city,
    officeAddress,
    password,
    businessTypeList,
    orgEmail,
    formError: { phoneErr, passwordErr, emailErr, inputErr },
  } = props;
  const [confirmPassword, setConfirmPassword] = useState();

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <Form>
      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fs-14">First Name</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="text"
              className="form-control fs-14"
              id="firstName"
              name="firstName"
              value={firstName}
              onChange={props.onChange}
              required
              minLength={1}
              onKeyUp={props.handleRequiredFieldValidation}
            />
            <p className="text-danger fs-12 mt-1">{props.inputErr}</p>
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="fs-14">Last Name</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="text"
              className="form-control fs-14"
              id="surname"
              name="surname"
              value={surname}
              required
              onChange={props.onChange}
              onKeyUp={props.handleRequiredFieldValidation}
            />
            <p className="text-danger fs-12 mt-1">{props.inputErr}</p>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fs-14">Business Type</label>
            <div className="input-container">
              <select
                className="input select"
                name="businessType"
                value={businessType}
                required
                onChange={props.onChange}
              >
                <option value="">Select option</option>
                {businessTypeList.map((val) => (
                  <option key={val.id} value={val.businessType}>
                    {val.businessType}
                  </option>
                ))}
              </select>
            </div>
            <p className="text-danger fs-12 mt-1">{inputErr?.businessType}</p>
          </div>
        </Col>

        <Col>
          <div className="form-group">
            <label className="fs-14">Phone Number</label>
            <input
              type="text"
              className="form-control fs-14"
              id="phoneNumber"
              name="phoneNumber"
              value={phoneNumber}
              required
              onChange={props.onChange}
              onKeyUp={props.handlePhoneValidation}
            />
            <p className="text-danger fs-12 mt-1">{phoneErr.phoneNumber}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="form-group">
            <label className="fs-14">Organisation Name</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="text"
              className="form-control fs-14"
              id="orgName"
              name="orgName"
              value={orgName}
              required
              onChange={props.onChange}
            />
          </div>
          <p className="text-danger fs-12 mt-1">{inputErr.orgName}</p>
        </Col>
        <Col>
          <div className="form-group">
            <label className="fs-14">Organisation Email address</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="email"
              className="form-control fs-14"
              id="email"
              name="orgEmail"
              value={orgEmail}
              required
              onChange={props.onChange}
              onKeyUp={props.handleEmailValidation}
            />
          </div>
          <p className="text-danger fs-12 mt-1">{emailErr?.orgEmail}</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fs-14">Organization State</label>
            <div className="input-container">
              <select
                className="input select"
                name="orgState"
                value={orgState}
                required
                onChange={props.onChange}
              >
                <option value="">Select option</option>
                {statesArray.map(({ name, id }) => (
                  <option key={id} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            <p className="text-danger fs-12 mt-1">{inputErr?.state}</p>
          </div>
        </Col>

        <Col>
          <div className="form-group">
            <label className="fs-14">Organization Phone Number</label>
            <input
              type="text"
              className="form-control fs-14"
              id="orgNumber"
              name="orgPhone"
              value={orgPhone}
              required
              onChange={props.onChange}
              onKeyUp={props.handlePhoneValidation}
            />
            <p className="text-danger fs-12 mt-1">{phoneErr?.orgPhone}</p>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fs-14">Organisation City</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="text"
              className="form-control fs-14"
              id="city"
              name="city"
              value={city}
              required
              onChange={props.onChange}
            />
          </div>
          <p className="text-danger fs-12 mt-1">{inputErr.city}</p>
        </Col>
        <Col>
          <div className="form-group">
            <label className="fs-14">Organisation address</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="text"
              className="form-control fs-14"
              id="officeAddress"
              name="officeAddress"
              value={officeAddress}
              required
              onChange={props.onChange}
            />
          </div>
          <p className="text-danger fs-12 mt-1">{inputErr.officeAddress}</p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <div className="form-group">
            <label className="fs-14">Email address</label>
            <span className="text-danger mr-1">*</span>
            <input
              type="email"
              className="form-control fs-14"
              id="email"
              name="email"
              value={email}
              required
              onChange={props.onChange}
              onKeyUp={props.handleEmailValidation}
            />
          </div>
          <p className="text-danger fs-12 mt-1">{emailErr?.email}</p>
        </Col>
        <Col>
          <div className="form-group">
            <label className="fs-14">Date Of Birth</label>
            <input
              type="date"
              className="form-control fs-14"
              id="dateOfBirth"
              name="dateOfBirth"
              value={dateOfBirth}
              required
              onChange={props.onChange}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="form-group">
            <label className="fs-14">Password</label>
            <input
              type="password"
              className="form-control fs-14"
              id="password"
              name="password"
              value={password}
              required
              onChange={props.onChange}
              onKeyUp={props.handlePasswordValidation}
            />
            <p className="text-danger fs-12 mt-1">{passwordErr}</p>
          </div>
        </Col>
        <Col>
          <div className="form-group">
            <label className="fs-14">Confirm Password</label>
            <input
              type="password"
              className="form-control fs-14"
              id="confirmPassword"
              name="confirmPassword"
              required
              onChange={confirmPasswordHandler}
            />
            <p className="text-danger fs-12 mt-1">
              {password === confirmPassword ? "" : "Password not match"}
            </p>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
