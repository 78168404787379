import React from "react";
import { IoFilterOutline } from "react-icons/io5";

const FilterPricings = ({
  pricingStatus,
  pricingProducts,
  handleFilterByProduct,
  handleFilterByStatus,
}) => {
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-05 ms-2">Filter</h4>
          </div>
        </button>
        <div className="dropdown-menu " style={{ width: "22rem" }}>
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Pricing Status</label>
              <div className="input-container">
                <select
                  className="input"
                  id="status"
                  name="status"
                  onChange={handleFilterByStatus}
                >
                  <option>Show All</option>
                  {pricingStatus.map((status, i) => {
                    return (
                      <option value={status} key={i}>
                        {status}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Products</label>
              <div className="input-container">
                <select
                  className="input"
                  id="product"
                  name="product"
                  onChange={handleFilterByProduct}
                >
                  <option>Show All</option>
                  {pricingProducts.map((product, i) => {
                    return (
                      <option value={product} key={i}>
                        {product}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter">Clear Filter</button>
              <button type="submit" className="px-3 py-2 apply-filter">
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterPricings;
