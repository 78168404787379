import React from "react";
import ReactPaginate from "react-paginate";

const Pagination2 = ({ total, paginate, selected }) => {
  return (
    <div className="">
      <ReactPaginate
        containerClassName="d-flex align-items-center list-unstyled text-decoration-none"
        pageClassName=""
        pageLinkClassName=" px-1 py-2 mx-1 text-decoration-none text-orange"
        activeClassName=" mx-2 "
        activeLinkClassName="text-dark"
        previousLinkClassName="text-orange text-decoration-none"
        nextLinkClassName="text-orange text-decoration-none"
        previousClassName={`dark-text mx-2 ${selected <= 1 ? "text-grey" : ""}`}
        nextClassName={` mx-2 ${selected + 1 >= total ? "text-grey " : ""}`}
        breakLabel="...."
        nextLabel=" >"
        onPageChange={paginate}
        pageRangeDisplayed={10}
        pageCount={total}
        previousLabel="<  "
        renderOnZeroPageCount={null}
      />
    </div>
  );
};

export default Pagination2;
