import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { VscAdd } from "react-icons/vsc";
import { Slide, toast } from "react-toastify";
import Modal from "../../components/Modal/Modal";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import Swal from "../../components/Swal/swal";
import axios from "../../plugins/axios";
import {
  allRemoteUpdate,
  allStation,
  createRemoteUpdate,
} from "../../plugins/urls";
import AddRemoteFile from "./AddRemoteFile";

export const getAllStations = (setState) => {
  axios({
    method: "get",
    url: `${allStation}`,
  })
    .then((res) => {
      if (res.data.respCode === 0) {
        setState((state) => ({
          ...state,
          stationsList: res.data.respBody,
        }));
      }
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const RemoteUpdateComp = (props) => {
  const [state, setState] = useState({
    show: false,
    modalValue: "",
    remoteUpdates: [],
    pageNo: 1,
    pageSize: 20,
    id: "",
    submit: false,
    editRemoteUpdate: {},
  });

  const { submit, show, modalValue, remoteUpdates, pageNo, pageSize } = state;

  const showModal = (value, editRemoteUpdate) => {
    if (!show) {
      setState((state) => ({
        ...state,
        show: true,
        modalValue: value,
        ...editRemoteUpdate,
      }));
    } else {
      setState({
        show: false,
        modalValue: "",
        remoteUpdates: [],
        pageNo: 1,
        pageSize: 20,
        id: "",
        submit: false,
        editRemoteUpdate: {},
      });
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const getAllRemoteUpdate = () => {
    axios({
      method: "get",
      url: `${allRemoteUpdate}?pageNo=${pageNo}&pageSize=${pageSize}`,
    })
      .then((res) => {
        if (res.data.respCode === 200) {
          setState((state) => ({
            ...state,
            remoteUpdates: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    if (_.isEmpty(remoteUpdates)) {
      getAllRemoteUpdate();
    }
  }, [show]);

  const onAddRemoteUpdate = () => {
    const { appUrl, terminalName, version } = state;
    const data = { appUrl, terminalName, version };
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${createRemoteUpdate}`,
      data,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRemoteUpdate();
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  /* const onUpdateRemoteUpdate = () => {
    
    const data = { appUrl, terminalName, version };
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${update}/${id}`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRemoteUpdate();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(res.data.respBody, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRemoteUpdate();
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  }; */

  const onDeleteRoute = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this terminal Update!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        /*  axios({
          method: "delete",
          url: `${deleteRouting}/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.data.respCode === 0) {
              toast.success(res.data.respDesc, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              getAllRemoteUpdate();
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }); */
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        show={show}
        clicked={showModal}
        loading={submit}
        submit={onAddRemoteUpdate}
        title={
          modalValue === "add"
            ? "Add Terminal Application"
            : "Edit Terminal Application"
        }
        action={
          modalValue === "add" ? "Add Terminal Application" : "Save Update"
        }
      >
        <AddRemoteFile onChange={onChange} state={state} />
      </Modal>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "36px 20px 56px 20px" }}
      >
        <div className="d-flex justify-content-center align-items-center">
          <div className="request-button" onClick={() => showModal("add")}>
            <VscAdd color={"#fff"} className="mr-5" />
            {"Add Application"}
          </div>
        </div>
      </div>

      <Container fluid>
        <Table responsive borderless className="bg-inherit">
          <thead>
            <tr style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}>
              <th>TERMINAL TYPE</th>
              <th>TERMINAL DESCRIPTION</th>
              <th>VERSION</th>
              <th>DOWNLOAD</th>
              <th>ACTIONS</th>
            </tr>
          </thead>

          <tbody>
            {remoteUpdates ? (
              remoteUpdates.length === 0 ? (
                <NoResultFound />
              ) : (
                remoteUpdates.map((remoteUpdate, i) => {
                  const { id, terminalName, version, terminalDesc, appUrl } =
                    remoteUpdate;

                  return (
                    <tr key={i}>
                      <td>{terminalName}</td>
                      <td>{terminalDesc ?? "N/A"}</td>
                      <td>{version}</td>
                      <td>
                        <Button as={"a"} download={true} href={appUrl}>
                          {"Download"}
                        </Button>
                      </td>
                      <td>
                        <span>
                          <BsPencil
                            color="#0086E8"
                            size={20}
                            onClick={() => {
                              showModal("edit", remoteUpdate);
                            }}
                          />
                        </span>
                        <span className="ml-22">
                          <MdDelete
                            size={20}
                            color="#FF6700"
                            onClick={() => {
                              onDeleteRoute(id);
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <NoResultFound />
            )}
          </tbody>
        </Table>
      </Container>
    </React.Fragment>
  );
};
