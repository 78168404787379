import React from "react";
import "../UsersComponents/users.scss";

const AdminUserCards = (props) => {
  return (
    <div className="admin-user-cards">
      <h4 className={`fs-12 fw-600 text-darker `}>
         {props.title}
      </h4>
      <h4 className={`fs-20 fw-700 text-darker`}>
        {props.number}
      </h4>
    </div>
  );
};

export default AdminUserCards;
