import React from "react";

export default function ChangeRole() {
  return (
    <div>
      <div>
        <p className="text-center p-2">
          This action will change the role of this user. This user will no
          longer have access to the previous role.
        </p>
        <div>
          <div className="row w-100 my-2 mx-auto">
            <select
              name="role"
              id="role"
              className="input-styles py-2 text-grey fs-14"
            >
              <option value="">Select New Role for this User</option>
              <option value="Business Owner">Business Owner</option>
              <option value="Customer Support">Customer Support</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}
