import { baseUrlList } from "../../plugins/baseUrl.js";
import { getRequest } from "../https.js";

export const getTerminalMetricsRequest = () => {
  return getRequest(baseUrlList.terminal, "terminals/admingetallstats");
};

export const getRevenueMetricsRequest = () => {
  return getRequest(baseUrlList.settlementV1, "settlement/statistics", {});
};

export const getRevenueByDateRequest = (date) => {
  return getRequest(
    baseUrlList.settlementV1,
    `settlement/revenue/income/byDateRange?endDate=${date}&startDate=${date}&status=SETTLED`
  );
};

export const getSuccessRateRequest = () => {
  return getRequest(
    baseUrlList.transactions,
    "api/v1/transactions/successRate"
  );
};

export const getPaymentMethodRequest = () => {
  return getRequest(
    baseUrlList.transactions,
    "api/v1/transactions/paymentMethods/metrics"
  );
};

export const getMembershipRequest = () => {
  return getRequest(baseUrlList.merchants, "/agent/metrics");
};

export const getIncomeRequest = () => {
  return getRequest(
    baseUrlList.settlementV1,
    "settlement/revenue/income/survey"
  );
};
