import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import { MdOutlineKeyboardBackspace, MdSettings } from "react-icons/md";
import Layout from "../../Layout/Layout";
import invitePeople from "../../../assets/icons/people-plus.png";
import NoResultFound from "../../NoResultFound/NoResultFound";
import { CustomModal } from "../../Modal/CustomModal";
import InviteTeamMember from "./InviteTeamMember";
import TeamActionsModal from "../../Modal/TeamActionsModal";
import ChangeBusinessOwner from "./ChangeBusinessOwner";
import ChangeRole from "./ChangeRole";
import { useLocation, useNavigate } from "react-router";

const teamMembersList = [
  {
    name: "Adeyanju Akorede",
    email: "adeyanju.celestial@gmail.com",
    role: "Business Owner",
    status: "Enabled",
  },
  {
    name: "Yussuf Binta",
    email: "binta.yussuf@gmail.com",
    role: "Customer Support",
    status: "Disabled",
  },
  {
    name: "Adebola Koko",
    email: "adebola.kokol@gmail.com",
    role: "Business Owner",
    status: "Enabled",
  },
  {
    name: "Chima Eze",
    email: "chima.eze@gmail.com",
    role: "Customer Support",
    status: "Enabled",
  },
];

export default function TeamMembers() {
  const [teamState, setTeamState] = useState({
    add: false,
    submit: false,
    transfer: false,
    changeRole: false,
    remove: false,
  });
  const { add, submit, transfer, changeRole, remove } = teamState;
  const navigate = useNavigate();

  const { state } = useLocation();

  const showModal = () => {
    if (!add) {
      setTeamState((teamState) => ({
        ...teamState,
        add: true,
      }));
    } else {
      setTeamState((teamState) => ({
        ...teamState,
        add: false,
      }));
    }
  };

  const showChangeBusinessOwnerModal = () => {
    if (!transfer) {
      setTeamState((teamState) => ({
        ...teamState,
        transfer: true,
      }));
    } else {
      setTeamState((teamState) => ({
        ...teamState,
        transfer: false,
      }));
    }
  };

  const showChangeRoleModal = () => {
    if (!changeRole) {
      setTeamState((teamState) => ({
        ...teamState,
        changeRole: true,
      }));
    } else {
      setTeamState((teamState) => ({
        ...teamState,
        changeRole: false,
      }));
    }
  };

  const showRemoveTeamMemberModal = () => {
    if (!remove) {
      setTeamState((teamState) => ({
        ...teamState,
        remove: true,
      }));
    } else {
      setTeamState((teamState) => ({
        ...teamState,
        remove: false,
      }));
    }
  };

  const handleInviteTeamMember = () => {};

  const handleChangeBusinessOwner = () => {};

  const handleChangeRole = () => {};

  const handleRemoveTeamMemberModal = () => {};

  return (
    <Layout>
      <CustomModal
        show={add}
        clicked={showModal}
        loading={submit}
        submit={handleInviteTeamMember}
        title="Invite Team Member"
        action="Invite Team Member"
        size="md"
      >
        <InviteTeamMember />
      </CustomModal>
      <TeamActionsModal
        show={transfer}
        clicked={showChangeBusinessOwnerModal}
        loading={submit}
        submit={handleChangeBusinessOwner}
        title="Change Business Owner"
        action="Transfer"
        close="Cancel"
      >
        <ChangeBusinessOwner />
      </TeamActionsModal>
      <TeamActionsModal
        show={changeRole}
        clicked={showChangeRoleModal}
        loading={submit}
        submit={handleChangeRole}
        title="Change Role"
        action="Transfer"
        close="Cancel"
      >
        <ChangeRole />
      </TeamActionsModal>
      <TeamActionsModal
        show={remove}
        clicked={showRemoveTeamMemberModal}
        loading={submit}
        submit={handleRemoveTeamMemberModal}
        title="Remove Team Member"
        action="Yes, Remove"
        close="Cancel"
      >
        <div>
          <p className="text-center px-2 my-2">
            By doing this, <strong>{"adeyanju.celestial@gmail.com"}</strong>{" "}
            will no longer be able to access this business anymore. Do you want
            to continue?
          </p>
        </div>
      </TeamActionsModal>
      <Container>
        <div className="mb-3">
          <span
            className="pointer fs-14"
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="me-1">
              <MdOutlineKeyboardBackspace />
            </i>
            Back
          </span>
        </div>
        <Container>
          <div className="p-4">
            <div
              className="p-4 rounded bg-white"
              style={{ border: "1px solid #bbb" }}
            >
              <div className="d-flex justify-content-between align-items-center py-2 w-100">
                <h5 className="fs-16">Team Members - {"10"}</h5>
                <div className="d-flex justify-content-center align-items-center">
                  <div className="me-3">
                    <button
                      className="d-flex justify-content-between align-items-center border border-2 border-grey rounded-1 fs-12 p-2"
                      onClick={() => {
                        navigate("/manage-roles");
                      }}
                    >
                      <i className="me-1">
                        <MdSettings />
                      </i>
                      Manage Roles
                    </button>
                  </div>
                  <div className=" ">
                    <button
                      className="btn d-flex justify-content-between align-items-center p-2 me-4 fs-12 text-white rounded-0"
                      style={{ background: "#FF6700" }}
                      onClick={showModal}
                    >
                      <img
                        src={invitePeople}
                        alt=""
                        className="mr-5"
                        style={{ width: "1.5em" }}
                      />
                      Invite someone
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-20 w-100 border border-1">
                <Table
                  borderless
                  responsive
                  style={{ width: "94%", margin: "auto" }}
                >
                  <thead
                    className="fs-16"
                    style={{ borderBottom: "0.5px solid #bbb" }}
                  >
                    <tr>
                      <th className="py-4 fw-500">Name:</th>
                      <th className="py-4 fw-500">Email:</th>
                      <th className="py-4 fw-500">Role:</th>
                      <th className="py-4 fw-500">2FA Status</th>
                      <th className="py-4 fw-500">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="pt-4 pb-2 fs-12 fw-500">
                        {state != null && state.firstName && state.surname ? `${state.firstName} ${state.surname}` : "Main User"}
                      </td>
                      <td className="pt-4 pb-2 fs-12 fw-500">
                        {state != null && state.email ? state.email : "N/A"}
                      </td>
                      <td className="pt-4 pb-2 fs-12 fw-500">Business Owner</td>
                      <td className="pt-4 pb-2 fs-12">
                        <span className="d-flex justify-content-start align-items-center">
                          <div
                            className="p-1 bg-success rounded-circle me-1"
                            style={{ width: "0.5em" }}
                          ></div>{" "}
                          Enabled
                        </span>
                      </td>
                      <td className="pt-4 pb-2">
                        <button
                          className="btn p-2"
                          style={{
                            border: "1px solid #555",
                            width: "14em",
                            fontSize: "11px",
                          }}
                          onClick={showChangeBusinessOwnerModal}
                        >
                          Change Business Owner
                        </button>
                      </td>
                    </tr>
                    {teamMembersList ? (
                      teamMembersList.length === 0 ? (
                        <NoResultFound />
                      ) : (
                        teamMembersList.map((member, i) => {
                          const { name, email, role, status } = member;
                          return (
                            <tr key={i}>
                              <td className="py-2 fs-12">{name}</td>
                              <td className="py-2 fs-12">{email}</td>
                              <td className="py-2 fs-12">{role}</td>
                              <td className="py-2 fs-12">
                                {status.toLowerCase() === "enabled" ? (
                                  <span className="d-flex justify-content-start  align-items-center">
                                    <div
                                      className="p-1 bg-success rounded-circle me-1"
                                      style={{ width: "0.5em" }}
                                    ></div>{" "}
                                    Enabled
                                  </span>
                                ) : (
                                  <span className="d-flex justify-content-start align-items-center">
                                    <div
                                      className="p-1 bg-grey rounded-circle me-1"
                                      style={{ width: "0.5em" }}
                                    ></div>{" "}
                                    Disabled
                                  </span>
                                )}
                              </td>
                              <td
                                className="d-flex justify-content-between align-items-center"
                                style={{ width: "80%" }}
                              >
                                <button
                                  className="btn py-1 px-2"
                                  style={{
                                    border: "1px solid #555",
                                    fontSize: "11px",
                                  }}
                                  onClick={showChangeRoleModal}
                                >
                                  Change Role
                                </button>
                                <button
                                  className="btn py-1 px-2"
                                  style={{
                                    border: "1px solid #bbb",
                                    fontSize: "11px",
                                  }}
                                  onClick={showRemoveTeamMemberModal}
                                >
                                  Remove
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      )
                    ) : (
                      <NoResultFound />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </Layout>
  );
}
