import _ from "lodash";
import moment from "moment";
import React, { createContext, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { AiFillCaretRight, AiFillEye } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { MdCheckCircle } from "react-icons/md";
import { matchPath, useLocation, useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import { downloadDispute } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import axios from "../../plugins/axios";
import {
  allWayaDisputes,
  createWayaPosDisputes,
  filterAllTransactions,
  getDisputeMetrics,
} from "../../plugins/urls";
import { DisputeModal } from "../Modal/DisputeModal";
import NoResultFound from "../NoResultFound/NoResultFound";
import CreateWayaPosDispute from "./CreateWayaPosDispute";
import "./dispute.scss";
import FilterDispute from "./FilterDispute";
import WayaCards from "./WayaCards";

// let pagesize = 10;
export const WayaDisputeContext = createContext();
const WayaDispute = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showForBusinessReport = matchPath("/business-reports", pathname);
  const [state, setState] = useState({
    wayaDisputes: [],
    from: Date.now(),
    to: Date.now(),
    pageNo: 1,
    pageSize: 20,
    createDispute: false,
    submit: false,
    customer: "",
    customerEmail: "",
    transactionDate: "",
    initiationDate: "",
    dateCreated: "",
    dueWhen: "",
    disputeType: "",
    amount: "",
    merchantName: "",
    emailAddress: "",
    merchantID: "",
    reason: "",
    responseState: "",
    responseDate: "",
    image: null,
    status: "",
    transactionReference: "",
    totalOpenedDispute: 0,
    totalWonDispute: 0,
    totalInitiatedDispute: 0,
    totalClosedDispute: 0,
  });

  const {
    wayaDisputes,
    pageNo,
    pageSize,
    createDispute,
    submit,
    customer,
    customerEmail,
    disputeType,
    merchantID,
    merchantName,
    transactionReference,
    reason,
    totalOpenedDispute,
    totalInitiatedDispute,
    totalClosedDispute,
  } = state;
  // const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState(wayaDisputes);
  const currentDate = moment(new Date(Date.now())).format("LL HH:mm A");

  const showCreateModal = () => {
    if (!createDispute) {
      setState((state) => ({
        ...state,
        createDispute: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        createDispute: false,
      }));
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getDisputeStatusCount();
    getAllDisputes();
  }, []);

  const getAllDisputes = () => {
    let reqBody = {
      // from,
      // to,
      id: 0,
      pageNo,
      pageSize,
      // id: user ? user.id : "",
    };

    axios({
      method: "post",
      url: `${allWayaDisputes}`,
      data: reqBody,
    })
      .then((res) => {
        console.log(res);
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            wayaDisputes: res.data.respBody.content,
          }));
          setFilteredList(res.data.respBody.content);
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.error(`${err.response.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };

  const getDisputeStatusCount = () => {
    axios({
      method: "get",
      url: `${getDisputeMetrics}`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          const { open, close, initiated } = res.data.respBody;
          setState((state) => ({
            ...state,
            totalOpenedDispute: open,
            totalInitiatedDispute: initiated,
            totalClosedDispute: close,
          }));
        }
      })
      .catch((err) => {
        if (err.response.status === 500) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        } else {
          toast.error(`${err.response.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };

  const createWayaDispute = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      customer,
      customerEmail,
      disputeType,
      merchantID,
      reason,
      transactionReference,
    };

    axios({
      method: "post",
      url: `${createWayaPosDisputes}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            createDispute: false,
          }));

          toast.success(`Dispute Created Successfully`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllDisputes();
        } else {
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            createDispute: false,
          }));
        }
      })
      .catch((err) => {
        // console.log(err);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`Transaction not found.`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setState((state) => ({
          ...state,
          createDispute: false,
        }));
      });
  };

  const handleSearch = () => {
    try {
      let result = [];
      if (searchInput !== "") {
        result = wayaDisputes.filter((val) => {
          return (
            val.customer.includes(searchInput) ||
            val.transactionReference === searchInput
          );
        });
        setFilteredList(result);
      }
      return wayaDisputes;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateSearch = (e) => {
    try {
      let result = [];
      const value = moment(new Date(e.target.value)).format("D-MM-YYYY");
      console.log(value);
      if (value !== null) {
        result = wayaDisputes.filter((val) => {
          let d = moment(new Date(val.dateCreated)).format("DD-MM-YYYY");
          return d.includes(value);
        });
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByDisputeStatus = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        if (value.toLowerCase() === "open") {
          result = wayaDisputes.filter((val) => {
            return val.adminStatus === "OPEN";
          });
        } else if (value.toLowerCase() === "closed") {
          result = wayaDisputes.filter((val) => {
            return val.adminStatus === "CLOSE";
          });
        } else if (value.toLowerCase() === "initiated") {
          result = wayaDisputes.filter((val) => {
            return val.adminStatus === "INITIATED";
          });
        }
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByResolutionStatus = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        if (value.toLowerCase() === "accepted") {
          result = wayaDisputes.filter((val) => {
            return val.disputeResolution === "accepted";
          });
        } else if (value.toLowerCase() === "rejected") {
          result = wayaDisputes.filter((val) => {
            return val.disputeResolution === "declined";
          });
        }
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const clearFilter = (e) => {
    e.preventDefault();
    getAllDisputes();
  };

  const headers = [
    { label: "Status", key: "status" },
    { label: "Dispute Type", key: "disputeType" },
    { label: "Transaction Amount", key: "amount" },
    { label: "Customer", key: "customer" },
    { label: "Date Created", key: "dateCreated" },
    { label: "Due When", key: "dueWhen" },
  ];

  const csvReport = {
    data: wayaDisputes.map((row) => ({
      ...row,
      dateCreated: moment(row.dateCreated).format("d/MM/yyyy HH:mm A"),
      dueWhen: moment(row.dueWhen).format("d/MM/yyyy HH:mm A"),
    })),
    headers: headers,
    filename: `WayaDisputes_${currentDate}.csv`,
  };

  const handleBlur = () => {
    console.log("blur");
    let refId = state?.transactionReference;
    if (refId) {
      axios.get(`${filterAllTransactions}?referenceId=${refId}`).then((res) => {
        const {
          data: { respBody, respDesc },
        } = res;
        if (!_.isEmpty(respBody)) {
          const {
            terminals: {
              merchants: { merchantId, firstname, surname, email },
            },
          } = respBody[0];
          setState((s) => ({
            ...s,
            merchantID: merchantId,
            customer: `${surname} ${firstname}`,
            customerEmail: email,
          }));
        } else {
          toast.error(respDesc);
        }
      });
    }
  };

  return (
    <WayaDisputeContext.Provider
      value={{
        onChange,
        handleDateSearch,
        handleFilterByDisputeStatus,
        handleFilterByResolutionStatus,
        state,
        clearFilter,
      }}
    >
      <DisputeModal
        show={createDispute}
        clicked={showCreateModal}
        submit={createWayaDispute}
        loading={submit}
        title="Raise a dispute to merchant"
        action="Create Dispute"
        size="md"
        close="Cancel"
        subtitle="Kindly provide the following requirements to complete dispute
        initiation"
      >
        <CreateWayaPosDispute
          onChange={onChange}
          handleBlur={handleBlur}
          transactionReference={transactionReference}
          merchantID={merchantID}
          merchantName={merchantName}
          customer={customer}
          customerEmail={customerEmail}
          disputeType={disputeType}
          reason={reason}
        />
      </DisputeModal>
      <div className="tableHeaders d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between filter-contents align-items-center">
          <div className="d-flex justify-content-start align-items-center width-50">
            <div className="d-flex justify-content-center align-items-center ">
              <div className="d-flex justify-content-center align-items-center ">
                <FilterDispute />
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center filter-search ml-22">
              <div className="input_Search d-flex justify-content-center align-items-center">
                <div className="justify-content-center align-items-center">
                  <FiSearch color="#FF6700" />
                </div>
                <input
                  className="input ml-10"
                  placeholder="Search dispute by Name or Reference Id"
                  onChange={(event) => {
                    setSearchInput(event.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-start align-items-center ">
            <div className="d-flex justify-content-center align-items-center ">
              <ExportData
                url={downloadDispute}
                filename={`Waya Dispute report-${new Date().toISOString()}`}
              />
            </div>
            {!showForBusinessReport && (
              <div className="d-flex justify-content-center align-items-center ">
                <div
                  className="orange-button fs-14"
                  role="button"
                  onClick={showCreateModal}
                >
                  <Plus className="mr-5" />
                  Create New Dispute
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Container fluid>
        <Row className="mt-40">
          <Col>
            <WayaCards
              color="text-orange"
              title="0PEN DISPUTE"
              number={totalOpenedDispute ? totalOpenedDispute : 0}
            />
          </Col>
          <Col>
            <WayaCards
              color="text-sharp-green"
              title="CLOSED DISPUTE"
              number={totalClosedDispute ? totalClosedDispute : 0}
            />
          </Col>
          <Col>
            <WayaCards
              color="text-deep-grey"
              title="INITIATED DISPUTE"
              number={totalInitiatedDispute ? totalInitiatedDispute : 0}
            />
          </Col>
          {/* <Col>
            <WayaCards
              color="text-sharp-green"
              title="WON DISPUTE"
              number={totalWonDispute ? totalWonDispute : 0}
            />
          </Col> */}
        </Row>

        <div className="data-table mt-40">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr
                style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                className="t-head-colored"
              >
                <th className="py-2 th">STATUS</th>
                <th className="py-2 th">DISPUTE TYPE</th>
                <th className="py-2 th">TRANSACTION</th>
                <th className="py-2 th">CUSTOMER NAME</th>
                <th className="py-2 th">DATE CREATED</th>
                <th className="py-2 th">DUE WHEN</th>
                <th className="py-2 th">ACTION</th>
              </tr>
            </thead>

            <tbody>
              {filteredList ? (
                filteredList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  filteredList.map((dispute, i) => {
                    const {
                      adminStatus,
                      // status,
                      disputeType,
                      amount,
                      transactionDate,
                      customer,
                      dateCreated,
                      id,
                    } = dispute;
                    return (
                      <tr key={i}>
                        <td className="td">
                          {adminStatus === "OPEN" ? (
                            <span
                              className="py-2 px-4 text-white rounded"
                              style={{ background: "#FF6700" }}
                            >
                              Open
                            </span>
                          ) : adminStatus === "CLOSE" ||
                            adminStatus === "CLOSED" ? (
                            <span
                              className="py-2 text-white rounded"
                              style={{
                                paddingInline: "1.5em",
                                background: "#27AE60",
                              }}
                            >
                              Closed
                            </span>
                          ) : (
                            <span
                              className="py-2 px-3 text-white rounded"
                              style={{ background: "#828282" }}
                            >
                              Initiated
                            </span>
                          )}
                        </td>
                        <td className="td">
                          {disputeType ? disputeType : "N/A"}
                        </td>
                        <td className="td">
                          {transactionDate && amount
                            ? `NGN${amount} on ${moment(
                                new Date(transactionDate)
                              ).format("D/MM/YYYY")}`
                            : "N/A"}
                        </td>
                        <td className="td text-capitalize">
                          {customer ? customer : "N/A"}
                        </td>
                        <td className="td">
                          {dateCreated
                            ? moment(new Date(dateCreated)).format(
                                "DD-MM-YYYY hh:mm A"
                              )
                            : "N/A"}
                        </td>
                        <td className="td">
                          {adminStatus === "OPEN" ? (
                            <span>In A Day</span>
                          ) : adminStatus === "CLOSE" ||
                            adminStatus === "CLOSED" ? (
                            <span>---</span>
                          ) : (
                            <span>In A Day</span>
                          )}
                        </td>
                        <td className="td">
                          {adminStatus === "OPEN" ? (
                            <span
                              className="d-flex justify-content-between align-items-center text-success rounded px-1"
                              style={{
                                border: "1.5px solid #27AE60",
                                width: "8em",
                                background: "#DDFFEB",
                              }}
                              onClick={() => {
                                navigate(`/disputes/details/${id}`, {
                                  state: dispute,
                                });
                              }}
                            >
                              <MdCheckCircle
                                size={20}
                                style={{ color: "#27AE60" }}
                              />
                              Resolve
                              <AiFillCaretRight
                                size={20}
                                style={{ color: "#27AE60" }}
                              />
                            </span>
                          ) : adminStatus === "CLOSE" ||
                            adminStatus === "CLOSED" ? (
                            <>
                              <span
                                className="actionBlue"
                                style={{
                                  borderWidth: "1.5px",
                                  paddingInline: "1em",
                                }}
                                onClick={() => {
                                  navigate(`/disputes/resolution/${id}`, {
                                    state: dispute,
                                  });
                                }}
                              >
                                <AiFillEye size={20} color="#064A72" />
                              </span>
                              {/* <span
                                className="ms-2 actionDanger"
                                style={{
                                  borderWidth: "1.5px",
                                  paddingInline: "1em",
                                }}
                              >
                                <MdDelete size={20} color="#FF6700" />
                              </span> */}
                            </>
                          ) : (
                            <span
                              className="d-flex justify-content-between align-items-center text-success rounded px-1"
                              style={{
                                border: "1.5px solid #27AE60",
                                width: "8em",
                                background: "#DDFFEB",
                              }}
                              onClick={() => {
                                navigate(`/disputes/initiated/${id}`, {
                                  state: dispute,
                                });
                              }}
                            >
                              <MdCheckCircle
                                size={20}
                                style={{ color: "#27AE60" }}
                              />
                              Resolve
                              <AiFillCaretRight
                                size={20}
                                style={{ color: "#27AE60" }}
                              />
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
          {/* <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredList.length}
            pagesize={pagesize}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        </div>
      </Container>
    </WayaDisputeContext.Provider>
  );
};

export default WayaDispute;
