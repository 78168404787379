import React from "react";
import { IoFilterOutline } from "react-icons/io5";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react";
import { SettlementTransactionsContext } from "./WayaSettlement";

const statusoptions = ["successful", "pending", "failed"];
const terminals = ["PAX", "NEXGO2", "NEXGO3"];
const transactions = ["withdrawal", "deposit"];
const paymentCh = ["pos"];

const FilterCashoutTransactions = () => {
  const {
    onFilterChange,
    setStartDate,
    setEndDate,
    clearFilter,
    filterParams: {
      startDate,
      endDate,
      terminalName,
      status,
      transactionType,
      paymentChannel,
    },
    handleFilter,
  } = useContext(SettlementTransactionsContext);

  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu3"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div className="dropdown-menu " style={{ width: "22rem" }}>
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Status</label>
              <div className="input-container">
                <select
                  className="input"
                  name="status"
                  value={status}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {statusoptions.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Terminal Name</label>
              <div className="input-container">
                <select
                  className="input"
                  name="terminalName"
                  value={terminalName}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {terminals.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">
                Transaction type
              </label>
              <div className="input-container">
                <select
                  className="input"
                  name="transactionType"
                  value={transactionType}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {transactions.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">
                Payment Channel
              </label>
              <div className="input-container">
                <select
                  className="input"
                  name="paymentChannel"
                  value={paymentChannel}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {paymentCh.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Date Range</label>
              <div className="input-container d-flex">
                <span className="text-muted fs-12 me-1 mt-1">From</span>
                <DatePicker
                  className="input fs-13 "
                  name="startDate"
                  selected={startDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setStartDate(date)}
                  //   closeOnScroll={true}
                  maxDate={new Date()}
                />
                {/* <input className="input" type="date"/> */}
                <span className="text-muted fs-12 me-2 mt-1">to</span>
                <DatePicker
                  className="input fs-13 "
                  name="startDate"
                  selected={endDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setEndDate(date)}
                  //   closeOnScroll={true}
                  maxDate={new Date()}
                />
                {/* <input className="input" type="date" /> */}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter" onClick={clearFilter}>
                Clear Filter
              </button>
              <button
                type="submit"
                className="px-3 py-2 apply-filter"
                onClick={(e) => handleFilter(e)}
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterCashoutTransactions;
