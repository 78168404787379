import Layout from "../../components/Layout/Layout";
import { useEffect } from "react";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { CgArrowLongLeft } from "react-icons/cg";
import { useState } from "react";
import { useNavigate } from "react-router";
import RemoveUserModal from "../../components/Modal/RemoveUserModal";
import {
  allPriviledges,
  createCustomRole,
  defaultAdminUserRoles,
  allAdminUsers,
  roleUsers,
} from "../../plugins/urls";
import { toast, Slide } from "react-toastify";
import axios from "../../plugins/axios";

export const getDefaultRoles = (setState) => {
  axios({
    method: "get",
    url: `${defaultAdminUserRoles}`,
  })
    .then((res) => {
      setState((state) => ({
        ...state,
        defaultRoles: res.data ? res.data : [],
      }));
      // getRoleNoPermissions()
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 401) {
        toast.error(
          `${
            err.response.data
              ? `${err.response.data.error}. Please login to access the page`
              : "Something went wrong. Please try again"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        // navigate("/login");
      } else {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      }
    });
};
const ManageAdminUserRoles = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    isActiveRole: "",
    customRoleName: "",
    customeRoleDescription: "",
    customRoleUser: "",
    customRolePrivileges: [],
    privileges: [],
    defaultRoles: [],
    selectedDefaultRolePrivileges: [],
    selectedDefaultRoleNoPrivileges: [],
    selectedDefaultRoleUsers: [],
    numberOfselectedDefaultRoleUsers: 0,
    users: [],
    customRoleModal: false,
  });
  const {
    isActiveRole,
    privileges,
    defaultRoles,
    selectedDefaultRolePrivileges,
    selectedDefaultRoleNoPrivileges,
    selectedDefaultRoleUsers,
    numberOfselectedDefaultRoleUsers,
    users,
    customRoleModal,
    customRoleName,
    customRoleDescription,
    customRoleUser,
    customRolePrivileges,
  } = state;
  useEffect(() => {
    getPrivileges();
    getDefaultRoles(setState);
    getRoleUsers();
    getUsers();
  }, []);
  const getPrivileges = (page = 0, size = 1000) => {
    axios({
      method: "get",
      url: `${allPriviledges}?page=${page}&size=${size}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          privileges: res.data.body.data.privileges
            ? res.data.body.data.privileges
            : [],
        }));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err.response.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };
  const getUsers = () => {
    axios({
      method: "get",
      url: `${allAdminUsers}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          users: res.data ? res.data.data : [],
        }));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err.response.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };
  const getRoleUsers = (id = 21, page = 0, size = 20) => {
    axios({
      method: "get",
      url: `${roleUsers}/${id}?page=${page}&size=${size}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          numberOfselectedDefaultRoleUsers: res.data.page
            ? res.data.page.totalElements
            : 0,
          selectedDefaultRoleUsers: res.data._embedded
            ? res.data._embedded.userResponse
              ? res.data._embedded.userResponse
              : []
            : [],
        }));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err.response.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };

  const userSelectedRole = (role) => {
    // set role name of selected  role
    changeActiveRole(role.description);
    // get users that have the role
    getRoleUsers(role.id);
    // get the permissions for the selected role
    getRolePermissions(role.privileges);
    // get permissions selected role does not have
    getRoleNoPermissions(role.privileges);
  };
  const getRolePermissions = (role_privileges) => {
    setState((state) => ({
      ...state,
      selectedDefaultRolePrivileges: role_privileges,
    }));
  };
  const getRoleNoPermissions = (current_privilege) => {
    const permissions = privileges;
    for (let i = 0; i < current_privilege.length; i++) {
      let index = permissions.findIndex(
        (permission, index) => permission.id === current_privilege[i].id
      );
      if (index === -1) {
        continue;
      }
      permissions.splice(index, 1);
    }
    setState((state) => ({
      ...state,
      selectedDefaultRoleNoPrivileges: permissions,
    }));
  };
  const onCreateCustomRole = () => {
    let reqBody = {
      description: customRoleDescription,
      name: customRoleName,
      privileges: customRolePrivileges,
      userId: "639",
    };
    axios({
      method: "post",
      url: `${createCustomRole}`,
      data: reqBody,
    })
      .then((res) => {
        // setState((state) => ({
        //   ...state,
        //   privileges: res.data.body.data.privileges
        //     ? res.data.body.data.privileges
        //     : [],
        // }));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          toast.error(
            `${
              err.response.data
                ? `${err.response.data.error}. Please login to access the page`
                : "Something went wrong. Please try again"
            }`,
            {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            }
          );
          navigate("/login");
        } else {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      });
  };
  const changeActiveRole = (role) => {
    setState((state) => ({
      ...state,
      isActiveRole: role,
    }));
  };
  const onOpenModal = () => {
    setState((state) => ({
      ...state,
      customRoleModal: true,
    }));
  };
  const onCloseModal = () => {
    setState((state) => ({
      ...state,
      customRoleModal: false,
    }));
  };
  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  function chkbox(id, isChecked) {
    if (isChecked) {
      const privilege = privileges.find((privilege) => privilege.id === id);
      return setState((state) => ({
        ...state,
        customRolePrivileges: customRolePrivileges.concat(privilege),
      }));
    }
    let filteredPrivileges = customRolePrivileges.filter(
      (privilege) => privilege.id !== id
    );
    setState((state) => ({
      ...state,
      customRolePrivileges: filteredPrivileges,
    }));
  }

  return (
    <Layout>
      <div className="mb-3">
        <span
          className="pointer "
          onClick={() => {
            navigate(-1);
          }}
        >
          <i className="me-2">
            <CgArrowLongLeft />
          </i>
          Back
        </span>
      </div>
      <div className="container manage-admin-users-container">
        <div className="row">
          <div className="col-3 br-right">
            <h5 className="mt-4 ms-2">Default Roles</h5>
          </div>
          <div className="col-9">
            <h5 className="mt-4">{isActiveRole}</h5>
            <p>This role grants users the permissions listed below.</p>
            <div className="role-user-list mb-2">
              <span>
                {numberOfselectedDefaultRoleUsers} Members with this role:
              </span>
              <p align="justify" className="text-wrap">
                {selectedDefaultRoleUsers.map((user, i) => (
                  <span key={i} className="text-muted fs-12">
                    {" "}
                    {user.firstName} {user.lastName},{" "}
                  </span>
                ))}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3 px-0 br-right">
            <ul className="role-list-wrapper pb-5 pointer">
              {defaultRoles.map((role, i) => (
                <li
                  key={i}
                  onClick={() => {
                    userSelectedRole(role);
                  }}
                  className={`role-list-item ${
                    isActiveRole === role.description ? "active-role" : ""
                  }`}
                >
                  {role.description}
                </li>
              ))}
            </ul>

            <h5 className="ms-3 mt-5 mb-3">Custom Roles</h5>
            <ul className="role-list-wrapper ms-3 mb-2">
              <li>Custom Role 1</li>
              <li
                style={{ cursor: "pointer" }}
                className="my-4"
                onClick={onOpenModal}
              >
                <i>
                  <AiOutlinePlusCircle />
                </i>{" "}
                Add Custom Role
              </li>
            </ul>
          </div>
          <div className="col-9 d-flex justify-content-between">
            <div className="col-6 p-2">
              <table className="table table-striped role-permissions-table">
                <thead>
                  <tr className="role-permissions-thead">
                    <th>What this role can do</th>
                  </tr>
                </thead>

                <tbody>
                  {selectedDefaultRolePrivileges ? (
                    selectedDefaultRolePrivileges.map((privilege, i) => (
                      <tr key={i} className="">
                        <td className=" py-3">{privilege.description}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Privileges found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="col-6 p-2">
              <table className="table table-striped role-permissions-table">
                <thead>
                  <tr className="role-restrictions-thead">
                    <th>What this role cannot do</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedDefaultRoleNoPrivileges ? (
                    selectedDefaultRoleNoPrivileges.map((privilege, i) => (
                      <tr key={i} className="">
                        <td className=" py-3">{privilege.description}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>No Privileges found</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {customRoleModal && (
          <RemoveUserModal
            title="Create Custom Role"
            show={customRoleModal}
            close="Cancel"
            clicked={onCloseModal}
            submit={onCreateCustomRole}
            action="Create New Role"
          >
            <div className="d-flex flex-column">
              <div className="d-flex flex-column mb-4">
                <label className="fs-12 fw-400 mb-1">Role Name</label>
                <input
                  className="admin-user-inputs"
                  placeholder="Give this role a name"
                  name="customRoleName"
                  value={customRoleName}
                  onChange={onChange}
                />
              </div>
              <div className="d-flex flex-column mb-4">
                <label className="fs-12 fw-400 mb-1">Role Description</label>
                <input
                  className="admin-user-inputs"
                  placeholder="Give this role a description"
                  name="customRoleDescription"
                  value={customRoleDescription}
                  onChange={onChange}
                />
              </div>
              <div className="d-flex flex-column mb-4">
                <label className="fs-12 fw-400 mb-1">User</label>
                <select
                  className="admin-user-inputs"
                  name="customRoleUser"
                  value={customRoleUser}
                  onChange={onChange}
                >
                  <option>Select User</option>
                  {users.map((user, i) => (
                    <option
                      key={i}
                      value={user.id}
                    >{`${user.firstName} ${user.surname}`}</option>
                  ))}
                </select>
                {/* <input placeholder="Give this role a description" /> */}
              </div>
              <div className="d-flex flex-column">
                {privileges.map((privilege, i) => (
                  <div key={i} className="d-flex align-items-center mb-2">
                    <input
                      type="checkbox"
                      className="me-2"
                      name="privilege"
                      value={privilege.id}
                      onChange={(e) => chkbox(privilege.id, e.target.checked)}
                    />
                    {privilege.description}
                  </div>
                ))}
              </div>
            </div>
          </RemoveUserModal>
        )}
      </div>
    </Layout>
  );
};

export default ManageAdminUserRoles;
