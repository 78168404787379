import React from "react";
import { Form } from "react-bootstrap";

const disputeTypes = [
  "Chargeback",
  "Fraud",
  "Complaint",
  "Unsettled_transaction",
];

export default function CreateWayaPosDispute(props) {
  const {
    onChange,
    handleBlur,
    transactionReference,
    merchantID,
    customer,
    customerEmail,
    disputeType,
    reason,
  } = props;

  return (
    <Form>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold fs-12">
          {" "}
          Reference ID <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          className="fs-12 p-2"
          type="text"
          name="transactionReference"
          value={transactionReference}
          onChange={onChange}
          onBlur={handleBlur}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold fs-12">
          {" "}
          Merchant ID <span className="text-danger">*</span>
        </Form.Label>
        <Form.Control
          className="fs-12 p-2"
          type="text"
          name="merchantID"
          value={merchantID}
          onChange={onChange}
          disabled={true}
          required
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold fs-12">Customer Name</Form.Label>
        <Form.Control
          type="text"
          className="fs-12 p-2"
          name="customer"
          value={customer}
          disabled={true}
          onChange={onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="fw-bold fs-12">
          Customer Email Address
        </Form.Label>
        <Form.Control
          type="email"
          className="fs-12 p-2"
          name="customerEmail"
          value={customerEmail}
          disabled={true}
          onChange={onChange}
        />
      </Form.Group>
      <div className="input-div mb-3">
        <label className="mb-1 fw-bold fs-12">
          Dispute Type <span className="text-danger">*</span>
        </label>
        <div className="input-container">
          <select
            className="input select w-100"
            name="disputeType"
            id="disputeType"
            value={disputeType}
            onChange={onChange}
            required
          >
            <option value="">{""}</option>
            {disputeTypes.map((val, i) => {
              return (
                <option key={i} value={val}>
                  {val}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="input-div mb-3">
        <label className="mb-1 fw-bold fs-12">
          Dispute Reason <span className="text-danger">*</span>
        </label>
        <div>
          <textarea
            name="reason"
            value={reason}
            onChange={onChange}
            id="disputeReason"
            cols="72"
            rows="3"
            className="p-2 fs-12"
            required
          ></textarea>
          <p className="text-grey fs-10">Provide a reason for this dispute</p>
        </div>
      </div>
    </Form>
  );
}
