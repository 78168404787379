import { Autocomplete, Box, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { allMerchantAdmin } from "../../plugins/urls";

/* interface resultType {
    {
        "id": 700,
        "admin": true,
        "dateOfBirth": "2000-10-10",
        "email": "test-bolaji33@yopmail.com",
        "firstName": "AZEEZ",
        "gender": "MALE",
        "password": "********",
        "phoneNumber": "2349012222222",
        "referenceCode": "4tFvEAL1CV7CPKrX",
        "roleName": "OWNER",
        "surname": "AZ",
        "userId": "1622",
        "merchantUserId": "1622",
        "merchantId": "2WP000000000030",
        "deleted": false,
        "createdAt": [],
        "modifiedAt": [
        ],
        "menu": {}
      },
    description: string;
    structured_formatting: StructuredFormatting;
  } */

const FilterSearchMerchant = ({ setSelectedValue }) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const fetch = useMemo(
    () =>
      debounce((request, callback) => {
        const { field, value } = request;
        axios
          .get(
            `${allMerchantAdmin}?field=${field}&pageNo=1&pageSize=5&value=${value}`
          )
          .then((res) => {
            callback(res.data.data.content);
          });
      }, 300),
    []
  );
  useEffect(() => {
    let active = true;

    if (isEmpty(inputValue)) {
      //  setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ field: "all", value: inputValue }, (results) => {
      setOptions(results ? results : []);
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  return (
    <div>
      <div>Select Merchant</div>
      <Autocomplete
        id="filter-search-merchant"
        multiple
        disableClearable
        getOptionLabel={(option) =>
          typeof option === "string"
            ? option
            : `${option?.firstName} ${option?.surname} - ${option.email} - ${option.merchantId}`
        }
        options={options}
        value={value}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onChange={(e, value) => {
          setSelectedValue(value);
          setValue(value);
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth size="small" />
        )}
      />
    </div>
  );
};

export default FilterSearchMerchant;
