import React from "react";
import "./dashboard.scss";

const CountCards = (props) => {
  return (
    <div className="count-card text-center">
      <div className="w-25 mx-auto " style={{ height: "30px" }}>
        <img src={props.icon} alt="icon" />
      </div>
      <h4 className="text-default fs-12 mt-3">{props.countTitle}</h4>
      {props.showValues ? (
        <h4 className="text-darker fs-13 fw-700">{props.countValue}</h4>
      ) : (
        <h4 className="text-darker fs-18 fw-800">********</h4>
      )}
    </div>
  );
};

export default CountCards;
