import moment from "moment";
import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import { FiCopy } from "react-icons/fi";
import { TransactionContext } from "../../pages/Transactions/Transaction";
import { copyValue } from "../../plugins/utils";
import Divider from "../Divider/Divider";
import "./transaction.scss";

const TransactionDetails = () => {
  const {
    state: {
      paymentStatus,
      paymentMethod: channel,
      merchantId,
      transactionCategory,
      terminalType,
      amount,
      terminalId,
      referenceID,
      businessName,
      wayaposFees,
      date,
    },
  } = useContext(TransactionContext);

  const getAmount = () => {
    if (amount !== "") {
      let res = parseFloat(amount);
      return res / 100;
    } else {
      return "0.00";
    }
  };
  const statusButton = () => {
    if (paymentStatus) {
      switch (paymentStatus.toLowerCase()) {
        case "successful":
          return "button-success";
        case "failed":
          return "request-button";
        default:
          return "request-button";
      }
    }
  };
  return (
    <Col className="tran-details ms-2">
      <div className="tran-padding">
        <h4 className="text-semi-dark fs-14">Amount</h4>
        <div className="d-flex justify-content-between mt-20">
          <h4 className="fs-18 fw-700 text--darker">NGN {getAmount()}</h4>
          <span className={`${statusButton()} br-10 py-1`}>
            {paymentStatus ? paymentStatus : "N/A"}
          </span>
        </div>

        <div className="d-flex justify-content-between mt-40 mb-15">
          <h4 className="text-darker fs-12">Reference ID</h4>
          <h4 className="text-darker fs-12 fw-700">
            {referenceID}
            {""}
            <span
              className="ms-2 fs-12 text-muted"
              onClick={() => copyValue(referenceID)}
            >
              <FiCopy />
            </span>
          </h4>
        </div>
        <Divider />

        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Merchant ID</h4>
          <h4 className="text-darker fs-12 fw-700">
            {merchantId ? merchantId : "N/A"}
          </h4>
        </div>

        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Terminal ID</h4>
          <h4 className="text-darker fs-12 fw-700">
            {terminalId}{" "}
            <span
              className="ms-2 fs-12 text-muted"
              onClick={() => copyValue(terminalId)}
            >
              <FiCopy />
            </span>
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Terminal Device Name</h4>
          <h4 className="text-darker fs-12 fw-700">
            {terminalType ? terminalType : "N/A"}
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Business Name</h4>
          <h4 className="text-darker fs-12 fw-700">
            {businessName ? businessName : "N/A"}
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Transaction Category</h4>
          <h4 className="text-darker fs-12 fw-700">
            {transactionCategory ? transactionCategory : "N/A"}
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Channel</h4>
          <h4 className="text-darker fs-12 fw-700">
            {channel ? channel : "N/A"}
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Wayapos Fees</h4>
          <h4 className="text-darker fs-12 fw-700">
            {wayaposFees ? wayaposFees : "N/A"}
          </h4>
        </div>
        <Divider />
        <div className="d-flex justify-content-between mt-20 mb-15">
          <h4 className="text-darker fs-12">Date Paid</h4>
          <h4 className="text-darker fs-12 fw-700">
            {date ? moment(new Date(date)).format("D/MM/YYYY") : "N/A"}
          </h4>
        </div>
        <Divider />
      </div>
    </Col>
  );
};

export default TransactionDetails;
