import React from "react";
import { IoFilterOutline } from "react-icons/io5";
import "react-datepicker/dist/react-datepicker.css";
import { useContext } from "react";
import { WayaposTransactionsContext } from "./WayaPos";

const statusoptions = ["SUCCESSFUL", "ABANDONED", "REFUNEDED", "FAILED"];
const terminals = [
  "PAX",
  "NEXGOG2",
  "NEXGOG3",
  "VERIFONE",
  "TOPWISE",
  "MOREFUN",
];
const transactionCategoryList = [
  "Cash Advance",
  "Purchase",
  "Purchase with Cash back",
  "Balance Inquiry",
  "Refund",
  "Deposit",
];
const paymentCh = ["PayAttitude", "Card"];

const FilterTransactions = () => {
  const { onFilterChange, clearFilter, getFilterTransaction, filterParams } =
    useContext(WayaposTransactionsContext);
  const {
    from,
    to,
    terminalType,
    paymentStatus,
    transactionCategory,
    paymentChannel,
  } = filterParams;
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu3"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div className="dropdown-menu " style={{ width: "22rem" }}>
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Payment Status</label>
              <div className="input-container">
                <select
                  className="input"
                  name="paymentStatus"
                  value={paymentStatus}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {statusoptions.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Terminal Type</label>
              <div className="input-container">
                <select
                  className="input"
                  name="terminalType"
                  value={terminalType}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {terminals.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">
                Transaction Category
              </label>
              <div className="input-container">
                <select
                  className="input"
                  name="transactionCategory"
                  value={transactionCategory}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {transactionCategoryList.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">
                Payment Channel
              </label>
              <div className="input-container">
                <select
                  className="input"
                  name="paymentChannel"
                  value={paymentChannel}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  {paymentCh.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Date Range</label>
              <div className="input-container d-flex">
                <span className="text-muted fs-12 me-1 mt-1">From</span>

                <input
                  className="input"
                  type="date"
                  name="from"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={onFilterChange}
                />
                <span className="text-muted fs-12 me-2 mt-1">to</span>
                <input
                  className="input"
                  type="date"
                  name="to"
                  disabled={!Boolean(from)}
                  min={to}
                  max={new Date().toISOString().split("T")[0]}
                  onChange={onFilterChange}
                />
                {/* 
                <DatePicker
                  className="input fs-13 "
                  name="from"
                  selected={startDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setStartDate(date)}
                  //   closeOnScroll={true}
                  maxDate={new Date()}
                />
                
                <DatePicker
                  className="input fs-13 "
                  name="to"
                  selected={endDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setEndDate(date)}
                  //   closeOnScroll={true}
                  maxDate={new Date()}
                />*/}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter" onClick={clearFilter}>
                Clear Filter
              </button>
              <button
                type="submit"
                className="px-3 py-2 apply-filter"
                onClick={(e) => {
                  e.preventDefault();
                  getFilterTransaction(filterParams);
                }}
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterTransactions;
