import React, { useState, useEffect } from "react";
import { Table, Container } from "react-bootstrap";
import { VscAdd } from "react-icons/vsc";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";
import {
  allScheme,
  createScheme,
  deleteScheme,
  updateScheme,
} from "../../plugins/urls";
import {
  allStation,
  createStation,
  deleteStation,
  updateStation,
} from "../../plugins/urls";
import {
  allRouting,
  allUsers,
  createRouting,
  deleteRouting,
  updateRouting,
} from "../../plugins/urls";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import Modal from "../../components/Modal/Modal";
import { BsPencil } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import Swal from "../../components/Swal/swal";
import AddScheme from "../../components/TechComponents/AddScheme";
import AddStation from "../../components/TechComponents/AddStation";
import AddRoute from "../../components/TechComponents/AddRoute";
import styles from "../../pages/Tech/routing.module.css";
import { ToggleButton } from "@mui/material";
import { Switch } from "antd";

export const getAllStations = (setState) => {
  axios({
    method: "get",
    url: `${allStation}`,
  })
    .then((res) => {
      if (res.data.respCode === 0) {
        setState((state) => ({
          ...state,
          stationsList: res.data.respBody,
        }));
      }
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const RoutingTable = (props) => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const [state, setState] = useState({
    add: false,
    modalValue: "",
    users: [],
    routeRules: [],
    stationsList: [],
    schemeList: [],
    pageNo: 0,
    pageSize: 20,
    maximum_amount: "",
    minimum_amount: "",
    precedence: "",
    scheme: "",
    stationID: "",
    type: "SCHEME",
    userId: "",
    values: "",
    id: "",
    submit: false,
  });

  const {
    id,
    maximum_amount,
    minimum_amount,
    precedence,
    scheme,
    stationId,
    station,
    type,
    userId,
    values,
    submit,
    add,
    modalValue,
    routeRules,
    stationsList,
    schemeList,
    users,
    pageNo,
    pageSize,
  } = state;
  const showModal = (value, data) => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        modalValue: value,
        minimum_amount: data ? data.minimum_amount : "",
        maximum_amount: data ? data.maximum_amount : "",
        precedence: data ? data.precedence : "",
        scheme: data ? data.scheme : "",
        stationId: data ? (data.stationId ? data.stationId.id : "") : "",
        station: data ? (data.stationId ? data.stationId.name : "") : "",
        // type: data ? data.type : '',
        // userId: data ? data.userId : '',
        values: data ? data.values : "",
        id: data ? data.id : "",
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
      }));
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const getAllMerchants = () => {
    axios({
      method: "get",
      url: `${allUsers}/true`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            users: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getAllRoutes = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      id: user ? user.id : "",
    };

    axios({
      method: "get",
      url: `${allRouting}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            routeRules: res.data.respBody,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getAllSchemes = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      id: user ? user.id : "",
    };

    axios({
      method: "post",
      url: `${allScheme}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            schemeList: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    getAllRoutes();
    getAllStations(setState);
    getAllSchemes();
    getAllMerchants();
  }, []);

  const onCreateRoute = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      maximum_amount: parseInt(maximum_amount),
      minimum_amount: parseInt(minimum_amount),
      precedence: parseInt(precedence),
      scheme,
      stationID: parseInt(stationId),
      type,
      userId,
      values,
    };
    axios({
      method: "post",
      url: `${createRouting}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRoutes();
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onUpdateRoute = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      id,
      maximum_amount,
      minimum_amount,
      precedence,
      scheme,
      stationId,
      type,
      userId,
      values,
    };
    axios({
      method: "put",
      url: `${updateRouting}/${id}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRoutes();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(res.data.respBody, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllRoutes();
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onDeleteRoute = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this route!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        axios({
          method: "delete",
          url: `${deleteRouting}/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.data.respCode === 0) {
              toast.success(res.data.respDesc, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              getAllRoutes();
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        show={add}
        clicked={showModal}
        loading={submit}
        submit={modalValue === "add" ? onCreateRoute : onUpdateRoute}
        title={modalValue === "add" ? "Create New Route" : "Edit Route"}
        action="Save update"
      >
        <AddRoute
          onChange={onChange}
          stationsList={stationsList}
          schemeList={schemeList}
          modalValue={modalValue}
          minimum_amount={minimum_amount}
          maximum_amount={maximum_amount}
          precedence={precedence}
          scheme={scheme}
          type={type}
          userId={userId}
          values={values}
          stationId={stationId}
          station={station}
        />
      </Modal>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "36px 20px 56px 20px" }}
      >
        <div className={`d-flex flex-row mb-3 ${styles.tab__container}`}>
          <button
            onClick={() => props.navigateToTab(1)}
            className={props.activeTab === 1 ? styles.active__tab : styles.tab}
          >
            Routing
          </button>
          <button
            onClick={() => props.navigateToTab(2)}
            className={props.activeTab === 2 ? styles.active__tab : styles.tab}
          >
            Scheme
          </button>
          <button
            onClick={() => props.navigateToTab(3)}
            className={props.activeTab === 3 ? styles.active__tab : styles.tab}
          >
            Station
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="request-button" onClick={() => showModal("add")}>
            <VscAdd color={"#fff"} className="mr-5" />
            Add processor
          </div>
        </div>
      </div>

      <Container fluid>
        <Table responsive borderless className="bg-inherit">
          <thead>
            <tr style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}>
              <th>SCHEME</th>
              <th>minimum amount</th>
              <th>maximum amount</th>
              <th>precedence</th>
              <th>processor</th>
              <th>values</th>
              <th>ACTION </th>
            </tr>
          </thead>

          <tbody>
            {routeRules ? (
              routeRules.length === 0 ? (
                <NoResultFound />
              ) : (
                routeRules.map((rule, i) => {
                  const {
                    id,
                    userId,
                    scheme,
                    maximum_amount,
                    minimum_amount,
                    precedence,
                    station: stationId,
                    values,
                  } = rule;
                  const stationName = stationsList.filter(station => station.processorId === Number(stationId))[0]

                  return (
                    <tr key={i}>
                      <td>{scheme ? scheme : "N/A"}</td>
                      <td>{minimum_amount ? minimum_amount : "0"}</td>
                      <td>{maximum_amount ? maximum_amount : "0"}</td>
                      <td>{precedence ? precedence : "N/A"}</td>
                      <td>{stationName ? stationName.name : "N/A"}</td>
                      <td>{values ? values : "N/A"}</td>
                      <td>
                        <span>
                          <BsPencil
                            color="#0086E8"
                            size={20}
                            onClick={() => {
                              showModal("edit", rule);
                            }}
                          />
                        </span>
                        <span className="ml-22">
                          <MdDelete
                            size={20}
                            color="#FF6700"
                            onClick={() => {
                              onDeleteRoute(id);
                            }}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <NoResultFound />
            )}
          </tbody>
        </Table>
      </Container>
    </React.Fragment>
  );
};

export const SchemeTable = (props) => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const [state, setState] = useState({
    add: false,
    modalValue: "",
    schemeList: [],
    pageNo: 0,
    pageSize: 20,
    id: "",
    name: "",
    regex: "",
    submit: false,
  });

  const {
    id,
    name,
    regex,
    submit,
    add,
    modalValue,
    schemeList,
    pageNo,
    pageSize,
  } = state;
  const showModal = (value, data) => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        modalValue: value,
        name: data ? data.name : "",
        regex: data ? data.regex : "",
        id: data ? data.id : "",
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
      }));
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const getAllSchemes = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      id: user ? user.id : "",
    };

    axios({
      method: "post",
      url: `${allScheme}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            schemeList: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  useEffect(() => {
    getAllSchemes();
  }, []);

  const onCreateScheme = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      name,
      regex,
    };
    axios({
      method: "post",
      url: `${createScheme}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllSchemes();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  const onUpdateScheme = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      id,
      name,
      regex,
    };
    axios({
      method: "post",
      url: `${updateScheme}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllSchemes();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
          add: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  const onDeleteScheme = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this scheme!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        axios({
          method: "post",
          url: `${deleteScheme}`,
          headers: {
            "Content-Type": "application/json",
          },
          data: id,
        })
          .then((res) => {
            if (res.data.respCode === 0) {
              toast.success(res.data.respDesc, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              getAllSchemes();
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        show={add}
        clicked={showModal}
        loading={submit}
        submit={modalValue === "add" ? onCreateScheme : onUpdateScheme}
        title={modalValue === "add" ? "Create New Scheme" : "Edit Scheme"}
        action="Submit"
      >
        <AddScheme
          onChange={onChange}
          modalValue={modalValue}
          name={name}
          regex={regex}
        />
      </Modal>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "36px 20px 20px 20px" }}
      >
        <div className={`d-flex flex-row mb-3 ${styles.tab__container}`}>
          <button
            onClick={() => props.navigateToTab(1)}
            className={props.activeTab === 1 ? styles.active__tab : styles.tab}
          >
            Routing
          </button>
          <button
            onClick={() => props.navigateToTab(2)}
            className={props.activeTab === 2 ? styles.active__tab : styles.tab}
          >
            Scheme
          </button>
          <button
            onClick={() => props.navigateToTab(3)}
            className={props.activeTab === 3 ? styles.active__tab : styles.tab}
          >
            Station
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="request-button" onClick={() => showModal("add")}>
            <VscAdd color={"#fff"} className="mr-5" />
            Add Scheme
          </div>
        </div>
      </div>

      <Container fluid>
        <div className="data-table mt-40">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}>
                <th>#</th>
                <th>name</th>
                <th>regex </th>
                <th>actions </th>
              </tr>
            </thead>

            <tbody>
              {schemeList ? (
                schemeList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  schemeList.map((scheme, i) => {
                    const { id, name, regex } = scheme;
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{name}</td>
                        <td>{regex}</td>
                        <td>
                          <span>
                            <BsPencil
                              color="#0086E8"
                              size={20}
                              onClick={() => {
                                showModal("edit", scheme);
                              }}
                            />
                          </span>
                          <span className="ml-22">
                            <MdDelete
                              size={20}
                              color="#FF6700"
                              onClick={() => {
                                onDeleteScheme(id);
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    </React.Fragment>
  );
};

export const StationTable = (props) => {
  const [state, setState] = useState({
    add: false,
    modalValue: "",
    stationsList: [],
    pageNo: 0,
    pageSize: 20,
    formData: {},
    submit: false,
  });

  const { name, formData, submit, add, modalValue, stationsList } = state;
  const showModal = (value, data) => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        modalValue: value,
        formData: { ...data },
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
        formData: {},
      }));
    }
  };

  const onChange = (e) => {
    setState((state) => {
      const { formData } = state;
      const newFormData = { ...formData, [e.target.name]: e.target.value };
      return { ...state, formData: newFormData };
    });
  };
  useEffect(() => {
    getAllStations(setState);
  }, []);
  const onCreateStation = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${createStation}`,
      data: state.formData,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllStations(setState);
        } else {
          throw new Error(res.data.respBody);
        }
      })
      .catch((err) => {
        toast.error(`${err.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
          add: false,
        }));
      });
  };

  const onUpdateStation = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "PUT",
      url: `${updateStation}/${state.formData.processorId}`,
      data: state.formData,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllStations(setState);
        } else {
          throw new Error(res.data.respBody);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
          add: false,
        }));
      });
  };

  const onToggleStation = (dta) => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "PUT",
      url: `${updateStation}/${dta.processorId}`,
      data: {
        ...dta,
        status: dta.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
      },
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllStations(setState);
        } else {
          throw new Error(res.data.respBody);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      })
      .finally(() => {
        setState((state) => ({
          ...state,
          submit: false,
          add: false,
        }));
      });
  };
  const onDeleteStation = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to delete this station!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((remove) => {
      if (remove.isConfirmed) {
        axios({
          method: "delete",
          url: `${deleteStation}/${id}`,
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then((res) => {
            if (res.data.respCode === 0) {
              toast.success(res.data.respDesc, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
              getAllStations(setState);
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          });
      }
    });
  };
  return (
    <React.Fragment>
      <Modal
        show={add}
        clicked={showModal}
        loading={submit}
        submit={modalValue === "add" ? onCreateStation : onUpdateStation}
        title={modalValue === "add" ? "Create New Station" : "Edit Station"}
        action="Submit"
      >
        <AddStation
          onChange={onChange}
          modalValue={modalValue}
          name={name}
          formData={formData}
        />
      </Modal>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ padding: "36px 20px 20px 20px" }}
      >
        <div className={`d-flex flex-row mb-3 ${styles.tab__container}`}>
          <button
            onClick={() => props.navigateToTab(1)}
            className={props.activeTab === 1 ? styles.active__tab : styles.tab}
          >
            Routing
          </button>
          <button
            onClick={() => props.navigateToTab(2)}
            className={props.activeTab === 2 ? styles.active__tab : styles.tab}
          >
            Scheme
          </button>
          <button
            onClick={() => props.navigateToTab(3)}
            className={props.activeTab === 3 ? styles.active__tab : styles.tab}
          >
            Station
          </button>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div className="request-button" onClick={() => showModal("add")}>
            <VscAdd color={"#fff"} className="mr-5" />
            Add Station
          </div>
        </div>
      </div>

      <Container fluid>
        <div className="data-table mt-40">
          <Table responsive borderless className="bg-inherit">
            <thead>
              <tr style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}>
                <th>Name</th>
                <th>Status </th>
                <th>Actions </th>
              </tr>
            </thead>

            <tbody>
              {stationsList ? (
                stationsList.length === 0 ? (
                  <NoResultFound />
                ) : (
                  stationsList.map((station, i) => {
                    const { processorId, name, status } = station;
                    const statusClass = () => {
                      if (status) {
                        if (status.toLowerCase() === "active") {
                          return "tabactive";
                        } else {
                          return "tabdanger";
                        }
                      }
                    };
                    return (
                      <tr key={i}>
                        <td>{name}</td>
                        <td>
                        <Switch onChange={() => onToggleStation(station)} checked={status === 'ACTIVE' ? true : false }/>
                          <span className={`${statusClass()}`}>
                            {status}
                          </span>
                        </td>
                        <td>
                          <span>
                            <BsPencil
                              color="#0086E8"
                              size={20}
                              onClick={() => {
                                showModal("edit", station);
                              }}
                            />
                          </span>
                          <span className="ml-22">
                            <MdDelete
                              size={20}
                              color="#FF6700"
                              onClick={() => {
                                onDeleteStation(processorId);
                              }}
                            />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                )
              ) : (
                <NoResultFound />
              )}
            </tbody>
          </Table>
        </div>
      </Container>
    </React.Fragment>
  );
};
