import { Chip } from "@mui/material";
import _ from "lodash";
import moment from "moment";
import React, { createContext, useState } from "react";
import { Table } from "react-bootstrap";
import NoResultFound from "../../NoResultFound/NoResultFound";
import TableFooter from "./TableFooter";
export const SimpleTableContext = createContext({});

const statusType = (value)=>{

  if(value.includes("pend")||value==="pend"){
      return <Chip label={_.startCase(value.split("_").join(" "))} color="primary" />
  }else if(value.includes("approve")||value==="approved"){
    return <Chip label={_.startCase(value.split("_").join(" "))} color="success" />
  }else if(value.includes("reject")||value==="rejected"){
    return <Chip label={_.startCase(value.split("_").join(" "))} color="error" />
  }else{
    return _.startCase(value.split("_").join(" "))
  }
}
const SimpleTable = ({
  color = "text-orange",
  bg = "bg-orange-light",
  cols,
  tableData,
  setPageFn,
  setEntity,
  setLoading
}) => {
  const renderTd = (type, val, data, comp) => {
    if (type === undefined) return val || "";
    switch (type) {
      case "date":
        return moment(val).format("MMMM Do YYYY, h:mm a");
      case "status":{
          const value = val.toLowerCase()
          return statusType(value)
      }

      case "currency":
        return (
          <span>
            <span>&#8358;</span>
            <span className="ps-2">{val?.toLocaleString() || ""}</span>
          </span>
        );
      case "number":
        return <span className="ps-2">{val?.toLocaleString() || ""}</span>;
      case "action":
        return comp(data,setLoading);
      default:
        return val || null;
    }
  };
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  return (
    <SimpleTableContext.Provider
      value={{
        color,
        bg,
        cols,
        setEntity,
        pageNo,
        setPageSize,
        pageSize,
        setPageNo,
        ...tableData,
        ...tableData.pageable,
      }}
    >
      <div>
        <Table
          responsive
          borderless
          className="bg-inherit table table-responsive"
        >
          <thead className={`${bg} ${color} ps-5`}>
            <tr className="ps-3" style={{ borderRadius: "5px" }}>
              {cols.map((col) => (
                <th key={col.name}>{col.name}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableData?.content?.length === 0 ? (
              <NoResultFound />
            ) : (
              tableData?.content?.map((data) => {
                return (
                  <tr key={data.id}>
                    {cols.map((col, key) => (
                      <td key={key}>
                        {renderTd(col?.type, data[col.value], data, col?.Comp)}
                      </td>
                    ))}
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
        {tableData?.content?.length > 0 && (
          <TableFooter setPageFn={setPageFn} />
        )}
      </div>
    </SimpleTableContext.Provider>
  );
};

export default SimpleTable;
