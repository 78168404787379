import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/Layout";
import AgencyPricings from "../../components/TechComponents/AgencyPricings";
import WayaPosPricings from "../../components/TechComponents/WayaPosPricings";

const EditPricings = () => {
  const [state, setState] = useState({
    tab: "agency",
  });

  const { tab } = state;

  const switchTabs = (value) => {
    setState((state) => ({
      ...state,
      tab: value,
    }));
  };

  const renderPages = () => {
    switch (tab) {
      case "agency":
        return <AgencyPricings />;
      case "wayapos":
        return <WayaPosPricings />;
      default:
        return <AgencyPricings />;
    }
  };
  return (
    <Layout>
      <Container fluid>
      <Row>
          <Col>
            <Link to="/pricing" className="text-decoration-none">
              <h4 className="text-darker fs-16 fw-700 cursor-pointer py-1">
                <span className="mr-15">
                  <MdOutlineKeyboardBackspace size={20} />
                </span>
                Back
              </h4>
            </Link>
          </Col>
        </Row>
        <div className="d-flex filter-contents align-items-center">
          <div className="bg-grey d-flex justify-content-between font-secondary br-30 width-35 mb-20 cursor-pointer">
            <div
              className={`d-flex justify-content-between ${
                tab === "agency" ? "active-option" : "inactive-option"
              }`}
              onClick={() => switchTabs("agency")}
            >
              <h4
                className={`fs-16 ${
                  tab === "agency" ? "text-white" : "text-darker"
                }`}
              >
                Agency Pricings
              </h4>
            </div>

            <div
              className={`d-flex justify-content-between ${
                tab === "wayapos" ? "active-option" : "inactive-option"
              }`}
              onClick={() => switchTabs("wayapos")}
            >
              <h4
                className={`fs-16 ${
                  tab === "wayapos" ? "text-white" : "text-darker"
                }`}
              >
                WayaPos Pricings
              </h4>
            </div>
          </div>
        </div>
        {renderPages()}
      </Container>
    </Layout>
  );
};

export default EditPricings;
