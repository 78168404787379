import React from "react";
import "./FileUpload.css";

export default function ExcelUpload(props) {
  const { regLink, fileUploadHandler, selectedFile, isFilePicked } = props;
  return (
    <div>
      <div className="input-div mt-20 px-4" style={{ width: "95%" }}>
        <label className="text-darker fs-14">
          Send Registration Continuation Link
        </label>
        <div className="input-container">
          <select
            className="input select"
            type="text"
            name="businessType"
            required
            onChange={props.onChange}
          >
            <option value="">Select Option</option>
            <option value={regLink}>Email Address</option>
          </select>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mt-20">
        <span>
          <p className="fs-14">Select Excel Sheet</p>
          <input
            type="file"
            name="file"
            id="inputGroupFile02"
            //value={selectedFile?.name ?? ""}
            accept=".csv"
            className="custom-file-input-upload w-100"
            aria-describedby="basic-addon2"
            onChange={fileUploadHandler}
            required
          />
        </span>
        <label
          className="tabblack browse-btn text-grey bg-lightgrey"
          htmlFor="inputGroupFile02"
          style={{ marginTop: "2.33em", paddingBlock: "0.7em" }}
        >
          Browse
        </label>
      </div>
      {isFilePicked ? (
        <div className="px-4" style={{ fontSize: "12px" }}>
          <p>
            <strong> Filename: </strong> {selectedFile.name}
          </p>
          <p>
            <strong>Type: </strong> {selectedFile.type}
          </p>
          <p>
            <strong>Size in bytes: </strong>
            {selectedFile.size}
          </p>
          <p>
            <strong>lastModifiedDate: </strong>
            {selectedFile.lastModifiedDate.toLocaleDateString()}
          </p>
        </div>
      ) : (
        <p className="px-4" style={{ fontSize: "12px" }}>
          Select a file to show details
        </p>
      )}
    </div>
  );
}
