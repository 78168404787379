import moment from "moment";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { FaCaretDown } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import Cards from "../../components/Cards/Cards";
import { downloadTransactions } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import axios from "../../plugins/axios";
import {
  filterAllTransactions,
  revenueStats,
  transactionStats,
} from "../../plugins/urls";
import { copyValue } from "../../plugins/utils";
import TransactionCard from "../Cards/TransactionCard";
import NoResultFound from "../NoResultFound/NoResultFound";
import Pagination2 from "../Pagination/pagination2";
import FilterTransactions from "./FilterTransactions";

export const getRevenueStats = (setState) => {
  axios(revenueStats)
    .then((res) => {
      if (res.data.respCode === 0) {
        const { gross_revenue, net_revenue } = res.data.respBody;
        setState((state) => ({
          ...state,
          gross_revenue,
          net_revenue,
        }));
      }
    })
    .catch((err) => {
      toast.error(`${err.response.data.message}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    });
};

export const WayaposTransactionsContext = createContext();
const WayaPos = () => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const navigate = useNavigate();
  const de37Ref = useRef(null);

  const [state, setState] = useState({
    transactions: [],
    from: "",
    to: "",
    pageNo: 0,
    pageSize: 20,
    totalPages: 0,
    totaltransactions: 0,
    refunded: 0,
    failed: 0,
    abandoned: 0,
    successful: 0,
    gross_revenue: 0,
    net_revenue: 0,
  });

  const {
    pageNo,
    pageSize,
    totalPages,
    totalTransactions,
    transactions,
    failed,
    refunded,
    abandoned,
    successful,
    net_revenue,
    gross_revenue,
  } = state;
  const [filterParams, setFilterParams] = useState({});
  const [currentPage, setCurrentPage] = useState();
  const [searchParam, setSearchParam] = useState("");
  const [loading, setLoading] = useState(false);
  const onFilterChange = (e) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      [e.target.name]: e.target.value,
    }));
  };
  const setStartDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      startDate: date,
    }));
  };
  const setEndDate = (date) => {
    setFilterParams((filterParams) => ({
      ...filterParams,
      endDate: date,
    }));
  };
  const clearFilter = (e) => {
    e.preventDefault();
    setFilterParams({});
    getFilterTransaction(filterParams);
  };
  const clearRRN = (e) => {
    setSearchParam({});
    getFilterTransaction(filterParams);
  };

  const getFilterTransaction = (data) => {
    const qs = new URLSearchParams({
      ...data,
      pageNo: pageNo == 0 ? 1 : pageNo,
      pageSize,
    });
    setLoading(true);
    axios
      .get(`${filterAllTransactions}?${qs}`)
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            transactions: res.data.respBody,
            totalPages: res.data.noOfPages,
            totalTransactions: res.data.total,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err?.response?.data?.message
              ? err.response.data.message
              : err?.message
              ? err.message
              : "Something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .finally(() => setLoading(false));
  };

  const getTransactionsStats = () => {
    axios(transactionStats)
      .then((res) => {
        if (res.data.respCode === 0) {
          const { failed, refunded, abandoned, successful } = res.data.respBody;
          setState((state) => ({
            ...state,
            failed,
            refunded,
            abandoned,
            successful,
          }));
        }
      })
      .catch((err) => {
        toast.error(
          `${
            err.response
              ? err.response.data
                ? err.response.data.message
                : "something went wrong"
              : err.response.data.error
              ? err.response.data.error
              : "something went wrong"
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const searchTransactionByReferenceID = () => {
    if (!searchParam) {
      toast.error(`${"Please input referenceID"}`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    getFilterTransaction(searchParam);
  };
  const onChange = (e) => {
    const { name, value } = e.target;
    setSearchParam({ [name]: value });
  };

  const firstIndex = pageNo * pageSize;
  const lastIndex = firstIndex + pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected + 1,
    }));
    // getTerminals(selected);
    setCurrentPage(selected);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };

  useEffect(() => {
    // getTransactions();
    getTransactionsStats();
    getRevenueStats(setState);
  }, []);
  useEffect(() => {
    getFilterTransaction(filterParams);
  }, [pageNo, pageSize]);
  const headers = [
    { label: "Reference ID", key: "de37" },
    { label: "Transaction Category", key: "transactionCategory" },
    { label: "Terminal Type", key: "terminalType" },
    { label: "Payment Method", key: "paymentMethod" },
    { label: "Amount", key: "de4" },
    { label: "Created At", key: "createdAt" },
    { label: "Payment Status", key: "paymentStatus" },
  ];
  const csvReport = {
    data: transactions ?? [],
    headers: headers,
    filename: "WayaCashoutTransactions_Report.csv",
  };

  return (
    <>
      <WayaposTransactionsContext.Provider
        value={{
          filterParams,
          setStartDate,
          setEndDate,
          clearFilter,
          onFilterChange,
          getFilterTransaction,
        }}
      >
        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-between filter-contents align-items-center">
            <div className="d-flex justify-content-start align-items-center width-50">
              <FilterTransactions />
              <div className="d-flex justify-content-center align-items-center filter-search ml-22">
                <div className="input_Search d-flex justify-content-center align-items-center">
                  <div className="justify-content-center align-items-center">
                    <FiSearch color="#FF6700" />
                  </div>
                  <input
                    className="input ml-10"
                    placeholder="search with reference id"
                    name="referenceId"
                    value={searchParam.referenceId ?? ""}
                    onChange={onChange}
                  />
                  {Boolean(searchParam.referenceId) && (
                    <i onClick={clearRRN}>
                      <AiFillCloseCircle color="#FF6700" />
                    </i>
                  )}
                </div>
                <button
                  className="orange-button ml-10"
                  onClick={searchTransactionByReferenceID}
                >
                  Search
                </button>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center ">
              <div className="d-flex justify-content-center align-items-center ">
                <div className="d-flex justify-content-center align-items-center ">
                  <ExportData
                    url={downloadTransactions}
                    filename={`WAYAPOS transaction report-${new Date().toISOString()}`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Container fluid>
          <Row className="mt-40">
            <Col>
              <Cards
                cardTitle="Merchant's Gross Revenue"
                value={gross_revenue}
                color="text-orange"
                fontSize="fs-14"
              />
            </Col>
            <Col>
              <Cards
                cardTitle="Merchant's Net Revenue"
                value={net_revenue}
                color="text-orange"
                fontSize="fs-14"
                textColor="text-darker"
              />
            </Col>
            <Col>
              <TransactionCard
                failed={failed}
                refunded={refunded}
                abandoned={abandoned}
                successful={successful}
              />
            </Col>
          </Row>

          <div className="data-table mt-40">
            <table className="bg-inherit table table-borderless table-responsive w-100">
              <thead>
                <tr
                  className=" t-head-colored"
                  style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                >
                  <th>Reference Id</th>
                  <th>Business Name</th>
                  <th>Transaction Category</th>
                  <th>Terminal Type</th>
                  <th>Terminal Serial No</th>
                  <th>Payment Method</th>
                  <th>Payment Processor</th>
                  <th>Amount</th>
                  <th>Created At</th>
                  <th>Payment Status</th>
                  <th>Settlement Status</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {loading ? (
                  <tr>
                    <td style={{ display: "flex" }}>
                      <Spinner animation="border" role="status" />
                    </td>
                  </tr>
                ) : transactions && transactions.length > 0 ? (
                  transactions.map((transaction, i) => {
                    const {
                      de37: referenceId,
                      terminals: {
                        merchants: { firstname },
                      },
                      terminals: {
                        terminalType,                      },
                      terminals: {
                        merchants: { surname },
                      },
                      terminals: {
                        terminalSerialNumber,
                      },
                      transactionCategory,
                      paymentMethod,
                      processedBy,
                      createdAt,
                      paymentStatus,
                      isSettled,
                      de4: transactionAmount,
                      id,
                    } = transaction;
                    const getAmount = () => {
                      let amount = 0.0;
                      if (transactionAmount) {
                        let res = parseFloat(transactionAmount);
                        amount = res / 100;
                      }
                      return amount;
                    };
                    const statusClass = () => {
                      if (paymentStatus) {
                        switch (paymentStatus.toLowerCase()) {
                          case "successful":
                            return "tabactive";
                          case "refunded":
                            return "tabpending";
                          case "abandoned":
                            return "tabdamaged";
                          default:
                            return "tabdanger";
                        }
                      }
                    };

                    return (
                      <tr key={i}>
                        <td className=" td text-nowrap ">
                          {referenceId ? (
                            <span ref={de37Ref}>
                              {referenceId}
                              <i
                                className="ms-1 text-muted fs-16"
                                onClick={() => copyValue(referenceId)}
                              >
                                <BiCopy />
                              </i>
                            </span>
                          ) : (
                            "N/A"
                          )}
                        </td>
                        <td className="td text-wrap">
                          <span>
                            {firstname ? firstname : "N/A"}{" "}
                            {surname ? surname : ""}
                          </span>
                        </td>
                        <td className="td">
                          {transactionCategory ? transactionCategory : "N/A"}
                        </td>
                        <td className="td">
                          {terminalType ? terminalType : "N/A"}
                        </td>
                        <td className="td">
                          {terminalSerialNumber ?? "N/A"}
                        </td>
                        <td className="td">
                          {paymentMethod ? paymentMethod : "N/A"}
                        </td>
                        <td className="td">
                          {processedBy ? processedBy : "N/A"}
                        </td>
                        <td className="td">NGN{getAmount()}</td>
                        <td className="td">
                          {createdAt
                            ? moment(new Date(createdAt)).format("D/MM/YYYY")
                            : "N/A"}
                        </td>
                        <td className="td">
                          <span className={`${statusClass()}`}>
                            {paymentStatus ? paymentStatus : "N/A"}
                          </span>
                        </td>
                        <td className="td">
                            {isSettled === true ? 'Settled' : isSettled === false ? "Not Settled" : '-'}
                        </td>
                        <td className="td text-nowrap">
                          <span
                            className="tabtransparent"
                            onClick={() => {
                              navigate(`/transaction/${id}`);
                            }}
                          >
                            View More
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </table>
          </div>
          {transactions && transactions.length > 0 ? (
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
              <div className="d-flex me-5">
                <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                <div className="dropdown">
                  <i
                    id="addminuserdropdownMenuButton1"
                    data-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FaCaretDown />
                  </i>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="adminuserdropdownMenuButton1"
                  >
                    <li className="dropdown-item" onClick={() => noPerPage(10)}>
                      10
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(20)}>
                      20
                    </li>
                    <li className="dropdown-item" onClick={() => noPerPage(50)}>
                      50
                    </li>
                  </ul>
                </div>
              </div>
              <div className="me-4">
                <p className="m-0">
                  {firstIndex} - {lastIndex} of {totalTransactions}
                </p>
              </div>
              <div className="mt-3 me-2">
                <Pagination2
                  total={totalPages}
                  paginate={paginate}
                  selected={currentPage}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </WayaposTransactionsContext.Provider>
    </>
  );
};

export default WayaPos;
