import React from 'react'

const ComingSoon = () => {
  return (
    <div className='d-flex align-items-center justify-content-center mt-5'>
        <p className='fs-18'>Coming Soon....</p>
    </div>
  )
}

export default ComingSoon