import axios from "axios";

export const getRequest = async(ROOT, RELATIVE_PATH) => {
    try {
        const isOnline = window.navigator.onLine;
        if(isOnline){
            const API_PATH = `${ROOT}/${RELATIVE_PATH}`;
            const header = {
                Authorization: JSON.parse(window.localStorage.getItem("userDetails")).token,
            }
            const response = await axios.get(API_PATH, header);
            return response.data;
        }else{
            return "Network offline";
        }
    } catch (error) {
        return error.response.data;
    }
}

export const postRequest = async(ROOT, RELATIVE_PATH, payload={}) => {
    try {
        const isOnline = window.navigator.onLine;
        if(isOnline){
            const API_PATH = `${ROOT}/${RELATIVE_PATH}`;
            const header = {
                Authorization: JSON.parse(window.localStorage.getItem("userDetails")).token,
            }
            const response = await axios.post(API_PATH, payload, header);
            return response.data;
        }else{
            return "Network offline";
        }
    } catch (error) {
        return error.response.data;
    }
}