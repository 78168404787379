import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";

import Spinner from "react-bootstrap/Spinner";
import { BiLinkExternal } from "react-icons/bi";
import SimpleDateFilter from "../../components/UI/DateFilter";
import SimpleDropDown from "../../components/UI/DropDown";
import SimpleCard from "../../components/UI/SimpleCard";
import SimpleSearchBox from "../../components/UI/SimpleSearchBox";
import SimpleTable from "../../components/UI/Tables/Table";
import { allLogs, filterLogs } from "../../plugins/urls";
import { apiCall } from "../../utilities/apis";
import { ExportToExcel } from "../../utilities/downloadExcelFron";
import { camelToString, sentenceCaps } from "../../utilities/stringOperations";
import { ExportData } from "../BusinessReports/ExportData";
import { downloadAuditLog } from "../BusinessReports/data";

const tableCols = [
  {
    value: "adminName",
    name: "Name",
  },

  {
    value: "activity",
    name: "action performed",
  },
  {
    value: "duration",
    name: "date",
    type: "date",
  },
];
const filters = ["activity", "duration", "adminName", "adminEmail"];
const roles = [
  "admin",
  "super admin",
  "executive admin",
  "merchant",
  "developer",
];

const AdminLogs = ({ hidden }) => {
  const [formData, setFormData] = useState({
    pageSize: 10,
    pageNo: 1,
    from: "",
    to: "",
  });
  const [tableData, setTableData] = useState({});
  const [role, setRole] = useState("");
  const [filter, setFilter] = useState(filters[0]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const { pageNo, pageSize } = formData;
  const getAllLogs = () => {
    apiCall({
      url: `${allLogs}?pageNo=${pageNo}&pageSize=${pageSize}`,
      success: (data) => setTableData(data),
      setLoading,
      method: "get",
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });
  };

  const filterTerm = (value, field) => {
    apiCall({
      url: `${filterLogs}?field=${field}&value=${value}&pageSize=${formData.pageSize}&pageNo=${formData.pageNo}`,
      success: (data) => {
        setTableData(data);
        setSearchTerm("");
      },
      setLoading,
      method: "post",
      meta: {
        dataIndex: "respBody",
        codeIndex: "respCode",
        messageIndex: "respDesc",
      },
    });
  };

  useEffect(() => {
    console.log(formData);
    if (searchTerm !== "") filterTerm(searchTerm, filter);
    else getAllLogs();
  }, [searchTerm, pageNo, pageSize]);

  //business logic

  const setEntity = (name, value) => {
    setFormData((fd) => ({ ...fd, [name]: value }));
  };
  const setDateFilter = (from, to) => setFormData({ ...formData, from, to });
  const clearSearch = () => {
    setSearchTerm("");
    getAllLogs();
  };

  return (
    <Layout title="Activity Log" hidden={hidden}>
      <div className="d-flex justify-content-between">
        <div style={{ width: 435 }}>
          <div className="d-flex">
            <SimpleSearchBox
              items={filters}
              setSelected={setFilter}
              selected={filter}
              filter
              camel
              handleSearch={setSearchTerm}
              clearSearch={clearSearch}
              placeholder={
                filter === "Filter"
                  ? filter
                  : `Filter by ${sentenceCaps(camelToString(filter))}`
              }
            />
          </div>
        </div>

        {tableData?.content?.length > 0 && (
          <div className="d-flex justify-content-center align-items-center ">
            <ExportData
              url={downloadAuditLog}
              name="adminLog"
              filename={`Waya admin-log report-${new Date().toISOString()}`}
            />
          </div>
        )}
      </div>
      <div className="d-flex my-3">
        <SimpleCard title="Total System Log" body={tableData?.totalElements} />
      </div>
      <div className="col-md-5">
        <div className=" d-flex flex-wrap " style={{ minWidth: 435 }}>
          <div className="me-2">
            <SimpleDropDown
              items={roles}
              selected={role || "Role Type"}
              setSelected={setRole}
              clss="border collapse-btn d-flex justify-content-between text-muted"
            />
          </div>
          <div className="">
            <SimpleDateFilter setDateFilter={setDateFilter} />
          </div>
        </div>
      </div>

      {loading ? (
        <div className="align-items-center d-flex justify-content-center">
          <Spinner animation="border" role="status" variant="success" />
        </div>
      ) : (
        <Container fluid>
          <div className="data-table mt-40">
            <SimpleTable
              cols={tableCols}
              tableData={tableData}
              setEntity={setEntity}
              pageNumber={pageNo}
              pageSize={pageSize}
            />
          </div>
        </Container>
      )}
    </Layout>
  );
};

export default AdminLogs;
