import React,{useState, useEffect} from "react";
import "./dashboard.scss";
import { Row, Col } from "react-bootstrap";
import { Bar, Doughnut } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {getRevenueByDateRequest, getIncomeRequest} from "../../services/requests/dashboard.request.js";
import { Chart as ChartJS, registerables } from "chart.js";
ChartJS.register(...registerables, ChartDataLabels);

const BarChart = ({membershipMetrics}) => {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const data = {
    labels: [
      "Jan",
      "Feb",
      "March",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Total Revenue",
        data: [
          10000, 20000, 50000, 30000, 10000, 40000, 20000, 40000, 10000, 30000,
          10000, 20000,
        ],
        backgroundColor: "transparent",
        borderColor: "rgba(249, 132, 53, 0.44)",
        borderRadius: 10,
        hoverBackgroundColor: "#FF6700",
        borderWidth: 2,
      },
    ],
  };
  let usersData = [membershipMetrics?.activeUser, membershipMetrics?.inActiveUser, membershipMetrics?.activeAdminUser, membershipMetrics?.inActiveAdminUser];
  let userLabels = [
    "Active Users",
    "Inactive Users",
    "Active Admins",
    "Inactive Admins",
  ];
  let customUserLabels = userLabels.map(
    (label, index) => `${label}: ${usersData[index]}`
  );
  const success = {
    labels: customUserLabels,
    datasets: [
      {
        label: "# of Votes",
        data: usersData,
        borderColor: "transparent",
        backgroundColor: ["#05B862", "#5F5F5F", "#F63002", "#FFFFFF"],
        hoverBackgroundColor: ["#05B862", "#5F5F5F", "#F63002", "#FFFFFF"],
      },
    ],
  };

  useEffect(()=>{
    getRevenueForCurrent();
    getIncomeRequestHandle();
  },[]);

  const getRevenueForCurrent = () => {
    const date = new Date().toISOString().slice(0, 10);
		getRevenueByDate(date);
  }

  const generateRevenueWithDate = (e) => {
    getRevenueByDate(e.target.value);
  }

  const getRevenueByDate = async(date) => {
		const response = await getRevenueByDateRequest(date);
		setTotalRevenue(response.respBody.totalRevenue)
  }
  const getIncomeRequestHandle = async() => {
    const response = await getIncomeRequest();
    // alert(JSON.stringify(response.respBody));
  }
  return (
    <Row>
      <Col lg={8}>
        <div className="bar mt-40">
          <div className=" w-75 d-flex justify-content-between " >
            <span className="cards mx-2 text-center" style={{width: '20%'}}>
              <label className="text-muted fs-12 mx-1">Select Date</label>
              <input className="input fs-11 " type="date" onChange={generateRevenueWithDate}/>
            </span>
            <div className="cards text-center mx-2" style={{width: '60%'}} >
              <p className="text-muted fs-12 mx-1">Total Income for: 29 June 2022</p>
              <span className="text-muted fs-12 me-1">&#8358; {totalRevenue}</span>
            </div>
          </div>
          <Bar
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                  position: "top",
                  align: "center",
                  labels: {
                    font: {
                      family: "Lato",
                      size: 12,
                    },
                    color: "#373D3F",
                    backgroundColor: "rgba(0, 0, 0, 0)",
                    boxWidth: 40,
                    padding: 20,
                    // usePointStyle: true
                  },
                },
                datalabels: {
                  display: false,
                  // color:'#ffffff',
                  // font:{
                  //     family: "Poppins",
                  //     size: 10
                  // },
                },
              },
              layout: {
                padding: {
                  left: 20,
                  right: 20,
                  top: 120,
                },
              },
              scales: {
                xAxis: {
                  ticks: {
                    display: true,
                    padding: 10,
                    font: {
                      family: "Lato",
                      size: 10,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: false,
                    drawBorder: false,
                  },
                },
                yAxis: {
                  ticks: {
                    display: true,
                    padding: 10,
                    font: {
                      family: "Lato",
                      size: 10,
                      weight: "bold",
                    },
                  },
                  grid: {
                    display: true,
                    drawBorder: false,
                    color: "rgba(249, 132, 53, 0.44)",
                    borderDash: [2, 5],
                  },
                },
              },
            }}
          />
        </div>
      </Col>
      <Col lg={4}>
        <div className="bar-pie mt-40">
          <div className="cards ">
            <div className="input-container d-flex">
              <span className="text-muted fs-12 mx-1">From</span>
              <input className="input fs-12" type="date" />
              <span className="text-muted fs-12 me-1">to</span>
              <input className="input fs-12" type="date" />
            </div>
          </div>
          <Doughnut
            data={success}
            width={350}
            height={350}
            options={{
              responsive: true,
              cutout: "80%",
              tooltips: {
                enabled: true,
              },
              layout: {
                padding: {
                  left: 20,
                  right: 20,
                },
              },
              plugins: {
                legend: {
                  display: true,
                  position: "bottom",
                  align: "center",
                  labels: {
                    font: {
                      family: "Lato",
                      size: 14,
                    },
                    color: "#373D3F",
                    boxWidth: 12,
                    padding: 20,
                    // usePointStyle: true
                  },
                },
                title: {
                  display: true,
                  color: "#000",
                  text: "Merchant Overview",
                  font: {
                    family: "Lato",
                    size: 14,
                    weight: "700",
                  },
                  align: "start",
                  padding: {
                    top: 10,
                    bottom: 0,
                  },
                },
                datalabels: {
                  display: false,
                  color: "#ffffff",
                  font: {
                    family: "Lato",
                    size: 10,
                  },
                },
              },
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default BarChart;
