import React, { useContext, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoFilterOutline } from "react-icons/io5";
import { PosDeviceContext } from "./PosDevice";

const FilterPOS = () => {
  const {
    onFilterChange,
    setStartDate,
    setEndDate,
    clearFilter,
    getTerminals,
    filterFieldVal,
    filterParams: { startDate, endDate, terminalName, status },
  } = useContext(PosDeviceContext);
  const [open, setOpen] = useState(true);
  return (
    <div>
      <div className="dropdown">
        <button
          className="btn btn-transparent "
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <div className="d-flex justify-content-start align-items-center ">
            <IoFilterOutline size={15} />
            <h4 className="fs-14 text-muted mt-2 ms-2">Filter</h4>
          </div>
        </button>
        <div
          className="dropdown-menu "
          data-auto-close="outside"
          style={{ width: "22rem" }}
        >
          <form className="p-3">
            <div className="">
              <label className="text-darker fs-14 fw-600">Status</label>
              <div className="input-container">
                <select
                  className="input"
                  name="status"
                  value={status}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  <option value="ACTIVATED">Activated</option>
                  <option value="DEACTIVATED">Deactivated</option>
                  <option value="FAULTY">Faulty</option>
                  <option value="DAMAGED">Damaged</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Terminal Name</label>
              <div className="input-container">
                <select
                  className="input"
                  name="terminalName"
                  value={terminalName}
                  onChange={onFilterChange}
                >
                  <option value="">Show All</option>
                  <option value="NEXGOG2">NEXGOG2</option>
                  <option value="NEXGOG3">NEXGOG3</option>
                  <option value="PAX">PAX</option>
                  <option value="TOPWISE">TOPWISE</option>
                  <option value="MOREFUN">MOREFUN</option>
                  <option value="VERIFONE">VERIFONE</option>
                </select>
              </div>
            </div>
            <div className="">
              <label className="text-darker fs-14 fw-600">Date Range</label>
              <div className="input-container d-flex">
                <span className="text-muted fs-12 me-1 mt-1">From</span>
                <DatePicker
                  className="input fs-13 "
                  name="startDate"
                  selected={startDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setStartDate(date)}
                  closeOnScroll={false}
                  maxDate={new Date()}
                  showMonthYearDropdown
                />
                {/* <input className="input" type="date"/> 
                <span className="text-muted fs-12 me-2 mt-1">to</span>
                <DatePicker
                  className="input fs-13 "
                  name="startDate"
                  selected={endDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="yyyy/mm/dd"
                  onChange={(date) => setEndDate(date)}
                  closeOnScroll={false}
                  maxDate={new Date()}
                  showMonthYearDropdown
                />
                {/* <input className="input" type="date" /> */}
              </div>
            </div>

            <div className="d-flex align-items-center justify-content-around mt-3 mb-2">
              <button className="px-3 py-2 clear-filter" onClick={clearFilter}>
                Clear Filter
              </button>
              <button
                className="px-3 py-2 apply-filter "
                onClick={(e) => {
                  e.preventDefault();
                  getTerminals(filterFieldVal);
                  setOpen(false);
                }}
              >
                Apply Filter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterPOS;
