import { getMessaging, getToken } from "firebase/messaging";
import React, { Suspense, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import DisputeDetails from "./components/DisputeComponents/DisputeDetails";
import DisputeDocs from "./components/DisputeComponents/DisputeDocs";
import DisputeResolution from "./components/DisputeComponents/DisputeResolution";
import InitiatedDisputeDetails from "./components/DisputeComponents/initiatedDisputeDetails";
import ManageProfile from "./components/UsersComponents/ManageProfile/ManageProfile";
import ReferralList from "./components/UsersComponents/ManageReferrals/ReferralList";
import ManageRoles from "./components/UsersComponents/ManageTeamMembers/ManageRoles";
import TeamMembers from "./components/UsersComponents/ManageTeamMembers/TeamMembers";
import AcquiredTerminals from "./components/UsersComponents/ManageTerminals/AcquiredTerminals";
import RequestedTerminals from "./components/UsersComponents/ManageTerminals/RequestedTerminals";
import { firebaseApp } from "./firebase";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import Login from "./pages/Auth/Login";
import { BusinessReports } from "./pages/BusinessReports/BusinessReports";
import Dashboard from "./pages/Dashboard/Dashboard";
import Disputes from "./pages/Disputes/Disputes";
import Kyc from "./pages/KYC/Kyc";
import AdminLogs from "./pages/Logs/AdminLogs";
import RequestLogs from "./pages/Logs/RequestLog";
import RemoteUpdate from "./pages/RemoteUpdate/RemoteUpdate";
import Commissions from "./pages/Tech/Commissions";
import EditPricings from "./pages/Tech/EditPricings";
import Pricing from "./pages/Tech/Pricing";
import Routing from "./pages/Tech/Routing";
import Scheme from "./pages/Tech/Scheme";
import Stations from "./pages/Tech/Stations";
import Terminals from "./pages/Terminals/Terminals";
import Settlement from "./pages/Transactions/Settlement";
import Transaction from "./pages/Transactions/Transaction";
import Transactions from "./pages/Transactions/Transactions";
import AdminUsers from "./pages/Users/AdminUsers";
import Corporate from "./pages/Users/Corporate";
import ManageAdminUserRoles from "./pages/Users/ManageAdminUserRoles";
import { ManageUnit } from "./pages/Users/ManageUnit";
import TierLevel from "./pages/Users/TierLevel";
// import AdminUsers from './pages/Users/AdminUsers';

const PrivateRoute = ({ children }) => {
  // console.log(JSON.parse(localStorage.getItem('userDetails')))
  return localStorage.getItem("userDetails") ? (
    children
  ) : (
    <Navigate to="/login" />
  );
};


const theme = createTheme({
  palette: {
    primary: {
      main: "#FF6700",
    },
  }
})

function App() {
  const { GENERATED_MESSAGING_KEY } = process.env;
  const messaging = getMessaging(firebaseApp);
  const [isTokenFound, setTokenFound] = useState(false);

  const getFirebaseToken = (setTokenFound) => {
    return getToken(messaging, { vapidKey: GENERATED_MESSAGING_KEY })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log(
            "No registration token available. Request permission to generate one."
          );
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // catch error while creating client token
      });
  };

  useEffect(() => {
    getFirebaseToken(setTokenFound);
  }, [isTokenFound]);

  return (
    <ThemeProvider theme={theme}>
    <Suspense fallback="f">
      <Routes>
        <Route
          path="/"
          exact
          element={
            <PrivateRoute>
              <Dashboard />{" "}
            </PrivateRoute>
          }
        />

        {/* Login */}

        <Route path="/login" exact element={<Login />} />
        <Route path="/forgot-password" exact element={<ForgotPassword />} />

        {/* Dashboard Management */}

        <Route
          path="/dashboard"
          exact
          element={
            <PrivateRoute>
              <Dashboard />{" "}
            </PrivateRoute>
          }
        />

        {/* Users */}

        {/* Admin */}
        <Route
          path="/admin-users"
          exact
          element={
            <PrivateRoute>
              <AdminUsers />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-admin-users"
          exact
          element={
            <PrivateRoute>
              <ManageAdminUserRoles />{" "}
            </PrivateRoute>
          }
        />
        {/* Corporate */}
        <Route
          path="/corporate-users"
          exact
          element={
            <PrivateRoute>
              <Corporate />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/corporate-users/:id"
          exact
          element={
            <PrivateRoute>
              <TierLevel />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-profile/:userId"
          exact
          element={
            <PrivateRoute>
              <ManageProfile />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/requested-terminals/:userId"
          exact
          element={
            <PrivateRoute>
              <RequestedTerminals />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/acquired-terminals/:userId"
          exact
          element={
            <PrivateRoute>
              <AcquiredTerminals />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/team-members/:userId"
          exact
          element={
            <PrivateRoute>
              <TeamMembers />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/manage-roles"
          exact
          element={
            <PrivateRoute>
              <ManageRoles />{" "}
            </PrivateRoute>
          }
        />

        {/* Referrals */}
        {/* 
    <Route path='/referrals' exact element={ <PrivateRoute><Referrals /> </PrivateRoute> } /> */}
        <Route
          path="/referral-list/:referralCode"
          exact
          element={
            <PrivateRoute>
              <ReferralList />{" "}
            </PrivateRoute>
          }
        />

        {/* Manage Pos */}
        <Route
          path="/manage-pos"
          exact
          element={
            <PrivateRoute>
              <Terminals />
            </PrivateRoute>
          }
        />

        {/* Manage Kyc */}
        <Route
          path="/manage-kyc"
          exact
          element={
            <PrivateRoute>
              <Kyc />
            </PrivateRoute>
          }
        />

        {/* Transactions */}

        <Route
          path="/transactions"
          exact
          element={
            <PrivateRoute>
              <Transactions />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/transaction/:id"
          exact
          element={
            <PrivateRoute>
              <Transaction />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/settlement/:id"
          exact
          element={
            <PrivateRoute>
              <Settlement />{" "}
            </PrivateRoute>
          }
        />

        {/* Disputes */}

        <Route
          path="/disputes"
          exact
          element={
            <PrivateRoute>
              <Disputes />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/disputes/docs"
          exact
          element={
            <PrivateRoute>
              <DisputeDocs />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/disputes/details/:id"
          exact
          element={
            <PrivateRoute>
              <DisputeDetails />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/disputes/resolution/:id"
          exact
          element={
            <PrivateRoute>
              <DisputeResolution />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/disputes/initiated/:id"
          exact
          element={
            <PrivateRoute>
              <InitiatedDisputeDetails />{" "}
            </PrivateRoute>
          }
        />

        {/* Logs */}
        <Route
          path="/admin-logs"
          exact
          element={
            <PrivateRoute>
              <AdminLogs />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/request-logs"
          exact
          element={
            <PrivateRoute>
              <RequestLogs />{" "}
            </PrivateRoute>
          }
        />

        {/* Tech */}
        <Route
          path="/routing"
          exact
          element={
            <PrivateRoute>
              <Routing />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/pricing"
          exact
          element={
            <PrivateRoute>
              <Pricing />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-pricings"
          exact
          element={
            <PrivateRoute>
              <EditPricings />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/stations"
          exact
          element={
            <PrivateRoute>
              <Stations />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/scheme"
          exact
          element={
            <PrivateRoute>
              <Scheme />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="/commissions"
          exact
          element={
            <PrivateRoute>
              <Commissions />{" "}
            </PrivateRoute>
          }
        />
        <Route
          path="admin/manage-unit"
          exact
          element={
            <PrivateRoute>
              <ManageUnit />{" "}
            </PrivateRoute>
          }
        />
        
        <Route
          path="business-reports"
          exact
          element={
            <PrivateRoute>
              <BusinessReports />
            </PrivateRoute>
          }
        />
        <Route
          path="remote-update"
          exact
          element={
            <PrivateRoute>
              <RemoteUpdate />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
    </ThemeProvider>
  );
}

export default App;
