import React, { useContext } from "react";
import { PosDeviceContext } from "./PosDevice";
import { FaPlus } from "react-icons/fa";

const AddDevice = () => {
  const {
    onChange,
    imageHandler,
    state: {
      users,
      terminalTypesList,
      actualTerminalName,
      description,
      terminalCost,
      amountPaid,
      amountLeft,
      terminalSerialNumber,
      terminalType,
      image,
      modalValue,
    },
  } = useContext(PosDeviceContext);
  return (
    <form>
      <p className="fs-13 text-center">
        Kindly complete the below form to add a new POS terminal device.
      </p>
      {modalValue === "issue" ? (
        <div className="input-div">
          <label className="text-darker fs-14 fw-600">
            Select User <span style={{ color: "red" }}>*</span>
          </label>
          <div className="input-container">
            <select
              className="input select"
              type="text"
              name="merchantUserID"
              required
              onChange={onChange}
            >
              <option>Select User</option>
              {users
                ? users.map((user, i) => {
                    return (
                      <option value={user.id} key={i}>
                        {user.firstname + " " + user.surname}
                      </option>
                    );
                  })
                : null}
            </select>
          </div>
        </div>
      ) : null}

      <div className="input-div">
        <label className="text-darker fs-14 fw-600">
          Terminal Serial number <span style={{ color: "red" }}>*</span>
        </label>
        <div className="input-container">
          <input
            className="input"
            type="text"
            name="terminalSerialNumber"
            value={terminalSerialNumber}
            required
            placeholder="Enter terminal device ID"
            onChange={onChange}
          />
        </div>
      </div>

      <div className="input-div">
        <label className="text-darker fs-14 fw-600">Terminal Cost</label>
        <div
          className={`input-container ${
            modalValue === "issue" ? "container-disabled" : ""
          }`}
        >
          <input
            className="input"
            type="number"
            name="terminalCost"
            value={terminalCost}
            required
            onChange={onChange}
          />
        </div>
      </div>
      {modalValue === "issue" ? (
        <div className="input-div">
          <label className="text-darker fs-14 fw-600">Amount Paid</label>
          <div className="input-container">
            <input
              className="input"
              type="text"
              name="amountPaid"
              value={amountPaid}
              required
              onChange={onChange}
            />
          </div>
          <label className="text-darker fs-14 fw-600">Amount Left</label>
          <div className="input-container">
            <input
              className="input"
              type="text"
              name="amountLeft"
              value={amountLeft}
              required
              onChange={onChange}
            />
          </div>
        </div>
      ) : null}
      {/* 
      {modalValue === "issue" ? null : (
        <div className="input-div">
          <label className="text-darker fs-14 fw-600">
            Number of Payment Time
          </label>
          <div className="input-container">
            <input
              className="input"
              type="number"
              name="numberOfPaymentTime"
              value={numberOfPaymentTime}
              required
              onChange={onChange}
            />
          </div>
        </div>
      )} */}

      <div className="input-div">
        <label className="text-darker fs-14 fw-600">Terminal Type</label>
        <div className="input-container">
          <select
            className="input select"
            type="text"
            name="terminalType"
            required
            onChange={onChange}
          >
            <option value="">
              {terminalType ? terminalType : "Select terminal type"}
            </option>
            {terminalTypesList.map((terminal) => {
              return (
                <option key={terminal.id} value={terminal?.terminalName}>
                  {terminal.terminalName}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="input-div">
        <label className="text-darker fs-14 fw-600">
          Actual Terminal Name <span style={{ color: "red" }}>*</span>
        </label>
        <div className="input-container ">
          <input
            className="input"
            type="text"
            name="actualTerminalName"
            required
            disabled={modalValue === "issue"}
            value={actualTerminalName}
          />
        </div>
      </div>

      {/* <div className="input-div">
        <label className="text-darker fs-14 fw-600">
          Preferred Terminal Name
        </label>
        <div className="input-container">
          <input
            className="input"
            type="text"
            name="preferredTerminalName"
            required
            placeholder="Enter prefered terminal name [Optional]"
            value={preferredTerminalName}
            onChange={onChange}
          />
        </div>
      </div> */}

      {modalValue === "issue" ? null : (
        <div className="input-div">
          <label className="text-darker fs-14 fw-600">Description</label>
          <div className="input-container">
            <textarea
              className="input textarea h-50"
              type="text-area"
              name="description"
              value={description}
              required
              placeholder="Give a brief description of what is obtainable using this terminal POS"
              onChange={onChange}
            ></textarea>
          </div>
        </div>
      )}

      {modalValue === "issue" ? null : (
        <div className="input-div">
          <label className="text-darker fs-14 fw-600">
            Upload Terminal Images
          </label>
          <p className="fs-12 text-muted">
            This image will show when the page is requesting for a terminal. We
            recommend a 148 x 107 pixel JPG or PNG, under 1 MB in size.
          </p>
          <div className="d-flex w-100">
            <div className="custom-file-input-wrapper d-flex align-items-center me-1">
              {image ? (
                <label className="pointer fs-12 p-1" htmlFor="imageInput1">
                  <span className="imageName">
                    <img
                      style={{ width: "8.0rem", height: "5.0rem" }}
                      src={image ? image : null}
                      alt={image ? "pos image" : null}
                    />
                    <input
                      id="imageInput1"
                      className="custom-file-input"
                      type="file"
                      onChange={imageHandler}
                      accept
                    />
                  </span>
                </label>
              ) : (
                <label className="pointer fs-12 p-4" htmlFor="imageInput1">
                  <i className="pe-1 mb-4">
                    <FaPlus />
                  </i>
                  Upload Image 1 <br />
                  <input
                    id="imageInput1"
                    className="custom-file-input"
                    type="file"
                    onChange={imageHandler}
                    accept
                  />
                  <br />
                  {/* <span className="imageName" ><img style={{width: '50px', height: '50px'}} src={terminalImage? URL.createObjectURL(terminalImage) : null} alt={terminalImage? terminalImage.name : null}/></span> */}
                </label>
              )}
            </div>
            {/* <div className="custom-file-input-wrapper d-flex align-items-center me-1">
              <i className="pe-1 mb-4">
                <FaPlus />
              </i>
              <label className="pointer fs-12" htmlFor="imageInput2">
                Upload Image 2<br />
                <input
                  id="imageInput2"
                  className="custom-file-input"
                  type="file"
                />
                <br />
                <span className="imageName"></span>
              </label>
            </div>
            <div className="custom-file-input-wrapper d-flex align-items-center">
              <i className="pe-1 mb-4">
                <FaPlus />
              </i>
              <label className="pointer fs-12" htmlFor="imageInput3">
                Upload Image 3<br />
                <input
                  id="imageInput3"
                  className="custom-file-input"
                  type="file"
                />
                <br />
                <span className="imageName"></span>
              </label>
            </div> */}
          </div>
        </div>
      )}
    </form>
  );
};

export default AddDevice;
