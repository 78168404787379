import React, { createContext, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useMatch, useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { ReactComponent as Back } from "../../assets/icons/back.svg";
import Layout from "../../components/Layout/Layout";
import CardDetails from "../../components/TransactionComponents/CardDetails";
import TransactionDetails from "../../components/TransactionComponents/TransactionDetails";
import axios from "../../plugins/axios";
import { viewTransaction } from "../../plugins/urls";

export const TransactionContext = createContext();
const Transaction = () => {
  const navigate = useNavigate();
  const match = useMatch("/transaction/:id");
  const id = match ? match.params.id : "";
  const [state, setState] = useState({
    referenceID: "",
    transactionCategory: "",
    terminalType: "",
    paymentMethod: "",
    amount: "",
    terminalId: "",
    paymentStatus: "",
    businessName: "",
    channel: "",
    wayaposFees: "",
    date: "",
    message: "",
    cardType: "",
    cardNumber: "",
    authCode: "",
    issuingBank: "",
    country: "",
    transactionLocation: "",
    paymentProcessor: "",
    deviceType: "",
  });
  useEffect(() => {
    axios({ url: `${viewTransaction}?id=${id}`, method: "GET" })
      .then((res) => {
        // console.log(res)
        if (res.data.respCode === 0) {
          const {
            paymentStatus,
            paymentMethod,
            transactionCategory,
            terminalType,
            transactionDate,
            de2,
            de4,
            de41,
            de37,
            de38,
            bank,
            transactionLocation,
            scheme,
            processedBy,
            terminals: {
              merchants: { orgName, merchantId },
            },
            // terminals: {
            //   merchants: { surname },
            // },
          } = res.data.respBody;
          setState((state) => ({
            ...state,
            issuingBank: bank,
            businessName: orgName,
            transactionLocation,
            authCode: de38,
            merchantId,
            paymentStatus,
            paymentMethod,
            transactionCategory,
            terminalType,
            amount: de4,
            terminalId: de41,
            referenceID: de37,
            cardNumber: de2,
            date: transactionDate,
            cardType: scheme,
            paymentProcessor: processedBy,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  }, []);
  return (
    <Layout title={`Transactions>${id}`}>
      <TransactionContext.Provider
        value={{
          state,
        }}
      >
        <div className="font-default address">
          <h4
            className="text-darker fs-14 fw-700 cursor-pointer ms-2"
            onClick={() => navigate(-1)}
          >
            <span className="mr-15">
              <Back />
            </span>
            Back
          </h4>

          <Row className="mt-20 px-3">
            <TransactionDetails />
            <CardDetails />
          </Row>
        </div>
      </TransactionContext.Provider>
    </Layout>
  );
};

export default Transaction;
