import FileSaver from "file-saver";
import _, { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Col,
  ListGroup,
  Modal,
  ModalTitle,
  Row,
  Table,
} from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import { matchPath, useLocation } from "react-router";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { Slide, toast } from "react-toastify";
import Swal from "sweetalert2";
import facebook from "../../assets/icons/facebook.png";
import instagram from "../../assets/icons/Instagram.png";
import { ReactComponent as Plus } from "../../assets/icons/plus.svg";
import Twitter from "../../assets/icons/Twitter.png";
import Whatsapp from "../../assets/icons/WhatsApp.png";
import { downloadMerchant } from "../../pages/BusinessReports/data";
import { ExportData } from "../../pages/BusinessReports/ExportData";
import axios from "../../plugins/axios";
import {
  activeCorporate,
  allMerchants,
  allUsers,
  businessTypesList,
  createBulkUsersOnline,
  createSingleCorporateUser,
  createUsersWithExcelFileUpload,
  deactivateSingleUser,
  deactivateUsers,
  downloadExcelTemplate,
  inactiveCorporate,
  registeredCorporate,
} from "../../plugins/urls";
import TerminalCards from "../Cards/TerminalCards";
import { CustomModal } from "../Modal/CustomModal";
import NoResultFound from "../NoResultFound/NoResultFound";
import Pagination from "../Pagination/pagination";
import Popover from "../Popover/Popover";
import CreateSingleUser from "./CreateSingleUser";
import DeactivateMultipleUsers from "./DeactivateMultipleUsers";
import DeactivateSingleUser from "./DeactivateSingleUser";
import ExcelUpload from "./ExcelUpload";

let pagesize = 10;

export const statesArray = [
  { name: "Abia", id: 1 },
  { name: "Adamawa", id: 2 },
  { name: "Akwa Ibom", id: 3 },
  { name: "Anambra", id: 4 },
  { name: "Bauchi", id: 5 },
  { name: "Bayelsa", id: 6 },
  { name: "Benue", id: 7 },
  { name: "Borno", id: 8 },
  { name: "Cross River", id: 9 },
  { name: "Delta", id: 10 },
  { name: "Ebonyi", id: 11 },
  { name: "Edo", id: 12 },
  { name: "Ekiti", id: 13 },
  { name: "Enugu", id: 14 },
  { name: "Gombe", id: 15 },
  { name: "Imo", id: 16 },
  { name: "Jigawa", id: 17 },
  { name: "Kaduna", id: 18 },
  { name: "Kano", id: 19 },
  { name: "Katsina", id: 20 },
  { name: "Kebbi", id: 21 },
  { name: "Kogi", id: 22 },
  { name: "Kwara", id: 23 },
  { name: "Lagos", id: 24 },
  { name: "Nasarawa", id: 25 },
  { name: "Niger", id: 26 },
  { name: "Ogun", id: 27 },
  { name: "Ondo", id: 28 },
  { name: "Osun", id: 29 },
  { name: "Oyo", id: 30 },
  { name: "Plateau", id: 31 },
  { name: "Rivers", id: 32 },
  { name: "Sokoto", id: 33 },
  { name: "Taraba", id: 34 },
  { name: "Yobe", id: 35 },
  { name: "Zamfara", id: 36 },
  { name: "FCT", id: 37 },
];

const statusList = [
  { name: "ACTIVE", id: 1 },
  { name: "INACTIVE", id: 2 },
];
const Users = () => {
  const { pathname } = useLocation();
  const showForBusinessReport = matchPath("/business-reports", pathname);
  const [state, setState] = useState({
    activeUsers: 0,
    inactiveUsers: 0,
    registeredUsers: 0,
    corporateList: [],
    admin: true,
    firstName: "",
    surname: "",
    email: "",
    businessType: "",
    phoneNumber: "",
    dateOfBirth: "",
    orgName: "",
    orgEmail: "",
    orgPhone: "",
    orgState: "",
    city: "",
    officeAddress: "",
    referralCode: "",
    referenceCode: "",
    password: "",
    submit: false,
    add: false,
    deactivateMultiple: false,
    deactivateSingle: false,
    createSingle: false,
    upload: false,
    isCorporate: true,
    businessTypeList: [],
  });

  const {
    activeUsers,
    inactiveUsers,
    registeredUsers,
    corporateList,
    admin,
    firstName,
    surname,
    email,
    businessType,
    phoneNumber,
    dateOfBirth,
    orgName,
    orgEmail,
    orgPhone,
    orgState,
    city,
    officeAddress,
    // referralCode,
    referenceCode,
    password,
    submit,
    add,
    deactivateMultiple,
    deactivateSingle,
    createSingle,
    upload,
    isCorporate,
    businessTypeList,
  } = state;

  const [currentPage, setCurrentPage] = useState(1);
  const [smShow, setSmShow] = useState(false);
  const [showSocials, setShowSocials] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [passwordErr, setPasswordErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [inputErr, setInputErr] = useState({});

  const [onlineFormValues, setOnlineFormValues] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState(corporateList);
  const currentDate = moment(new Date(Date.now())).format("LL HH:mm A");

  const showCreateUsersOnlineModal = () => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
      }));
    }
  };

  const showCreateSingleUserModal = () => {
    if (!createSingle) {
      setState((state) => ({
        ...state,
        createSingle: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        createSingle: false,
      }));
    }
  };

  const showUploadExcelSheetToCreateModal = () => {
    if (!upload) {
      setState((state) => ({
        ...state,
        upload: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        upload: false,
      }));
    }
  };

  const showDeactivateMultipleModal = () => {
    if (!deactivateMultiple) {
      setState((state) => ({
        ...state,
        deactivateMultiple: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        deactivateMultiple: false,
      }));
    }
  };

  const showDeactivateSingleModal = () => {
    if (!deactivateSingle) {
      setState((state) => ({
        ...state,
        deactivateSingle: true,
      }));
    } else {
      setState((state) => ({
        ...state,
        deactivateSingle: false,
      }));
    }
  };

  //this function will target the value in a form.
  const onChange = (e) => {
    const { value, name } = e.target;
    const pattern = /^$/;
    const validInput = pattern.test(value?.trim());
    setInputErr((err) => ({
      ...err,
      [name]: validInput ? "This field is Required" : "",
    }));
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeUser = (e) => {
    let targetUser = e.target.value;

    setSelectValue(JSON.parse(targetUser));
  };
  const handlePasswordValidation = (e) => {
    const passwordInputValue = e.target.value.trim();
    const passwordInputFieldName = e.target.name;

    if (passwordInputFieldName === "password") {
      const minLengthRegExp = /.{8,}/;
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);

      let errMsg = "";
      if (!minLengthPassword) {
        errMsg = "Password should be at least a minumum of 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
  };

  const handlePhoneValidation = (e) => {
    const phoneInputValue = e.target.value.trim();
    const phoneInputFieldName = e.target.name;

    if (phoneInputFieldName !== !undefined) {
      let pattern = new RegExp(/^[0-9\b]+$/);
      let validPhone = pattern.test(phoneInputValue);

      let errMsg = "";
      if (!validPhone) {
        errMsg = "Please enter only numbers";
      } else if (phoneInputValue.substring(0, 3) !== "234") {
        errMsg = "Phone number must start with 234";
      } else if (phoneInputValue.length !== 13) {
        errMsg = "Phone number must be 13 characters";
      }
      setPhoneErr((err) => ({
        ...err,
        [phoneInputFieldName]: errMsg,
      }));
    }
  };

  const handleEmailValidation = (e) => {
    const emailInputValue = e.target.value.trim();
    const emailInputFieldName = e.target.name;

    if (emailInputFieldName === "email" || emailInputFieldName === "orgEmail") {
      const pattern =
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i || /\S+@\S+\.\S+/;
      let validEmail = pattern.test(emailInputValue);
      let errMsg = "";
      if (!emailInputValue || emailInputValue === "") {
        errMsg = "Required";
      } else if (!validEmail) {
        errMsg = "Email is not valid";
      } else {
        errMsg = "";
      }
      setEmailErr((err) => ({
        ...err,
        [emailInputFieldName]: errMsg,
      }));
    }
  };

  useEffect(() => {
    axios({
      method: "get",
      url: `${activeCorporate}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          activeUsers: res.data,
        }));
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });

    axios({
      method: "get",
      url: `${inactiveCorporate}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          inactiveUsers: res.data,
        }));
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });

    axios({
      method: "get",
      url: `${registeredCorporate}`,
    })
      .then((res) => {
        setState((state) => ({
          ...state,
          registeredUsers: res.data,
        }));
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
    getUsers();
    getBusinessTypeList();
  }, []);

  const getUsers = () => {
    axios({
      method: "get",
      url: `${allMerchants}?size=10`,
    })
      .then((res) => {
        console.log('respb7', res)

        if (res?.data?.respBody) {
          console.log('respb', res?.data?.respBody)
          setState((state) => ({
            ...state,
            corporateList: res?.data?.respBody,
          }));
          setFilteredList(res?.data?.respBody);
        }
      })
      .catch((err) => {
        toast.error(`${err.response?.respDesc}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const handleDeactivateSingleUser = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));
    if (!selectValue?.userId) {
      toast.error("Hold on tight,Fetching userId...", {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
    } else {
      axios({
        method: "post",
        url: `${deactivateSingleUser}?userId=${selectValue?.userId}`,
        data: selectValue,
      })
        .then((res) => {
          if (res.data.status === true) {
            setState((state) => ({
              ...state,
              submit: false,
            }));
            toast.success(`${res.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
            setState((state) => ({
              ...state,
              deactivateSingle: false,
            }));
          } else {
            setState((state) => ({
              ...state,
              submit: false,
              deactivateSingle: false,
            }));
            toast.success(`There was an error deactivating user`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        });
    }
  };

  const onCreateUsersWithExcelFile = () => {
    const formData = new FormData();

    if (selectedFile !== undefined) {
      if (selectedFile.name.split(".").slice(-1)[0] === "csv") {
        formData.append("file", selectedFile, selectedFile.name);
        setState((state) => ({
          ...state,
          submit: true,
        }));
        axios({
          method: "post",
          url: `${createUsersWithExcelFileUpload}`,
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then(async (res) => {
            if (res.data.status === true) {
              setState((state) => ({
                ...state,
                submit: false,
                createUsers: res.data,
                isCorporate,
              }));
              toast.success(`${res.data.message}`, {
                transition: Slide,
                hideProgressBar: true,
                autoClose: 3000,
              });
            } else {
              try {
                Swal.fire({
                  icon: "error",
                  title: "Something went wrong!",
                  text: `${res.data?.data?.join("\r\n")}`,
                });
              } catch (error) {
                console.log(error, "error");
              }
            }
          })
          .catch((err) => {
            toast.error(`${err.response?.data?.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          })
          .finally(() => {
            setState((state) => ({
              ...state,
              upload: false,
              submit: false,
            }));
          });
      } else {
        toast.error(
          "Invalid file type, Ensure the file to upload is in a csv file format."
        );
      }
    } else {
      toast.error("Please select a file");
    }
  };

  const handleCreateUsersOnMultipleForm = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    axios({
      method: "post",
      url: `${createBulkUsersOnline}`,
      data: onlineFormValues,
      // data: reqBody,
    })
      .then((res) => {
        if (res.data.status) {
          setOnlineFormValues((onlineFormValues) => ({
            ...onlineFormValues,
            submit: false,
            firstName: "",
            surname: "",
            email: "",
            orgName: "",
            orgEmail: "",
            orgPhone: "",
            state: "",
            businessType: "",
            city: "",
            officeAddress: "",
            dateofbirth: "",
            referenceCode: "",
            password: "",
          }));
          toast.success(`${res.data.data}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getUsers();
          setState((state) => ({
            ...state,
            add: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.error(`An error occured while creating user`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            add: false,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onCreateSingleUser = (e) => {
    e.preventDefault();
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      admin,
      businessType,
      firstName,
      surname,
      email,
      phoneNumber,
      dateOfBirth,
      referenceCode,
      password,
      wayaBankPayPosGram: "wayaPos",
      officeAddress,
      city,
      orgName,
      orgEmail,
      orgPhone,
      state: orgState,
    };

    axios({
      method: "post",
      url: `${createSingleCorporateUser}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.status) {
          setState((state) => ({
            ...state,
            submit: false,
            firstName: "",
            surname: "",
            email: "",
            phonenumber: "",
            businessType: "",
            dateofbirth: "",
            referenceCode: "",
            officeAddress: "",
            city: "",
            orgName: "",
            orgEmail: "",
            orgState: "",
            password: "",
          }));
          toast.success(`${res.data.data}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getUsers();
          setState((state) => ({
            ...state,
            createSingle: false,
          }));
        } else {
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.error(`An error occured while creating user`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            createSingle: false,
          }));
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
        setState((state) => ({
          ...state,
          createSingle: false,
        }));
      });
  };

  const fileUploadHandler = (event) => {
    // setSelectedFile(event.target.files[0]);
    // setIsFilePicked(true);
    const { target } = event;
    if (target.value.length > 0) {
      setSelectedFile(target.files[0]);
      setIsFilePicked(true);
    } else {
      target.reset();
      setIsFilePicked(false);
      toast.error("Please select a file");
    }
  };

  const handleDeactivateMultipleUsers = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    if (
      selectedFile.name.split(".").pop() === "xlsx" ||
      selectedFile.name.split(".").pop() === "xls"
    ) {
      axios({
        method: "post",
        url: `${deactivateUsers}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.data.status) {
            setState((state) => ({
              ...state,
              deactivateUsers: res.data,
              submit: false,
              deactivateMultiple: false,
            }));
            toast.success(`${res.data.message}`, {
              transition: Slide,
              hideProgressBar: true,
              autoClose: 3000,
            });
          }
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.success("There was an error deactivating user", {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            deactivateMultiple: false,
          }));
        })
        .catch((err) => {
          setState((state) => ({
            ...state,
            submit: false,
          }));
          toast.error(`${err.response.data.message}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          setState((state) => ({
            ...state,
            deactivateMultiple: false,
          }));
        });
    } else {
      toast.error(
        "Invalid file type, please upload an excel sheet to deactivate users."
      );
    }
  };

  const handleDownloadTemplate = () => {
    axios({
      method: "get",
      url: `${downloadExcelTemplate}`,
      responseType: "arraybuffer",
      headers: {
        "Content-Type": "*/*",
      },
    })
      .then((res) => {
        const blob = new Blob([res.data], {
          type: "application/csv",
        });
        FileSaver.saveAs(blob, `corporateUsers~${Date.now()}.csv`);
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const getBusinessTypeList = () => {
    axios({
      method: "get",
      url: `${businessTypesList}`,
    })
      .then((res) => {
        if (res.data) {
          setState((state) => ({
            ...state,
            businessTypeList: res.data.businessTypeList,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const handleSearch = () => {
    try {
      let result = [];
      const regExpVal = /^[a-zA-Z]+$/;
      if (searchInput !== "") {
        result = corporateList.filter((user) => {
          return (
            user.otherDetails.organisationName.toLowerCase() === searchInput ||
            user.email.includes(searchInput) ||
            user.createdAt.includes(searchInput) ||
            user.otherDetails.organisationName.search(regExpVal) ===
              searchInput ||
            user.otherDetails.organisationName.search(searchInput) !== -1
          );
        });
        setFilteredList(result);
      }
      return corporateList;
    } catch (error) {
      console.error(error);
    }
  };

  const handleDateSearch = (e) => {
    try {
      let result = [];
      const value = moment(new Date(e.target.value)).format("D-MM-YYYY");
      if (value !== null) {
        result = corporateList.filter((user) => {
          return user.createdAt.includes(value);
        });
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  //this handles filtering city data on the filter dropdown where there are duplicate values.
  let filteredCity = corporateList?.map(
    (cy) => cy?.city
  );
  let sortFilteredCity = [];
  filteredCity.forEach((el) => {
    if (!sortFilteredCity.includes(el)) {
      if (el !== undefined) {
        sortFilteredCity.push(el);
      }
    }
  });

  let filteredBusinessType = corporateList.map(
    (cy) => cy?.businessType
  );
  let sortFilteredBusType = [];
  filteredBusinessType.forEach((el) => {
    if (!sortFilteredBusType.includes(el)) {
      if (el !== undefined) {
        sortFilteredBusType.push(el);
      }
    }
  });

  const handleFilterByCity = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        result = corporateList.filter((val) => {
          return val?.city === value;
        });
        setFilteredList(result);
      } else if (value === "") {
        getUsers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByState = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        result = corporateList.filter((val) => {
          return val.state === value;
        });
        setFilteredList(result);
      } else if (value === "") {
        getUsers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByBusinessType = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        result = corporateList.filter((val) => {
          return (
            val.businessType === value ||
            val.businessType.includes(value)
          );
        });
        setFilteredList(result);
      } else if (value === "") {
        getUsers();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByStatus = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        if (value.toLowerCase() === "active") {
          result = corporateList.filter((val) => {
            return val.active === true;
          });
        } else if (value.toLowerCase() === "inactive") {
          result = corporateList.filter((val) => {
            return val.active === false;
          });
        } else if (value === "") {
          getUsers();
        }
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pagesize;
    const lastPageIndex = firstPageIndex + pagesize;
    if (filteredList !== null) {
      return filteredList.slice(firstPageIndex, lastPageIndex);
    }
    return;
  }, [currentPage, filteredList]);

  /*   const headers = [
    { label: "ID", key: "userId" },
    { label: "Business Name", key: "otherDetails.organisationName" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "surname" },
    { label: "Phone", key: "otherDetails.organisationPhone" },
    { label: "Email", key: "otherDetails.organisationEmail" },
    { label: "State", key: "otherDetails.organizationState" },
    { label: "City", key: "otherDetails.organizationCity" },
    { label: "Business Type", key: "otherDetails.businessType" },
    { label: "Active", key: "active" },
    { label: "Date Created", key: "createdAt" },
  ];

  const csvReport = {
    data: corporateList,
    headers: headers,
    filename: `CorporateUsers_${currentDate}.csv`,
  }; */

  return (
    <div>
      <CustomModal
        show={deactivateMultiple}
        clicked={showDeactivateMultipleModal}
        submit={handleDeactivateMultipleUsers}
        loading={submit}
        title="Upload Excel Sheet to Deactivate Users"
        action="Deactivate"
        size="md"
      >
        <DeactivateMultipleUsers
          fileUploadHandler={fileUploadHandler}
          selectedFile={selectedFile}
          isFilePicked={isFilePicked}
        />
      </CustomModal>
      <CustomModal
        show={deactivateSingle}
        clicked={showDeactivateSingleModal}
        submit={handleDeactivateSingleUser}
        loading={submit}
        title={`${
          !isEmpty(selectValue)
            ? selectValue?.active
              ? "Deactivate Single User"
              : "Activate Single User"
            : "Deactivate/Activate Single User"
        }`}
        action={`${
          !isEmpty(selectValue)
            ? selectValue?.active
              ? "Deactivate User"
              : "Activate User"
            : "Deactivate/Activate User"
        }`}
        size="md"
      >
        <DeactivateSingleUser onChange={onChangeUser} />
      </CustomModal>
      <CustomModal
        show={createSingle}
        clicked={showCreateSingleUserModal}
        submit={onCreateSingleUser}
        loading={submit}
        title="Single User"
        action="Create User"
        size="lg"
      >
        <CreateSingleUser
          onChange={onChange}
          firstName={firstName}
          lastName={surname}
          businessType={businessType}
          phoneNumber={phoneNumber}
          email={email}
          dateOfBirth={dateOfBirth}
          referralCode={referenceCode}
          password={password}
          handlePasswordValidation={handlePasswordValidation}
          formError={{ phoneErr, passwordErr, emailErr, inputErr }}
          handlePhoneValidation={handlePhoneValidation}
          handleEmailValidation={handleEmailValidation}
          businessTypeList={businessTypeList}
        />
      </CustomModal>
      <CustomModal
        show={upload}
        clicked={showUploadExcelSheetToCreateModal}
        submit={onCreateUsersWithExcelFile}
        loading={submit}
        title="Upload Excel Sheet to Create Users"
        action="Upload"
        size="md"
      >
        <ExcelUpload
          fileUploadHandler={fileUploadHandler}
          selectedFile={selectedFile}
          isFilePicked={isFilePicked}
        />
      </CustomModal>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex justify-content-start align-items-center width-50">
          <div
            className="d-flex justify-content-center align-items-center filter-search  w-100"
            style={{ marginLeft: "-20px" }}
          >
            <div className="input_Search d-flex justify-content-center align-items-center">
              <div className="justify-content-center align-items-center">
                <FiSearch color="#FF6700" />
              </div>
              <input
                className="input ml-10"
                name="search"
                placeholder="Search Users by Name, Email or Date"
                onChange={(event) => {
                  setSearchInput(event.target.value.toLowerCase());
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end align-items-center w-100">
          {_.isEmpty(showForBusinessReport) && (
            <>
              <div className="d-flex justify-content-center align-items-center ">
                <Button
                  className="mr-5 btn btn-dark btn-sm"
                  onClick={() => setSmShow(true)}
                  style={{ fontSize: "12px" }}
                >
                  Activate / Deactivate User
                </Button>
                <Modal
                  size="sm"
                  show={smShow}
                  modal-backdrop-bg="#000"
                  onHide={() => setSmShow(false)}
                  aria-labelledby="contained-modal-sizes-title-sm-vcenter"
                  centered
                >
                  <Modal.Body className="cursor-pointer fs-14">
                    <ListGroup variant="flush">
                      <ListGroup.Item onClick={showDeactivateSingleModal}>
                        Activate/Deactivate Single User
                      </ListGroup.Item>
                      <ListGroup.Item onClick={showDeactivateMultipleModal}>
                        Deactivate Multiple Users
                      </ListGroup.Item>
                    </ListGroup>
                  </Modal.Body>
                </Modal>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <button
                  className="mr-5 text-white border-0 rounded"
                  type="button"
                  data-toggle="dropdown"
                  style={{
                    fontSize: "12px",
                    background: "#0086E8",
                    padding: "5px",
                  }}
                >
                  Invite Users
                </button>
                <div className="dropdown-menu">
                  <a
                    className="dropdown-item fs-14"
                    href="#*"
                    onClick={() => setShowSocials(true)}
                  >
                    Via Social Media
                  </a>
                  <Modal
                    show={showSocials}
                    onHide={() => setShowSocials(false)}
                    keyboard={false}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header className="px-4 border-bottom-0">
                      <ModalTitle className="m-auto fs-18 pt-2 pb-0">
                        Send Via Social Media
                      </ModalTitle>
                    </Modal.Header>
                    <Modal.Body className="px-4 pt-0 pb-2">
                      <div className="d-flex justify-content-center row-cols-4 mt-20">
                        <div className="text-center">
                          <WhatsappShareButton
                            url="https://wayapos.ng"
                            quote={
                              "Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ..."
                            }
                            hashtag="#WayaPOS"
                          >
                            <img src={Whatsapp} alt="WhatsApp logo" />
                            <p>WhatsApp</p>
                          </WhatsappShareButton>
                        </div>
                        <div className="text-center">
                          <TwitterShareButton
                            url="https://wayapos.ng"
                            quote={
                              "Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ..."
                            }
                            hashtag="#WayaPOS"
                          >
                            <img src={Twitter} alt="Twitter logo" />
                            <p>Twitter</p>
                          </TwitterShareButton>
                        </div>
                        <div className="text-center">
                          <a
                            href="https://instagram.com"
                            className="text-decoration-none text-dark"
                          >
                            <img src={instagram} alt="Instagram logo" />
                            <p>Instagram</p>
                          </a>
                        </div>
                        <div className="text-center">
                          <FacebookShareButton
                            url="https://wayapos.ng"
                            quote={
                              "Discover the best way to to carry out your financial transactions. Free internet banking and bills payment · Discover beautiful places, people, conversations, ..."
                            }
                            hashtag="#WayaPOS"
                          >
                            <img src={facebook} alt="Facebook logo" />
                            <p>Facebook</p>
                          </FacebookShareButton>
                        </div>
                      </div>
                    </Modal.Body>
                  </Modal>
                  {/* <a className="dropdown-item fs-14" href="#*">
                Via SMS
              </a>
              <a className="dropdown-item fs-14" href="#*">
                Via Email
              </a> */}
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <Button
                  className="mr-5 text-white border-0"
                  style={{
                    fontSize: "12px",
                    background: "#27AE60",
                    padding: "5px",
                  }}
                  onClick={handleDownloadTemplate}
                >
                  Download Template
                </Button>
              </div>
              <div className="d-flex justify-content-center align-items-center ">
                <div
                  className="d-flex justify-content-center align-items-center text-white rounded mr-5"
                  role="button"
                  data-toggle="dropdown"
                  style={{
                    fontSize: "12px",
                    background: "#FF6700",
                    padding: "5px",
                  }}
                >
                  <Plus style={{ width: "12px", marginRight: "3px" }} />
                  Create New User
                </div>
                <div className="dropdown-menu fs-14 fw-bold">
                  <a
                    href="#*"
                    className="dropdown-item fs-14"
                    onClick={showCreateSingleUserModal}
                  >
                    Create Single User
                  </a>
                  <a
                    href="#*"
                    className="dropdown-item fs-14"
                    onClick={showUploadExcelSheetToCreateModal}
                  >
                    Upload Excel Sheet to Create
                  </a>
                  {/* <a
                href="#*"
                className="dropdown-item fs-14"
                onClick={showCreateUsersOnlineModal}
              >
                Upload Online Form
              </a> */}
                </div>
              </div>
            </>
          )}
          <div>
            {/* <Button
              className="btn btn-success border-0"
              style={{ marginRight: "1.5em", fontSize: "12px" }}
            >
              <img src={exportIcon} alt="" className="me-2" />
              <CSVLink
                {...csvReport}
                style={{ color: "#FFF", textDecoration: "none" }}
              >
                Export Data
              </CSVLink>
            </Button> */}
            <ExportData
              url={downloadMerchant}
              filename={`Corporate Users-${new Date().toISOString()}`}
            />
          </div>
        </div>
      </div>
      <Row className="mt-20">
        <Col>
          <TerminalCards
            title="Total Registered Users"
            value={registeredUsers}
            color="text-dark"
            textColor="text-dark"
            size="fs-14"
          />
        </Col>
        <Col>
          <TerminalCards
            title="Total Active users"
            value={activeUsers}
            color="text-dark"
            textColor="text-dark"
            size="fs-14"
          />
        </Col>
        <Col>
          <TerminalCards
            title="Total Inactive Users"
            value={inactiveUsers}
            color="text-dark"
            textColor="text-dark"
            size="fs-14"
          />
        </Col>
      </Row>
      <Row className="mt-20">
        <div className="d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-center align-items-center me-3">
            <div className="input-div ">
              <div className="input-container">
                <select
                  className="input select"
                  name="city"
                  id="city"
                  onChange={handleFilterByCity}
                  style={{ color: "#555", width: "9em" }}
                >
                  <option value="">City</option>
                  {sortFilteredCity.map((val, index) => (
                    <option key={index} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center me-3">
            <div className="input-div ">
              <div className="input-container">
                <select
                  className="input select"
                  name="state"
                  id="state"
                  onChange={handleFilterByState}
                  style={{ color: "#555", width: "9em" }}
                >
                  <option value="">State</option>
                  {statesArray.map((val) => (
                    <option key={val.id} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center me-3">
            <div className="input-div ">
              <div className="input-container">
                <select
                  className="input select"
                  name="businessType"
                  id="businessType"
                  onChange={handleFilterByBusinessType}
                  style={{ color: "#555", width: "12em" }}
                >
                  <option value="">Business Type</option>
                  {/* {corporateList.map((val) => (
                    <option key={val.id} value={val.otherDetails.businessType}>
                      {val.otherDetails.businessType}
                    </option>
                  ))} */}
                  {sortFilteredBusType.map((val, index) => (
                    <option key={index} value={val}>
                      {val}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center me-3">
            <div className="input-div ">
              <div className="input-container">
                <select
                  className="input select"
                  name="status"
                  id="status"
                  onChange={handleFilterByStatus}
                  style={{ color: "#555", width: "9em" }}
                >
                  <option value="">Status</option>
                  {statusList.map((val) => (
                    <option key={val.id} value={val.name}>
                      {val.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <div className="form-group fs-14">
              <div className="input-group date" id="datetimepicker1">
                <input
                  type="date"
                  className="form-control fs-14"
                  onChange={handleDateSearch}
                  style={{ color: "#555", width: "11em" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Row>
      <div className="data-table mt-20 data-table--scroll">
        <Table responsive borderless className="bg-inherit">
          <thead>
            <tr
              style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
              className="text-nowrap t-head-colored"
            >
              <th>BUSINESS NAME</th>
              <th className="px-4 py-2 th">PHONE</th>
              <th className="px-4 py-2 th">EMAIL</th>
              <th className="px-4 py-2 th">STATE</th>
              <th className="px-4 py-2 th">CITY</th>
              <th className="px-4 py-2 th">BUSINESS TYPE</th>
              <th className="px-4 py-2 th">STATUS</th>
              <th className="px-4 py-2 th">DATE CREATED</th>
              <th className="px-4 py-2 th">ACTION</th>
            </tr>
          </thead>
          <tbody>
            {filteredList ? (
              filteredList.length === 0 ? (
                <NoResultFound />
              ) : (
                currentTableData.map((business, i) => {
                  const {
                      orgEmail,
                      businessType,
                      orgName,
                      city,
                      state,
                      orgPhone,                    
                    active,
                    createdAt,
                    userId,
                  } = business;
                  return (
                    <tr key={i} className="text-nowrap">
                      <td
                        className="px-2 py-1 td text-wrap text-capitalize"
                        style={{ lineHeight: "1.3" }}
                      >
                        {orgName ?? "N/A"}
                      </td>
                      <td className="px-4 td">
                        {orgPhone ??  "N/A"}
                      </td>
                      <td className="px-4 td">
                        {orgEmail ??  "N/A"}
                      </td>
                      <td className="px-4 td text-capitalize">
                        {state ? state : "N/A"}
                      </td>
                      <td className="px-4 td text-capitalize">
                        {city ? city : "N/A"}
                      </td>
                      <td
                        className="px-4 py-1 td text-wrap"
                        style={{ lineHeight: "1.3" }}
                      >
                        {businessType ??   "N/A"}
                      </td>
                      <td className="td">
                        {active ? (
                          <span className="fw-bold text-success">ACTIVE</span>
                        ) : (
                          <span className="fw-bold text-danger">INACTIVE</span>
                        )}
                      </td>
                      <td
                        className="px-4 td text-wrap"
                        style={{ lineHeight: "1.3" }}
                      >
                        {createdAt ? moment(createdAt).format('DD-MM-YY') : "N/A"}
                      </td>
                      <td className="px-4 td">
                        <button
                          className="dropdown fw-bold text-black border-0 bg-white fs-16"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <BsThreeDotsVertical />
                        </button>
                        <div
                          className="dropdown-menu"
                          style={{
                            backgroundColor: "transparent",
                            border: "none",
                          }}
                          aria-labelledby="dropdownMenuButton"
                        >
                          <Popover userId={userId} business={business} />
                        </div>
                      </td>
                    </tr>
                  );
                })
              )
            ) : (
              <NoResultFound />
            )}
          </tbody>
        </Table>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={filteredList.length}
          pagesize={pagesize}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </div>
  );
};

export default Users;
