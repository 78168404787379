import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Slide, toast } from "react-toastify";
import { terminalTypes } from "../../plugins/urls";

const AddRemoteFile = (props) => {
  const { onChange, state } = props;
  const [terminalList, setTerminalTypeList] = useState({
    terminalTypesList: [],
  });
  const navigate = useNavigate();

  const { appUrl, version, terminalName } = state;

  const getTerminalTypes = () => {
    axios({
      method: "get",
      url: `${terminalTypes}`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setTerminalTypeList((state) => ({
            ...state,
            terminalTypesList: res.data.respBody,
          }));
        }
      })
      .catch((err) => {
        if (err.response.data.status === 401) {
          toast.error(`${err.response.data.error}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          navigate("/login");
          return;
        }
      });
  };
  useEffect(() => {
    getTerminalTypes();
  }, []);

  const { terminalTypesList } = terminalList;
  return (
    <form>
      <div className="input-div mt-20">
        <label className="text-darker fs-14">Terminal Type</label>
        <div className="input-container">
          <select
            className="input select"
            name="terminalName"
            required
            onChange={onChange}
            value={terminalName ?? ""}
          >
            <option value="">{"Select Terminal Type"}</option>
            {terminalTypesList?.map(({ terminalName }, i) => {
              return (
                <option value={terminalName} key={i}>
                  {terminalName}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="input-div">
        <label className="text-darker fs-14">Terminal Description</label>
        <div className="input-container">
          <textarea
            className="input"
            name="terminalDesc"
            required
            onChange={onChange}
          />
        </div>
      </div>

      <div className="input-div mt-20">
        <label className="text-darker fs-14">Version</label>
        <div className="input-container">
          <input
            value={version}
            className="input"
            name="version"
            required
            onChange={onChange}
          />
        </div>
      </div>

      <div className="input-div mt-20">
        <label className="text-darker fs-14">App Url</label>
        <div className="input-container">
          <input
            className="input"
            type="text"
            name={"appUrl"}
            value={appUrl}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="input-div mt-20">
        <label className="text-darker fs-14">Upload zip</label>
        <div className="input-container">
          <input className="input" type="file" onChange={onChange} />
        </div>
      </div>
    </form>
  );
};

export default AddRemoteFile;
