import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { MdOutlinePersonSearch } from "react-icons/md";
import { PricingModal } from "../Modal/PricingModal";
import FailedIcon from "../../assets/icons/Failed2.png";
import "./EditPricingForm.css";
// import { PricingsContext } from "../../pages/Tech/Pricing";
import { allPricing } from "../../plugins/urls";
import axios from "../../plugins/axios";
import { Slide, toast } from "react-toastify";

export default function SelectUserType({ showRemoveModal, remove, users }) {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));

  // const { onChange, state: {pricingList}} = useContext(PricingsContext);

  const [state, setState] = useState({
    submit: false,
    searchVal: '',
    pricingList: [],
    pageNo: 0,
    pageSize: 20,
  });
  const { submit, searchVal, pricingList, pageNo, pageSize } = state;

  useEffect(() => {
    getAllPricing();
  }, [])
  

  const getAllPricing = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      id: user ? user.id : "",
    };

    axios({
      method: "post",
      url: `${allPricing}`,
      data: reqBody,
    })
      .then((res) => {
        console.log("All Pricing: ", res.data.respBody.content);
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            pricingList: res.data.respBody.content,
          }));
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };
  
  const removeFromCustomPricing = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    console.log("User removed from custom pricing");
  };

  const handleChange = (e) => {
    setState((state) => ({
      ...state,
      searchVal: e.target.value,
    }));
  };

  const handleSearch = () => {
    pricingList.map(pricing => pricing.name).filter(val => val.toLowerCase().includes(searchVal.toLowerCase));
  }

  return (
    <>
      <PricingModal
        show={remove}
        clicked={showRemoveModal}
        loading={submit}
        submit={removeFromCustomPricing}
        action="Yes, remove"
        close="Cancel"
      >
        <div className="text-center p-2">
          <img
            src={FailedIcon}
            alt=""
            className="py-2"
            style={{ width: "5em" }}
          />
          <h5 className="p-2 fw-500">Are you sure you want to remove user?</h5>
          <p className="py-2 px-4 fs-14">
            This action will remove this user from the custom pricing and
            general pricing will take effect on this user.
          </p>
        </div>
      </PricingModal>
      <Col className="mt-20 bg-white">
        <Row className="mt-20">
          <div className="ms-4 text-start" style={{ width: "max-content" }}>
            <label className="checkbox-container fs-6 fw-500">
              <span className="ms-1 fs-14">Select all Users</span>
              <input
                type="checkbox"
                // checked={isChecked}
                value="Users"
                // onChange={handleAllUserChange}
                // onClick={props.onClick}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </Row>

        <div
          className="p-4 m-4"
          style={{ border: "1px solid grey", borderRadius: "20px" }}
        >
          <div>
            <label
              className="fw-bold w-100 p-2"
              style={{ backgroundColor: "#F3F3F3" }}
            >
              Choose a new set of users
            </label>
            <div
              className="px-4 py-3 select-user-type"
              style={{ backgroundColor: "#E3E3E3" }}
            >
              <div
                className="d-flex justify-content-center align-items-center p-2 rounded bg-white"
                style={{ border: "1px solid grey" }}
              >
                <input
                  className="input ml-10 p-2"
                  placeholder="Search by name, id or email address"
                  name="users"
                  value={searchVal}
                  multiple
                  required
                  onChange={handleChange}
                />
                <div className="justify-content-center align-items-center">
                  <MdOutlinePersonSearch size={20} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Row>
          {/* <div>{<NamesContainer pricingsList={handleSearch()} />}</div> */}
          {/* <div>{handleSearch()}</div> */}
        </Row>
      </Col>
    </>
  );
}

 const NamesContainer = ({pricingsList}) => {
  return (
    <div>{pricingsList.map((val) => {
      return <div>{val}</div>
    })}</div>
  );
 }