import React from "react";
import Backdrop from "../Backdrop/Backdrop";
import SubmitLoader from "../SubmitLoader/SubmitLoader";
import "./modal.scss";

const TeamActionsModal = (props) => {
  return (
    <>
      <Backdrop show={props.show} closeModal={props.clicked} />

      <div
        className="Modal"
        style={{
          transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          opacity: props.show ? "1" : "0",
        }}
      >
        <div className="modaldialog modaldialogcentered">
          <div className="modalcontent">
            <div
              className="modalheader py-4"
              style={{
                backgroundColor: "#F1F7FA",
                border: "border: 1px solid #E0E0E0",
                borderRadius: "14px 14px 0px 0px",
              }}
            >
              <h6
                className="fs-20 fw-700 mb-0 py-2"
                style={{ color: "#064A72" }}
              >
                {props.title}
              </h6>
            </div>

            <div className="modalbody px-5">{props.children}</div>

            <div className="modalfooter px-5 d-flex flex-column">
              <button
                type="submit"
                className={`${props.extraPadding} orange-button fw-700`}
                onClick={props.submit}
              >
                {props.loading ? <SubmitLoader /> : props.action}
              </button>
              {props.close ? (
                <span
                style={{cursor: 'pointer'}}
                  className="text-orange mt-3"
                  onClick={props.clicked}
                >
                  {props.close}
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamActionsModal;
