import { useState } from "react";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import EditPricingForm from "./EditPricingForm";
import "./EditPricingForm.css";

export const CollapsibleComponent = ({ title, formTitle }) => {
  const [show, setShow] = useState(false);

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <>
      <li
        className={`p-3 mt-3 toggle-div cursor-pointer fs-16 ${
          show ? "isActive" : "inActive"
        }`}
        onClick={toggleShow}
      >
        {title}
        <span className="faq-hidden-plus">
          {show ? <AiOutlineMinus /> : <BsPlusLg />}
        </span>
      </li>
      {show && <EditPricingForm formTitle={formTitle} />}
    </>
  );
};
