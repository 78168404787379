import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyB3GKrvJJAPOGymqp7Gwya6u0rTo_xYr68",
  authDomain: "simple-cloud-messaging.firebaseapp.com",
  projectId: "simple-cloud-messaging",
  storageBucket: "simple-cloud-messaging.appspot.com",
  messagingSenderId: "255609033782",
  appId: "1:255609033782:web:33e76297aa67a080177497",
  measurementId: "G-XDT7QVNFV4",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
