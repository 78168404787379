import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import Layout from "../../components/Layout/Layout";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import axios from "../../plugins/axios";
import { toast, Slide } from "react-toastify";
import { allPricing, createPricing, updatePricing } from "../../plugins/urls";
import NoResultFound from "../../components/NoResultFound/NoResultFound";
import { FiEdit3, FiSearch } from "react-icons/fi";
import { FaCaretDown, FaCopy } from "react-icons/fa";
import exportIcon from "../../assets/icons/coolicon.png";
import PricingCards from "../../components/Cards/PricingsCards";
import CustomPricing from "../../components/TechComponents/CustomPricing";
import { PricingModal } from "../../components/Modal/PricingModal";
import FailedIcon from "../../assets/icons/Failed2.png";
import { CSVLink } from "react-csv";
import FilterPricings from "../../components/TechComponents/FilterPricings";
import UndoIcon from "../../assets/icons/undo.png";
import editIcon from "../../assets/icons/edit.png";
import { createContext } from "react";
import moment from "moment";
import { baseUrlList } from "../../plugins/baseUrl";
import Pagination2 from "../../components/Pagination/pagination2";

export const PricingsContext = createContext();
const pricingStatus = ["DEFAULT", "CUSTOM"];
const pricingProducts = ["CARD", "PAYATTITUDE"];
const Pricing = ({ hidden }) => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));
  const [state, setState] = useState({
    add: false,
    remove: false,
    modalValue: "",
    pricingList: [],
    pageNo: 0,
    pageSize: 20,
    agent: false,
    aggregator: false,
    merchant: false,
    // agentValue: "",
    // aggregatorValue: "",
    // merchantValue: "",
    // id: "",
    cap: 0,
    name: "",
    discount: 0,
    pricingRate: 0,
    products: "",
    userId: "",
    id: 0,
    submit: false,
    totalDefaultPricing: [],
    totalCustomPricing: [],
    totalPricing: 0,
  });
  const [copy, setCopy] = useState(false);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);

  const {
    agent,
    aggregator,
    merchant,
    // agentValue,
    // aggregatorValue,
    // merchantValue,
    totalPricing,
    name,
    discount,
    pricingRate,
    userId,
    id,
    products,
    submit,
    cap,
    add,
    remove,
    pricingList,
    pageNo,
    pageSize,
    modalValue,
    totalDefaultPricing,
    productName,
    totalCustomPricing,
  } = state;

  const [searchInput, setSearchInput] = useState("");
  const [filteredList, setFilteredList] = useState(pricingList);
  const currentDate = moment(new Date(Date.now())).format("LL HH:mm A");

  const showModal = (value, data, userId, productName) => {
    if (!add) {
      setState((state) => ({
        ...state,
        add: true,
        modalValue: value,
        pricingRate: data ? data.pricingRate : "",
        discount: data ? data.discount : "",
        cap: data ? data.cap : "",
        userId: userId,
        productName,
      }));
    } else {
      setState((state) => ({
        ...state,
        add: false,
      }));
    }
  };

  const showRemoveModal = (id, userId) => {
    if (!remove) {
      setState((state) => ({
        ...state,
        remove: true,
        id: id,
        userId: userId,
      }));
    } else {
      setState((state) => ({
        ...state,
        remove: false,
      }));
    }
  };

  const onChange = (e) => {
    setState((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    getAllPricing();
  }, [pageSize, pageNo]);

  const getAllPricing = () => {
    let reqBody = {
      from: "",
      to: "",
      pageNo,
      pageSize,
      id: user ? user.id : "",
    };

    let defaulStatusArr = [];
    let customStatusArr = [];

    axios({
      method: "post",
      url: `${allPricing}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          const result = res.data.respBody.content;
          result.forEach((obj) => {
            if (obj.status === "DEFAULT" || obj.status === null) {
              defaulStatusArr.push(obj);
            } else if (obj.status === "CUSTOM") {
              customStatusArr.push(obj);
            }
          });
          setState((state) => ({
            ...state,
            pricingList: res.data.respBody.content,
            totalPricing: res?.data?.respBody?.totalElements,
            totalDefaultPricing: defaulStatusArr,
            totalCustomPricing: customStatusArr,
          }));
          setFilteredList(res.data.respBody.content);
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onCreatePricing = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));
    let reqBody = {
      agent,
      aggregator,
      merchant,
      name,
      discount,
      pricingRate,
      userId,
      products,
      cap,
    };
    axios({
      method: "post",
      url: `${createPricing}`,
      data: reqBody,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllPricing();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const onUpdatePricing = () => {
    setState((state) => ({
      ...state,
      submit: true,
    }));

    let reqBody = {
      discount,
      pricingRate,
      cap,
      userId,
      productName,
    };

    axios({
      method: "post",
      url: `${updatePricing}`,
      data: reqBody,
    })
      .then((res) => {
        // let { cap, discount, pricingRate } = res.data.respBody;
        if (res.data.respCode === 0) {
          setState((state) => ({
            ...state,
            cap: cap,
            discount: discount,
            pricingRate: pricingRate,
            userId: userId,
            submit: false,
            add: false,
          }));
          console.log(res, "res");
          toast.success(res.data.respDesc, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllPricing();
        } else {
          setState((state) => ({
            ...state,
            submit: false,
            add: false,
          }));
          toast.error(`${res.data.respBody}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        console.log(err, "rr");
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.error(`${err.response.data.message}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const handlePricingReset = () => {
    axios({
      method: "put",
      url: `${baseUrlList.settings}/pricing/id/${id}/userId/${userId}/reset`,
    })
      .then((res) => {
        if (res.data.respCode === 0) {
          toast.success(`Reset ${res.data.respDesc}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
          getAllPricing();
        } else {
          toast.error(`${res.data.respDesc}`, {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        toast.error(`${err.response.data.respDesc}`, {
          transition: Slide,
          hideProgressBar: true,
          autoClose: 3000,
        });
      });
  };

  const handleSearch = () => {
    try {
      let result = [];
      const regExpVal = /^[a-zA-Z]+$/;
      if (searchInput !== "") {
        result = pricingList.filter((user) => {
          if (user.name !== null) {
            return (
              user.name.toLowerCase() === searchInput ||
              user.name.includes(searchInput) ||
              user.name.search(regExpVal) === searchInput
            );
          } else if (user.products !== null) {
            return (
              user.products.toLowerCase() === searchInput ||
              user.products.includes(searchInput)
            );
          } else if (user.status !== null) {
            return (
              user.status.toLowerCase() === searchInput ||
              user.status.includes(searchInput)
            );
          }
          return (
            user.name.includes(searchInput) ||
            user.products.includes(searchInput) ||
            user.name.includes(searchInput)
          );
        });
        setFilteredList(result);
      }
      return pricingList;
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByStatus = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        result = pricingList.filter((val) => {
          return val.status === value;
        });
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFilterByProduct = (e) => {
    const value = e.target.value;
    try {
      let result = [];
      if (value !== null) {
        result = pricingList.filter((val) => {
          return val.products === value;
        });
        setFilteredList(result);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const headers = [
    { label: "User ID", key: "userId" },
    { label: "Name", key: "name" },
    { label: "Products", key: "products" },
    { label: "Rate", key: "pricingRate" },
    { label: "Cap(#)", key: "cap" },
    { label: "Discount", key: "discount" },
    { label: "Pricing Status", key: "status" },
  ];

  const csvReport = {
    data: pricingList,
    headers: headers,
    filename: `WayaPricingsReport_${currentDate}.csv`,
  };
  const lastIndex = (pageNo + 1) * pageSize;
  const firstIndex = lastIndex + 1 - pageSize;
  const paginate = (ev) => {
    let selected = ev.selected;
    setState((state) => ({
      ...state,
      pageNo: selected,
    }));
    setCurrentPage(selected + 1);
  };
  const noPerPage = (no) => {
    setState((state) => ({
      ...state,
      pageSize: no,
    }));
    // getTerminals();
  };

  return (
    <PricingsContext.Provider value={{ onChange, state }}>
      <Layout title="Pricings" hidden={hidden}>
        <PricingModal
          show={add}
          clicked={showModal}
          loading={submit}
          submit={modalValue === "add" ? onCreatePricing : onUpdatePricing}
          title="Custom Pricing"
          action="Okay"
          close="Cancel"
        >
          <CustomPricing />
        </PricingModal>
        <PricingModal
          show={remove}
          clicked={showRemoveModal}
          loading={submit}
          submit={handlePricingReset}
          action="Yes, remove"
          close="Cancel"
        >
          <div className="text-center p-2">
            <img
              src={FailedIcon}
              alt=""
              className="py-2"
              style={{ width: "5em" }}
            />
            <h5 className="p-2 fw-500">
              Are you sure you want to remove user?
            </h5>
            <p className="py-2 px-5 fs-16">
              This action will remove this user from the custom pricing and
              general pricing will take effect on this user.
            </p>
          </div>
        </PricingModal>
        <div className="tableHeaders d-flex justify-content-start align-items-center">
          <div className="d-flex justify-content-between filter-contents align-items-center">
            <div className="d-flex justify-content-start align-items-center width-50">
              <div className="d-flex justify-content-center align-items-center">
                <div className="d-flex justify-content-center align-items-center">
                  <FilterPricings
                    pricingStatus={pricingStatus}
                    pricingProducts={pricingProducts}
                    handleFilterByProduct={handleFilterByProduct}
                    handleFilterByStatus={handleFilterByStatus}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center filter-search ml-4">
                <div className="input_Search d-flex justify-content-center align-items-center">
                  <div className="justify-content-center align-items-center">
                    <FiSearch color="#FF6700" />
                  </div>
                  <input
                    className="input ml-10"
                    name="search"
                    placeholder="Search Users by Name, Products or Status"
                    onChange={(event) => {
                      setSearchInput(event.target.value.toLowerCase());
                    }}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleSearch();
                      }
                    }}
                  />
                </div>
                {/* <button className="orange-button ml-10">Search</button> */}
              </div>
            </div>
            {!hidden && (
              <div className="d-flex justify-content-start align-items-center ">
                <div className="d-flex justify-content-center align-items-center ">
                  <button
                    className="request-button px-4 fs-14"
                    onClick={() => {
                      navigate("/edit-pricings");
                    }}
                  >
                    <FiEdit3 color="#FFF" size={15} className="mr-5" />
                    Edit Pricing
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <Container fluid>
          <Row className="mt-40 ">
            <Col className="pe-0">
              <PricingCards
                title="Total Users with default pricing"
                number={
                  totalDefaultPricing &&
                  (totalDefaultPricing.length > 0
                    ? totalDefaultPricing.length
                    : 0)
                }
              />
            </Col>
            <Col>
              <PricingCards
                title="Total Users with custom pricing"
                number={
                  totalCustomPricing &&
                  (totalCustomPricing.length > 0
                    ? totalCustomPricing.length
                    : 0)
                }
              />
            </Col>
            <Col>
              <div className="d-flex justify-content-end align-items-end">
                <Button
                  className="btn-md fs-14 btn-success border-0 p-2"
                  style={{ paddingBlock: "1em", marginRight: "1.5em" }}
                >
                  <img src={exportIcon} alt="" className="me-2" />
                  <CSVLink
                    {...csvReport}
                    style={{ color: "#FFF", textDecoration: "none" }}
                  >
                    Export Data
                  </CSVLink>
                </Button>
              </div>
            </Col>
          </Row>
          <div className="data-table mt-40">
            <Table responsive borderless className="bg-inherit">
              <thead>
                <tr
                  style={{ backgroundColor: "#F9843533", borderRadius: "5px" }}
                  className="t-head-colored"
                >
                  <th>USER ID</th>
                  <th>USERS</th>
                  <th>PRODUCTS</th>
                  <th>RATE</th>
                  <th>CAP(#)</th>
                  <th>DISCOUNT </th>
                  <th>PRICING STATUS</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {filteredList ? (
                  filteredList.length === 0 ? (
                    <NoResultFound />
                  ) : (
                    filteredList.map((price, i) => {
                      const {
                        userId,
                        name,
                        products,
                        pricingRate,
                        cap,
                        discount,
                        status,
                        id,
                      } = price;
                      return (
                        <tr key={i}>
                          <td className="td">
                            {userId ? (
                              <span className="d-flex justify-content-between align-items-center w-75">
                                {userId}{" "}
                                <FaCopy
                                  color="adadad"
                                  onClick={() => {
                                    navigator.clipboard.writeText(`${userId}`);
                                    setCopy(!copy);
                                  }}
                                />
                              </span>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td className="td text-capitalize">
                            {name ? name : "N/A"}
                          </td>
                          <td className="td">{products ? products : "N/A"}</td>
                          <td className="td">
                            {pricingRate ? `${pricingRate}%` : "-"}
                          </td>
                          <td className="td">{cap ? cap : "-"}</td>
                          <td className="td">
                            {discount ? `${discount}%` : "-"}
                          </td>
                          <td className="td">
                            {status === "DEFAULT" || status === null ? (
                              <span className="fw-500 text-success">
                                DEFAULT
                              </span>
                            ) : (
                              status === "CUSTOM" && (
                                <span
                                  className="fw-500"
                                  style={{ color: "#FF6700" }}
                                >
                                  CUSTOM
                                </span>
                              )
                            )}
                          </td>
                          <td className="td">
                            <span>
                              <img
                                src={editIcon}
                                alt=""
                                onClick={() =>
                                  showModal("edit", price, userId, products)
                                }
                              />
                            </span>
                            <span
                              className={`text-center p-2 ms-2 rounded ${
                                status === "DEFAULT" || status === null
                                  ? "opaque no-click"
                                  : ""
                              }`}
                            >
                              <img
                                src={UndoIcon}
                                alt=""
                                onClick={() => showRemoveModal(id, userId)}
                              />
                            </span>
                          </td>
                        </tr>
                      );
                    })
                  )
                ) : (
                  <NoResultFound />
                )}
              </tbody>
            </Table>
            <div className="t-head-colored mt-4 py-2 d-flex justify-content-center align-items-center w-100">
                <div className="d-flex me-5">
                  <p className="m-0 p-0 me-4">Rows per page : {pageSize}</p>
                  <div className="dropdown">
                    <i
                      id="addminuserdropdownMenuButton1"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FaCaretDown />
                    </i>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="adminuserdropdownMenuButton1"
                    >
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(10)}
                      >
                        10
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(20)}
                      >
                        20
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(50)}
                      >
                        50
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => noPerPage(100)}
                      >
                        100
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="me-4">
                  <p className="m-0">
                    {firstIndex} - {lastIndex} of {totalPricing}
                  </p>
                </div>
                <div className="mt-3 me-2">
                  <Pagination2
                    total={Math.ceil(totalPricing / pageSize)}
                    paginate={paginate}
                    selected={currentPage}
                  />
                </div>
              </div>
          </div>
        </Container>
      </Layout>
    </PricingsContext.Provider>
  );
};

export default Pricing;
