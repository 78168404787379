import React from "react";
import "./InviteTeamMember.css";

export default function InviteTeamMember() {
  return (
    <div>
      <div>
        <p className="text-center py-2 px-4">
          Please enter email to invite team members to have access to your
          dashboard
        </p>
        <div>
          <div className="row w-100 my-4 mx-auto">
            <label className="text-grey fs-14 fw-500">Email Address</label>
            <input
              type="email"
              name="email"
              id="email"
              className="input-styles pt-2 fs-14"
            />
          </div>
          <div className="row w-100 my-4 mx mx-auto">
            <label className="text-grey fs-14 fw-500">Tel</label>
            <input type="tel" name="tel" id="tel" className="input-styles fs-14" />
          </div>
          <div className="row w-100 my-4 mx mx-auto">
            <label className="text-grey fs-14 fw-500">Select Role</label>
            <select name="role" id="role" className="input-styles fs-14 py-2 mt-1 text-grey">
              <option value="Business Owner">Business Owner</option>
              <option value="Customer Support">Customer Support</option>
            </select>
          </div>
          <div className="d-flex justify-content-start align-items-center py-2 my-4 mx-auto">
            <label className="checkbox-container2 fs-14">
            <span className="ms-2 text-grey fw-500">This team member is a developer</span>
              <input type="checkbox" value="" />
              <span className="checkmark2"></span>       
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}
