// import Spinner from 'react-bootstrap/Spinner';

function CustomSpinner() {
  return (
    <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
  );
}

export default CustomSpinner;