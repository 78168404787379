import React, { useEffect, useState } from "react";
import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { ReactComponent as Check } from "../../assets/icons/approve.svg";
import { ReactComponent as Upload } from "../../assets/icons/Upload.svg";
import { AiOutlineEye } from "react-icons/ai";
import { toast, Slide } from "react-toastify";
import axios from "../../plugins/axios";
import {
  getKYCRequirementsByTier,
  kycStatus,
  approveUserKYC,
  rejectUserKYC,
  uploadUserKYC,
} from "../../plugins/urls";
import Modal from "../Modal/Modal";
import { FcCancel } from "react-icons/fc";
import { BsCheckCircle } from "react-icons/bs";
import { RiErrorWarningLine } from "react-icons/ri";
import { getBase64 } from "../../plugins/utils";
import { FaPlus } from "react-icons/fa";
import reject from "../../assets/icons/reject.svg";

const Tier3 = (props) => {
  const { id, tierName, completedTier } = props;
  const [state, setState] = useState({
    passport: {},
    proofAddress: {},
    nationalId: {},
    tierLevelStatus: {},
    viewUrl: "",
    selectedKYC: "",
    formDataImage: new FormData(),
    image: "",
    imageString: "",
    submit: false,
  });
  const {
    tierLevelStatus,
    passport,
    proofAddress,
    nationalId,
    viewUrl,
    selectedKYC,
    formDataImage,
    image,
    imageString,
    submit,
  } = state;
  const [uploadImageModal, setUploadImageModal] = useState(false);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [reason, setReason] = useState("");

  const onChange = (e) => setReason(e.target.value);

  const uploadDoc = (file) => {
    setState((state) => ({
      ...state,
      selectedKYC: file,
    }));
    setUploadImageModal(true);
  };
  const imageHandler = (e) => {
    if (e.target.files && e.target.files[0]) {
      let uploadedimage = e.target.files[0];
      // formDataImage.append("file", uploadedimage);
      getBase64(uploadedimage)
        .then((result) => {
          uploadedimage["base64"] = result;
          setState((state) => ({
            ...state,
            image: uploadedimage,
            imageString: uploadedimage.base64,
          }));
          // console.log(image, imageString)
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      return;
    }
  };
  const getFileName = (name) => {
    switch (name.toLowerCase()) {
      case "passport":
        return "passport";
      case "nin":
        return "nationalIdentity";
      case "proof-address":
        return "proofAddress";

      default:
        break;
    }
  };
  const switchStatus = (status) => {
    switch (status.toUpperCase()) {
      case "APPROVE":
        return (
          <span className="text-sharp-green">
            <i className="me-2">
              <BsCheckCircle size={15} />
            </i>
            {status}
          </span>
        );
      case "REJECT":
        return (
          <span className="text-red">
            <i className="me-2">
              <FcCancel size={15} />
            </i>
            {status}
          </span>
        );
      case "UNDER REVIEW":
        return (
          <span className="text-yellow">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );
      case "PENDING":
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            {status}
          </span>
        );
      default:
        return (
          <span className="text-muted">
            <i className="me-2">
              <RiErrorWarningLine size={15} />
            </i>
            Pending
          </span>
        );
    }
  };
  const findReq = (reqList, reqName) => {
    let reqObj = reqList.find((req) => req.reqItem === reqName);
    return reqObj;
  };
  const getUserKYCRequirements = () => {
    axios({
      method: "get",
      url: `${getKYCRequirementsByTier}/${id}/${tierName}`,
    })
      .then((res) => {
        if (res.data.data) {
          let reqList = res.data.data;

          setState((state) => ({
            ...state,
            passport: findReq(reqList, "PASSPORT") ?? null,
            nationalId: findReq(reqList, "NATIONAL IDENTITY") ?? null,
            proofAddress: findReq(reqList, "PROOF OF ADDRESS") ?? null,
          }));
        }
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  const onUploadUserKYC = (fileName, id) => {
    let formFileName = getFileName(fileName);
    formDataImage.append(`${formFileName}`, image);

    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "post",
      url: `${uploadUserKYC}/${id}/${fileName}?fileName=${formFileName}`,
      data: formDataImage,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
          selectedKYC: "",
          image: "",
          imageString: "",
          formDataImage: new FormData(),
        }));
        setUploadImageModal(false);
        toast.success(
          `${res.data.message ? res.data.message : "File saved successfully"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        getUserKYCRequirements();
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
        setState((state) => ({
          ...state,
          submit: false,
        }));
      });
    setState((state) => ({
      ...state,
      submit: false,
    }));
  };
  const onViewKYCDoc = (url) => {
    setState((state) => ({
      ...state,
      viewUrl: url ? url : "",
    }));
    setViewImageModal(true);
  };
  const onApproveUserKYC = (id) => {
    if (!id) {
      toast.error(`cannot approve a kyc requirement without data`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "get",
      url: `${approveUserKYC}/${id}`,
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        toast.success(
          `${res.data.message ? res.data.message : "File approved"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };
  const onRejectUserKYC = (id) => {
    if (!id) {
      toast.error(`cannot reject a kyc requirement without data`, {
        transition: Slide,
        hideProgressBar: true,
        autoClose: 3000,
      });
      return;
    }
    setState((state) => ({
      ...state,
      submit: true,
    }));
    axios({
      method: "get",
      url: `${rejectUserKYC}/reason/${id}?reason=${reason}`,
    })
      .then((res) => {
        console.log(res.data);
        setState((state) => ({
          ...state,
          submit: false,
        }));
        setOpenReject(false);
        toast.success(
          `${res.data.message ? res.data.message : "File rejected"}`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      })
      .catch((err) => {
        console.log(err.response);
        toast.error(
          `${
            err?.response?.data?.message ??
            err?.response?.data?.error ??
            err.message
          }`,
          {
            transition: Slide,
            hideProgressBar: true,
            autoClose: 3000,
          }
        );
      });
  };

  useEffect(() => {
    getUserKYCRequirements();
  }, []);

  return (
    <div style={{ marginBottom: "50px" }}>
      <div className="d-flex justify-content-between">
        <div>
          <p className="fs-18 fw-700 text-grey">Tier 3</p>
        </div>

        <div>
          <p className="fs-14 fw-700 text-darker m-0">Status</p>
          <button
            className={` mt-2 ${
              completedTier === true ? "button-success" : "orange-button"
            }`}
          >
            {completedTier === true ? (
              <span>Completed</span>
            ) : (
              <span>Incomplete</span>
            )}
          </button>
        </div>
      </div>
      <div className="tier p-20 mt-40">
        <div className="   ">
          <table className="bg-inherit table table-borderless table-responsive w-100">
            <thead>
              <tr className="kyc-table" style={{ borderRadius: "5px" }}>
                <th>KYC Requirement</th>
                <th>Status</th>
                <th></th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>

            <tbody className="align-bottom">
              <tr className="w-100">
                <td className="tier-td text-wrap">PASSPORT PHOTOGRAPH</td>
                <td className="tier-td text-nowrap">
                  {passport?.status ? (
                    switchStatus(passport.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                {passport?.reqValue && (
                  <td className=" tier-td">
                    <input
                      disabled
                      className="tier-detail w-100"
                      value={passport?.reqValue ? passport.reqValue : ""}
                    />
                  </td>
                )}

                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                <td className="tier-td text-end">
                  <span
                    className="tabblack ml-10"
                    onClick={() => uploadDoc("passport")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {passport?.reqValue ? "Change" : "Upload"}
                  </span>
                  {passport?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          passport?.uploadUrl
                            ? onViewKYCDoc(passport.uploadUrl, true)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {passport?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() =>
                              onApproveUserKYC(passport?.id ?? null)
                            }
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() => setOpenReject(passport?.id ?? null)}
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              <tr>
                <td className="tier-td text-wrap">
                  INTERNATIONAL PASSPORT/NIN/
                  <br />
                  DRIVER'S LICENSE /VOTER'S CARD
                </td>
                <td className="tier-td text-nowrap">
                  {nationalId?.status ? (
                    switchStatus(nationalId.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>

                <td className=" tier-td">
                  {nationalId?.reqValue && (
                    <td className=" tier-td">
                      <input
                        disabled
                        className="tier-detail w-100"
                        value={nationalId?.reqValue ? nationalId.reqValue : ""}
                      />
                    </td>
                  )}
                </td>

                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10"
                    onClick={() => uploadDoc("nin")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {nationalId?.reqValue ? "Change" : "Upload"}
                  </span>
                  {nationalId?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          passport?.uploadUrl
                            ? onViewKYCDoc(nationalId.uploadUrl, true)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {nationalId?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() =>
                              onApproveUserKYC(nationalId?.id ?? null)
                            }
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() =>
                              setOpenReject(nationalId?.id ?? null)
                            }
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              {/* <tr>
                <td className="tier-td text-wrap">UTILITY BILL</td>
                <td className="tier-td text-nowrap">
                  {utilityBill.status ? (
                    switchStatus(utilityBill.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                <td className=" tier-td">
                  <input
                    disabled
                    className="tier-detail w-100"
                    value={utilityBill.reqValue ? utilityBill.reqValue : ""}
                  />
                </td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10 "
                    onClick={() => uploadDoc("proof-address")}
                  >
                    <Upload size={20} color="#000" /> Upload
                  </span>
                  <span className="tabblack ml-10">
                    <AiOutlineEye size={20} color="#000" /> View
                  </span>
                  <span
                    className="tabactive ml-10"
                    onClick={() => onApproveUserKYC("UTILITY_BILL", id)}
                  >
                    <Check size={20} color="#FF6700" /> Approve
                  </span>
                  <span
                    className="actionDanger ml-10"
                    onClick={() => onRejectUserKYC("UTILITY_BILL", id)}
                  >
                    <Cancel size={20} color="#FF6700" /> Reject
                  </span>
                </td>
              </tr> */}
              <tr>
                <td className="tier-td text-wrap">
                  Proof of Address, not less than 1 month old
                </td>
                <td className="tier-td text-nowrap">
                  {proofAddress?.status ? (
                    switchStatus(proofAddress.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                <td className=" tier-td">
                  {proofAddress?.reqValue && (
                    <td className=" tier-td">
                      <input
                        disabled
                        className="tier-detail w-100"
                        value={
                          proofAddress?.reqValue ? proofAddress.reqValue : ""
                        }
                      />
                    </td>
                  )}
                </td>

                <td className="tier-td text-end" style={{ opacity: 0 }}></td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10"
                    onClick={() => uploadDoc("proof-address")}
                  >
                    <Upload size={20} color="#000" />{" "}
                    {proofAddress?.reqValue ? "Change" : "Upload"}
                  </span>
                  {proofAddress?.reqValue && (
                    <span>
                      <span
                        className="tabblack ml-10"
                        onClick={() => {
                          proofAddress?.uploadUrl
                            ? onViewKYCDoc(proofAddress.uploadUrl)
                            : toast.error(`${"No Image to view"}`, {
                                transition: Slide,
                                hideProgressBar: true,
                                autoClose: 3000,
                              });
                        }}
                      >
                        <AiOutlineEye size={20} color="#000" /> View
                      </span>
                      {proofAddress?.status?.toUpperCase() !== "APPROVE" && (
                        <span>
                          <span
                            className="tabactive ml-10"
                            onClick={() =>
                              onApproveUserKYC(proofAddress?.id ?? null)
                            }
                          >
                            <Check size={20} color="#FF6700" /> Approve
                          </span>
                          <span
                            className="actionDanger ml-10"
                            onClick={() =>
                              setOpenReject(proofAddress?.id ?? null)
                            }
                          >
                            <Cancel size={20} color="#FF6700" /> Reject
                          </span>
                        </span>
                      )}
                    </span>
                  )}
                </td>
              </tr>
              {/* <tr>
                <td className="tier-td text-wrap">
                  Address verification - photo of the street, house number, left
                  and right{" "}
                </td>
                <td className="tier-td text-nowrap">
                  {addressVerification.status ? (
                    switchStatus(addressVerification.status)
                  ) : (
                    <span className="text-muted">
                      <i className="me-2">
                        <RiErrorWarningLine size={15} />
                      </i>
                      Pending
                    </span>
                  )}
                </td>
                <td className=" tier-td">
                  <input
                    disabled
                    className="tier-detail w-100"
                    value={
                      addressVerification.reqValue
                        ? addressVerification.reqValue
                        : ""
                    }
                  />
                </td>
                <td className="tier-td text-end text-nowrap">
                  <span
                    className="tabblack ml-10 "
                    onClick={() => uploadDoc("ADDRESS_PHOTOGRAPH")}
                  >
                    <Upload size={20} color="#000" /> Upload
                  </span>
                  <span className="tabblack ml-10">
                    <AiOutlineEye size={20} color="#000" /> View
                  </span>
                  <span
                    className="tabactive ml-10"
                    onClick={() => onApproveUserKYC("ADDRESS_VERIFICATION", id)}
                  >
                    <Check size={20} color="#FF6700" /> Approve
                  </span>
                  <span
                    className="actionDanger ml-10"
                    onClick={() => onRejectUserKYC("ADDRESS_VERIFICATION", id)}
                  >
                    <Cancel size={20} color="#FF6700" /> Reject
                  </span>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>

      {uploadImageModal && (
        <Modal
          show={uploadImageModal}
          clicked={() => setUploadImageModal(false)}
          submit={() => onUploadUserKYC(selectedKYC, id)}
          loading={submit}
          title={`Upload ${selectedKYC} file`}
          action="Submit"
        >
          <label className="pointer fs-12 p-1 " for="imageInput1">
            <span className="imageName border-1 p-1 border-dark rounded-3">
              {imageString && imageString.length ? (
                <img
                  style={{
                    width: "12.0rem",
                    height: "8.0rem",
                    padding: "10px",
                    border: "1px solid grey",
                  }}
                  src={imageString}
                  alt="pos "
                />
              ) : (
                <span className="imageName bg-grey p-4">
                  <i className="">
                    <FaPlus />
                  </i>
                </span>
              )}

              <input
                id="imageInput1"
                className="custom-file-input"
                type="file"
                onChange={imageHandler}
                accept
              />
            </span>
          </label>
        </Modal>
      )}

      {viewImageModal && (
        <Modal
          show={viewImageModal}
          clicked={() => setViewImageModal(false)}
          submit={() => setViewImageModal(false)}
          title=""
          action="Close"
        >
          <div className="mx-auto" style={{ width: "20rem", height: "18rem" }}>
            <img src={viewUrl} alt="kyc doc" className="w-100 h-100" />
          </div>
        </Modal>
      )}
      <Modal show={openReject} clicked={() => setOpenReject(false)} title="">
        <div className="mx-auto" style={{ width: "20rem", height: "18rem" }}>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <img src={reject} alt="" />
              <div className="fs-16 ms-2 text-danger">REJECT APPROVAL</div>
            </div>
            <div className="py-2 fs-14 text-muted text-center">
              Are you sure you want to Reject this KYC request?
            </div>
            <div className="input-div full-width">
              <label className="text-default fs-14">State Reason</label>
              <div className="inputnoBorder input-container">
                <textarea
                  className="input"
                  value={reason}
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-between"
              style={{ background: "#F2F2F2" }}
            >
              <button className="btn" onClick={() => setOpenReject(false)}>
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => onRejectUserKYC(openReject)}
              >
                <span className="text-orange">Yes, Proceed</span>
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Tier3;
