import React from "react";

export const AddUnit = (props) => {
  const { desc, onChange, update, unitItem } = props;
  const { unitName, status } = unitItem || {};
  return (
    <form>
      <div className="container-fluid ">
        <div className="col-12 ">
          <label className="fs-12 fw-400 mb-1">{"Unit Name"}</label>
          <input
            className="admin-user-inputs"
            placeholder=""
            type="text"
            name="unitName"
            value={unitName}
            required
            onChange={onChange}
          />
        </div>
        <div className="col-12   ">
          <label className="fs-12 fw-400 mb-1">{"Description"}</label>
          <input
            className="admin-user-inputs"
            type="text"
            name="desc"
            value={desc}
            onChange={onChange}
          />
        </div>

        {update && (
          <div className="col-12   ">
            <label className="fs-12 fw-400 mb-1">{"Status"}</label>
            <select
              className="admin-user-inputs my-1 "
              value={status}
              name="status"
              required
              onChange={onChange}
            >
              <option>{"--SELECT STATUS--"}</option>
              <option value="active">{"ACTIVE"}</option>
              <option value="inactive">{"INACTIVE"}</option>
            </select>
          </div>
        )}
      </div>
    </form>
  );
};
